/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*@author Sanobar Golandaj
 *@fileName :
 *Desc:
 */

/*
@author:Raunak Thakkar
@Creation Date:01-08-2019
*Desc:The new UI for the review Leads with the Client Response tab 
*/

import React from "react";
// import { Tabs, Tab } from "react-bootstrap"; //Nilesh-4242-Removing console warnings
import Footer from "../layouts/footer";
// import * as $ from "jquery";  //Nilesh-4242-Removing console warnings
import PublisherNavigation from "../layouts/publisherNavPage";
// import { Navbar, Nav } from "react-bootstrap";
import { saveAs } from "file-saver";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { Table } from "antd";
import { css } from "emotion";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import "./publisherInternalReviewLead.css";
import { LeadReviewLoader } from "../loaders/LeadReviewLoader"; // Sandeep-task-3441-added loader
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //karan-task-3717-added default header

const Swal = require("sweetalert2");

// const queryString = require("query-string"); //Nilesh-4242-Removing console warnings
const xlsx = require("xlsx");
//shivani-task 3206 -added antd table css
const tableCSS = css({
  backgroundColor: "white",
  fontFamily: "sans-serif",
  borderStyle: "bold",
  "& thead  > tr": {
    backgroundImage: "linear-gradient(to right,#28166f,#007cc3)!important",
    color: "white",
    fontSize: "12px", // Sandeep-task-3441-added font-Size
    fontWeight: "500",
  },
  "& thead > tr >th": {
    // border: "1px solid black",
    height: "20%",
    color: "white",
  },
});
class PublisherInternalReviewLead extends React.Component {
  constructor() {
    super();
    this.state = {
      fields: {},
      key: 1,
      errors: {},
      reerrors: {},
      campID: "",
      agencyID: "",
      btnDisable: "disabled1",
      reviewLeadFlag: false,
      reviewlead: [],
      marketingChannelFlag: false,
      campaignLeadDetails: [],
      uploadLeadFile: [],
      clientDownloadLead: [],
      status: "Accepted",
      parentCampID: "",
      reallocationID: "",
      pID: "",
      campaignLevelSwitch: "",
      DI_QA_Switch: "",
      bypassAgencySwitch: "",
      diFlag: "",
      buttonFlag: false, //shivani-task 3089-used this flag to make submit button enable or disabled.
      pageSize: 100, //shivani-task-3206-declare variable for pagination records .
      campaignLeadDetailsSearch: [], //shivani-task-3206-added array for search functionality.
      tableSearchText: "", //shivani-task 3206-added this parameter for search functionality.
      leadReviewDashboard: [], // shivani-3206- added this array for campID and campName iteration from dashboard api .
      loading1: false, //shivani-3206- made this flag false for loader .
      loader2: "", //sandeep-task-3394-used for loader.
      rejReasonDisplay: "none",
      DIFlagDetails: [], //Priyanka-4602-Added array to store campaignwise DI QA switch details
      downloadFileLeadsLength: 0, //4788 - Initialize with the default value
    };

    // this.handleChange= this.handleChange.bind(this);
    this.submitreviewlead = this.submitreviewlead.bind(this);
    this.handleDownloadLeadDetails = this.handleDownloadLeadDetails.bind(this);
    this.handleFileShowDownload = this.handleFileShowDownload.bind(this);
    this.handleClientDownloadLeadDetails =
      this.handleClientDownloadLeadDetails.bind(this);
    this.handleUploadLeadFile = this.handleUploadLeadFile.bind(this);
    this.handleChangeFileUpload = this.handleChangeFileUpload.bind(this);
    this.handleChangeClientResponseFileUpload =
      this.handleChangeClientResponseFileUpload.bind(this);
    this.openLinkInBrowser = this.openLinkInBrowser.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleChangePageSize = this.handleChangePageSize.bind(this); //shivani-task 3206 - Bind handlechange for pagination .
    this.handleChangeSearch = this.handleChangeSearch.bind(this); //shivani-task 3206- bind this handleChangeSearch for search bar functionality .
    this.leadreviewDetails = this.leadreviewDetails.bind(this); //shivani-task 3206- bind this handlechange for campID as user will click on camp ID respective data will display .
    this.displayCampaignSpecification =
      this.displayCampaignSpecification.bind(this); //sunita-task-3727-added handle change for displaying campaign specification

    // User Story - 3441- Sandeep- - reference to control Loader
    this.loadingRef = React.createRef(); // shows the Loader
    this.closeLoadingRef = React.createRef(); // hides the Loader
    this.fun_getCampaignFromList = this.fun_getCampaignFromList.bind(this); //Chaitanya-4246-R28.9-Prod issue-Publisher Login-Lead review issue
    this.validateForm = this.validateForm.bind(this);
    this.fetchDownloadFileLength = this.fetchDownloadFileLength.bind(this);
  }

  /**
   * @author Sunita Landge - task-3727
   * @param  Description handle the campaignSpecification on agency review lead page
   * @return Description redirect to campaignSpecificationPublisherDashboard
   */

  displayCampaignSpecification(e) {
    e.preventDefault(); //Somnath Task-3955 Add method
    // var parsed = this.props.location.state; //Nilesh-4242-Removing console warnings
    var campID = this.state.campID; //sandeep-task-3095-added URL parameter campID
    var parentCampID = this.state.parentCampID; //sandeep-task-3095-added URL parameter parentCampID
    var reallocationID = this.state.reallocationID; //sandeep-task-3095-added URL parameter reallocationID

    sessionStorage.setItem("campID", campID);
    sessionStorage.setItem("parentCampID", parentCampID);
    sessionStorage.setItem("reallocationID", reallocationID);
    window.open("/campaignSpecificationPublisherDashboard");

    //this.props.history.push("/campaignSpecificationPublisherDashboard",{campID: campID,parentCampID:parentCampID,reallocationID:reallocationID}); //sonali-task-3716-replace query params
    //sonali-4020-added above line to remove params from url
    // window.location.href("#");

    // ); //sunita-task-3099-added changes to open link in new tab
  }
  /**
   * @author Shivani pathak - 3206
   * @param  Description for pagination
   * @return return as per page size record
   */
  handleChangePageSize(e) {
    var pageSize = e.target.value;
    this.setState({ pageSize: pageSize });
  }
  openLinkInBrowser(e) {
    e.preventDefault(); //Nilesh-4242-Removing console warnings
    var leadInfoID = e.target.id;
    fetch("publisher/getVoiceLogLink?leadInfoID=" + leadInfoID + "")
      .then((res) => res.json())
      .then((voiceLogDetails) => {
        this.setState({ voiceLogDetails: voiceLogDetails });
        window.open(voiceLogDetails[0].voiceLogLink, "_blank");
      })
      .catch(function (err) {
        console.log(err);
      });
  }
  handleSelect(key) {
    this.setState({
      key: key,
    });
  }
  /**
   * @author Shivani pathak 3206
   * @param  Description for searching campaign id and it's data.
   * @return return the searched data
   */

  handleChangeSearch(e) {
    e.preventDefault();
    let currentList = [];
    // Variable to hold the filtered list before putting into state
    let newList = [];
    let filterData = e.target.value;
    // If the search bar isn't empty
    if (e.target.value === "") {
      newList = this.state.leadReviewDashboard;
    } else {
      // Assign the original list to currentList
      currentList = this.state.leadReviewDashboard;
      newList = currentList.filter((item) => {
        // change current item to lowercase
        const lc = item;
        //const lc = item.toLowerCase();
        // change search term to lowercase
        const filter = e.target.value.toLowerCase();
        // check to see if the current list item includes the search term
        // If it does, it will be added to newList. Using lowercase eliminates
        // issues with capitalization in search terms and search content
        var data = Object.values(lc);
        var dataFinal = data.toString();
        dataFinal = dataFinal.replace(",", " ");
        var dataToLower = dataFinal.toLowerCase();
        return dataToLower.includes(filter);
      });
    }
    // Set the filtered state based on what our rules added to newList
    this.setState({
      campaignLeadDetailsSearch: [...newList],
      tableSearchText: filterData,
    });
  }

  /**
   * @author Shivani pathak 3206
   * @param  Description  new function created for showing lead's table data as per campID.
   *   */
  leadreviewDetails(e) {
    e.preventDefault(); //4102-adding preventDefault to remove # from the url
    let campID = e.target.id;
    const { user } = this.props.auth;
    var pID = user.id;
    // campID = this.state.campID;
    this.setState({ campID: campID, pID: pID });
    //Sonali-4102-removing pID from the route
    let data = {
      campID: campID,
      //pID: pID,
    };
    //shivani-task 3206 - api call display table data
    fetch("/publisherReviewLead/campaignLeadDetails", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((campaignLeadDetails) => {
        //shivani-task 3206- this condition used to display voice Log column .
        if (
          campaignLeadDetails[0].channel === "Telemarketing" ||
          campaignLeadDetails[0].channel === "Email/Telemarketing"
        ) {
          this.setState({ marketingChannelFlag: true });
        } else {
          this.setState({ marketingChannelFlag: false });
        }
        this.setState({ campaignLeadDetails: campaignLeadDetails }); //shivani-task 3206 - setState all the needed data .
        if (campaignLeadDetails.length > 0) {
          this.setState({ diFlag: campaignLeadDetails[0].diFlag });
          // this.state.btnDisable = "btn add-button"; //Nilesh-4242-Removing console warnings
          this.setState({
            btnDisable: "btn add-button",
          });
          this.forceUpdate();
        }
      })
      .catch(function (err) {
        console.log(err);
      });
    this.getCampaignInfo(campID);
    this.fun_Get_Lead_TableData(campID);
    var filesData1 = this.state.filesData1;
    if (
      filesData1 === undefined ||
      filesData1 === "undefined" ||
      filesData1 === "" ||
      filesData1 === null
    ) {
      var label = document.getElementById("fileName");
      label.innerHTML = "No File Choosen";
      // this.state.errors.successMessage = "";
      this.setState({
        //Nilesh-4242-Removing console warnings
        errors: {
          ...this.state.errors,
          successMessage: "",
          rejReasonDisplay: "none" //Rutuja 5502 reason display none after changing camp
        },
      });
      // this.state.reerrors.errorMessage = ""; //sunita-task-3318-added for removing the error which came for uploaded file.
      this.setState((prevState) => ({
        //Nilesh-4242-Removing console warnings
        reerrors: {
          ...prevState.reerrors,
          errorMessage: "",
          rejReasonDisplay: "none"  //Rutuja 5502 reason display none after changing camp
        },
      }));
    }
  }
  /**
   * @author Shivani pathak 3206
   * @param  Description  new function created for showing data as per clicked campid and called in leadreviewDetails .
   *   */
  getCampaignInfo(campID) {
    // const { user } = this.props.auth; //Nilesh-4242-Removing console warnings
    // var pID = user.id; //Nilesh-4242-Removing console warnings

    //Priyanka-4602-Fetching DI QA switch details
    fetch("/diQARole/getDIFlags?campID=" + campID)
      .then((res) => res.json())
      .then((DIFlagDetails) => {
        this.setState({
          DIFlagDetails: DIFlagDetails,
        });
      })
      .catch(function (err) {
        console.log(err);
      });

    //Sonali-4102-removing pID from the route
    //shivani-task 3206- below api and conditions is for telemarketing campaign , voice log column in table
    fetch("/publisherReviewLead/publisherCommercialReviewLead?campID=" + campID)
      .then((res) => res.json())
      .then((reviewlead) => {
        if (
          (reviewlead[0].marketingChannel === "TeleMarketing" &&
            reviewlead[0].callAudit === "yes") ||
          (reviewlead[0].marketingChannel === "Email/Telemarketing" &&
            reviewlead[0].callAudit === "Yes") //Nilesh-4242-Removing console warnings
        ) {
          this.setState({ marketingChannelFlag: true });
        }
        document.getElementById("abm1").value = "";
        document.getElementById("fileName").value = "";

        document.getElementsByClassName("successMessage").value = "";
        document.getElementsByClassName("errorMessage").value = "";
        this.setState({
          reviewlead: reviewlead,
          campID: campID,
          agencyID: reviewlead[0].agencyID,
          buttonFlag: false,
          parentCampID: reviewlead[0].parentCampID,
        });
        // shivani-task 3206 - setStated all the required data .
      })
      .catch(function (err) {
        console.log(err);
      });
  }
  /**
   * @author Shivani pathak 3206
   * @param  Description  new function created for showing data in table as per clicked campid and called in leadreviewDetails .
   *   */
  fun_Get_Lead_TableData(campID) {
    this.setState({ loading1: true });
    // const { user } = this.props.auth; //Nilesh-4242-Removing console warnings
    // var pID = user.id;  //Nilesh-4242-Removing console warnings
    //Sonali-4102-removing pID from the route
    let data = {
      campID: campID,
      //pID: pID,
    };
    //s hivani-task 3206 - this api call is to display data in table .
    fetch("/publisherReviewLead/campaignLeadDetails", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((campaignLeadDetails) => {
        //shivani-task 3206  - this condition is added for voice log column in table .
        if (
          campaignLeadDetails[0].channel === "Telemarketing" ||
          campaignLeadDetails[0].channel === "Email/Telemarketing"
        ) {
          this.setState({ marketingChannelFlag: true });
        }
        this.setState({
          campaignLeadDetails: campaignLeadDetails,
          loading1: false,
          reviewLeadFlag: false,
           rejReasonDisplay: "none"  //Rutuja 5502 reason display none after changing camp
        });
        //shivani-task 3206-SetStated all the needed data .
        if (campaignLeadDetails.length > 0) {
          this.setState({ diFlag: campaignLeadDetails[0].diFlag });
          // this.state.btnDisable = "btn add-button";
          this.setState({
            //Nilesh-4242-Removing console warnings
            btnDisable: "btn add-button",
          });
          this.forceUpdate();
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  }
  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      // var parsed = queryString.parse(this.props.location.search);

      const { user } = this.props.auth;
      var pID = user.id;

      //Sanjana Godbole-3728-VAPT--publisher side--Home--URL links And Routes-FE
      if (this.props.location.state !== undefined) {
        var parsed = this.props.location.state;
        var campID = parsed.campID;
        var parentCampID = parsed.parentCampID;
        var reallocationID = parsed.reallocationID;

        this.setState({
          parentCampID,
          reallocationID,
          pID: pID,
          campID,
          loader2: "loader_review",
        });

        this.fun_getCampaignFromList(campID); //Chaitanya-4246-R28.9-Prod issue-Publisher Login-Lead review issue
        //Sandeep-task-3394-added code for loader issue
        //This API is for checking DI flags
        //this.loadingRef.current.click();//Sandeep-task-3441-added loader
        //Sonali-4102-removing pID from the route
        fetch("/diQARole/getDIFlags?campID=" + campID)
          .then((res) => res.json())
          .then((DIFlagDetails) => {
            this.setState({
              DI_QA_Switch: DIFlagDetails[0].DI_QA_Switch,
              bypassAgencySwitch: DIFlagDetails[0].bypassAgencySwitch,
              campaignLevelSwitch: DIFlagDetails[0].campaignLevelSwitch,
              DIFlagDetails: DIFlagDetails, //Priyanka-4602
            });
          })
          .catch(function (err) {
            console.log(err);
          });

        //This API is for campaign details from campaign table
        //Sonali-4102-removing pID from the route
        fetch(
          "/publisherReviewLead/publisherCommercialReviewLead?campID=" + campID
        )
          .then((res) => res.json())
          .then((reviewlead) => {
            if (
              (reviewlead[0].marketingChannel === "TeleMarketing" &&
                reviewlead[0].callAudit === "yes") ||
              (reviewlead[0].marketingChannel === "Email/Telemarketing" &&
                reviewlead[0].callAudit === "Yes") //Nilesh-4242-Removing console warnings
            ) {
              this.setState({ marketingChannelFlag: true });
            }
            this.setState({
              reviewlead: reviewlead,
              campID: campID,
              agencyID: reviewlead[0].agencyID,
            });
          })
          .catch(function (err) {
            console.log(err);
          });

        /**
         * @author Narendra Phadke
         * @param  Description fetch all campaign lead
         * @return Description return leads
         */
        //Sonali-4102-removing pID from the route
        let data = {
          campID: campID,
          //pID: pID,
        };

        fetch("/publisherReviewLead/campaignLeadDetails", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((campaignLeadDetails) => {
            // this.closeLoadingRef.current.click();//Sandeep-task-3441-added loader
            this.setState({
              campaignLeadDetails: campaignLeadDetails,
              loader2: "",
            });
            if (campaignLeadDetails.length > 0) {
              this.setState({ diFlag: campaignLeadDetails[0].diFlag });
              //  this.setstate({btnDisable:"button"})
              // this.state.btnDisable = "btn add-button";
              this.setState({
                //Nilesh-4242-Removing console warnings
                btnDisable: "btn add-button",
              });
              this.forceUpdate();
            }
          })
          .catch(function (err) {
            console.log(err);
          });
        //shivani-3206 - added this api for lead data when user click on id from dashboard and nevigate to lead review page .
        pID = user.id; //Nilesh-4242-Removing console warnings
        fetch("publisherDashboard/leadReviewDashboard")
          .then((res) => res.json())
          .then((leadReviewDashboard) => {
            this.setState({
              leadReviewDashboard: leadReviewDashboard,
            }); //shivani-task 3206 - setStated all the data .
          });
      }
    }
  }

  /**
   * @author Chaitanya Shinde
   * @param  Description handle the refresh lead review page under task 4246
   * @return Campaign Details and Leads are displaying whose name in top in the left side list
   */
  fun_getCampaignFromList(campID) {
    // const { user } = this.props.auth; //Nilesh-4242-Removing console warnings
    // const { id: agencyID, role: userRole } = user; //Nilesh-4242-Removing console warnings
    fetch("publisherDashboard/leadReviewDashboard")
      .then((res) => res.json())
      .then((leadReviewDashboard) => {
        var campArray = leadReviewDashboard.filter((a) => {
          return a.campID === campID; //Nilesh-4242-Removing console warnings
        });
        if (campArray.length > 0) {
        } else {
          campID = leadReviewDashboard[0].campID;
        }
        this.setState({
          campID,
          leadReviewDashboard,
          loading: false,
          loader2: "",
        });
        this.getCampaignInfo(campID);
        this.fun_Get_Lead_TableData(campID);
      })
      .catch(function (err) {});
  }
  /* @author Narendra Phadke
   * @param  Description hanlde change status of the lead details
   * @return Description return accepted or rejected lead
   */
  handleChange(absoluteIndex, event) {
    event.preventDefault();
    // let formIsvalid = true; //Nilesh-4242-Removing console warnings
    const { name, value } = event.target;
    const updatedDetails = [...this.state.campaignLeadDetails];//Nilesh-5486- Update the campaignLeadDetails array
    //Nilesh-5486- Update the specific record
    updatedDetails[absoluteIndex] = { ...updatedDetails[absoluteIndex], [name]: value };
    if (value !== "Rejected" || value !== "PVRejected" || value !== "publisherQARejected") {
      updatedDetails[absoluteIndex].reason = ""; //Nilesh-5486- Clear the reason field
    }
    //Nilesh-5486-Update the state with modified details
    this.setState({ campaignLeadDetails: updatedDetails });
    //Nilesh-4242-Removing console warnings
    if (
      event.target.value === "PVRejected" ||
      event.target.value === "publisherQARejected"
    ) {
      this.setState({ rejReasonDisplay: "block" }); //kiran-4384-added for displaying validation
    } else {
      if (event.target.value === "QA Review") {
        //Nilesh-4242-Removing console warnings
        this.setState({ rejReasonDisplay: "none" });
        // document.getElementById("reason").style.border = "1px solid grey";
      }
      this.setState({ rejReasonDisplay: "none" });
      // document.getElementById("reason").style.border = "1px solid grey";
    }
  }

  //Nilesh-5486- Create a new function for reason 
  handleReasonChange = (absoluteIndex, event) => {
    const { name, value } = event.target; 
    const updatedDetails = [...this.state.campaignLeadDetails];
  
    //Nilesh-5486- Update the specific record's field using the absolute index
    updatedDetails[absoluteIndex][name] = value;
  
    //Nilesh-5486- Update the state with the modified data source
    this.setState({ campaignLeadDetails: updatedDetails });
  };


  onTableChange = (pagination, filters, sorter) => {
    //Nilesh-4562- Update the current page in the state
    this.setState({
      tableState: {
        ...this.state.tableState,
        current: pagination.current, //Nilesh-4562- Update the current page
      },
    });
  };

  /* @author Narendra Phadke
   * @param  Description Download the lead details
   * @return Description return excel file for download
   */
  handleDownloadLeadDetails(e) {
    e.preventDefault();
    let errors = {};
    // var parsed = queryString.parse(this.props.location.search);
    var campID = this.state.campID;
    var parentCampID = this.state.parentCampID;
    // var reallocationID = this.state.reallocationID; //Nilesh-4242-Removing console warnings

    let leadReviewDashboard = this.state.leadReviewDashboard; //4587-Nilesh File Naming convention change
    let campDetails = leadReviewDashboard.filter((el) => {
      return Number(el.campID) === Number(campID); //Chaitanya-5041-Added datatype Number to campId
    });
    //Sonali-4102-remove pID from the below data
    fetch("publisherReviewLead/downloadLeadDetails?campID=" + campID)
      .then((res) => res.json())
      .then((downloadFiles) => {
        this.setState({ downloadFiles: downloadFiles });
        if (downloadFiles.success === true) {
          //errors['failureMsg']="No Data Exists"
          Swal.fire({
            type: "warning",

            text: "No Data Exists!",
          });
          this.setState({ errors: errors });
        } else {
          //       /* make the worksheet */
          var ws = xlsx.utils.json_to_sheet(downloadFiles);
          /* add to workbook */
          var wb = xlsx.utils.book_new();
          xlsx.utils.book_append_sheet(wb, ws, "Lead");

          // /* write workbook */
          var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
          // var str = xlsx.write(wb, { bookType: "xlsx", type: "binary" }); //Nilesh-4242-Removing console warnings
          /* generate a download */
          function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (
              var i = 0;
              i !== s.length;
              ++i //Nilesh-4242-Removing console warnings
            )
              view[i] = s.charCodeAt(i) & 0xff;
            return buf;
          }
          // var fileName = parentCampID + "_Publisher Internal Review" + ".xlsx";

          var fileName =
            "Publisher_Internal_Review_" +
            campDetails[0].clientCampID +
            "_" +
            parentCampID +
            ".xlsx"; //4587-Nilesh File Naming convention change
          saveAs(
            new Blob([s2ab(buf)], { type: "application/octet-stream" }),
            fileName
          );
        }
      });
  }

  /* @author Narendra Phadke
   * @param  Description Download the lead details download only agency accepted for send to client
   * @return Description return excel file for download
   */
  handleClientDownloadLeadDetails(e) {
    e.preventDefault();
    let errors = {};
    var parsed = this.props.location.state;
    var campID = parsed.campID;
    var parentCampID = parsed.parentCampID;
    var reallocationID = parsed.reallocationID;
    //Nilesh-4242-Removing console warnings
    // let data = {
    //   campID: campID,
    //   leadDetails: this.state.campaignLeadDetails,
    // };
    fetch("lead/clientDownloadLeadDetails?campID=" + campID + "")
      .then((res) => res.json())
      .then((clientDownloadLead) => {
        this.setState({ clientDownloadLead: clientDownloadLead });

        if (clientDownloadLead.success === true) {
          errors["failureMsg2"] = "No Data Exists";
          this.setState({ errors: errors });
        } else {
          //       /* make the worksheet */
          var ws = xlsx.utils.json_to_sheet(clientDownloadLead);
          /* add to workbook */
          var wb = xlsx.utils.book_new();
          xlsx.utils.book_append_sheet(wb, ws, "Lead");

          // /* write workbook */
          var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
          // var str = xlsx.write(wb, { bookType: "xlsx", type: "binary" }); //Nilesh-4242-Removing console warnings
          /* generate a download */
          function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (
              var i = 0;
              i !== s.length;
              ++i //Nilesh-4242-Removing console warnings
            )
              view[i] = s.charCodeAt(i) & 0xff;
            return buf;
          }
          var fileName =
            parentCampID + "-" + reallocationID + "Agency Accepted.xlsx";
          saveAs(
            new Blob([s2ab(buf)], { type: "application/octet-stream" }),
            fileName
          );
        }
      });
  }

  /* @author Narendra Phadke
   * @param  Description Download the lead details
   * @return Description return excel file for download
   */
  handleFileShowDownload(blob) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    let excelFileDownloadName = "ABC.xlsx";
    console.warn("Blob Size in function===>" + JSON.stringify(blob));
    //console.warn("Blob Size===>"+JSON.stringify(this.state.downloadFiles));
    var newBlob = new Blob([this.state.downloadFiles], {
      type: "application/zip",
    });
    // console.warn("New Blob Data===>"+JSON.stringify(newBlob));
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    link.href = data;
    link.download = excelFileDownloadName;
    link.click();
  }

  /**
   * @author Narendra Phadke
   * @param  Description handle the upload lead
   * @return Description return All details of lead
   */
  // showfilename()
  // {

  // }

  async handleChangeFileUpload(e) {
    e.preventDefault();

    let errors = {};
    let reerrors = {};
    //("ABM handle Changed");
    // var parsed = this.props.location.state; //Nilesh-4242-Removing console warnings
    // var campID = parsed.campID; //Nilesh-4242-Removing console warnings
    // var names = [];//Nilesh-4242-Removing console warnings

    // let filesData = e.target.files; //Nilesh-4242-Removing console warnings
    let filesData1 = e.target.files[0];
    var ext;

    await this.fetchDownloadFileLength(); //Chaitanya-4788-fuction call for validation of wrong leads count

    console.warn("data files", filesData1);
    if (filesData1 == null) {
      ext = "";
      var label = document.getElementById("fileName");
      label.innerHTML = "No File Choosen";
    } else {
      ext = filesData1.name.split(".").pop();
      label = document.getElementById("fileName"); //Nilesh-4242-Removing console warnings
      label.innerHTML = filesData1.name;
    }
    let message = "";
    //Rutuja 5048 set diflag on(yes) and off(NO) according to the below switch
    if (
      this.state.DIFlagDetails[0].DI_QA_Switch === "ON" &&
      (this.state.DIFlagDetails[0].bypassAgencySwitch === "OFF" ||
        this.state.DIFlagDetails[0].bypassAgencySwitch === "ON") &&
      this.state.DIFlagDetails[0].campaignLevelSwitch === "ON"
    ) {
      this.state.diFlag = "Yes";
    } else {
      this.state.diFlag = "No";
    }
    // const { isAuthenticated, user } = this.props.auth; //Nilesh-4242-Removing console warnings
    var data = new FormData();
    if (ext === "xlsx") {
      //Nilesh-4242-Removing console warnings
      //Sonali-4102-removing user related information from below data
      data.append("campID", this.state.campID);
      data.append("agencyID", this.state.agencyID);
      data.append("message", message);
      data.append("file", filesData1);
      //data.append("userID", userID);
      //data.append("user", JSON.stringify(user));
      //data.append("userName", userName);
      //data.append("firstName", firstName);
      //data.append("lastName", lastName);
      data.append("diFlag", this.state.diFlag);
      data.append(
        "downloadFileLeadsLength",
        this.state.downloadFileLeadsLength
      );
      fetch("/publisherReviewLead/uploadReviewLead", {
        method: "POST",
        //mode:'no-cors',
        body: data,
      })
        .then((res) => res.json())

        .then((campaignLeadDetails) => {
          //Chaitanya-4788-Alert for validation of wrong leads count upload in file
          if (true === campaignLeadDetails.success) {
            //Nilesh-4242-Removing console warnings
            Swal.fire({
              text: "Leads count in uploaded file is greater than total leads count",
              type: "error",
              allowOutsideClick: false,
              heightAuto: false,
              preConfirm: () => {
                window.location.reload();
              },
            });
            return;
          }

          if (campaignLeadDetails.length > 0) {
            //4612-Nilesh-File validation for wrong status upload in file
            this.setState({
              campaignLeadDetails: campaignLeadDetails,
              reviewLeadFlag: true,
              buttonFlag: true,
            }); //shivani-task 3089-added buttonFlag:true to make button disabled if i upload leads through excel file.

            errors["successMessage"] = <li>File Uploaded Sucessfully</li>;

            reerrors["errorMessage"] = "";
            this.setState({ errors: errors, reerrors: reerrors });
            //Nilesh-4242-Removing console warnings
          } else if (false === campaignLeadDetails.success) {
            //Nilesh-4770-validation for wrong camp ID upload in file
            Swal.fire({
              text: campaignLeadDetails.message,
              type: "error",
              allowOutsideClick: false,
              heightAuto: false,
              preConfirm: () => {
                window.location.reload();
              },
            });
          } else {
            Swal.fire({
              text: "Please enter valid status against leads as per the given instructions on the screen", //4612-Nilesh-File validation for wrong status upload in file
              type: "error",
              allowOutsideClick: false,
              heightAuto: false,
              preConfirm: () => {
                window.location.reload();
              },
            });
          }
        })
        .catch(function (err) {
          console.log(err);
        });
      // window.location.reload()
      // errors["successMessage"] = <li>File Uploaded Sucessfully</li>;

      reerrors["errorMessage"] = "";
      this.setState({ errors: errors, reerrors: reerrors });
    } else {
      errors["successMessage"] = "";
      reerrors["errorMessage"] = (
        <li>File Invalid Please Upload File with .xlsx format</li>
      );
      this.setState({ errors: errors, reerrors: reerrors });
    }
  }

  /**
   * @author Narendra Phadke
   * @param  Description handle the upload lead
   * @return Description return All details of lead
   */
  handleUploadLeadFile(e) {
    e.preventDefault();
    // const { isAuthenticated, user } = this.props.auth; //Nilesh-4242-Removing console warnings
    const { user } = this.props.auth;
    var userID = user.id;
    var userName = user.name;
    var firstName = user.firstName;
    var lastName = user.lastName;
    var data = new FormData();
    data.append("campID", this.state.campID);
    data.append("file", this.state.uploadLeadFile);
    data.append("userID", userID);
    data.append("userName", userName);
    data.append("firstName", firstName);
    data.append("lastName", lastName);
    fetch("/lead/uploadReviewLead", {
      method: "POST",
      //mode:'no-cors',
      body: data,
    })
      .then(function (response) {
        if (response.status >= 400) {
          throw new Error("Bad response from server");
        }
        return response.json();
      })
      .then(function (response) {
        console.log("Return with response");
        //var campID=response.body.campID;
        console.log(response);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  /**
   * @author Narendra Phadke
   * @param  Description handle the View lead
   * @return Description return All details of lead
   */
  handleViewReviewLead(e) {
    e.preventDefault();
    var parsed = this.props.location.state;
    var campID = parsed.campID;
    // const { isAuthenticated, user } = this.props.auth; //Nilesh-4242-Removing console warnings
    const { user } = this.props.auth;
    let data = {
      campID: campID,
      campaignLeadDetails: this.state.campaignLeadDetails,
      user: user,
    };
    fetch("/lead/displayLeadReview", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((campaignLeadDetails) =>
        this.setState({
          campaignLeadDetails: campaignLeadDetails,
          reviewLeadFlag: true,
        })
      )
      .catch(function (err) {
        console.log(err);
      });
  }

  /**
   * @author Narendra Phadke
   * @param  Description handle the upload client response lead
   * @return Description return All details of lead
   */
  handleChangeClientResponseFileUpload(e) {
    e.preventDefault();
    // const { isAuthenticated, user } = this.props.auth; //Nilesh-4242-Removing console warnings
    const { user } = this.props.auth;
    var parsed = this.props.location.state;
    var campID = parsed.campID;
    var reAllocationID = parsed.reallocationID;
    var userID = user.id;
    var userName = user.name;
    var firstName = user.firstName;
    var lastName = user.lastName;
    let filesData1 = e.target.files[0];
    var data = new FormData();

    data.append("campID", campID);
    data.append("reAllocationID", reAllocationID);
    data.append("file", filesData1);
    data.append("userID", userID);
    data.append("userName", userName);
    data.append("firstName", firstName);
    data.append("lastName", lastName);
    fetch("/lead/uploadClientReviewLead", {
      method: "POST",
      //mode:'no-cors',
      body: data,
    })
      .then(function (response) {
        if (response.status >= 400) {
          throw new Error("Bad response from server");
        }
        return response.json();
      })
      .then(function (response) {
        console.log("Return with response");

        console.log(response);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  /**
   * @author Narendra Phadke
   * @param  Description handle the upload lead from submit button on portal
   * @return Description return All details of leads
   */

  // kiran-4423-added validation for publisherqarejected and pv rejected
  // validateForm() {
  //   let formIsvalid = true;
  //   for (let i = 0; i < this.state.campaignLeadDetails.length; i++) {
  //     if (
  //       this.state.campaignLeadDetails[i].status === "publisherQARejected" &&
  //       this.state.campaignLeadDetails[i].reason === undefined
  //     ) {
  //       formIsvalid = false;
  //       this.setState({ rejReasonDisplay: "block" });
  //     } else if (
  //       this.state.campaignLeadDetails[i].status === "PVRejected" &&
  //       this.state.campaignLeadDetails[i].reason === undefined
  //     ) {
  //       formIsvalid = false;
  //       this.setState({ rejReasonDisplay: "block" });
  //     }
  //   }
  //   return formIsvalid;
  // }

  //Nilesh-5486- Modified the below function
  validateForm() {
    let formIsValid = true;
  
    for (let i = 0; i < this.state.campaignLeadDetails.length; i++) {
      const { status, reason } = this.state.campaignLeadDetails[i];
  
      if (
        (status === "publisherQARejected" || status === "PVRejected") &&
        (!reason || reason.trim() === "")
      ) {
        formIsValid = false;
        this.setState({ rejReasonDisplay: "block" });
        break; //Nilesh-5486- Exit the loop as soon as we find an invalid record
      }
    }
  
    return formIsValid;
  }
  
  

  // kiran-4384-added pvrejected validation
  submitreviewlead(e) {
    e.preventDefault();
    if (this.validateForm() === false) {
      this.setState({
        formIsvalid: false,
      });
    } else {
      // var parsed = this.props.location.state; //Nilesh-4242-Removing console warnings
      // var campID = parsed.campID;
      var campID = this.state.campID;
      // const { isAuthenticated, user } = this.props.auth; //Nilesh-4242-Removing console warnings
      // let errors = {}; //Nilesh-4242-Removing console warnings
      //Sonali-4102-removing user from the below data
      delete this.state.campaignLeadDetails[0]["pID"];
      let data = {
        campID: campID,
        campaignLeadDetails: this.state.campaignLeadDetails,
        //user: user,
        agencyID: this.state.agencyID,
      };

      this.loadingRef.current.click(); //Sandeep-task-3441-added loader
      fetch("/publisherReviewLead/uploadReviewLeadSubmit", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((campaignLeadDetails) => {
          this.setState({
            campaignLeadDetails: campaignLeadDetails,
            reviewLeadFlag: true,
          });
          this.closeLoadingRef.current.click(); //Sandeep-task-3441-added loader
          if (campaignLeadDetails.length > 0) {
            Swal.fire({
              text: "Lead reviewed successfully",
              type: "success",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              preConfirm: () => {
                window.location.reload();
              },
            });

            this.setState({
              // errors:errors,
              btnDisable: "disabled1",
            });
          } else {
            this.setState({
              btnDisable: "btn add-button",
            });
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }

  //Chaitanya-4788-fuction for validation of wrong leads count upload in file
  async fetchDownloadFileLength() {
    const campID = this.state.campID;

    try {
      const response = await fetch(
        "publisherReviewLead/downloadLeadDetails?campID=" + campID
      );
      const downloadFiles1 = await response.json();

      var downloadFileLeadsLength = downloadFiles1.length;
      this.setState({ downloadFileLeadsLength: downloadFileLeadsLength });
    } catch (error) {
      console.error("Error fetching download files:", error);
    }
  }

  /**
   * @author Shivani pathak- 3206
   * @param  Description  Complete render part done by me as per given screen, Redesigned complete screen ...
   */
  render() {
    // const { isAuthenticated, user } = this.props.auth; //Nilesh-4242-Removing console warnings
    //shivani-task 3206 -added column to render data for table .

    // let locale = { //Nilesh-4242-Removing console warnings
    //   emptyText: " ",
    // };
    const column1 = [
      {
        title: "Lead ID",
        dataIndex: "leadInfoID",
        key: "leadInfoID",
        width: "50%",
        align: "left", //Sandeep-task-3441-algin table data to left
        render: (text, record) => <div>{record.leadInfoID}</div>,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: "50%",
        align: "left", //Sandeep-task-3441-algin table data to left
        render: (text, record) => <div>{record.email}</div>,
      },
      {
        title: "Job Title", //Sandeep-task-3341
        dataIndex: "jobTitle",
        key: "jobTitle",
        width: "50%",
        align: "left", //Sandeep-task-3441-algin table data to left
        render: (text, record) => <div>{record.jobTitle}</div>,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: "50%",
        align: "left", //Sandeep-task-3441-algin table data to left
        render: (text, record, index) =>{
          const { reviewLeadFlag, DIFlagDetails } = this.state;
          const { user } = this.props.auth;
          const userRole = user.role;
          const pageSize = this.state.pageSize || 10; // Default to 10 rows per page
          const currentPage = this.state.tableState?.current || 1;
          const absoluteIndex = (currentPage - 1) * pageSize + index; // Calculate absolute index
          //Nilesh-5486- Function to render a dropdown
          const renderDropdown = (options) => (
            <select
              style={{ height: "60%" }}
              id="status"
              name="status"
              value={record.status}
              onChange={(e) => this.handleChange(absoluteIndex, e)}
              className="form-control"
            >
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          );

          //Nilesh-5486- Read flags from state
          const { DI_QA_Switch, bypassAgencySwitch, campaignLevelSwitch } = DIFlagDetails[0] || {};

          if (reviewLeadFlag) {
            return <label>{record.status}</label>; //Nilesh-5486- Display status as a label if reviewLeadFlag is true
          }

          if (DI_QA_Switch === "ON" && bypassAgencySwitch === "ON" && campaignLevelSwitch === "ON") {
            return renderDropdown([
              { value: "DI QA Review", label: "DI QA Review" },
              ...(userRole === "PQA"
                ? [{ value: "publisherQARejected", label: "publisherQARejected" }]
                : [{ value: "Rejected", label: "Rejected" }]),
            ]);
          }

          if (DI_QA_Switch === "ON" && bypassAgencySwitch === "OFF" && campaignLevelSwitch === "ON") {
            return renderDropdown([
              { value: "DI QA Review", label: "DI QA Review" },
              ...(userRole === "PQA"
                ? [{ value: "publisherQARejected", label: "publisherQARejected" }]
                : [{ value: "Rejected", label: "Rejected" }]),
            ]);
          }

          return renderDropdown([
            { value: "QA Review", label: "QA Review" },
            ...(userRole === "PQA"
              ? [
                  { value: "publisherQARejected", label: "publisherQARejected" },
                  { value: "PVRejected", label: "PV Rejected" },
                ]
              : [{ value: "Rejected", label: "Rejected" }]),
          ]);
        }
      },
      // kiran-4384-added pvrejected
      {
        title: "Reason For Rejection", // Sandeep-task-3341
        dataIndex: "reason",
        key: "reason",
        width: "50%",
        align: "left", // Sandeep-task-3441-align table data to left
        render: (text, record, i) => {
          const pageSize = this.state.pageSize;
          const currentPage = this.state.tableState?.current || 1;
          const absoluteIndex = (currentPage - 1) * pageSize + i; // Calculate absolute index for proper binding
          return (
            <div style={{ color: "red" }}>
              {this.state.reviewLeadFlag ? (
                <td>
                  <input
                    type="textbox"
                    id={`reason-${absoluteIndex}`} //Nilesh-5486- Unique ID for input
                    name="reason"
                    value={record.reason || ""} //Nilesh-5486- Bind the input to the `reason` field
                    disabled={!(record.status === "Rejected" || record.status === "PV Rejected" || record.status === "publisherQARejected")} // Enable only for specific statuses
                    onChange={(e) => this.handleReasonChange(absoluteIndex, e)} // Call handler on change
                    className="form-control"
                  />
                </td>
              ) : (
                <td>
                  <input
                    type="textbox"
                    id={`reason-${absoluteIndex}`} //Nilesh-5486-Unique ID for input
                    name="reason"
                    value={record.reason || ""} //Nilesh-5486-Bind the input to the `reason` field
                    disabled={!(record.status === "Rejected" || record.status === "PVRejected" || record.status === "publisherQARejected")} // Enable only for specific statuses
                    onChange={(e) => this.handleReasonChange(absoluteIndex, e)} // Call handler on change
                    className="form-control"
                  />
                </td>
              )}
            </div>
          );
        }
      },
    ];

    //shivani-task 3206 -added column to render data for table with voiceLog column.
    const column2 = [
      {
        title: "Lead ID",
        dataIndex: "leadInfoID",
        key: "leadInfoID",
        width: "50%",
        align: "left", //Sandeep-task-3441-algin table data to left
        render: (text, record) => <div>{record.leadInfoID}</div>,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: "65%",
        align: "left", //Sandeep-task-3441-algin table data to left
        render: (text, record) => <div>{record.email}</div>,
      },
      {
        title: "Voice Log Link",
        dataIndex: "voiceLogLink",
        key: "voiceLogLink",
        width: "50%",
        align: "left", //Sandeep-task-3441-algin table data to left

        render: (text, record) => (
          <div>
            <span style={{ fontSize: "13px" }}>
              {/* //shivani-task 3340 added color for link as per condition, for teleMarketing link will be in blue color and "No Link Available" will be in black. */}

              {this.state.marketingChannelFlag === true ? (
                record.channel === "Telemarketing" ? (
                  <td style={{ color: "#1890FF" }}>
                    <a
                      href="#/" //Nilesh-4242-Removing console warnings
                      onClick={this.openLinkInBrowser}
                      id={record.leadInfoID}
                    >
                      Link
                    </a>
                  </td>
                ) : (
                  <td>No Link Available</td>
                )
              ) : (
                ""
              )}
            </span>
          </div>
        ),
      },
      {
        title: "Job Title", //Sandeep-task-3341
        dataIndex: "jobTitle",
        key: "jobTitle",
        width: "50%",
        align: "left", //Sandeep-task-3441-algin table data to left
        render: (text, record) => <div>{record.jobTitle}</div>,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: "50%",
        align: "left", //Sandeep-task-3441-algin table data to left
        render: (text, record, index) =>{
          const { reviewLeadFlag, DIFlagDetails } = this.state;
          const { user } = this.props.auth;
          const userRole = user.role;
          const pageSize = this.state.pageSize || 10; // Default to 10 rows per page
          const currentPage = this.state.tableState?.current || 1;
          const absoluteIndex = (currentPage - 1) * pageSize + index; // Calculate absolute index
          //Nilesh-5486-Function to render a dropdown
          const renderDropdown = (options) => (
            <select
              style={{ height: "60%" }}
              id="status"
              name="status"
              value={record.status}
              onChange={(e) => this.handleChange(absoluteIndex, e)}
              className="form-control"
            >
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          );

          //Nilesh-5486-Read flags from state
          const { DI_QA_Switch, bypassAgencySwitch, campaignLevelSwitch } = DIFlagDetails[0] || {};

          if (reviewLeadFlag) {
            return <label>{record.status}</label>; //Nilesh-5486- Display status as a label if reviewLeadFlag is true
          }

          if (DI_QA_Switch === "ON" && bypassAgencySwitch === "ON" && campaignLevelSwitch === "ON") {
            return renderDropdown([
              { value: "DI QA Review", label: "DI QA Review" },
              ...(userRole === "PQA"
                ? [{ value: "publisherQARejected", label: "publisherQARejected" }]
                : [{ value: "Rejected", label: "Rejected" }]),
            ]);
          }

          if (DI_QA_Switch === "ON" && bypassAgencySwitch === "OFF" && campaignLevelSwitch === "ON") {
            return renderDropdown([
              { value: "DI QA Review", label: "DI QA Review" },
              ...(userRole === "PQA"
                ? [{ value: "publisherQARejected", label: "publisherQARejected" }]
                : [{ value: "Rejected", label: "Rejected" }]),
            ]);
          }

          return renderDropdown([
            { value: "QA Review", label: "QA Review" },
            ...(userRole === "PQA"
              ? [
                  { value: "publisherQARejected", label: "publisherQARejected" },
                  { value: "PVRejected", label: "PV Rejected" },
                ]
              : [{ value: "Rejected", label: "Rejected" }]),
          ]);
        }
      },
      {
        title: "Reason For Rejection", //Sandeep-task-3341
        dataIndex: "reason",
        key: "reason",
        width: "50%",
        align: "left", //Sandeep-task-3441-algin table data to left
        render: (text, record, i) => {
          const pageSize = this.state.pageSize;
          const currentPage = this.state.tableState?.current || 1;
          const absoluteIndex = (currentPage - 1) * pageSize + i; // Calculate absolute index for proper binding
          return (
            <div style={{ color: "red" }}>
              {this.state.reviewLeadFlag ? (
                <td>
                  <input
                    type="textbox"
                    id={`reason-${absoluteIndex}`} //Nilesh-5486-Unique ID for input
                    name="reason"
                    value={record.reason || ""} //Nilesh-5486-Bind the input to the `reason` field
                    disabled={!(record.status === "Rejected" || record.status === "PVRejected" || record.status === "publisherQARejected")} // Enable only for specific statuses
                    onChange={(e) => this.handleReasonChange(absoluteIndex, e)} // Call handler on change
                    className="form-control"
                  />
                </td>
              ) : (
                <td>
                  <input
                    type="textbox"
                    id={`reason-${absoluteIndex}`}
                    name="reason"
                    value={record.reason || ""}
                    disabled={!(record.status === "Rejected" || record.status === "PVRejected" || record.status === "publisherQARejected")} // Enable only for specific statuses
                    onChange={(e) => this.handleReasonChange(absoluteIndex, e)} //Nilesh-5486- Call handler on change
                    className="form-control"
                  />
                </td>
              )}
            </div>
          );
        }
      },
    ];

    console.warn(
      "Download file Data=" + JSON.stringify(this.state.downloadFiles)
    );
    return (
      <div>
        <LeadReviewLoader
          loadingRef={this.loadingRef}
          closeLoadingRef={this.closeLoadingRef}
          message="Please wait. Saving the data...."
        />
        {/* Sandeep-task3410-added loader */}
        <PublisherNavigation />
        <div class="container-fluid" style={{ paddingTop: "85px" }}>
          {/* <Tabs activeKey={this.state.key} onSelect={this.handleSelect}>
              <Tab eventKey={1} title="Internal Lead Review">
                <div class="card card-signin my-1">
                  <div class="card-body" > */}
          <div
            class="row"
            style={{ paddingTop: "20px", marginBottom: "-22px" }}
          >
            {/* <div class="col-md-2"> */}
            <div class="col-md-1">
              {" "}
              {/*sunita-task-3727-added css as per requirement*/}
              {/* IoIosArrowDropleftCircle */}
              <a
                //Nilesh-4242-Removing console warnings
                href="#/"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push("/newPublisherDashboard", {
                    pID: this.state.pID,
                  });
                }} //Sanjana-task-3728-replace query params
                style={{ color: "#056eb8" }}
              >
                <FaArrowAltCircleLeft
                  size={32}
                  style={{ float: "left" }}
                  title="Back to Dashboard"
                />
              </a>
            </div>
            <div class="col-md-8 reviewLead1">
              {" "}
              {/*sunita-task-3727-added column as per requirement*/}
              <center>
                <label
                  id="labelDI"
                  class="PubReviewLead"
                  style={{ marginLeft: "135px" }}
                >
                  {" "}
                  {/*Chaitanya-4246-R28.9-Prod issue-Publisher Login-Lead review issue*/}
                  Lead Review
                </label>
              </center>
            </div>

            {/*sunita-task-3727-added below code as per new requirement for opening the specification page */}
            <div
              class="col-sm-12 col-md-3 col-lg-3 specificationCamp"
              style={{ textAlign: "right" }}
            >
              <button
                class="btn add-button"
                onClick={this.displayCampaignSpecification}
              >
                {" "}
                Campaign Specification
              </button>
              &nbsp;
            </div>
          </div>
          <br />
          {/* snehal-task-3934-Publisher side-lead review */}
          <form
            name="reviewlead"
            onSubmit={this.submitreviewlead}
            encType="multipart/form-data"
          >
            <div class="card card-signin my-1">
              <div class="card-body" style={{ padding: "5px" }}>
                <div
                  class="col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 "
                  style={{
                    background: "#FFFFFF",
                    borderRight: "2px solid #eee",
                    padding: "0px",
                  }}
                >
                  <div>
                    {/* //shivani-task 3206- i have added black color for the label as per @Tanuja's suggetion (it was blue in screen)*/}
                    <center>
                      <label
                        id="Camp_revw_lab"
                        style={{
                          fontSize: "17px",
                          color: "#14254A",
                          marginTop: "10px",
                          fontWeight: "500",
                          fontFamily: "roboto",
                          marginLeft: "-60px",
                        }}
                      >
                        Campaigns for Lead Review :
                      </label>
                    </center>
                  </div>
                  {/* //search bar */}
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                      <span>
                        <input
                          type="text"
                          id="searchLeadId"
                          name="search"
                          placeholder="Search Campaign"
                          style={{
                            backgroundImage: "url(searchIcon.png)",
                            backgroundPosition: "15px 6px",
                            backgroundSize: "15px 15px",
                            backgroundRepeat: "no-repeat",
                            width: "280px",
                            height: "28px",
                            fontSize: "12px",
                            // float: 'left',
                            marginLeft: "22px",
                            border: "1px solid #CCCCCC",
                            padding: "12px 20px 15px 36px",
                            backgroundColor: "#FFFFFF",
                            // marginBottom: '5px',
                            fontFamily: "sans-serif",
                            borderRadius: "2px",
                          }}
                          onChange={this.handleChangeSearch}
                          onPressEnter={this.handleChangeSearch}
                        ></input>
                      </span>
                    </div>
                  </div>
                  <br></br>
                  <hr></hr>
                  {/* <div class="card card-signin my-1">
                  <div class="card-body" style={{ padding: '5px' }} > */}
                  <div
                    style={{
                      overflowY: "scroll",
                      background: "#FFFFFF",
                      borderRight: "2px solid #eee",
                      padding: "0px",
                      marginTop: "-9px",
                    }}
                  >
                    {/* //id-name-link */}
                    {this.state.tableSearchText === "" ? (
                      <div>
                        {this.state.leadReviewDashboard.map(
                          (leadReviewDashboard) => (
                            <div
                              style={{
                                borderBottom: "1px solid #eee",
                                padding: "5px 15px",
                              }}
                              className="campNames"
                            >
                              <a
                                href="#/" //Nilesh-4242-Removing console warnings
                                id={leadReviewDashboard.campID}
                                onClick={this.leadreviewDetails}
                                className="campclickelli"
                              >
                                {leadReviewDashboard.campID} -{" "}
                                {leadReviewDashboard.clientCampID} -{" "}
                                {leadReviewDashboard.campaignName}{" "}
                              </a>
                              {/* Saurabh – Task- 3747 – Adding Agency campaign ID i.e clientCampID */}
                            </div>
                          )
                        )}
                      </div>
                    ) : this.state.campaignLeadDetailsSearch.length > 0 ? (
                      <div>
                        {this.state.campaignLeadDetailsSearch.map(
                          (campaignLeadDetailsSearch) => (
                            <div
                              style={{
                                borderBottom: "1px solid #eee",
                                padding: "5px 15px",
                              }}
                              className="campNames"
                            >
                              <a
                                href="#/" //Nilesh-4242-Removing console warnings
                                id={campaignLeadDetailsSearch.campID}
                                onClick={this.leadreviewDetails}
                                className="campclickelli"
                              >
                                {campaignLeadDetailsSearch.campID} -{" "}
                                {campaignLeadDetailsSearch.clientCampID} -{" "}
                                {campaignLeadDetailsSearch.campaignName}{" "}
                              </a>
                              {/* Saurabh – Task- 3747 – Adding Agency campaign ID i.e clientCampID */}
                            </div>
                          )
                        )}
                      </div>
                    ) : (
                      <center>
                        <label
                          style={{
                            color: "red",
                            fontSize: "medium",
                            marginTop: "34px",
                          }}
                        >
                          No Data Exist
                        </label>
                      </center>
                    )}
                  </div>
                </div>
                {/* </div>
                        </div> */}
                {/* <br></br>
                <hr></hr> */}
                <div
                  className="col-xs-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 "
                  id="for-sm-mb"
                  style={{ background: "#FFFFFF", paddingTop: "20px" }}
                >
                  <div>
                    {this.state.reviewlead.map((reviewlead) => (
                      <div class="row" style={{ marginTop: "-9px" }}>
                        <div class="col-sm-4 col-md-4">
                          <label
                            style={{
                              fontSize: "14px",
                              color: "black",
                              fontWeight: "600",
                              marginRight: "5px",
                            }}
                          >
                            Campaign ID :
                          </label>
                          {reviewlead.campID}
                        </div>
                        <div class="col-sm-5 col-md-8">
                          <label
                            style={{
                              fontSize: "14px",
                              color: "black",
                              fontWeight: "600",
                              marginRight: "5px",
                            }}
                          >
                            Campaign Name :
                          </label>
                          {reviewlead.campaignName}
                        </div>
                        <br />
                        {/* <div class="col-sm-3">
                              <label style={{fontSize:'14px',color:'black',fontWeight:'600'}} >Campaign Status : </label>
                              {reviewlead.status}
                            </div> */}
                        <div class="col-sm-4 col-md-4">
                          <label
                            style={{
                              fontSize: "14px",
                              color: "black",
                              fontWeight: "600",
                              marginRight: "5px",
                            }}
                          >
                            Start Date :{" "}
                          </label>
                          {reviewlead.startDate}
                        </div>

                        <div class="col-sm-5 col-md-8">
                          <label
                            style={{
                              fontSize: "14px",
                              color: "black",
                              fontWeight: "600",
                              marginRight: "5px",
                            }}
                          >
                            End Date :{" "}
                          </label>
                          {reviewlead.endDate}
                        </div>
                      </div>
                    ))}
                  </div>
                  <br></br>
                  <hr></hr>

                  <div class="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      {/*sunita-3160- added row and column as per requirement */}
                      <div>
                        <span>
                          <span
                            style={{ fontWeight: "bold", color: "#F28C0F" }}
                          >
                            Offline Lead Review Instructions :-{" "}
                          </span>
                          <br /> 1. Click on
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            "Download Leads"{" "}
                          </span>
                          to get Lead's File.
                        </span>
                        <br />
                        {this.props.auth.user.role === "PQA" ? (
                          <span>
                            2. After reviewing the lead, Please update status as
                            <span style={{ fontWeight: "bold" }}>
                              "QA Review"
                            </span>{" "}
                            OR{" "}
                            {/*Priyanka - 4600 - Added DI QA Review status in instructions */}
                            <span style={{ fontWeight: "bold" }}>
                              "DI QA Review"
                            </span>{" "}
                            OR{" "}
                            <span style={{ fontWeight: "bold" }}>
                              "PublisherQARejected"{" "}
                            </span>
                            OR{" "}
                            <span style={{ fontWeight: "bold" }}>
                              "PVRejected"{" "}
                            </span>
                            in Status column in Lead's File.
                          </span>
                        ) : (
                          <span>
                            2. After reviewing the lead, Please update status as{" "}
                            <span style={{ fontWeight: "bold" }}>
                              "QA Review"
                            </span>{" "}
                            OR{" "}
                            {/*Priyanka - 4600 - Added DI QA Review status in instructions */}
                            <span style={{ fontWeight: "bold" }}>
                              "DI QA Review"
                            </span>{" "}
                            OR{" "}
                            <span style={{ fontWeight: "bold" }}>
                              "Rejected"{" "}
                            </span>
                            in Status column in Lead's File.
                          </span>
                        )}
                      </div>
                      <div>
                        <span>
                          3. If you rejecting the lead then please{" "}
                          <span style={{ fontWeight: "bold" }}>
                            add reason of rejection
                          </span>{" "}
                          in reason column in Lead's File.
                        </span>
                        <br />
                        <span>
                          4. Click on{" "}
                          <span style={{ fontWeight: "bold" }}>
                            "Upload Lead"{" "}
                          </span>
                          to upload reviewed Lead's File.
                        </span>
                      </div>
                    </div>
                    <div
                      class="row"
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      {/*sunita-3160- added row  as per requirement */}

                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                        <button
                          class="btn add-button"
                          type="button"
                          onClick={this.handleDownloadLeadDetails}
                          style={{
                            marginLeft: "15px",
                            backgroundColor: "#056eb8",
                            color: "white",
                            height: "32px",
                            fontFamily: "sans-serif",
                            fontSize: "14px",
                            fontWeight: "400",
                            borderRadius: "4px",
                          }}
                        >
                          <i
                            class="fa fa-cloud-download"
                            aria-hidden="true"
                          ></i>
                          &nbsp;Download Leads
                        </button>
                      </div>
                      <div
                        class="col-xs-12 col-sm-12 col-md-6 col-lg-9"
                        id="btn_info29"
                      >
                        {/* <label class="btn11 info11"> */}
                        <label
                          class=" btn btn-defaulttab  "
                          style={{
                            height: "32px",
                            fontFamily: "sans-serif",
                            fontSize: "14px",
                            fontWeight: "400",
                            borderRadius: "4px",
                            backgroundColor: "transparent",
                            color: "#193D8F",
                            border: "1px solid #193D8F",
                            marginRight: "5px",
                          }}
                        >
                          Upload Lead
                          <input
                            style={{ display: "none" }}
                            type="file"
                            id="abm1"
                            name="abmFiles"
                            multiple="multiple"
                            onChange={this.handleChangeFileUpload}
                          />
                        </label>
                        <span id="fileName">No File Choosen</span>
                        <div class="errorMessage">
                          {this.state.reerrors.errorMessage}
                        </div>
                        <div class="successMessage">
                          {this.state.errors.successMessage}
                        </div>
                      </div>
                      <div
                        class="row"
                        style={{
                          marginBottom: "10px",
                          marginLeft: "20px",
                          marginTop: "10px",
                        }}
                      >
                        {/*sunita-3160- added row and column as per requirement */}
                        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
                          <span
                            style={{ fontWeight: "bold", color: "#056eb8" }}
                          >
                            Online Lead Review & Instant Status Update:-
                            <br />
                          </span>

                          <span>
                            Total Leads :{this.state.campaignLeadDetails.length}
                          </span>

                          <div
                            style={{
                              fontSize: "18px",
                              color: "green",
                              textAlign: "center",
                            }}
                          >
                            {this.state.errors.leaderror}
                          </div>
                          <div
                            style={{
                              fontSize: "20px",
                              color: "red",
                              textAlign: "center",
                            }}
                          >
                            {this.state.errors.failureMsg}
                          </div>
                        </div>

                        <div
                          className="col-xs-12 col-sm-12 col-md-4 offset-lg-5 col-lg-1 col-xl-1"
                          id="btn29"
                          style={{ marginTop: "5px" }}
                        >
                          {" "}
                          {/*sunita-3160- added column as per requirement */}
                          {this.state.buttonFlag === true ? ( //shivani-task 3089-added condition for buttonFlag:true to make button disable if i upload leads through excel file.
                            <button
                              class="btn  btn-primary  text-uppercase"
                              id="customClassbtn "
                              style={{
                                align: "right",
                                paddingBottom: "9px",
                                marginLeft: "245px",
                                // marginLeft: "67px",
                                fontFamily: "sans-serif",
                                fontSize: "14px",
                                fontWeight: "400",
                                borderRadius: "4px",
                              }}
                              disabled
                              className={this.state.btnDisable}
                              type="submit"
                            >
                              Submit
                            </button>
                          ) : (
                            //Chaitanya-4638-Rename button name as a "Submit" instead of "Submit and Send to Agency"
                            <button
                              class="btn  btn-primary  text-uppercase"
                              id="customClassbtn "
                              style={{
                                align: "right",
                                paddingBottom: "9px",
                                marginLeft: "245px",
                                // marginLeft: "67px",
                                fontFamily: "sans-serif",
                                fontSize: "14px",
                                fontWeight: "400",
                                borderRadius: "4px",
                              }}
                              className={this.state.btnDisable}
                              type="submit"
                            >
                              Submit
                            </button>
                            //Chaitanya-4638-Rename button name as a "Submit" instead of "Submit and Send to Agency"
                          )}
                        </div>
                      </div>
                      <div
                        style={{ alignSelf: "self-end", paddingLeft: "99px" }}
                      >
                        <span
                          style={{
                            display: this.state.rejReasonDisplay,
                            marginLeft: "100px;",
                          }}
                          className="errorMessage"
                        >
                          Please enter Reason for Rejection
                        </span>
                        <div style={{ color: "red" }}>
                          {this.state.errors.reasonRej}
                        </div>
                      </div>
                      {/* <div  class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"style={{marginLeft:"28px"}}> */}

                      {/* </div> */}
                    </div>
                  </div>
                  <div id={this.state.loader2}></div>
                  {/* Sandeep-task-3341-added code for loader  */}
                  <Table
                    {...this.state.tableState}
                    id="myTable"
                    bordered
                    dataSource={this.state.campaignLeadDetails}
                    onChange={this.onTableChange} //Nilesh-5486-Call onTableChange function
                    //locale={this.state.loading1?locale:"No Data"}//Sandeep-task-3394-added code for loader issue
                    // loading={this.state.loader2==""?"No Data":this.state.loader2}
                    columns={
                      this.state.marketingChannelFlag === true
                        ? column2
                        : column1
                    }
                    // className="ant-table-thead"
                    className={`${tableCSS} "ant-table-thead"`} //Nilesh-4242-Removing console warnings
                    size="small"
                    scroll={{ x: 930, y: 1333 }}
                    pagination={{ pageSize: this.state.pageSize }}
                  ></Table>
                  <div
                    class="col-xs-12 col-sm-12 col-md-5 col-lg-6 col-xl-6 RecordsPerPagePubReviewLead" //Chaitanya-4246-R28.9-Prod issue-Publisher Login-Lead review issue
                    style={{
                      marginTop: "-53px",
                      marginLeft: "5px",
                      fontSize: "13px",
                      color: "#4F4F4F",
                      fontFamily: "sans-serif",
                    }}
                  >
                    Records per page:&nbsp;
                    <select
                      defaultValue={this.state.pageSize}
                      onChange={this.handleChangePageSize}
                      id="pacing11"
                      class="input-small"
                      className="form-control"
                      name="pacing"
                      style={{
                        width: "71px",
                        height: "30px",
                        display: "initial",
                        fontSize: "13px",
                        fontFamily: "sans-serif",
                        color: "#4F4F4F",
                      }}
                    >
                      <option value="100" selected>
                        100
                      </option>
                      <option value="200">200</option>
                      <option value="300">300</option>
                    </select>
                  </div>
                  <div
                    className="col-xs-12 col-sm-12  offset-md-5 col-md-4 offset-lg-8 col-lg-1 col-xl-1"
                    id="btn29"
                    style={{ marginTop: "10px" }}
                  >
                    {this.state.buttonFlag === true ? ( //shivani-task 3089-added condition for buttonFlag:true to make button disable if i upload leads through excel file.
                      <div
                        class="col"
                        align="right"
                        id="btm-btn"
                        style={{
                          paddingBottom: "60px",
                          marginLeft: "102px",
                          align: "right",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                          fontWeight: "400",
                          borderRadius: "4px",
                        }}
                      >
                        <button
                          class="btn  btn-primary  text-uppercase"
                          disabled
                          className={this.state.btnDisable}
                          type="submit"
                        >
                          Submit
                        </button>
                        {/* Chaitanya-4638-Rename button name as a "Submit" instead of "Submit and Send to Agency" */}
                      </div>
                    ) : (
                      <div
                        class="col"
                        align="right"
                        id="btm-btn"
                        style={{
                          paddingBottom: "60px",
                          marginLeft: "102px",
                          align: "right",
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                          fontWeight: "400",
                          borderRadius: "4px",
                        }}
                      >
                        <button
                          class="btn  btn-primary  text-uppercase"
                          className={this.state.btnDisable}
                          type="submit"
                        >
                          Submit
                        </button>
                        {/* Chaitanya-4638-Rename button name as a "Submit" instead of "Submit and Send to Agency" */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <br />

            {/* <div class="row" style={{marginTop:'10px',marginBottom:'10px'}}>
                     
                      
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3" >
                      <button class="btn add-button" type="button" onClick={this.handleDownloadLeadDetails} style={{ backgroundColor: "#056eb8", color: "white" }}>
                          <i class="fa fa-cloud-download" aria-hidden="true"></i>&nbsp;Download Leads
                        </button>
                      </div>           

                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" >
                  

                        <label class="chooseFile btn btn-default">Upload Lead
                    <input style={{ display: 'none' }} type="file" id="abm1" name="abmFiles" multiple="multiple"
                            onChange={this.handleChangeFileUpload} /></label><label id="fileName">No File Choosen</label>
                        <div class="errorMessage">{this.state.reerrors.errorMessage}</div>
                        <div class="successMessage">{this.state.errors.successMessage}</div>
                       
                      </div>

                    </div> */}

            {/* <div class="row" style={{marginBottom:'10px'}}>
                      <div className="col-xs-12 col-sm-12 col-md-8 col-lg-9 col-xl-9">
                        <span style={{ fontWeight: "bold", color: "#056eb8" }}>Online Lead Review & Instant Status Update:-<br />
                        </span>

                        <h5>Total Leads :{this.state.campaignLeadDetails.length}</h5>

                        <div style={{ fontSize: '18px', color: 'green', textAlign: 'center' }}>
                        {this.state.errors.leaderror}

                        </div>
                        <div style={{ fontSize: '20px', color: 'red', textAlign: 'center' }}>
                          {this.state.errors.failureMsg}
                        </div>
                      </div>
                      
                       <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 submit_Button" style={{marginTop:'5px'}}> 
                        {this.state.buttonFlag === true ? //shivani-task 3089-added condition for buttonFlag:true to make button disable if i upload leads through excel file.
                        
                          <button class="btn  btn-primary  text-uppercase" id="customClassbtn" style={{align:'right',paddingBottom:'9px'}}
                            disabled className={this.state.btnDisable} type="submit"
                          >Submit & Send To Agency</button>
                        
                        :
                        
                          <button class="btn  btn-primary  text-uppercase" id="customClassbtn" style={{align:'right',paddingBottom:'9px'}}
                            className={this.state.btnDisable} type="submit"
                          >Submit & Send To Agency</button>
                        
                        }
                      </div>
                      
                    </div> */}

            {/* <div class="row" style={{paddingTop:'20px'}}> */}
            {/* <div class="table-responsive-lg">
                        <div style={{overflowY:'scroll',maxHeight:'2526px',tableLayout:'fixed'}} >{/*sunita-task-3180-added css as per requirement*/}
            {/* <table class="table table-bordered" style={{ paddingTop: '80px', borderCollapse: 'collapse', border: '1px solid #D3D3D3', overflowX: 'hidden' }}> */}

            {/* <table id="myTable" class="table" className=" table table-bordered table-striped "> */}
            {/* <thead class="thead1">
                                          <tr class="info tr1">
                                          {this.state.marketingChannelFlag===true?
                                          <div>
                                          <th class="table-header" style={{width:'70px'}}>Lead ID</th>
                        <th  class="table-header" style={{width:'237px'}}>Email</th>
                        <th   class="table-header" style={{width:'237px'}}>Voice Log Link</th>
                        <th  class="table-header" style={{width:'237px'}}>Job title</th>
                        <th  class="table-header" style={{width:'238px'}}>Status</th>
                        <th class="table-header" style={{width:'255px'}}>Reason for rejection</th>
                        </div>
                        :<div>
                            <th class="table-header" style={{width:'70px'}}>Lead ID</th>
                            <th  class="table-header" style={{width:'296px'}}>Email</th>
                            <th  class="table-header" style={{width:'297px'}}>Job title</th>
                            <th  class="table-header" style={{width:'297px'}}>Status</th>
                            <th class="table-header" style={{width:'314px'}}>Reason for rejection</th>
                          </div>}

                                          </tr>
                                        </thead> */}

            {/* {this.state.marketingChannelFlag === true ?

                          <thead style={{position:'sticky',top:'0'}}>
                            <tr>
                              <th style={{ width: '90px', color: 'white', backgroundColor: '#124E9C', textAlign: 'center' }}>Lead ID</th>
                              <th style={{ width: '105px', color: 'white', backgroundColor: '#124E9C', textAlign: 'center' }}>Email</th>
                              <th style={{ width: '130px', color: 'white', backgroundColor: '#124E9C', textAlign: 'center' }}>Voice Log</th>
                              <th style={{ width: '150px', color: 'white', backgroundColor: '#124E9C', textAlign: 'center' }}>Job title</th>
                              <th style={{ width: '150px', color: 'white', backgroundColor: '#124E9C', textAlign: 'center' }}>Status</th>
                              <th style={{ width: '130px', color: 'white', backgroundColor: '#124E9C', textAlign: 'center' }}>Reason for rejection</th>

                            </tr>
                          </thead>
                          :
                          <thead syle={{position:'sticky',top:'0'}}>
                            <tr>
                              <th style={{ width: '90px', color: 'white', backgroundColor: '#124E9C', textAlign: 'center' }}>Lead ID</th>
                              <th style={{ width: '105px', color: 'white', backgroundColor: '#124E9C', textAlign: 'center' }}>Email</th>
                              <th style={{ width: '150px', color: 'white', backgroundColor: '#124E9C', textAlign: 'center' }}>Job title</th>
                              <th style={{ width: '150px', color: 'white', backgroundColor: '#124E9C', textAlign: 'center' }}>Status</th>
                              <th style={{ width: '130px', color: 'white', backgroundColor: '#124E9C', textAlign: 'center' }}>Reason for rejection</th>
                            </tr>
                          </thead>
                        } */}

            {/* {this.state.campaignLeadDetails.map((campaignLeadDetails, i) => (
                          
                          <tbody style={{ height: '260px', textAlign: 'center'}} >
                            <tr  > */}
            {/* <td style={{ width: '70px' }}>
                                {campaignLeadDetails.leadInfoID}
                              </td> */}
            {/* <td>
                      {campaignLeadDetails.supportDocID}
                        </td> */}
            {/* <td>
                                {campaignLeadDetails.email}
                              </td> */}
            {/* {this.state.marketingChannelFlag === true ?
                                campaignLeadDetails.channel === "Telemarketing" ?
                                  <td>
                                    <a href='#' onClick={this.openLinkInBrowser} id={campaignLeadDetails.leadInfoID}>Link</a>
                                  </td> : <td>No Link Available</td>
                                : ''} */}
            {/* <td>
                                {campaignLeadDetails.jobTitle}
                              </td> */}
            {/* {this.state.reviewLeadFlag === true ?
                                <td ><label>{campaignLeadDetails.status}</label> </td>
                                :
                                  this.props.auth.user.role==="PQA"?
                                  <td >
                                  <select style={{ height: '60%' }} 
                                  id="status" name="status"
                                  Value={campaignLeadDetails.status}
                                  defaultValue={this.state.status} 
                                 onChange={this.handleChange.bind(this,i)}
                                className="form-control">
                                 <option value="DI QA Review"> DI QA Review</option>
                                <option value="publisherQARejected">publisherQARejected</option>
                                </select>
                                </td>
                                : 

                                this.state.DI_QA_Switch === "ON" && this.state.bypassAgencySwitch === "ON" && this.state.campaignLevelSwitch === "ON" ?
                                  <td >
                                    <select style={{ height: '60%' }}
                                      id="status" name="status"
                                      Value={campaignLeadDetails.status}
                                      defaultValue={this.state.status}
                                      onChange={this.handleChange.bind(this, i)}
                                      className="form-control">
                                      <option value="DI QA Review"> DI QA Review</option>
                                     
                                      {this.props.auth.user.role === "PQA" ?
                                        <option value="publisherQARejected">publisherQARejected</option>
                                        : <option value="Rejected">Rejected</option>}
                                    </select>
                                  </td>
                                  :

                                  this.state.DI_QA_Switch==="ON" && this.state.bypassAgencySwitch==="OFF"&&this.state.campaignLevelSwitch==="OFF"?
                                    <td >
                                     <select style={{ height: '60%' }} 
                                     id="status" name="status"
                                     Value={campaignLeadDetails.status}
                                     defaultValue={this.state.status} 
                                    onChange={this.handleChange.bind(this,i)}
                                   className="form-control">
                                   <option value="DI QA Review"> DI QA Review</option>
                                  
                                   <option value="Rejected">Rejected</option>
                                   </select>
                                   </td>

                                    :
                                  this.state.DI_QA_Switch === "ON" && this.state.bypassAgencySwitch === "OFF" && this.state.campaignLevelSwitch === "ON" ?
                                    <td >
                                      <select style={{ height: '60%' }}
                                        id="status" name="status"
                                        Value={campaignLeadDetails.status}
                                        defaultValue={this.state.status}
                                        onChange={this.handleChange.bind(this, i)}
                                        className="form-control">
                                        <option value="DI QA Review"> DI QA Review</option>
                                       
                                        {this.props.auth.user.role === "PQA" ?
                                          <option value="publisherQARejected">publisherQARejected</option>
                                          : <option value="Rejected">Rejected</option>}
                                      </select>
                                    </td>

                                      :

                                      this.state.DI_QA_Switch==="ON" &&  this.state.bypassAgencySwitch==="ON"&&this.state.campaignLevelSwitch==="OFF"?
                                      <td >
                                       <select style={{ height: '60%' }} 
                                       id="status" name="status"
                                       Value={campaignLeadDetails.status}
                                       defaultValue={this.state.status} 
                                      onChange={this.handleChange.bind(this,i)}
                                     className="form-control">
                                     <option value="DI QA Review"> DI QA Review</option>
                                    
                                     <option value="Rejected">Rejected</option>
                                     </select>
                                     </td>

                                    :



                                    <td>
                                      <select style={{ height: '60%' }}
                                        id="status" name="status"
                                        Value={campaignLeadDetails.status}
                                        defaultValue={this.state.status}
                                        onChange={this.handleChange.bind(this, i)}
                                        className="form-control">
                                        <option value="QA Review">QA Review</option>
                                       
                                        {this.props.auth.user.role === "PQA" ?
                                          <option value="publisherQARejected">publisherQARejected</option>
                                          : <option value="Rejected">Rejected</option>}
                                      </select>
                                    </td>}


                              {this.state.reviewLeadFlag === true ?
                                <td>
                                  <input type="textbox" id="reason" name="reason"
                                    defaultValue={campaignLeadDetails.reason} onChange={this.handleChange.bind(this, i)}
                                    class="form-control"></input>
                                </td> :
                                <td>
                                  <input type="textbox" id="reason" name="reason" value={this.state.reason}
                                    onChange={this.handleChange.bind(this, i)}
                                    class="form-control"></input>
                                </td>} 

                            </tr>
                          </tbody>
                          ))}



                      </table>
                        </div>
                    </div>
                     */}
            {/* end of col */}
            {/* 
                    {this.state.buttonFlag === true ? //shivani-task 3089-added condition for buttonFlag:true to make button disable if i upload leads through excel file.
                      <div class="col" align="right" style={{ paddingBottom: '20px' }}>
                        <button class="btn  btn-primary  text-uppercase"
                          disabled className={this.state.btnDisable} type="submit"
                        >Submit & Send To Agency</button>
                      </div>
                      :
                      <div class="col" align="right" style={{ paddingBottom: '20px' }}>
                        <button class="btn  btn-primary  text-uppercase"
                          className={this.state.btnDisable} type="submit"
                        >Submit & Send To Agency</button>
                      </div>} */}

            {/* </div>
                </div>
              </Tab> */}

            {/* <Tab eventKey={2} title="Lead QA review by Client" style={{backgroundcolor:'144c9b'}}> 
              <div class="card card-signin my-1">
                          <div class="card-body" >
                          <div class="row">
                        <div class="col-md-1">
                              IoIosArrowDropleftCircle
                              <a href={'dashboard?agencyID='+this.state.agencyID}  style={{color: '#056eb8'}}><FaArrowAltCircleLeft size={32} style={{float:'left'}} title="Back to Dashboard"/></a>
                                </div>
                                <div class="col-md-11">
                                <center><label id="label" style={{fontSize:"20px",fontWeight:"bold",color:"#056eb8"}}>Client Response</label></center>

                                    </div>
                        </div>
                        <hr/>

                        {this.state.reviewlead.map(
                        reviewlead => (    
                        <div class="row">
                            <div class="col-sm-2">
                              <label id="label">
                                Campaign ID: 
                              </label>
                                {this.state.parentCampID}
                          </div>
                          <div class="col-sm-4" >
                              <label id="label" >
                                Campaign Name: 
                              </label>
                              {reviewlead.campaignName}
                             
                            </div>
                            <div class="col-sm-2">
                              <label id="label" >Start Date: </label>
                              {reviewlead.startDate}
                            </div>
                            <div class="col-sm-2">
                              <label id="label" >Campaign Status: </label>
                              {reviewlead.campaignStatus}
                            </div>
                          
                            <div class="col-sm-2">
                              <label id="label" >End Date: </label>
                              {reviewlead.endDate}
                            </div>
                                  </div>
                                  )
                                  )}
                                
                                 

          <hr/>
                             
          <div class="row">       

<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">                       
<p>Instruction :-Review the Leads by clicking on "Download Agency Accepted Leads" or by looking at the table</p>
<button class="btn  btn-primary " type="button" onClick={this.handleClientDownloadLeadDetails} style={{backgroundColor:"#056eb8",color:"white"}}>
Download Client Response
  </button>  
</div>

<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
<p>Instruction :- After Reviewing leads Please click below and select respected file and upload it</p>                                    
<input  type="file"  id="abm1"  name="abmFiles" multiple="multiple"
                            onChange={this.handleChangeClientResponseFileUpload}/>

 </div>
</div>

<div style={{fontSize:'18px',color:'red',textAlign:'center'}}>
          {this.state.errors.failureMsg2}
          </div>
</div>
</div>
              </Tab> */}
            {/* </Tabs> */}
          </form>{" "}
        </div>
        {/* //end of form*/}
        <Footer />
      </div>
    );
  }
}
/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
PublisherInternalReviewLead.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
  withRouter(PublisherInternalReviewLead)
);
