/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

import React from "react";
//import { Launcher } from "react-chat-window";//Rutuja 2978
import { addResponseMessage } from "react-chat-widget"; //RUtuja 2978 removed Widget as never used
import "react-chat-widget/lib/styles.css";
import {
  Container,
  Button,
  lightColors,
  // darkColors, Rutuja 2978
  // Link, Rutuja 2978
} from "react-floating-action-button";
import {
  Drawer,
  Comment,
  //Icon, Rutuja 2978
  //Tooltip, Rutuja 2978
  Avatar,
  Input,
  Table, //Rutuja 5209 aaded Table
  //List,//RUtuja 2978
  Form,
} from "antd";
import "antd/dist/antd.css";
import moment from "moment";
//import Moment from "react-moment"; Rutuja 2978
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { MDBBtn, CardBody } from "mdbreact"; //Rutuja 2978remove CardHeader, CardTitle, as never used
import { CampaignSpecificationLoader } from "../loaders/CampaignSpecificationLoader"; // 3428 - karan - loader
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // karan-task-3767-added default header
import { css } from "emotion"; // Rutuja task 5209 added css 
// import logo from './DI.png';
/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*@author Sanobar  Golandaj
 * Campaign Specification
 @version 1.0
 */

// Rutuja task 5209 added css
 const tableCSS = css({
	backgroundColor: "white",
	borderStyle: "bold",
	"& thead  > tr": {
		backgroundImage: "linear-gradient(to right,#28166f,#007cc3)!important",
		color: "white",
	},
	"& thead > tr >th": {
		border: "1px solid black",
		color: "white",
	},
});


var abmFile = [];
var suppressionFile = [];
var execlusionFile = [];
var otherFile = [];
//Rutuja 2978 commented below var as never used
// var assetFile = [];
// var countryArray = [];
// var jobLevelArray = [];
// var companyRevenueArray = [];
// var jobFunctionArray = [];
// var employeeSizeArray = [];
// var customJobFunctionArray = [];
// var customJobLevelArray = [];
// var regionArray = [];
// var greeting = "";
const { TextArea } = Input;
//Rutuja 2978 commented CommentList as never used
// const CommentList = ({ comments }) => (
//   <List
//     dataSource={comments}
//     header={`${comments.length} ${comments.length > 1 ? "messages" : "message"
//       }`}
//     itemLayout="horizontal"
//     renderItem={(props) => <Comment {...props} />}
//   />
// );

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <div>
    <Form.Item>
      <TextArea
        rows={4}
        onChange={onChange}
        value={value}
        style={{ resize: "none" }}
      />
    </Form.Item>
    <Form.Item>
      <button
        type="submit"
        class="btn add-button"
        id="cmd"
        loading={submitting}
        onClick={onSubmit}
      >
        Send Message
      </button>
    </Form.Item>
  </div>
);
class CampaignSpecificationDashboardDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      pubColor: "",
      Broadcastcolor: "#F28C0F",
      newsuccess: "",
      fields: {},
      dynamic: [],
      campCount: [],
      assetListArray: [],
      linkListArray: [],
      gdprPublisher: [],
      // gdprNoPublisher:[],
      otherDetails: [],
      abmDetails: [],
      pendingCampaignCount: "",
      suppresionDetails: [],
      exclusionDetails: [],
      region: "",
      sumOfAllocatedLeads: [],
      assetLinkArray: [],
      campID: " ",
      campIDTemp: " ",
      parentCampID: "",
      reallocationID: "",
      PublisherID: "",
      pID: "",
      id: "",
      leadAllocation: 0,
      stateCopy: [
        {
          allocatedLead: "",
          cpl: "",
          campCPL: "",
        },
      ],
      newDynamicArray: [],
      newGDPRPubArray: [],
      gdprStatus: "false",
      campaignName: "",
      requiredLeadPerAsset: "",
      multiTouch: "",
      btnStatus: "",
      // allocatedLead: "",
      pendingLead: 0,
      campaignVisibleTime: "",
      campAcceptReject: "",
      campaignDetailAllocates: [],
      campaignDetailAssign: [],
      campaignDetailID: [],
      liveCampaignList: [],
      onClickAllocatedLead: [],
      onClickAllocatedLeadPubList: [],
      assignDeliveredLead: [],
      errors: {},
      alertDisplay: "none",
      alertBorder: "",
      alertWidth: "",
      alertAlign: "",
      spanSpaceDisplay: "none",
      success: "",
      puberror: "",
      allocerrors: "",
      reerrors: "",
      dateerrors: "",
      agencyID: "",
      show: false,
      campaignBriefDetails: [],
      campaignBriefDeliveryDetails: [],
      campaignBriefCustomQuestionDetails: [],
      campaignBriefSupportingDocumentAssetDetails: [],
      campaignBriefSupportingDocumentAbmDetails: [],
      campaignBriefSupportingDocumentSuppresionDetails: [],
      campaignBriefSupportingDocumentExclusionDetails: [],
      campaignBriefSupportingDocumentOtherDetails: [],
      publisherCampCPL: [],
      mergedCampaignDetailAssign: [],
      mergedGdprPublisher: [],
      isSuppLink: false,
      messageList: [],
      visible: false,
      likes: 0,
      dislikes: 0,
      action: null,
      comments: [],
      submitting: false,
      value: "",
      publisherList: [],
      customizedColumns: [],
      deliveryURL: "",
      customQuestionURL: "",
      pacingCampaignDetails: [],
      pacingUnit: "None",
      countryWiseAllocationArray: [],
      requiredCountryWiseAllocation: "No",
      button: false, //shivani-task 3137-button glag used below to hide and show chat option.
      loading: "'whirly-loader ", // Sanjana-3128-Campaign Spec Performance and many time come as blank data-loader added
      totalApiCalls: 12, //3428-karan-loader
      apiCallsCompleted: 0, //3428-karan-loader
      customizeMappingFormat: "", //kiran-4884-declare variable for customize mapping format
      nonEnglishCheck: "No",
      extraAddedFields: "", //Aman-5089 added to store 21-30 Extra fields
      extraAddedFieldsYes: "", //Aman-5089 added to store yes value only of 21-30 Extra fields
      columnsequencingarray:[],// Rutuja task 5209 initialized columnsequencingarray for storing columnsequence array
      columnSequenceCheck:"No" //rutuja task 5209 initialized columnSequenceCheck no

    };

    this.handleCampaignBriefDetails =
      this.handleCampaignBriefDetails.bind(this);
    this.handleChangePublisherMessages =
      this.handleChangePublisherMessages.bind(this);
    this.broadCastMessage = this.broadCastMessage.bind(this);

    this.loadingRef = React.createRef(); //3428-karan-loader handlers
    this.closeLoadingRef = React.createRef(); //3428-karan-loader handlers
  } //end of constructor

  broadCastMessage(e) {
    /**
     * @author Narendra Phadke
     * @param  Description fetch brodcast message history
     * @return Description return all history
     */
    //{/* snehal-task-3211-RFP - messaging facility between Agency and Publisher
    e.preventDefault();
    var parsed = this.props.location.state;
    if (parsed !== undefined) {
      var campID = parsed.campID;
      let data = {
        campID: campID,
      }; // karan-task-3714-vapt header and query params
      var pID = undefined;
      //This API is for brodcast message history
      fetch("/notification/campaignSpecificationBrodcastMessageHistory", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((comments) => {
          this.setState({
            comments: comments,
            pID: pID,
            Broadcastcolor: "#F28C0F",
            pubColor: "",
          });
        })
        .catch(function (err) {
          console.log(err);
        });

      let data1 = {
        campID: campID,
      }; // karan-task-3714-vapt header and query params
      //This API is for publiher list
      fetch("/notification/agencyCampaignSpecificationPublisherList", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data1),
      })
        .then((res) => res.json())
        .then((publisherList) => {
          this.setState({
            publisherList: publisherList,
            campID: campID,
            Broadcastcolor: "#F28C0F",
            pubColor: "",
          });
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }
  // end of broadCastMessage

  /**
   * @author Narendra Phadke
   * @param  Description handle Publisher Messages
   * @return Description return Get perticular publisher message
   */
  handleChangePublisherMessages(e) {
    e.preventDefault();
    //{/* snehal-task-3211-RFP - messaging facility between Agency and Publisher
    var parsed = this.props.location.state;
    if (parsed !== undefined) {
      var campID = parsed.campID;
      var pID = e.target.name;

      let data = {
        campID: campID,
        pID: pID,
      }; // karan-task-3714-vapt header and query params
      // var parentCampID=e.target.getAttribute('parentCampID');
      // var reallocationID=e.target.getAttribute('reallocationID');
      //This API is for publiher message history
      fetch("/notification/agencyCampaignSpecificationOnePublisherMessages", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((comments) => {
          this.setState({
            comments: comments,
            visible: true,
            campID: campID,
            pID: pID,
            Broadcastcolor: "",
            pubColor: "orange",
          });
        })
        .catch(function (err) {
          console.log(err);
        });

      fetch(
        "/notification/agencySendMessageToPerticulaterPublisherStatusChanged",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      )
        .then((res) => res.json())
        .then((comments) => {
          this.setState({
            comments: comments,
            visible: true,
            campID: campID,
            pID: pID,
            Broadcastcolor: "",
            pubColor: "orange",
          });
        })
        .catch(function (err) {
          console.log(err);
        });

      let data1 = {
        campID: campID,
      }; // karan-task-3714-vapt header and query params
      //This API is for publiher list
      fetch("/notification/agencyCampaignSpecificationPublisherList", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data1),
      })
        .then((res) => res.json())
        .then((publisherList) => {
          this.setState({
            publisherList: publisherList,
            campID: campID,
            Broadcastcolor: "",
            pubColor: "orange",
          });
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }
  // end of handleChangePublisherMessages

  handleSubmit = () => {
    if (!this.state.value) {
      return;
    } else {
      const { user } = this.props.auth;
      if (this.state.pID !== undefined) {
        //Rutuja 2978
        var pID = this.state.pID;
        let data = {
          campID: this.state.campID,
          pID: pID,
          message: this.state.value,
        }; // karan-task-3714-vapt header and query params
        fetch("notification/agencySendMessageToPerticulaterPublisher", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((message) => {
            this.setState({ message: message, submitting: true });
          })
          .catch(function (err) {
            console.log(err);
          });
      } else {
        // var campID = this.state.campID;
        let data = {
          campID: this.state.campID,
          message: this.state.value, //4407-Brodcast Message to All Publishers
        }; // karan-task-3714-vapt header and query params
        fetch("notification/campaignSpecificationBrodcastMessage", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((message) => {
            this.setState({ message: message, submitting: true });
          })
          .catch(function (err) {
            console.log(err);
          });
      }

      var uname = user.firstName + " " + user.lastName;

      var fnamefirst = user.firstName.slice(0, 1);
      var lnamefirst = user.lastName.slice(0, 1);

      var uavatar = fnamefirst + lnamefirst;

      setTimeout(() => {
        this.setState({
          submitting: false,
          value: "",
          comments: [
            {
              author: uname,
              avatar: uavatar,
              content: <p>{this.state.value}</p>,
              datetime: moment().calendar(),
            },
            ...this.state.comments,
          ],
        });
      }, 1000);
    }
  };

  handleChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  showDrawer = () => {
    /**
     * @author Narendra Phadke
     * @param  Description fetch brodcast message history
     * @return Description return all history
     */

    //var parsed = queryString.parse(this.props.location.search);
    var campID = this.state.campID;
    let data = {
      campID: campID,
    }; // karan-task-3714-vapt header and query params
    //This API is for brodcast message history
    fetch("/notification/campaignSpecificationBrodcastMessageHistory", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((comments) => {
        this.setState({ comments: comments, visible: true });
      })
      .catch(function (err) {
        console.log(err);
      });
    //  this.setState({
    //    visible: true,
    //  });
  };

  contactPublisher = () => {
    var campID = this.state.campID;
    window.location.href = "/contactPublisher?campID=" + campID;
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  handleNewUserMessage = (newMessage) => {
    // Now send the message throught the backend API
    //addResponseMessage(newMessage);
  };

  _onMessageWasSent(message) {
    this.setState({
      messageList: [...this.state.messageList, message],
    });
  }

  _sendMessage(text) {
    this.setState({
      messageList: [
        ...this.state.messageList,
        {
          author: "them",
          type: "text",
          data: { text },
        },
      ],
    });
  }
  /**
   * @author : Karan Jagtap
   * @description : because references dont work in willmount() hence using didMount()
   */
  componentDidMount() {
    this.loadingRef.current.click();
  }

  componentWillMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      var campID = this.props.greeting;
      var parentCampID = this.props.parentCampID;
      var parsed = this.props.location.state;
      // karan-task-3954-prod issue for campaign specification page when came from agency lead review
      if (parsed !== undefined || campID !== undefined) {
        var pID = undefined;
        var reallocationID;
        if (parsed !== undefined) {
          if (parsed.hasOwnProperty("pID")) {
            pID = parsed.pID;
          }
          if (parsed.hasOwnProperty("reallocationID")) {
            reallocationID = parsed.reallocationID;
          } else if (sessionStorage.getItem("reallocationID") !== undefined) {
            reallocationID = sessionStorage.getItem("reallocationID");
          }
        } else if (sessionStorage.getItem("reallocationID") !== undefined) {
          reallocationID = sessionStorage.getItem("reallocationID");
        }
        this.setState({ campID, parentCampID, pID });
        this.handleCampaignBriefDetails(campID, parentCampID, reallocationID); //Somnath Task-3128, Pass parentCampID and allocationID to the function

        this._sendMessage("Welcome to this awesome chat!");
        addResponseMessage("Welcome to this awesome chat!");

        let data = {
          campID: campID,
          pID: pID,
        }; // karan-task-3714-vapt header and query params
        //snehal-task-3766-specification VAPT api call
        let data1 = {
          campID: campID,
          pID: pID,
        };

        if (pID !== undefined) {
          //Rutuja 2978
          // var parentCampID=e.target.getAttribute('parentCampID');
          // var reallocationID=e.target.getAttribute('reallocationID');
          //This API is for publiher message history
          fetch(
            "/notification/agencyCampaignSpecificationOnePublisherMessages",
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data),
            }
          )
            .then((res) => res.json())
            .then((comments) => {
              this.setState({
                comments: comments,
                visible: true,
                campID: campID,
                pID: pID,
                Broadcastcolor: "",
                pubColor: "orange",
              });
            })
            .catch(function (err) {
              console.log(err);
            });

          fetch(
            "/notification/agencySendMessageToPerticulaterPublisherStatusChanged",
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data),
            }
          )
            .then((res) => res.json())
            .then((comments) => {
              this.setState({
                comments: comments,
                visible: true,
                campID: campID,
                pID: pID,
                Broadcastcolor: "",
                pubColor: "orange",
              });
            })
            .catch(function (err) {
              console.log(err);
            });
        }

        //This API is for publiher list
        fetch("/notification/agencyCampaignSpecificationPublisherList", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((publisherList) => {
            this.setState({
              publisherList: publisherList,
              campID: campID,
            });
          })
          .catch(function (err) {
            console.log(err);
          });

        //This API is for customized column mapping
        //snehal-task-3766-specification VAPT api call
        fetch("/agency/agencyDeliveryFormatMapping", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data1),
        })
          .then((res) => res.json())
          .then((customizedColumns) => {
            if (customizedColumns.length > 0) {
              //Mufiz-Bug-4243-R-28.8-Prod Issue-Agency-Campaign specification not opening -Shows blank page
              this.setState({
                customizedColumns: customizedColumns,
                campID: campID,
              });
            } //Mufiz-Bug-4243-R-28.8-Prod Issue-Agency-Campaign specification not opening -Shows blank page
            else {
              // alert("inside else")
            }
            //3428-karan-loader
            this.setState(
              (prev) => ({
                apiCallsCompleted: prev.apiCallsCompleted + 1,
              }),
              () => {
                this.dismissLoader();
              }
            );
          })
          .catch(function (err) {
            console.log(err);
            //3428-karan-loader
            this.setState(
              (prev) => ({
                apiCallsCompleted: prev.apiCallsCompleted + 1,
              }),
              () => {
                this.dismissLoader();
              }
            );
          });
        // this api for getting countrywise allocation
        fetch("campaignNew/getCountryWiseLead?campID=" + campID)
          .then((res) => res.json())
          .then((countryWiseAllocationArray) => {
            this.setState({
              countryWiseAllocationArray: countryWiseAllocationArray,
            });
            //3428-karan-loader
            this.setState(
              (prev) => ({
                apiCallsCompleted: prev.apiCallsCompleted + 1,
              }),
              () => {
                this.dismissLoader();
              }
            );
          })
          .catch(function (err) {
            //3428-karan-loader
            this.setState(
              (prev) => ({
                apiCallsCompleted: prev.apiCallsCompleted + 1,
              }),
              () => {
                this.dismissLoader();
              }
            );
            console.log(err);
          });
      } else {
        this.props.history.replace("/newDashboard");
      }
    }
  } // end of compoenetDidmount

  /**
   * @author Narendra Phadke
   * @param  Description handle the Campaign Modal Details
   * @return Description return All steps campaign Details
   */
  handleCampaignBriefDetails(campID, parentCampID, reallocationID) {
    //This API is for campaign details from campaign table
    const self = this; //karan-task-3489-creating instance
    fetch("/agency/campaignBriefDetailsForAgency?campID=" + campID)
      .then((res) => res.json())
      .then((campaignBriefDetails) => {
        //3428-karan-loader
        //Mufiz-Bug-4243-R-28.8-Prod Issue-Agency-Campaign specification not opening -Shows blank page
        self.setState(
          //karan-task-3489-using instance
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            self.dismissLoader(); //karan-task-3489-using instance
          }
        );
        //Somnath Task-3028, campaignBriefDetails desturucture changes done
        if (campaignBriefDetails.length > 0) {
          var {
            campaignName,
            requiredCountryWiseAllocation,
            nonEnglishCheck, //kiran-4923-added for showing on campaign specification
            requiredLeadPerAsset,
            multiTouch,
          } = campaignBriefDetails[0];
        }
        //shivani-task 3137-added condition to hide chat option from incomplete specification.
        var Status = campaignBriefDetails[0].status;
        var button = self.state.button; //karan-task-3489-using instance
        if (
          Status === "BasicDetails" ||
          Status === "DeliveryOption" ||
          Status === "CampaignSpecification" ||
          Status === "XlsStep1" ||
          Status === "Step1" ||
          Status === "undefined" ||
          Status === "null" ||
          Status === undefined ||
          Status === null
        ) {
          button = true;
        } else {
          button = false;
        }
        self.setState({
          //karan-task-3489-using instance
          campaignBriefDetails,
          campaignName,
          button,
          requiredLeadPerAsset,
          multiTouch,
          requiredCountryWiseAllocation,
          loading: "",
          nonEnglishCheck,
        });
        //// Sanjana-3128-Campaign Spec Performance and many time come as blank data-loader added

        //   if (this.state.campaignDetail && this.state.campaignDetail.length) {
        //     var str = this.state.campaignDetail[0].customJobFunction;
        //     if(str===null||str==="null"){} // shivani-task 3121- added condition to remove error for custJobFunction null values.
        //     else{
        //     var str_array = str.split(',');
        //     for (var i = 0; i < str_array.length; i++) {
        //       if (str_array[i] != "") {
        //         customJobFunctionArray.push({ 'jobFunction': str_array[i] });
        //       }
        //     }
        //   }
        // }

        //   if (this.state.campaignBriefDetails && this.state.campaignBriefDetails.length) {
        //     var str = this.state.campaignBriefDetails[0].country;
        //     if(str===null||str==="null"){}// shivani-task 3121- added condition to remove error for  country null values.
        //     else{
        //     var str_array = str.split(',');
        //     for (var i = 0; i < str_array.length; i++) {
        //       countryArray.push(str_array[i], ' | ');
        //     }
        //   }
        // }
        //   if (this.state.campaignBriefDetails && this.state.campaignBriefDetails.length) {
        //     var str = this.state.campaignBriefDetails[0].jobLevel;
        //     if(str===null||str==="null"){}// shivani-task 3121- added condition to remove error for job level null values.
        //     else{
        //     var str_array = str.split(',');
        //     for (var i = 0; i < str_array.length; i++) {
        //       jobLevelArray.push(str_array[i], ' | ');
        //     }
        //   }
        // }
        //   if (this.state.campaignBriefDetails && this.state.campaignBriefDetails.length) {
        //     var str = this.state.campaignBriefDetails[0].jobFunction;
        //     if(str===null||str==="null"){}// shivani-task 3121- added condition to remove error for jobfunction null values.
        //     else{
        //     var str_array = str.split(',');
        //     for (var i = 0; i < str_array.length; i++) {
        //       jobFunctionArray.push(str_array[i], ' | ');
        //     }
        //   }
        // }
        //   if (this.state.campaignBriefDetails && this.state.campaignBriefDetails.length) {
        //     var str = this.state.campaignBriefDetails[0].employeeSize;
        //     if(str===null||str==="null"){}// shivani-task 3121- added condition to remove error for employeeSize null values.
        //     else{
        //     var str_array = str.split(',');
        //     for (var i = 0; i < str_array.length; i++) {
        //       employeeSizeArray.push(str_array[i], ',');
        //     }
        //   }
        // }
        //   // if (this.state.campaignBriefDetails && this.state.campaignBriefDetails.length) {
        //   //   var str = this.state.campaignBriefDetails[0].customJobFunction;
        //   //   var str_array = str.split(',');
        //   //   for (var i = 0; i < str_array.length; i++) {
        //   //   customJobFunction.push(str_array[i],',');
        //   //   }
        //   // }
        //   if (this.state.campaignBriefDetails && this.state.campaignBriefDetails.length) {
        //     var str = this.state.campaignBriefDetails[0].companyRevenue;
        //     if(str===null||str==="null"){}// shivani-task 3121- added condition to remove error for companyRevenue null values.
        //     else{
        //     var str_array = str.split(',');
        //     for (var i = 0; i < str_array.length; i++) {
        //       companyRevenueArray.push(str_array[i], ',');
        //     }
        //   }
        // }
        // var voiceLogLink = '';
        // if (this.state.campaignBriefDetails && this.state.campaignBriefDetails.length) {
        //   var marketingChannel = this.state.campaignBriefDetails[0].marketingChannel;
        //   if (marketingChannel === 'Email/Telemarketing') {
        //     voiceLogLink = '&voiceLogLink=""&channel=""';
        //   } else if (marketingChannel === 'TeleMarketing') {
        //     voiceLogLink = '&voiceLogLink=""';
        //   }

        // }
        //This API is for campaign delivery details from delivery format jobLevelArray
      })
      .catch(function (err) {
        //3428-karan-loader
        self.setState(
          //karan-task-3489-using instance
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            self.dismissLoader(); //karan-task-3489-using instance
          }
        );
        console.log(err);
      });

    fetch(
      "/publisher/campaignBriefDeliveryDetails?campID=" +
        campID +
        "&parentCampID=" +
        parentCampID +
        "&reallocationID=" +
        reallocationID
    )
      .then((res) => res.json())
      .then((campaignBriefDeliveryDetails) => {
        //Aman-5089-added to get the 21-30 extra fileds value 
        const extraFields = ["extra21", "extra22", "extra23", "extra24", "extra25", "extra26", "extra27", "extra28", "extra29", "extra30"];

        //Aman-5089-Iterate over each object in the array and filter null values
        const extractedDetailsArray = campaignBriefDeliveryDetails.map(details => {
          return Object.fromEntries(
            Object.entries(details)
              .filter(([key, value]) => extraFields.includes(key) && value !== null)
          );
        }).filter(details => Object.keys(details).length > 0); // Filter out empty objects

        //Aman-5089 -added Function to transform keys extra21 to Extra 21
        const transformKey = (key) => {
          return key.replace(/extra(\d+)/, 'Extra $1');
        };

        //Aman-5089 - Filter the object where the value is "Yes" and transform keys
        const yesDetailsArray = extractedDetailsArray.map(details => {
          return Object.fromEntries(
            Object.entries(details)
              .filter(([key, value]) => value === "Yes")
              .map(([key, value]) => [transformKey(key), value]) // Transform the key here
          );
        }).filter(details => Object.keys(details).length > 0); // Filter out empty objects

          this.setState({
            extraAddedFields: extractedDetailsArray,
            extraAddedFieldsYes: yesDetailsArray
          });
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            self.dismissLoader(); //Rutuja 5017 changed this to self as page going crash
          }
        );
        //Somnath Task-3128, deliveryURL added
        if (campaignBriefDeliveryDetails.length > 0) {
          var { deliveryURL } = campaignBriefDeliveryDetails[0];
        }
        let customizeMappingFormat =
          campaignBriefDeliveryDetails[0].customizeMappingFormat; // kiran-4884-taken value from array and setState
        this.setState({
          campaignBriefDeliveryDetails,
          deliveryURL,
          customizeMappingFormat,
        });
      })
      .catch(function (err) {
        //3428-karan-loader
        self.setState( //Rutuja 5017 changed this to self as page going crash
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            self.dismissLoader(); //Rutuja 5017 changed this to self as page going crash
          }
        );
        console.log(err);
      }); // End of delivery format

    /**
     * @author Supriya Gore
     * @param  Description handle the pacing campaign details edit
     * @return Description return pacing campaign details
     */
    fetch("campaign/pacingDetailsForEdit?campID=" + campID + "")
      .then((res) => res.json())
      .then((pacingCampaignDetails) => {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            self.dismissLoader(); //Rutuja 5017 changed this to self as page going crash
          }
        );
        // Rutuja 2978 Commented pacingResult as never used
        // const pacingResult = Array.from(
        //   new Set(pacingCampaignDetails.map((p) => p.campID))
        // ).map((campID) => {
        //   return {
        //     campID: campID,
        //     pacingUnit: pacingCampaignDetails.find((p) => p.campID === campID)
        //       .pacingUnit,
        //   };
        // });
        this.setState({ pacingCampaignDetails: pacingCampaignDetails });
        if (pacingCampaignDetails.length > 0) {
          this.setState({ pacingUnit: pacingCampaignDetails[0].pacingUnit });
        }
      })
      // 3428-karan- added missing catch block to handle loader
      .catch((err) => {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            self.dismissLoader(); //Rutuja 5017 changed this to self as page going crash
          }
        );
        console.log(err);
      });

    //custom question come from new custom question
    fetch("/publisher/campaignBriefCustomQuestionDetails?campID=" + campID)
      .then((res) => res.json())
      .then((campaignBriefCustomQuestionDetails) => {
        //3428-karan-loader
        //alert("array=====>"+JSON.stringify(campaignBriefCustomQuestionDetails));
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            self.dismissLoader(); //Rutuja 5017 changed this to self as page going crash
          }
        );

        this.setState({
          campaignBriefCustomQuestionDetails:
            campaignBriefCustomQuestionDetails,
        });

        if (this.state.campaignBriefCustomQuestionDetails.length > 0) {
          var cqURL = "";
          for (
            var i = 0;
            i < this.state.campaignBriefCustomQuestionDetails.length;
            i++
          ) {
            var cq =
              "&CQ-" +
              this.state.campaignBriefCustomQuestionDetails[i]
                .customQuestionID +
              '=""';
            cqURL += cq;
          }
          this.setState({ customQuestionURL: cqURL });
        }
      })
      .catch(function (err) {
        //3428-karan-loader

        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            self.dismissLoader(); //Rutuja 5017 changed this to self as page going crash
          }
        );
        console.log(err);
      });
    // const self = this;
    //This API is for campaign supporting document Asset details from delivery format
    fetch(
      "/publisher/campaignBriefSupportingDocumentAssetDetails?campID=" +
        campID +
        ""
    )
      .then((res) => res.json())
      .then((campaignBriefSupportingDocumentAssetDetails) => {
        //3428-karan-loader
        self.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            self.dismissLoader();
          }
        );
        self.setState({
          campaignBriefSupportingDocumentAssetDetails:
            campaignBriefSupportingDocumentAssetDetails,
        });
        if (
          self.state.campaignBriefSupportingDocumentAssetDetails !==
            undefined ||
          self.state.campaignBriefSupportingDocumentAssetDetails !== ""
        ) {
          var assetListArray = [];
          for (
            var i = 0;
            i < self.state.campaignBriefSupportingDocumentAssetDetails.length;
            i++
          ) {
            assetListArray.push({
              supportDocID:
                self.state.campaignBriefSupportingDocumentAssetDetails[i]
                  .supportDocID,
              leadPercentage:
                self.state.campaignBriefSupportingDocumentAssetDetails[i]
                  .leadPercentage,
              leadPerAsset:
                self.state.campaignBriefSupportingDocumentAssetDetails[i]
                  .leadPerAsset,
              suppDocName:
                self.state.campaignBriefSupportingDocumentAssetDetails[i]
                  .suppDocName,
              assetStatus:
                self.state.campaignBriefSupportingDocumentAssetDetails[i]
                  .assetStatus,
              multiTouch:
                self.state.campaignBriefSupportingDocumentAssetDetails[i]
                  .multiTouch,
            });
          }
          self.setState({ assetListArray });
        }
      })
      // 3428-karan- added missing catch block to handle loader
      .catch((err) => {
        console.log(err);
        //3428-karan-loader
        self.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            self.dismissLoader();
          }
        );
      });

    //This API is for campaign supporting document ABM details from delivery format
    fetch(
      "/publisher/campaignBriefSupportingDocumentAbmDetails?campID=" +
        campID +
        ""
    )
      .then((res) => res.json())
      .then((campaignBriefSupportingDocumentAbmDetails) => {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            self.dismissLoader(); //Rutuja 5017 changed this to self as page going crash
          }
        );
        this.setState({
          campaignBriefSupportingDocumentAbmDetails:
            campaignBriefSupportingDocumentAbmDetails,
        });
        if (
          this.state.campaignBriefSupportingDocumentAbmDetails !== undefined || //Rutuja 2978
          this.state.campaignBriefSupportingDocumentAbmDetails !== "" //Rutuja 2978
        ) {
          for (
            var i = 0;
            i < this.state.campaignBriefSupportingDocumentAbmDetails.length;
            i++
          ) {
            abmFile.push(
              this.state.campaignBriefSupportingDocumentAbmDetails[i]
                .suppDocName + " | "
            );
          }
        }
      })
      // 3428-karan- added missing catch block to handle loader
      .catch((err) => {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            self.dismissLoader(); //Rutuja 5017 changed this to self as page going crash
          }
        );
        console.log(err);
      });

    //This API is for campaign supporting document Suppresion details from delivery format
    fetch(
      "/publisher/campaignBriefSupportingDocumentSuppresionDetails?campID=" +
        campID +
        ""
    )
      .then((res) => res.json())
      .then((campaignBriefSupportingDocumentSuppresionDetails) => {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            self.dismissLoader(); //Rutuja 5017 changed this to self as page going crash
          }
        );
        if (campaignBriefSupportingDocumentSuppresionDetails.length > 0) {
          var isSuppLink =
            campaignBriefSupportingDocumentSuppresionDetails[0].isSuppLink;
        }
        this.setState({
          isSuppLink: isSuppLink,
          campaignBriefSupportingDocumentSuppresionDetails:
            campaignBriefSupportingDocumentSuppresionDetails,
        });
        if (
          this.state.campaignBriefSupportingDocumentSuppresionDetails !== //Rutuja 2978
            undefined ||
          this.state.campaignBriefSupportingDocumentSuppresionDetails !== "" //Rutuja 2978
        ) {
          for (
            var i = 0;
            i <
            this.state.campaignBriefSupportingDocumentSuppresionDetails.length;
            i++
          ) {
            suppressionFile.push(
              this.state.campaignBriefSupportingDocumentSuppresionDetails[i]
                .suppDocName + " | "
            );
          }
        }
      })
      // 3428-karan- added missing catch block to handle loader
      .catch((err) => {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            self.dismissLoader(); //Rutuja 5017 changed this to self as page going crash
          }
        );
        console.log(err);
      });

    //This API is for campaign supporting document Exclusion details from delivery format
    fetch(
      "/publisher/campaignBriefSupportingDocumentExclusionDetails?campID=" +
        campID +
        ""
    )
      .then((res) => res.json())
      .then((campaignBriefSupportingDocumentExclusionDetails) => {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            self.dismissLoader(); //Rutuja 5017 changed this to self as page going crash
          }
        );
        this.setState({
          campaignBriefSupportingDocumentExclusionDetails:
            campaignBriefSupportingDocumentExclusionDetails,
        });
        if (
          this.state.campaignBriefSupportingDocumentExclusionDetails !== //Rutuja 2978
            undefined ||
          this.state.campaignBriefSupportingDocumentExclusionDetails !== "" //Rutuja 2978
        ) {
          for (
            var i = 0;
            i <
            this.state.campaignBriefSupportingDocumentExclusionDetails.length;
            i++
          ) {
            execlusionFile.push(
              this.state.campaignBriefSupportingDocumentExclusionDetails[i]
                .suppDocName + " | "
            );
          }
        }
      })
      // 3428-karan- added missing catch block to handle loader
      .catch((err) => {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            self.dismissLoader(); //Rutuja 5017 changed this to self as page going crash
          }
        );
        console.log(err);
      });

    //This API is for campaign supporting document Other details from delivery format
    fetch(
      "/publisher/campaignBriefSupportingDocumentOtherDetails?campID=" +
        campID +
        ""
    )
      .then((res) => res.json())
      .then((campaignBriefSupportingDocumentOtherDetails) => {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            self.dismissLoader(); //Rutuja 5017 changed this to self as page going crash
          }
        );
        this.setState({
          campaignBriefSupportingDocumentOtherDetails:
            campaignBriefSupportingDocumentOtherDetails,
        });
        if (
          this.state.campaignBriefSupportingDocumentOtherDetails !==
            undefined || //Rutuja 2978
          this.state.campaignBriefSupportingDocumentOtherDetails !== "" //Rutuja 2978
        ) {
          for (
            var i = 0;
            i < this.state.campaignBriefSupportingDocumentOtherDetails.length;
            i++
          ) {
            otherFile.push(
              this.state.campaignBriefSupportingDocumentOtherDetails[i]
                .suppDocName + " | "
            );
          }
        }
      })
      // 3428-karan- added missing catch block to handle loader
      .catch((err) => {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            self.dismissLoader(); //Rutuja 5017 changed this to self as page going crash
          }
        );
        console.log(err);
      });
    fetch("agency/getAssetLinkInSpec?campID=" + campID)
      .then((res) => res.json())
      .then((assetLinkArray) => {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            self.dismissLoader(); //Rutuja 5017 changed this to self as page going crash
          }
        );
        this.setState({ assetLinkArray: assetLinkArray });
        if (this.state.assetLinkArray.length > 0) {
          var linkListArray = [];
          for (var i = 0; i < this.state.assetLinkArray.length; i++) {
            linkListArray.push({
              supportDocID: this.state.assetLinkArray[i].supportDocID,
              leadPercentage: this.state.assetLinkArray[i].leadPercentage,
              leadPerAsset: this.state.assetLinkArray[i].leadPerAsset,
              suppDocName: this.state.assetLinkArray[i].suppDocName,
              assetLink: this.state.assetLinkArray[i].assetLink,
              assetStatus: this.state.assetLinkArray[i].assetStatus,
              multiTouch: this.state.assetLinkArray[i].multiTouch,
            });
          }
          this.setState({ linkListArray });
        }
      })
      .catch(function (err) {
        //3428-karan-loader
        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            self.dismissLoader(); //Rutuja 5017 changed this to self as page going crash
          }
        );
        console.log(err);
      });
      //Rutuja task 5209 fetch call for column sequencing
      fetch("agency/getColumnSequencing?campID=" + campID)
      .then((res) => res.json())
      .then((response) => {

        this.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            self.dismissLoader(); 
          }
        );
        if (response.columnSequenceCheck === "Yes") {
          // If columnsSequence is present in the response
          this.setState({ columnsequencingarray: response.columnsSequence , columnSequenceCheck: response.columnSequenceCheck});
        } else {
          // If only columnSequenceCheck is present in the response
          this.setState({ columnSequenceCheck: response.columnSequenceCheck });
        } 
       
      })
      .catch(function (err) {
        self.setState(
          (prev) => ({
            apiCallsCompleted: prev.apiCallsCompleted + 1,
          }),
          () => {
            self.dismissLoader(); //Rutuja 5017 changed this to self as page going crash
          }
        );
        console.log(err);
      });
    this.setState({ show: true });
  } // end of handleCampaignBriefDetails

  /**
   * @author Somnath Keswad
   * @param  Description  get multiple Link of Asset
   * @return Description return open Multiple Link when i click on button in campSpec
   */
  openAllLink() {
    var length = this.state.assetLinkArray.length;
    for (let i = 0; i < length; i++) {
      // window.open(this.state.assetLinkArray[i].assetLink,);
      let assetLink1 = this.state.assetLinkArray[i].assetLink;
      let assethttps = assetLink1.includes("https://");
      let assethttp = assetLink1.includes("http://");
      if (assethttps === true || assethttp === true) {
        window.open(this.state.assetLinkArray[i].assetLink);
      } else {
        window.open("https://" + this.state.assetLinkArray[i].assetLink);
      }
    }
  }

  /**
   * @author Snehal More
   * @param  Description  get  Link of Asset
   * @return Description return open Link when i click on link in campSpec
   */
  openAssetLink(e) {
    var assetLink = e.target.getAttribute("assetLink");
    let assethttps = assetLink.includes("https://");
    let assethttp = assetLink.includes("http://");
    if (assethttps === true || assethttp === true) {
      window.open(assetLink);
    } else {
      window.open("https://" + assetLink);
    }
  }
  // * @author Somnath Keswad
  // * @param  Description  get multiple Link of Suppression
  // * @return Description return open Multiple Link when i click on button in campSpec
  // */
  openAllSuppLink() {
    var supp = this.state.campaignBriefSupportingDocumentSuppresionDetails;
    var suppData = supp.filter(function (s) {
      return !s.assetLink == "";
    });
    for (var i = 0; i < suppData.length; i++) {
      window.open(suppData[i].assetLink);
    }
  }

  /**
   *
   * @author : Karan Jagtap
   * @description : dismisses the loader
   */
  dismissLoader = () => {
    if (this.state.apiCallsCompleted === this.state.totalApiCalls) {
      this.closeLoadingRef.current.click();
    }
  };

  render() {
    const { submitting, value } = this.state;

// Rutuja task 5209 added columns for column sequencing table 
    const columns = [
     
      {
				
				width: "3%",

				render: (text, record ,index) => (
					<div >
						<span style={{ fontSize: "14px" }}>
							Column {index + 1}
						</span>
						<br />
					</div>
				),
			},
      {
				
				width: "6%",

				render: (text, record) => (
					<div >
						<span style={{ fontSize: "14px",fontWeight:"bold" }}>
							{record.value}
						</span>
						<br />
					</div>
				),
			},
    ];
    return (
      <div>
        {/* Hello Campaign Specification {this.props.greeting} */}
        {/* 3428-karan-di loader */}
        <CampaignSpecificationLoader
          loadingRef={this.loadingRef}
          closeLoadingRef={this.closeLoadingRef}
          message="wait a moment while we are loading your data."
        />
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          {/* <div style={{ border: "1px solid #cdcdcd" }}> */}
          <div style={{ border: "white" }}>
            {/* snehal-task-3561-Reff(3428)----spec and cust mapping format issue(added css for subtitles)  */}
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "20px",
                color: "#193d8f",
                fontWeight: "600",
              }}
            >
              Basic Details :{" "}
            </div>
            {/* <div class="table-responsive">
                      <table id="myTable" className=" table table-bordered">
                        <thead>
                          <tr class="info">
                            <th class="table-header">Basic Details</th>
                          </tr>
                        </thead>
                      </table>
                      </div>
                   */}

            {/* /**
             * @author Narendra Phadke
             * @param  Description Display Campaign Details on Modal
             * @return Description return Campaign Details
             */}
            <div
              class="card"
              style={{
                backgroundColor: "#f8f8f8",
                borderColor: "#707070",
                borderWidth: "0.3px",
              }}
            >
              <CardBody>
                {this.state.campaignBriefDetails.map((campaignBriefDetails) => (
                  <div class="row">
                    <div class="col-lg-12">
                      <table>
                        <tr>
                          <td style={{ width: "170px" }}>
                            <label id="label">&nbsp;Campaign Name</label>
                          </td>
                          <td>:&nbsp;{campaignBriefDetails.campaignName}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                ))}

                {this.state.campaignBriefDetails.map((campaignBriefDetails) => (
                  <div class="row">
                    <div class="col-lg-4">
                      <table>
                        <tr>
                          <td style={{ width: "170px" }}>
                            <label id="label">&nbsp;Client Name</label>
                          </td>
                          <td>
                            <span>
                              :&nbsp;{campaignBriefDetails.clientName}
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ width: "170px" }}>
                            <label id="label">&nbsp;Lead Allocation</label>
                          </td>
                          <td>
                            <span>
                              :&nbsp;{campaignBriefDetails.leadAllocation}
                            </span>
                          </td>
                        </tr>

                        <tr>
                          {/* User Story - 3428 - Karan Jagtap - top alignment */}
                          <td style={{ width: "170px", display: "flex" }}>
                            <label id="label">&nbsp;Timezone</label>
                          </td>
                          <td>
                            <span>:&nbsp;{campaignBriefDetails.timezone}</span>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div class="col-lg-4">
                      <table>
                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">Agency Campaign ID</label>
                          </td>
                          <td>
                            <span>
                              :&nbsp;{campaignBriefDetails.clientCampID}
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label"> Start Date</label>
                          </td>
                          <td>
                            <span>:&nbsp;{campaignBriefDetails.startDate}</span>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">First Lead Delivery Date</label>
                          </td>
                          <td>
                            <span>
                              :&nbsp;
                              {campaignBriefDetails.firstLeadDeliveryDate}
                            </span>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div class="col-lg-4">
                      <table>
                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">Campaign Status</label>
                          </td>
                          <td>
                            <span>
                              :&nbsp;{campaignBriefDetails.campaignStatus}
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">End Date</label>
                          </td>
                          <td>
                            <span>:&nbsp;{campaignBriefDetails.endDate}</span>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">No Of Lead Per Domain</label>
                          </td>
                          <td>
                            <span>
                              :&nbsp;{campaignBriefDetails.noOfLeadPerDomain}
                            </span>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                ))}
              </CardBody>
            </div>
            {/* /*row1 */}
            <br />
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "20px",
                color: "#193d8f",
                fontWeight: "600",
              }}
            >
              Delivery Option :
            </div>
            <div
              class="card"
              style={{
                backgroundColor: "#f8f8f8",
                borderColor: "#707070",
                borderWidth: "0.3px",
              }}
            >
              {/* <table id="myTable" className=" table table-bordered">
                        <thead>
                          <tr class="info"> */}
              {/* <th class="table-header">Delivery Option</th>
                          </tr>
                        </thead>
                      </table> */}
              {/* /**
               * @author Narendra Phadke
               * @param  Description Display Campaign Details on Modal
               * @return Description return Campaign Details
               */}
              <CardBody>
                {this.state.campaignBriefDetails.map((campaignBriefDetails) => (
                  <div class="row">
                    <div class="col-lg-4">
                      <table>
                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">Campaign Type</label>
                          </td>
                          <td>:&nbsp;{campaignBriefDetails.ABM}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "170px" }}>
                            <label id="label">Campaign Reporting Day</label>
                          </td>
                          <td>
                            :&nbsp;{campaignBriefDetails.campaignReportingDay}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "170px" }}>
                            <label id="label">Creative Approval</label>
                          </td>
                          {campaignBriefDetails.requiredLPApproval === "" ||
                          campaignBriefDetails.requiredLPApproval === null ||
                          campaignBriefDetails.requiredLPApproval ===
                            undefined ? (
                            <td>:&nbsp;No </td>
                          ) : (
                            <td>
                              {" "}
                              :&nbsp;
                              {campaignBriefDetails.requiredLPApproval[0] ===
                              ","
                                ? campaignBriefDetails.requiredLPApproval.slice(
                                    1
                                  )
                                : campaignBriefDetails.requiredLPApproval}
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td style={{ width: "170px" }}>
                            <label id="label">Call Audit</label>
                          </td>
                          <td>
                            :&nbsp;
                            {campaignBriefDetails.callAudit === "undefined" ||
                            campaignBriefDetails.callAudit === "null" ||
                            campaignBriefDetails.callAudit === null ||
                            campaignBriefDetails.callAudit === undefined
                              ? ""
                              : campaignBriefDetails.callAudit}
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div class="col-lg-4">
                      <table>
                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">Marketing Channel</label>
                          </td>
                          <td>
                            :&nbsp;{campaignBriefDetails.marketingChannel}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">Lead Delivery Option</label>
                          </td>
                          <td>
                            :&nbsp;{campaignBriefDetails.leadDeliveryOption}
                          </td>
                        </tr>

                        {campaignBriefDetails.requiredLPApproval === "" ? (
                          ""
                        ) : (
                          <tr>
                            <td style={{ width: "160px" }}>
                              <label id="label">
                                Creative Approval Timeline (Hours)
                              </label>
                            </td>
                            <td>:&nbsp;{campaignBriefDetails.lpTimeline}</td>
                          </tr>
                        )}

                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">Pacing Unit </label>
                          </td>
                          <td>
                            <span>:&nbsp;{this.state.pacingUnit}</span>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div class="col-lg-4">
                      <table>
                        {/* User Story : 3188 : Karan Jagtap : Sub-Contracting value shown */}
                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">Sub-Contracting</label>
                          </td>
                          {campaignBriefDetails.subContracting === undefined ||
                          campaignBriefDetails.subContracting === null ||
                          campaignBriefDetails.subContracting === "undefined" ||
                          campaignBriefDetails.subContracting === "null" ||
                          campaignBriefDetails.subContracting === "" ? (
                            <td>:</td>
                          ) : (
                            <td>
                              {" "}
                              :&nbsp;
                              {campaignBriefDetails.subContracting}
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td style={{ width: "160px" }}>
                            <label id="label">Lead Interaction Days</label>
                          </td>
                          <td>
                            <span>
                              :&nbsp;
                              {campaignBriefDetails.leadInteractionDays ===
                                "" ||
                              campaignBriefDetails.leadInteractionDays ===
                                undefined
                                ? 0
                                : campaignBriefDetails.leadInteractionDays}
                            </span>
                          </td>
                        </tr>
                        {campaignBriefDetails.requiredLPApproval === "" ||
                        campaignBriefDetails.requiredLPApproval === null ||
                        campaignBriefDetails.requiredLPApproval ===
                          undefined ? (
                          ""
                        ) : (
                          <tr>
                            <td style={{ width: "170px" }}>
                              <label id="label">
                                Creative Approval Required For
                              </label>
                            </td>
                            <td>
                              :&nbsp;
                              {campaignBriefDetails.creativeApprovalRequiredFor}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td style={{ width: "160px" }}>
                            {" "}
                            <label id="label">Pacing</label>
                          </td>
                          <td>:&nbsp;{campaignBriefDetails.pacing}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                ))}
                {this.state.pacingCampaignDetails.length > 0 ? (
                  <div>
                    <br />
                    <table class="table table-bordered">
                      <thead>
                        <tr class="info">
                          <th
                            style={{
                              color: "#707070",
                              backgroundColor: " rgba(126, 127, 129, 0.22)",
                            }}
                          >
                            Pacing Month
                          </th>
                          <th
                            style={{
                              color: "#707070",
                              backgroundColor: " rgba(126, 127, 129, 0.22)",
                            }}
                          >
                            Pacing Percentage
                          </th>
                          <th
                            style={{
                              color: "#707070",
                              backgroundColor: " rgba(126, 127, 129, 0.22)",
                            }}
                          >
                            Pacing Lead Count
                          </th>
                          <th
                            style={{
                              color: "#707070",
                              backgroundColor: " rgba(126, 127, 129, 0.22)",
                            }}
                          >
                            Pacing End Date
                          </th>
                          <th
                            style={{
                              color: "#707070",
                              backgroundColor: " rgba(126, 127, 129, 0.22)",
                            }}
                          >
                            Pacing carry forward date
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {this.state.pacingCampaignDetails.map(
                          (pacingCampaignDetails, i) => {
                            return (
                              <tr>
                                <td>
                                  <span>
                                    {pacingCampaignDetails.pacingMonth}
                                  </span>
                                </td>
                                <td>
                                  {/* snehal-task-3598-Akshay sir-Campaign specification changes */}
                                  <span>
                                    {pacingCampaignDetails.pacingPercentage}%
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {pacingCampaignDetails.pacingLeadCount}
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {pacingCampaignDetails.pacingEndDate}
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {pacingCampaignDetails.pacingCarryForward}
                                  </span>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  ""
                )}
              </CardBody>
            </div>

            {/* /*row 2 */}
            <br />
            {/* <table id="myTable" className=" table table-bordered">
                        <thead>
                          <tr class="info">
                            <th class="table-header">Campaign Specification</th>
                          </tr>
                        </thead>
                      </table> */}
            {/* /**
             * @author Narendra Phadke
             * @param  Description Display Campaign Details on Modal
             * @return Description return Campaign Details
             */}
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "20px",
                color: "#193d8f",
                fontWeight: "600",
              }}
            >
              Campaign Specification :
            </div>
            <div
              class="card"
              style={{
                backgroundColor: "#f8f8f8",
                borderColor: "#707070",
                borderWidth: "0.3px",
              }}
            >
              <CardBody>
                {/* User Story - 3428 - Karan Jagtap - changed layout to fit whole label text in 1 line */}
                <div className="row">
                  <div className="col">
                    <table>
                      {this.state.campaignBriefDetails.map(
                        (campaignBriefDetails) => (
                          <>
                            {/* Chaitaya-5412-Replace position of Multilingual Campaign check */}
                            {/* kiran-4923-added Non-English Campaign field */}
                            {/* <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex",
                                }}
                              >
                                <label id="label">
                                  &nbsp;Multilingual Campaign 
                                </label>
                              </td>
                              <td>
                                <span class="word-wrap">
                                  :&nbsp;
                                  {campaignBriefDetails.nonEnglishCheck ===
                                  "Yes" ? (
                                    <td>&nbsp;Yes</td>
                                  ) : (
                                    <td>&nbsp;No</td>
                                  )}
                                </span>
                              </td>
                            </tr> */}
                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">&nbsp;Region</label>
                              </td>
                              <td>:&nbsp;{campaignBriefDetails.region}</td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">&nbsp;Country</label>
                              </td>
                              <td>
                                <span class="word-wrap">
                                  :&nbsp;
                                  {campaignBriefDetails.country === "null" ||
                                  campaignBriefDetails.country === null ||
                                  campaignBriefDetails.country === undefined ||
                                  campaignBriefDetails.country === "undefined"
                                    ? ""
                                    : campaignBriefDetails.country}
                                </span>
                              </td>
                            </tr>
                            {/* //snehal-task-3793-geowise lead display none while rfp is geowise  */}
                            {campaignBriefDetails.biddingType === "Geowise" &&
                            campaignBriefDetails.requiredCountryWiseAllocation ===
                              "Yes" ? (
                              ""
                            ) : (
                              <tr>
                                <td
                                  style={{
                                    width: "170px",
                                    display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                  }}
                                >
                                  <label id="label">
                                    &nbsp;Lead Allocation by country
                                  </label>
                                </td>
                                <td>
                                  {/* Sandeep-task-2441-added code for countryWise lead allocation */}
                                  <span class="word-wrap">
                                    :&nbsp;
                                    {campaignBriefDetails.requiredCountryWiseAllocation ===
                                      "null" ||
                                    campaignBriefDetails.requiredCountryWiseAllocation ===
                                      null ||
                                    campaignBriefDetails.requiredCountryWiseAllocation ===
                                      undefined ||
                                    campaignBriefDetails.requiredCountryWiseAllocation ===
                                      "undefined"
                                      ? ""
                                      : campaignBriefDetails.requiredCountryWiseAllocation}
                                  </span>
                                </td>
                              </tr>
                            )}
                          </>
                        )
                      )}
                    </table>
                    <div className="row">
                      {this.state.campaignBriefDetails.map(
                        (campaignBriefDetails) => (
                          <>
                            {/* snehal-task-3598-Akshay sir-Campaign specification changes (for zip code state and city)*/}
                            {/* snehal-task-3670-STC---agency side---edit camp---zip code, state, city file issue*/}
                            {campaignBriefDetails.stateFileName !== "" &&
                            campaignBriefDetails.state === "Yes" ? (
                              <div className="col-lg-4">
                                {/* User Story - 3428 - Karan Jagtap - top alignment */}
                                <table>
                                  <tr>
                                    <td
                                      style={{
                                        width: "170px",
                                        display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                      }}
                                    >
                                      <label id="label">&nbsp;State</label>
                                    </td>
                                    <td>
                                      <span class="word-wrap">
                                        :&nbsp;
                                        {campaignBriefDetails.stateFileName}
                                      </span>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            ) : (
                              ""
                            )}
                            {campaignBriefDetails.cityFileName !== "" &&
                            campaignBriefDetails.city === "Yes" ? (
                              <div className="col-lg-4">
                                <table>
                                  <tr>
                                    <td
                                      style={{
                                        width: "170px",
                                        display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                      }}
                                    >
                                      <label id="label">&nbsp;City</label>
                                    </td>
                                    <td>
                                      <span class="word-wrap">
                                        :&nbsp;
                                        {campaignBriefDetails.cityFileName}
                                      </span>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            ) : (
                              ""
                            )}
                            {campaignBriefDetails.zipCodeFileName !== "" &&
                            campaignBriefDetails.zipCode === "Yes" ? (
                              <div className="col-lg-4">
                                <table>
                                  <tr>
                                    <td
                                      style={{
                                        width: "170px",
                                        display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                      }}
                                    >
                                      <label id="label">&nbsp;Zip Code</label>
                                    </td>
                                    <td>
                                      <span class="word-wrap">
                                        :&nbsp;
                                        {campaignBriefDetails.zipCodeFileName}
                                      </span>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        )
                      )}
                    </div>
                    <table>
                      {this.state.campaignBriefDetails.map(
                        (campaignBriefDetails) => (
                          <>
                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">&nbsp;Job Level</label>
                              </td>
                              <td>
                                <span class="word-wrap">
                                  :&nbsp;
                                  {campaignBriefDetails.jobLevel === "|"
                                    ? " "
                                    : campaignBriefDetails.jobLevel}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">
                                  &nbsp;Job &nbsp;Function
                                </label>
                              </td>
                              <td>
                                <span class="word-wrap">
                                  :&nbsp;
                                  {campaignBriefDetails.jobFunction === "|"
                                    ? ""
                                    : campaignBriefDetails.jobFunction}
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">&nbsp;Job Title</label>
                              </td>
                              <td>
                                <span class="word-wrap">
                                  :&nbsp;{campaignBriefDetails.jobTitle}
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">&nbsp;Industry</label>
                              </td>
                              {/**shivani-task 3081-added code to reflect industry and custome industry values in speciffication.**/}
                              <td>
                                :&nbsp;
                                {/* Chaitanya-4878- added condition for if industry is NA */}
                                {(campaignBriefDetails.industry === "" &&
                                  campaignBriefDetails.customIndustry === "") ||
                                campaignBriefDetails.industry === "NA" ? (
                                  <span class="word-wrap">Not Applicable</span>
                                ) : campaignBriefDetails.customIndustry ===
                                  "" ? (
                                  <span class="word-wrap">
                                    {campaignBriefDetails.industry}
                                  </span>
                                ) : campaignBriefDetails.industry === "" ? (
                                  <span class="word-wrap">
                                    {campaignBriefDetails.customIndustry}
                                  </span>
                                ) : (
                                  <span class="word-wrap">
                                    {
                                      // Chaitanya-4893- Commented condition for custom Industry size is "null"
                                      // campaignBriefDetails.customIndustry ===
                                      //   "null" ||
                                      campaignBriefDetails.customIndustry ===
                                        null ||
                                      campaignBriefDetails.customIndustry ===
                                        undefined ||
                                      campaignBriefDetails.customIndustry ===
                                        "undefined" ||
                                      campaignBriefDetails.industry ===
                                        "null" ||
                                      campaignBriefDetails.industry === null ||
                                      campaignBriefDetails.industry ===
                                        undefined ||
                                      campaignBriefDetails.industry ===
                                        "undefined"
                                        ? ""
                                        : campaignBriefDetails.industry +
                                          "|" +
                                          campaignBriefDetails.customIndustry
                                    }
                                  </span>
                                )}
                              </td>
                            </tr>
                            {/* snehal-task-3647-Industry Exclusion on DI - FE */}
                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">
                                  &nbsp;Industry &nbsp;Exclusion
                                </label>
                              </td>
                              <td>
                                :&nbsp;{" "}
                                {/* snehal-task-3667-ref(3647)--Industry file--check box issue (changes in comparison)*/}
                                {campaignBriefDetails.excludedIndustryFlag ===
                                "Yes" ? (
                                  <span className="word-wrap">
                                    {
                                      campaignBriefDetails.excludedIndustryFileName
                                    }
                                  </span>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">
                                  &nbsp;Employee &nbsp;Size
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {/* Chaitanya-4878- added condition for if Employee size is NA */}
                                {(campaignBriefDetails.employeeSize === "" &&
                                  campaignBriefDetails.customEmpSize === "") ||
                                campaignBriefDetails.employeeSize === "NA" ? (
                                  <span class="word-wrap">Not Applicable</span>
                                ) : (
                                  <span class="word-wrap">
                                    {
                                      // Chaitanya-4878- Commented condition for custom employee size is "null"
                                      // campaignBriefDetails.customEmpSize ===
                                      //   "null" ||
                                      campaignBriefDetails.customEmpSize ===
                                        null ||
                                      campaignBriefDetails.customEmpSize ===
                                        undefined ||
                                      campaignBriefDetails.customEmpSize ===
                                        "undefined" ||
                                      campaignBriefDetails.employeeSize ===
                                        "null" ||
                                      campaignBriefDetails.employeeSize ===
                                        null ||
                                      campaignBriefDetails.employeeSize ===
                                        undefined ||
                                      campaignBriefDetails.employeeSize ===
                                        "undefined"
                                        ? ""
                                        : campaignBriefDetails.employeeSize
                                            .length > 0 &&
                                          campaignBriefDetails.customEmpSize
                                        ? campaignBriefDetails.employeeSize +
                                          "|" +
                                          campaignBriefDetails.customEmpSize
                                        : campaignBriefDetails.employeeSize +
                                          "" +
                                          campaignBriefDetails.customEmpSize
                                    }
                                  </span>
                                  //  snehal-task-3343----edit campaign---employee size, company revenue
                                )}
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">
                                  &nbsp;Company &nbsp;Revenue
                                </label>
                              </td>
                              <td>
                                {/* campaignBriefDetails.companyRevenue+','+campaignBriefDetails.customCompRevenue */}
                                <span class="word-wrap">
                                  :&nbsp;
                                  {campaignBriefDetails.companyRevenue +
                                    campaignBriefDetails.customCompRevenue ===
                                  "|"
                                    ? ""
                                    : campaignBriefDetails.companyRevenue
                                        .length === 0 &&
                                      campaignBriefDetails.customCompRevenue
                                        .length > 0
                                    ? campaignBriefDetails.companyRevenue +
                                      campaignBriefDetails.customCompRevenue
                                    : campaignBriefDetails.companyRevenue
                                        .length > 0 &&
                                      campaignBriefDetails.customCompRevenue
                                        .length > 0
                                    ? campaignBriefDetails.companyRevenue +
                                      "|" +
                                      campaignBriefDetails.customCompRevenue
                                    : campaignBriefDetails.companyRevenue +
                                      campaignBriefDetails.customCompRevenue}
                                </span>
                                {/* //  snehal-task-3343----edit campaign---employee size, company revenue */}
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  width: "170px",
                                  display: "flex", // User Story - 3428 - Karan Jagtap - top alignment
                                }}
                              >
                                <label id="label">&nbsp;Other Specs</label>
                              </td>
                              <td>
                                <span class="word-wrap">
                                  :&nbsp;
                                  {campaignBriefDetails.otherSpecs === "null" ||
                                  campaignBriefDetails.otherSpecs === null ||
                                  campaignBriefDetails.otherSpecs ===
                                    undefined ||
                                  campaignBriefDetails.otherSpecs ===
                                    "undefined"
                                    ? ""
                                    : campaignBriefDetails.otherSpecs}
                                </span>
                              </td>
                            </tr>
                          </>
                        )
                      )}
                    </table>
                  </div>
                </div>
              </CardBody>
            </div>

            <br />
            {/* Chaitanya-5412-Displayed listed checkboxes values on Campaign Specification */}
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "20px",
                color: "#193d8f",
                fontWeight: "600",
              }}
            >
              Campaign Settings :
            </div>
            <div
              class="card"
              style={{
                backgroundColor: "#f8f8f8",
                borderColor: "#707070",
                borderWidth: "0.3px",
              }}
            >
              <CardBody>
                <div className="row">
                  {this.state.campaignBriefDetails.map(
                    (campaignBriefDetails) => (
                        <div className="col-lg-4">
                          <table>
                            <tr>
                              <td>
                                <label id="label">&nbsp;DI QA</label>
                              </td>
                              <td>:&nbsp;{campaignBriefDetails.campaignLevelSwitch}</td>
                            </tr>
                          </table>
                        </div>
                      )
                  )}
                  {this.state.campaignBriefDetails.map(
                    (campaignBriefDetails) => (
                        <div className="col-lg-4">
                          <table>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Multilingual Campaign</label>
                              </td>
                              {/* <td>:&nbsp;{campaignBriefDetails.campaignLevelSwitch}</td> */}
                              <td>
                                <span class="word-wrap">
                                  :&nbsp;
                                  {campaignBriefDetails.nonEnglishCheck ===
                                  "Yes" ? (
                                    <td>&nbsp;Yes</td>
                                  ) : (
                                    <td>&nbsp;No</td>
                                  )}
                                </span>
                              </td>
                            </tr>
                          </table>
                        </div>
                      )
                  )}
                  {this.state.campaignBriefDeliveryDetails.map(
                    (campaignBriefDeliveryDetails) => (
                        <div className="col-lg-4">
                          <table>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Job Title Validation</label>
                              </td>
                              <td>:&nbsp;{campaignBriefDeliveryDetails.jobTitleValidation}</td>
                            </tr>
                          </table>
                        </div>
                      )
                  )}
                </div>
                <div className="row">
                  {this.state.campaignBriefDeliveryDetails.map(
                      (campaignBriefDeliveryDetails) => (
                          <div className="col-lg-4">
                            <table>
                              <tr>
                                <td>
                                  <label id="label">&nbsp;City/State Validation</label>
                                </td>
                                <td>:&nbsp;{campaignBriefDeliveryDetails.cityStateValidationCheck}</td>
                              </tr>
                            </table>
                          </div>
                          )
                  )}
                  {this.state.campaignBriefDeliveryDetails.map(
                      (campaignBriefDeliveryDetails) => (
                          <div className="col-lg-4">
                            <table>
                              <tr>
                                <td>
                                  <label id="label">&nbsp;Country Abbreviation Validation</label>
                                </td>
                                <td>:&nbsp;{campaignBriefDeliveryDetails.countryAbbreviation}</td>
                              </tr>
                            </table>
                          </div>
                      )
                  )}
                </div>
              </CardBody>
            </div>
            <br />
            {/* /**
             * @author Narendra Phadke
             * @param  Description Display Campaign Supporting Document Asset Details on Modal
             * @return Description return Campaign Details
             */}
            {/* <row 3> */}
            {/* <row4></row4> */}
            {/* Sandeep-task-2441-added code for countryWise lead allocation */}
            {/* //snehal-task-3793-geowise lead display none while rfp is geowise  */}
            {this.state.requiredCountryWiseAllocation === "Yes" &&
            this.state.campaignBriefDetails[0].biddingType !== "Geowise" ? (
              <div>
                <div
                  style={{
                    fontFamily: "roboto",
                    fontSize: "20px",
                    color: "#193d8f",
                    fontWeight: "600",
                  }}
                >
                  &nbsp;Lead Allocation by country :
                </div>
                <div
                  class="card"
                  style={{
                    backgroundColor: "#f8f8f8",
                    borderColor: "#707070",
                    borderWidth: "0.3px",
                  }}
                >
                  <CardBody>
                    <table class="col-md-5 col-lg-5 ">
                      <table class="table  table-bordered">
                        <thead>
                          <tr class="info">
                            <th
                              style={{
                                color: "#707070",
                                backgroundColor: " rgba(126, 127, 129, 0.22)",
                              }}
                            >
                              Country Name
                            </th>
                            <th
                              style={{
                                color: "#707070",
                                backgroundColor: " rgba(126, 127, 129, 0.22)",
                              }}
                            >
                              Lead Allocation
                            </th>
                            <th
                              style={{
                                color: "#707070",
                                backgroundColor: " rgba(126, 127, 129, 0.22)",
                              }}
                            >
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.countryWiseAllocationArray.map((item) => (
                            <tr>
                              {item.status === "Removed" ? (
                                <td style={{ color: "red" }}>{item.country}</td>
                              ) : (
                                <td>{item.country}</td>
                              )}
                              {item.status === "Removed" ? (
                                <td style={{ color: "red" }}>{item.lead}</td>
                              ) : (
                                <td>{item.lead}</td>
                              )}
                              {item.status === "Removed" ? (
                                <td style={{ color: "red" }}>{item.status}</td>
                              ) : (
                                <td>{item.status}</td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </table>
                    <br />
                  </CardBody>
                </div>
              </div>
            ) : (
              ""
            )}
            <br />
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "20px",
                color: "#193d8f",
                fontWeight: "600",
              }}
            >
              Supporting Document :
            </div>
            <div
              class="card"
              style={{
                backgroundColor: "#f8f8f8",
                borderColor: "#707070",
                borderWidth: "0.3px",
              }}
            >
              {/* kiran-4364-added display property in label for responsiveness */}
              <CardBody>
                <div class="row">
                  <div class="col-sm-6">
                    {" "}
                    <label
                      id="label"
                      style={{ width: "80px", display: "inline-flex" }}
                    >
                      &nbsp;ABM
                    </label>
                    :&nbsp;
                    {this.state.campaignBriefSupportingDocumentAbmDetails.map(
                      (campaignBriefSupportingDocumentAbmDetails) => (
                        <span className="word-wrap">
                          {
                            campaignBriefSupportingDocumentAbmDetails.suppDocName
                          }
                          |
                        </span>
                      )
                    )}
                  </div>
                  {/* kiran-4351-removed unnecessary full colon(:) in supporting document */}
                  <div class="col-sm-6">
                    <label
                      id="label"
                      style={{ width: "80px", display: "inline-flex" }}
                    >
                      Suppression
                    </label>
                    &nbsp;&nbsp;:&nbsp;&nbsp;
                    {this.state.campaignBriefSupportingDocumentSuppresionDetails
                      .length > 0 ? (
                      <span>
                        &nbsp;
                        {this.state.campaignBriefSupportingDocumentSuppresionDetails.map(
                          (suppressionList) => (
                            <span className="word-wrap">
                              {suppressionList.suppDocName}|
                            </span>
                          )
                        )}
                      </span>
                    ) : (
                      ""
                    )}
                    {this.state.isSuppLink === true ? (
                      <div class="float-right">
                        <button
                          type="button"
                          class="btn btn-primary btn-xs"
                          onClick={this.openAllSuppLink.bind(this)}
                        >
                          Open All
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div class="col-sm-6">
                    <label
                      id="label"
                      style={{ width: "80px", display: "inline-flex" }}
                    >
                      &nbsp;Exclusion
                    </label>
                    &nbsp;:
                    {this.state.campaignBriefSupportingDocumentExclusionDetails.map(
                      (campaignBriefSupportingDocumentExclusionDetails) => (
                        <span className="word-wrap">
                          &nbsp;
                          {
                            campaignBriefSupportingDocumentExclusionDetails.suppDocName
                          }
                          |
                        </span>
                      )
                    )}
                  </div>
                  <div class="col-sm-6">
                    <label
                      id="label"
                      style={{ width: "80px", display: "inline-flex" }}
                    >
                      Other
                    </label>
                    &nbsp; :&nbsp;
                    {this.state.campaignBriefSupportingDocumentOtherDetails.map(
                      (campaignBriefSupportingDocumentOtherDetails) => (
                        <span className="word-wrap">
                          {
                            campaignBriefSupportingDocumentOtherDetails.suppDocName
                          }
                          |
                        </span>
                      )
                    )}
                  </div>
                  {/* snehal-task-3620-Akshay Sir- Add exclude domains like .gov, .mil- Campaign to lead validation --> FE */}
                  {this.state.campaignBriefDetails.excludedDomain !== "" ||
                  this.state.campaignBriefDetails.excludedDomain !== null ||
                  this.state.campaignBriefDetails.excludedDomain !== "null" ||
                  this.state.campaignBriefDetails.excludedDomain !==
                    undefined ||
                  this.state.campaignBriefDetails.excludedDomain !==
                    "undefined" ? (
                    <div class="col-sm-12">
                      <label
                        id="label"
                        style={{ display: "inline-flex", overflow: "visible" }}
                      >
                        {/* Rutuja 5525 added space before exclusion */}
                         &nbsp;Exclude Domain Extension{" "}
                      </label>
                      &nbsp;&nbsp;&nbsp;:
                      {this.state.campaignBriefDetails.map(
                        (campaignBriefDetails) => (
                          <span className="word-wrap">
                            {campaignBriefDetails.excludedDomain}
                          </span>
                        )
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </CardBody>
            </div>

            {/*row 4 */}
            <br />
            {/* <table id="myTable" className=" table table-bordered">
                          <thead>
                            <tr class="info">
                              <th class="table-header">Campaign Asset</th>
                            </tr>
                          </thead>
                        </table> */}
            {/* /**
             * @author Narendra Phadke
             * @param  Description Display Campaign Supporting Document Asset Details on Modal
             * @return Description return Campaign Details
             */}
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "20px",
                color: "#193d8f",
                fontWeight: "600",
              }}
            >
              Campaign Asset :
            </div>

            <div
              class="card"
              style={{
                backgroundColor: "#f8f8f8",
                borderColor: "#707070",
                borderWidth: "0.3px",
              }}
            >
              <CardBody>
                <div class="row">
                  {" "}
                  <div class="scrollmenu">
                    <div class="col-sm-12">
                      <label id="label">Assets File :</label>
                      <br />
                      <br />

                      {this.state.assetListArray.length > 0 ? (
                        <table class="table  table-bordered">
                          <thead>
                            <tr class="info">
                              <th
                                style={{
                                  color: "#707070",
                                  backgroundColor: " rgba(126, 127, 129, 0.22)",
                                }}
                              >
                                Asset ID
                              </th>
                              {this.state.requiredLeadPerAsset === "Yes" ? (
                                <th
                                  style={{
                                    color: "#707070",
                                    backgroundColor:
                                      " rgba(126, 127, 129, 0.22)",
                                  }}
                                >
                                  Lead Percentage (%)
                                </th>
                              ) : (
                                ""
                              )}
                              {this.state.requiredLeadPerAsset === "Yes" ? (
                                <th
                                  style={{
                                    color: "#707070",
                                    backgroundColor:
                                      " rgba(126, 127, 129, 0.22)",
                                  }}
                                >
                                  Lead Per Asset
                                </th>
                              ) : (
                                ""
                              )}
                              {this.state.multiTouch === "Yes" ? (
                                <th
                                  style={{
                                    color: "#707070",
                                    backgroundColor:
                                      " rgba(126, 127, 129, 0.22)",
                                  }}
                                >
                                  Multi Touch
                                </th>
                              ) : (
                                ""
                              )}
                              <th
                                style={{
                                  color: "#707070",
                                  backgroundColor: " rgba(126, 127, 129, 0.22)",
                                }}
                              >
                                Asset Name
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.assetListArray.map((assetListArray) =>
                              assetListArray.assetStatus === "Removed" ? (
                                <tr>
                                  <td>
                                    <span
                                      style={{
                                        color: "red",
                                        textDecoration: "line-through",
                                      }}
                                    >
                                      {assetListArray.supportDocID}
                                    </span>
                                  </td>
                                  {this.state.requiredLeadPerAsset === "Yes" ? (
                                    <td>
                                      <span
                                        style={{
                                          color: "red",
                                          textDecoration: "line-through",
                                        }}
                                      >
                                        {assetListArray.leadPercentage}%
                                      </span>
                                      {/* snehal-task-3598-Akshay sir-Campaign specification changes */}
                                    </td>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.requiredLeadPerAsset === "Yes" ? (
                                    <td>
                                      <span
                                        style={{
                                          color: "red",
                                          textDecoration: "line-through",
                                        }}
                                      >
                                        {assetListArray.leadPerAsset}{" "}
                                      </span>
                                    </td>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.multiTouch === "Yes" ? (
                                    <td>
                                      <span
                                        style={{
                                          color: "red",
                                          textDecoration: "line-through",
                                        }}
                                      >
                                        {assetListArray.multiTouch}
                                      </span>
                                    </td>
                                  ) : (
                                    ""
                                  )}
                                  <td>
                                    <span
                                      style={{
                                        color: "red",
                                        textDecoration: "line-through",
                                      }}
                                    >
                                      {assetListArray.suppDocName}
                                    </span>
                                  </td>
                                </tr>
                              ) : (
                                <tr>
                                  <td>{assetListArray.supportDocID}</td>
                                  {this.state.requiredLeadPerAsset === "Yes" ? (
                                    <td>
                                      {assetListArray.leadPercentage}%
                                    </td> /* snehal-task-3598-Akshay sir-Campaign specification changes */
                                  ) : (
                                    ""
                                  )}
                                  {this.state.requiredLeadPerAsset === "Yes" ? (
                                    <td>{assetListArray.leadPerAsset}</td>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.multiTouch === "Yes" ? (
                                    <td>{assetListArray.multiTouch}</td>
                                  ) : (
                                    ""
                                  )}
                                  <td>{assetListArray.suppDocName}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <br />
                  <div class="scrollmenu">
                    <div class="col-sm-12">
                      {this.state.assetLinkArray.length > 0 ? (
                        <p>
                          <label id="label">&nbsp;Assets link :</label>
                          <br />
                          <br />
                          <div>
                            <table class="col-md-12 col-lg-12 ">
                              <table class="table  table-bordered">
                                <thead>
                                  <tr class="info">
                                    <th
                                      style={{
                                        color: "#707070",
                                        backgroundColor:
                                          " rgba(126, 127, 129, 0.22)",
                                      }}
                                    >
                                      Asset ID
                                    </th>

                                    {this.state.requiredLeadPerAsset !== null &&
                                    this.state.requiredLeadPerAsset ===
                                      "Yes" ? (
                                      <th
                                        style={{
                                          color: "#707070",
                                          backgroundColor:
                                            " rgba(126, 127, 129, 0.22)",
                                        }}
                                      >
                                        Lead Percentage (%)
                                      </th>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.requiredLeadPerAsset !== null &&
                                    this.state.requiredLeadPerAsset ===
                                      "Yes" ? (
                                      <th
                                        style={{
                                          color: "#707070",
                                          backgroundColor:
                                            " rgba(126, 127, 129, 0.22)",
                                        }}
                                      >
                                        Lead Per Asset
                                      </th>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.multiTouch !== null &&
                                    this.state.multiTouch === "Yes" ? (
                                      <th
                                        style={{
                                          color: "#707070",
                                          backgroundColor:
                                            " rgba(126, 127, 129, 0.22)",
                                        }}
                                      >
                                        Multi Touch
                                      </th>
                                    ) : (
                                      ""
                                    )}
                                    <th
                                      style={{
                                        color: "#707070",
                                        backgroundColor:
                                          " rgba(126, 127, 129, 0.22)",
                                      }}
                                    >
                                      Asset Name
                                    </th>
                                    <th
                                      style={{
                                        color: "#707070",
                                        backgroundColor:
                                          " rgba(126, 127, 129, 0.22)",
                                      }}
                                    >
                                      Asset Link
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.linkListArray.map(
                                    (linkListArray) =>
                                      linkListArray.assetStatus ===
                                      "Removed" ? (
                                        <tr>
                                          <td>
                                            <span
                                              style={{
                                                color: "red",
                                                textDecoration: "line-through",
                                              }}
                                            >
                                              {linkListArray.supportDocID}
                                            </span>
                                          </td>
                                          {this.state.requiredLeadPerAsset ===
                                          "Yes" ? (
                                            <td>
                                              <span
                                                style={{
                                                  color: "red",
                                                  textDecoration:
                                                    "line-through",
                                                }}
                                              >
                                                {linkListArray.leadPercentage}%
                                              </span>
                                              {/* snehal-task-3598-Akshay sir-Campaign specification changes */}
                                            </td>
                                          ) : (
                                            ""
                                          )}
                                          {this.state.requiredLeadPerAsset ===
                                          "Yes" ? (
                                            <td>
                                              <span
                                                style={{
                                                  color: "red",
                                                  textDecoration:
                                                    "line-through",
                                                }}
                                              >
                                                {linkListArray.leadPerAsset}
                                              </span>
                                            </td>
                                          ) : (
                                            ""
                                          )}
                                          {/* rutuja - task 4368 removing  this.state.campaignBriefDetails[0].multitouch from below line because page going crashed*/}
                                          {this.state.multiTouch === "Yes" ? (
                                            <td>
                                              <span
                                                style={{
                                                  color: "red",
                                                  textDecoration:
                                                    "line-through",
                                                }}
                                              >
                                                {linkListArray.multiTouch}
                                              </span>
                                            </td>
                                          ) : (
                                            ""
                                          )}
                                          <td>
                                            <span
                                              style={{
                                                color: "red",
                                                textDecoration: "line-through",
                                              }}
                                            >
                                              {linkListArray.suppDocName}
                                            </span>
                                          </td>
                                          <td
                                            style={{ wordBreak: "break-all" }}
                                          >
                                            <a
                                              href={linkListArray.assetLink}
                                              target="_blank"
                                              rel="noopener noreferrer" //Rutuja 2978 added rel attribute
                                            >
                                              <span
                                                style={{
                                                  color: "red",
                                                  textDecoration:
                                                    "line-through",
                                                }}
                                              >
                                                {linkListArray.assetLink}
                                              </span>{" "}
                                            </a>
                                          </td>
                                        </tr>
                                      ) : (
                                        <tr>
                                          <td>{linkListArray.supportDocID}</td>
                                          {this.state.requiredLeadPerAsset ===
                                          "Yes" ? (
                                            <td>
                                              {linkListArray.leadPercentage}%
                                            </td> /* snehal-task-3598-Akshay sir-Campaign specification changes */
                                          ) : (
                                            ""
                                          )}
                                          {this.state.requiredLeadPerAsset ===
                                          "Yes" ? (
                                            <td>
                                              {linkListArray.leadPerAsset}
                                            </td>
                                          ) : (
                                            ""
                                          )}
                                          {this.state.multiTouch === "Yes" ? (
                                            <td>{linkListArray.multiTouch}</td>
                                          ) : (
                                            ""
                                          )}
                                          <td>{linkListArray.suppDocName}</td>
                                          <td
                                            style={{ wordBreak: "break-all" }}
                                          >
                                            <a
                                              href={linkListArray.assetLink}
                                              target="_blank"
                                              rel="noopener noreferrer" //Rutuja 2978 added rel attribute
                                              assetLink={
                                                linkListArray.assetLink
                                              }
                                              onClick={this.openAssetLink.bind(
                                                this
                                              )}
                                            >
                                              {linkListArray.assetLink}{" "}
                                            </a>
                                          </td>
                                        </tr>
                                      )
                                  )}
                                </tbody>
                              </table>
                              <div class="float-right">
                                <button
                                  type="button"
                                  class="btn btn-primary btn-xs"
                                  onClick={this.openAllLink.bind(this)}
                                >
                                  Open All
                                </button>
                              </div>
                            </table>
                          </div>
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </CardBody>
            </div>

            {/*row 4 */}
            <br />

            {/*row 5 */}
            <br />
            {/* <table id="myTable" className=" table table-bordered">
                          <thead>
                            <tr class="info">
                              <th class="table-header">Lead Delivery Through API</th>
                            </tr>
                          </thead>
                        </table> */}
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "20px",
                color: "#193d8f",
                fontWeight: "600",
              }}
            >
              Lead Delivery Through API :
            </div>
            <div
              class="card"
              style={{
                backgroundColor: "#f8f8f8",
                borderColor: "#707070",
                borderWidth: "0.3px",
              }}
            >
              <CardBody>
                <div class="word-wrap">
                  <label>URL </label> :&nbsp;{this.state.deliveryURL}
                  {this.state.customQuestionURL}
                </div>
                <br />
              </CardBody>
            </div>

            {/* <table id="myTable" className=" table table-bordered">
                          <thead>
                            <tr class="info">
                              <th class="table-header">Delivery Format</th>
                            </tr>
                          </thead>
                        </table> */}
            {/* /**
             * @author Narendra Phadke
             * @param  Description Display Campaign Delivery Details on Modal
             * @return Description return Campaign Delivery Details
             */}
            <br />
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "20px",
                color: "#193d8f",
                fontWeight: "600",
              }}
            >
              Delivery Format :
            </div>
            <div
              class="card"
              style={{
                backgroundColor: "#f8f8f8",
                borderColor: "#707070",
                borderWidth: "0.3px",
              }}
            >
              <CardBody>
                {/* snehal-Task-3171-prod issue---specification customize mapping issue(on priority) */}

                {/* User Story - 3428 - Karan Jagtap - Reformating of Delivery format - Start */}
                {/* As this whole block of "Delivery Format" is re-arranged, but the data other than styling is not changed.
								Hence, I've still tried to maintain the comments given by other resources for their respective tasks inside this block  */}
                <div className="row">
                  {this.state.campaignBriefDeliveryDetails.map(
                    (campaignBriefDeliveryDetails) => (
                      <>
                        <div className="col-lg-3">
                          <table>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Publisher ID</label>
                              </td>
                              <td>:&nbsp;{campaignBriefDeliveryDetails.pID}</td>
                            </tr>

                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;Lead interaction date
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {
                                  campaignBriefDeliveryDetails.leadInteractionDate
                                }
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <label id="label">&nbsp;Work phone</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.workPhone}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Industry</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.industry}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;LinkedIn job title
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.linkedInJobTitle}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Country</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.country}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Address</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.address}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;Asset name touch 1
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.assetNameTouch1 ===
                                "Yes"
                                  ? campaignBriefDeliveryDetails.assetNameTouch1
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;LinkedIn</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.linkedIn}
                              </td>
                            </tr>
                            {/** Somnath Task-3128,  campaignBriefDetails.length>0 condition added*/}
                            {this.state.campaignBriefDetails.length > 0 ? (
                              (this.state.campaignBriefDetails[0]
                                .marketingChannel === "Email/Telemarketing" ||
                                this.state.campaignBriefDetails[0]
                                  .marketingChannel === "TeleMarketing") &&
                              (campaignBriefDeliveryDetails.channel === "yes" ||
                                campaignBriefDeliveryDetails.channel ===
                                  "Yes") ? (
                                <tr>
                                  <td>
                                    <label id="label">&nbsp;Channel</label>
                                  </td>
                                  <td>:&nbsp;Yes</td>
                                </tr>
                              ) : (
                                <tr>
                                  <td>
                                    <label id="label">&nbsp;Channel</label>
                                  </td>
                                  <td>:&nbsp;No</td>
                                </tr>
                              )
                            ) : (
                              ""
                            )}
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 4</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra4}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 8</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra8}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 12</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra12}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 16</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra16}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 20</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra20}
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div className="col-lg-3">
                          <table>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Campaign ID</label>
                              </td>
                              <td>
                                :&nbsp;{campaignBriefDeliveryDetails.campaignID}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;First name</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.firstName}
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;Alternate phone no
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.alternatePhoneNo}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;Company employee size
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {
                                  campaignBriefDeliveryDetails.companyEmployeeSize
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;Job title validation
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {
                                  campaignBriefDeliveryDetails.jobTitleValidation
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;State</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.state}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Street</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.street}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;Asset name touch 2
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.assetNameTouch2 ===
                                "Yes"
                                  ? campaignBriefDeliveryDetails.assetNameTouch2
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Domain</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.domain}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 1</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra1}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 5</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra5}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 9</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra9}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 13</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra13}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 17</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra17}
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div className="col-lg-3">
                          <table>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Campaign name</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.campaignName}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Last name</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.lastName}
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <label id="label">&nbsp;Company name</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.companyName}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Company revenue</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.companyRevenue}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Job level</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.jobLevel}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;City</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.city}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Asset name</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.assetNameTouch1 ===
                                "Yes"
                                  ? "No"
                                  : campaignBriefDeliveryDetails.assetName}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;Asset name touch 3
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.assetNameTouch3 ===
                                "Yes"
                                  ? campaignBriefDeliveryDetails.assetNameTouch3
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;IP</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.ip}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 2</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra2}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 6</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra6}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 10</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra10}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 14</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra14}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 18</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra18}
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div className="col-lg-3">
                          <table>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Allocation ID</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.reAllocationID}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Email</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.email}
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;LinkedIn company name
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {
                                  campaignBriefDeliveryDetails.linkedInCompanyName
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Job title</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.jobTitle}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Job function</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.jobFunction}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Zip code</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.zipCode}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Asset ID</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.supportDocID}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">
                                  &nbsp;Customize mapping
                                </label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.customizeMapping ===
                                "Yes"
                                  ? campaignBriefDeliveryDetails.customizeMapping
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Comments</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.comments}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 3</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra3}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 7</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra7}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 11</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra11}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 15</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra15}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label id="label">&nbsp;Extra 19</label>
                              </td>
                              <td>
                                :&nbsp;
                                {campaignBriefDeliveryDetails.extra19}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </>
                    )
                  )}
                </div>
                {/* Aman-5089-added to display setected extra filed 21-30  in Delivery Format*/}
                {this.state.extraAddedFieldsYes.length >= 1 ? (
                  <div style={{marginTop:"20px"}}>
                  <hr style={{borderColor:"#000"}}/>
                  <div
                        style={{
                        fontFamily: "roboto",
                        fontSize: "15px",
                        color: "#193d8f",
                        fontWeight: "600",
                      }}
                  >Additional Fields</div>
                    {this.state.extraAddedFieldsYes.map((details, index) => (
                      <div key={index} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: '20px' }}>
                        {Object.entries(details).map(([key, value]) => (
                          <div key={key} style={{ display: 'flex', marginRight: '80px' }}>
                            <label id="label">&nbsp;{key}</label>
                            <span>:&nbsp;{value}</span>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ) : ("")}
                {/* User Story - 3428 - Karan Jagtap - Reformating of Delivery format - End */}
              </CardBody>
            </div>

            <br />

            {
              this.state.customizedColumns.length === 0 ? (
                ""
              ) : (
                // <table id="myTable" className=" table table-bordered">
                //   <thead>
                //     <tr class="info">
                <div
                  style={{
                    fontFamily: "roboto",
                    fontSize: "20px",
                    color: "#193d8f",
                    fontWeight: "600",
                  }}
                >
                  Customized Column Mapping : &nbsp;
                  {this.state.customizeMappingFormat}
                  {/* kiran-4884-displaying format */}
                </div>
              )
              //     </tr>
              //   </thead>
              // </table>
            }
            {this.state.customizedColumns.length === 0 ? (
              ""
            ) : (
              <div
                class="card"
                style={{
                  backgroundColor: "#f8f8f8",
                  borderColor: "#707070",
                  borderWidth: "0.3px",
                }}
              >
                <CardBody>
                  <div
                    class="container-fluid"
                    style={{ paddingBottom: "11px" }}
                  >
                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3" />
                      <table
                        class="table table-bordered"
                        id="myTable3"
                        style={{ width: "60%" }}
                      >
                        <thead>
                          <tr style={{ height: "35px" }}>
                            <th
                              style={{
                                color: "#707070",
                                backgroundColor: " rgba(126, 127, 129, 0.22)",
                                width: "450px",
                              }}
                              class="tblofcuttumizecolumn"
                            >
                              System Field{" "}
                            </th>
                            <th
                              style={{
                                color: "#707070",
                                backgroundColor: " rgba(126, 127, 129, 0.22)",
                                width: "900px",
                              }}
                              class="tblofcuttumizecolumn"
                            >
                              Mapping Alias Field{" "}
                            </th>
                            <th
                              style={{
                                color: "#707070",
                                backgroundColor: " rgba(126, 127, 129, 0.22)",
                                width: "200px",
                              }}
                              class="tblofcuttumizecolumn"
                            >
                              Values
                            </th>
                          </tr>
                        </thead>

                        {this.state.campaignBriefDeliveryDetails.map(
                          (campaignBriefDeliveryDetails, i) => {
                            return (
                              <tbody>
                                {campaignBriefDeliveryDetails.pID === "yes" ||
                                campaignBriefDeliveryDetails.pID === "Yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Publisher ID </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Publisher ID</b>
                                        ) : (
                                          this.state.customizedColumns[0].pID
                                        )}
                                      </b>{" "}
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.campaignID ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.campaignID ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Campaign ID </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Campaign ID</b>
                                        ) : (
                                          this.state.customizedColumns[0].campID
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.reAllocationID ===
                                  "yes" ||
                                campaignBriefDeliveryDetails.reAllocationID ===
                                  "Yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Allocation ID </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Allocation ID</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .reAllocationID
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.leadInteractionDate ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.leadInteractionDate ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Lead Interaction Date </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Lead Interaction Date</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .leadInteractionDate
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.campaignName ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.campaignName ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Campaign Name </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Campaign Name</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .campaignName
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.firstName ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.firstName ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;First Name</td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>First Name</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .firstName
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.lastName ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.lastName ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Last Name </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Last Name</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .lastName
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.companyName ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.companyName ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Company Name </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Company Name</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .companyName
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {/* Chaitanya-5054-Added linkedInCompanyName*/}
                                {campaignBriefDeliveryDetails.linkedInCompanyName ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.linkedInCompanyName ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;LinkedIn Company Name </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>LinkedIn Company Name</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .linkedInCompanyName
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.email === "Yes" ||
                                campaignBriefDeliveryDetails.email === "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Email </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Email</b>
                                        ) : (
                                          this.state.customizedColumns[0].email
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.workPhone ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.workPhone ===
                                  "yes" ? (
                                  <tr class="CampSpecDashDetailforrespon">
                                    <td>&nbsp;Work Phone </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Work Phone</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .workPhone
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.workPhone ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.workPhone ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Work Phone Format</td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>91-XXXXXXXXXX</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .workPhoneFormat
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.jobTitle ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.jobTitle ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Job Title </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Job Title</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .jobTitle
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {/* Chaitanya-5054-Added linkedInJobTitle */}
                                {campaignBriefDeliveryDetails.linkedInJobTitle ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.linkedInJobTitle ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;LinkedIn Job Title </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>LinkedIn Job Title</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .linkedInJobTitle
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.address ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.address ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Address </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Address</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .address
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.country ===
                                  "yes" ||
                                campaignBriefDeliveryDetails.country ===
                                  "Yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Country </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Country</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .country
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.city === "Yes" ||
                                campaignBriefDeliveryDetails.city === "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;City </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>City</b>
                                        ) : (
                                          this.state.customizedColumns[0].city
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.state === "Yes" ||
                                campaignBriefDeliveryDetails.state === "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;State </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>State</b>
                                        ) : (
                                          this.state.customizedColumns[0].state
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.zipCode ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.zipCode ===
                                  "Yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Zip Code </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Zip Code</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .zipCode
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.companyEmployeeSize ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.companyEmployeeSize ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Company Employee Size </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Company Employee Size</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .companyEmployeeSize
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.companyRevenue ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.companyRevenue ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Company Revenue </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Company Revenue</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .companyRevenue
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.industry ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.industry ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Industry </td>
                                    <td>
                                      &nbsp;Current :
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Industry</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .industry
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.assetName ===
                                  "yes" ||
                                campaignBriefDeliveryDetails.assetName ===
                                  "Yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Asset Name </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Asset Name</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .assetName
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.assetNameTouch1 ===
                                  "yes" ||
                                campaignBriefDeliveryDetails.assetNameTouch1 ===
                                  "Yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Asset Name Touch 1 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Asset Name Touch 1</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .assetNameTouch1
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.assetTimestampTouch1 ===
                                  "yes" ||
                                campaignBriefDeliveryDetails.assetTimestampTouch1 ===
                                  "Yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Asset Timestamp Touch 1 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Asset Timestamp Touch 1</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .assetTimestampTouch1
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.assetNameTouch2 ===
                                  "yes" ||
                                campaignBriefDeliveryDetails.assetNameTouch2 ===
                                  "Yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Asset Name Touch 2 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Asset Name Touch 2</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .assetNameTouch2
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.assetTimestampTouch2 ===
                                  "yes" ||
                                campaignBriefDeliveryDetails.assetTimestampTouch2 ===
                                  "Yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Asset Timestamp Touch 2 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Asset Timestamp Touch 2</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .assetTimestampTouch2
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.assetNameTouch3 ===
                                  "yes" ||
                                campaignBriefDeliveryDetails.assetNameTouch3 ===
                                  "Yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Asset Name Touch 3 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Asset Name Touch 3</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .assetNameTouch3
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.assetTimestampTouch3 ===
                                  "yes" ||
                                campaignBriefDeliveryDetails.assetTimestampTouch3 ===
                                  "Yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Asset Timestamp Touch 3 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Asset Timestamp Touch 3</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .assetTimestampTouch3
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.street ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.street ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Street </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Street</b>
                                        ) : (
                                          this.state.customizedColumns[0].street
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.ip === "Yes" ||
                                campaignBriefDeliveryDetails.ip === "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;IP </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>IP</b>
                                        ) : (
                                          this.state.customizedColumns[0].ip
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.supportDocID ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.supportDocID ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Asset ID </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Asset ID</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .supportDocID
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.jobLevel ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.jobLevel ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Job Level </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Job Level</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .jobLevel
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.jobFunction ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.jobFunction ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Job Function </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Job Function</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .JobFunction
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {this.state.campaignBriefDetails[0]
                                  .marketingChannel ===
                                "Email/Telemarketing" ? (
                                  campaignBriefDeliveryDetails.channel ===
                                    "Yes" ||
                                  campaignBriefDeliveryDetails.channel ===
                                    "yes" ? (
                                    <tr class="tblofcuttumizecolumn">
                                      <td>&nbsp;Channel </td>
                                      <td>
                                        &nbsp;Current:{" "}
                                        <b>
                                          &nbsp;
                                          {this.state.customizedColumns
                                            .length === 0 ? (
                                            <b>Channel</b>
                                          ) : (
                                            this.state.customizedColumns[0]
                                              .channel
                                          )}
                                        </b>
                                        <br />
                                      </td>
                                      <td />
                                    </tr>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra1 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra1 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 1 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 1</b>
                                        ) : (
                                          this.state.customizedColumns[0].extra1
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra2 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra2 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 2 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 2</b>
                                        ) : (
                                          this.state.customizedColumns[0].extra2
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra3 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra3 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 3</td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 3</b>
                                        ) : (
                                          this.state.customizedColumns[0].extra3
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra4 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra4 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 4 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 4</b>
                                        ) : (
                                          this.state.customizedColumns[0].extra4
                                        )}
                                      </b>
                                      <br />{" "}
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra5 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra5 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 5 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 5</b>
                                        ) : (
                                          this.state.customizedColumns[0].extra5
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {/* // Sandeep-task-3158-added code for extra field from 6to20 */}
                                {campaignBriefDeliveryDetails.extra6 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra6 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 6 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 6</b>
                                        ) : (
                                          this.state.customizedColumns[0].extra6
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra7 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra7 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 7 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 7</b>
                                        ) : (
                                          this.state.customizedColumns[0].extra7
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra8 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra8 ===
                                  "yes" ? (
                                  <tr class="CampSpecDashDetailforrespon">
                                    <td class="tblofcuttumizecolumn">
                                      &nbsp;Extra 8{" "}
                                    </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 8</b>
                                        ) : (
                                          this.state.customizedColumns[0].extra8
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra9 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra9 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 9 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 9</b>
                                        ) : (
                                          this.state.customizedColumns[0].extra9
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra10 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra10 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 10 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 10</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra10
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra11 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra11 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 11 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 11</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra11
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra12 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra12 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 12 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 12</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra12
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra13 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra13 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 13 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 13</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra13
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra14 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra14 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 14 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 14</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra14
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra15 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra15 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 15 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 15</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra15
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra16 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra16 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 16 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 16</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra16
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra17 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra17 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 17 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 17</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra17
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra18 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra18 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 18 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 18</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra18
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra19 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra19 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 19 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 19</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra19
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.extra20 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra20 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 20 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 20</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra20
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.domain ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.domain ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Domain </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Domain</b>
                                        ) : (
                                          this.state.customizedColumns[0].domain
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.alternatePhoneNo ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.alternatePhoneNo ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Alternate Phone No </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Alternate Phone No</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .alternatePhoneNo
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.comments ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.comments ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Comments </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Comments</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .comments
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {campaignBriefDeliveryDetails.linkedIn ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.linkedIn ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;LinkedIn </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>LinkedIn</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .linkedIn
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {/* Aman-5089-added to display setected extra filed 21-30 in Customized Column Mapping*/}
                                {campaignBriefDeliveryDetails.extra21 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra21 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 21 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 21</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra21
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.extra22 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra22 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 22 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 22</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra22
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.extra23 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra23 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 23 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 23</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra23
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.extra24 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra24 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 24 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 24</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra24
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.extra25 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra25 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 25 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 25</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra25
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.extra26 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra26 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 26 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 26</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra26
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.extra27 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra27 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 27 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 27</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra27
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.extra28 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra28 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 28 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 28</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra28
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.extra29 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra29 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 29 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 29</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra29
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {campaignBriefDeliveryDetails.extra30 ===
                                  "Yes" ||
                                campaignBriefDeliveryDetails.extra30 ===
                                  "yes" ? (
                                  <tr class="tblofcuttumizecolumn">
                                    <td>&nbsp;Extra 30 </td>
                                    <td>
                                      &nbsp;Current:{" "}
                                      <b>
                                        &nbsp;
                                        {this.state.customizedColumns.length ===
                                        0 ? (
                                          <b>Extra 30</b>
                                        ) : (
                                          this.state.customizedColumns[0]
                                            .extra30
                                        )}
                                      </b>
                                      <br />
                                    </td>
                                    <td />
                                  </tr>
                                ) : (
                                  ""
                                )}
                              </tbody>
                            );
                          }
                        )}
                      </table>
                    </div>
                  </div>
                </CardBody>
              </div>
            )}
            <br />
            {/* Rutuja 5209 if columnseqencing is yes then show below table */}
            {this.state.columnSequenceCheck === "Yes" ? (
              <div>
                {" "}
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "20px",
                color: "#193d8f",
                fontWeight: "600",
              }}
            >
              Column Sequencing :
            </div>
            
            <div
              class="card"
              style={{
                backgroundColor: "#f8f8f8",
                borderColor: "#707070",
                borderWidth: "0.3px",
              }}
            >
              {/* kiran-4364-added display property in label for responsiveness */}
              <CardBody         style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
>
            
                 <div className="table-container" style={{width: "50%"}}>
                  <Table
                  columns={columns} 
                  dataSource={this.state.columnsequencingarray}
                  pagination={false}
                  bordered
                  id="myTable"
                  showHeader={false}
                  size="small"
                  class="ant-table-thead"
                  className={tableCSS}
                  //scroll={{ x: 1250, y: 4000 }}
                  >
 
                  </Table>
                  </div>
                                
                   </CardBody>
                   </div>
                      
                  </div>
                  ):("")}
                      <br/>
            {/*row 5 */}
            {/* /**
             * @author Narendra Phadke
             * @param  Description Display Campaign Custom Question Details on Modal
             * @return Description return Campaign Delivery Details
             */}
            {this.state.campaignBriefCustomQuestionDetails.length > 0 ? (
              <div>
                {" "}
                {/* snehal-task-3112--Custom Question Alias */}
                <div
                  style={{
                    fontFamily: "roboto",
                    fontSize: "20px",
                    color: "#193d8f",
                    fontWeight: "600",
                  }}
                >
                  Custom Questions
                </div>
                {/* Akash-Bug-4215 */}
                {this.state.campaignBriefDetails.map((campaignBriefDetails) => (
                  <div
                    className="card"
                    style={{
                      backgroundColor: "#f8f8f8",
                      borderColor: "#707070",
                      borderWidth: "0.3px",
                    }}
                  >
                    {campaignBriefDetails.customQuestionAliasName === "No" ? (
                      <CardBody>
                        <table>
                          <tr>
                            <td style={{ width: "160px" }}>
                              <label id="label">Add Question Alias</label>
                            </td>
                            {this.state.campaignBriefDetails.map(
                              (campaignBriefDetails) => (
                                <td>
                                  <span>
                                    :&nbsp;
                                    {
                                      campaignBriefDetails.customQuestionAliasName
                                    }
                                  </span>
                                </td>
                              )
                            )}
                          </tr>
                        </table>
                        {this.state.campaignBriefCustomQuestionDetails.map(
                          (campaignBriefCustomQuestionDetails, i) => (
                            <div class="row">
                              <div class="col-sm-12 col-lg-4">
                                {" "}
                                <label id="label">
                                  &nbsp;Question {i + 1}:
                                </label>
                                &nbsp;
                                <textarea
                                  id="customQuestion1"
                                  class="form-control"
                                  name="customQuestion1"
                                  disabled //bug-4450-disabled textarea
                                  defaultValue={unescape(
                                    //Mufiz-Bug-4243-R-28.8-Prod Issue-Agency-Campaign specification not opening -Shows blank page

                                    campaignBriefCustomQuestionDetails.customQuestion
                                  )}
                                  onChange={this.handleChangeCustomQuestion}
                                />
                              </div>
                              <div class="col-sm-12 col-lg-4">
                                {" "}
                                <label id="label">Answer {i + 1}:</label>
                                &nbsp;
                                <textarea
                                  id="answer1"
                                  class="form-control"
                                  name="answer1"
                                  disabled //bug-4450-disabled textarea
                                  defaultValue={unescape(
                                    campaignBriefCustomQuestionDetails.answer
                                  )}
                                  onChange={this.handleChangeCustomQuestion}
                                />
                              </div>
                              <div class="col-sm-12 col-lg-4">
                                {" "}
                                <label id="label">
                                  Not Allowed Answer {i + 1}:
                                </label>
                                &nbsp;
                                <textarea
                                  id="nonallowedanswer"
                                  class="form-control"
                                  name="nonallowedanswer"
                                  disabled //bug-4450-disabled textarea
                                  defaultValue={unescape(
                                    campaignBriefCustomQuestionDetails.disAllowAnswer
                                  )}
                                  onChange={this.handleChangeCustomQuestion}
                                  //kiran- 4351- added unescape to avoid %20 in custom question
                                />
                              </div>
                            </div>
                          )
                        )}
                      </CardBody>
                    ) : (
                      <CardBody>
                        {/* snehal-task-3112--Custom Question Alias */}
                        <table>
                          <tr>
                            <td style={{ width: "160px" }}>
                              <label id="label">Add Question Alias</label>
                            </td>
                            {this.state.campaignBriefDetails.map(
                              (campaignBriefDetails) => (
                                <td>
                                  <span>
                                    :&nbsp;
                                    {
                                      campaignBriefDetails.customQuestionAliasName
                                    }
                                  </span>
                                </td>
                              )
                            )}
                          </tr>
                        </table>
                        {this.state.campaignBriefCustomQuestionDetails.map(
                          (campaignBriefCustomQuestionDetails, i) => (
                            <div class="row">
                              <div class="col-sm-12 col-lg-3">
                                {" "}
                                <label id="label">&nbsp;Alias {i + 1}:</label>
                                &nbsp;
                                <textarea
                                  id="aliasName1"
                                  class="form-control"
                                  name="aliasName1"
                                  disabled //bug-4450-disabled textarea
                                  defaultValue={unescape(
                                    campaignBriefCustomQuestionDetails.aliasName
                                  )}
                                  onChange={this.handleChangeCustomQuestion}
                                />
                              </div>
                              <div class="col-sm-12 col-lg-3">
                                {" "}
                                <label id="label">
                                  &nbsp;Question {i + 1}:
                                </label>
                                &nbsp;
                                <textarea
                                  id="customQuestion1"
                                  class="form-control"
                                  name="customQuestion1"
                                  disabled //bug-4450-disabled textarea
                                  defaultValue={unescape(
                                    campaignBriefCustomQuestionDetails.customQuestion
                                  )}
                                  onChange={this.handleChangeCustomQuestion}
                                />
                              </div>
                              <div class="col-sm-12 col-lg-3">
                                {" "}
                                <label id="label">Answer {i + 1}:</label>
                                &nbsp;
                                <textarea
                                  id="answer1"
                                  class="form-control"
                                  name="answer1"
                                  disabled //bug-4450-disabled textarea
                                  defaultValue={unescape(
                                    campaignBriefCustomQuestionDetails.answer
                                  )}
                                  onChange={this.handleChangeCustomQuestion}
                                />
                              </div>
                              <div class="col-sm-12 col-lg-3">
                                {" "}
                                <label id="label">
                                  Not Allowed Answer {i + 1}:
                                </label>
                                &nbsp;
                                <textarea
                                  id="nonallowedanswer"
                                  class="form-control"
                                  name="nonallowedanswer"
                                  disabled //bug-4450-disabled textarea
                                  defaultValue={unescape(
                                    campaignBriefCustomQuestionDetails.disAllowAnswer
                                  )}
                                  onChange={this.handleChangeCustomQuestion}
                                />
                              </div>
                            </div>
                          )
                        )}
                      </CardBody>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
            <br />
            <br />
            {/*row 6x */}
          </div>
          {/* // border */}
        </div>
        {/* // col-lg-12 */}

        {/* @author Chaitanya Shinde
          @param  Description : Bug 4322
          @return Description : Agency-camp specification-message icon added 
      */}
        <Drawer
          style={{ marginTop: "16px" }}
          title="Basic Drawer"
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
          width={350}
        >
          <label
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              color: "#414141",
              marginLeft: "12px",
            }}
          >
            Messages
          </label>
          <MDBBtn style={{ left: "178px" }} onClick={this.onClose}>
            &times;
          </MDBBtn>

          {/* Chaitanya-4407-Broadcast Message link not ablet to get on messages screen-Added missng code */}
          <p
            style={{
              width: "100%",
              marginLeft: "10px",
            }}
          >
            <b>
              <a
                href="#/" //RUtuja 2978
                id="boradcastMsg"
                name="boradcastMsg"
                onClick={this.broadCastMessage}
                style={{ color: this.state.Broadcastcolor }}
              >
                BroadCast Message
                {/* sandeep-task-3261-change in content from BroadCast Messages to BroadCast Message	 */}
              </a>{" "}
            </b>
            {/* Sandeep-task-3336-added code for dropdown */}
            <div style={{ overflowY: "scroll", height: "60px" }}>
              {this.state.publisherList.map((publisherList) => (
                <div>
                  <a
                    href="#/" //Rutuja 2978
                    id={publisherList.campID}
                    name={publisherList.pID}
                    onClick={this.handleChangePublisherMessages}
                    style={{ color: this.state.pubColor }}
                  >
                    {publisherList.publisherName}
                    {/* {publisherList.pID==this.state.pID?<b>{publisherList.publisherName}</b>:<span>{publisherList.publisherName}</span>} */}
                  </a>
                  &nbsp;
                  {publisherList.unreadCount === undefined ||
                  publisherList.unreadCount === null ? (
                    "(0)"
                  ) : publisherList.unreadCount > 0 ? (
                    <span>({publisherList.unreadCount})</span>
                  ) : (
                    ""
                  )}
                  {/* </div> */}
                </div>
              ))}
            </div>
          </p>

          <p>
            {" "}
            <Comment
              style={{ top: "8px" }}
              content={
                <Editor
                  onChange={this.handleChange}
                  onSubmit={this.handleSubmit}
                  submitting={submitting}
                  value={value}
                />
              }
            />
            <label
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                marginLeft: "13px",
                marginBottom: "10px",
                color: "#414141",
              }}
            >
              History
            </label>
            <p
              style={{
                overflowY: "scroll",
                height: "200px",
                marginBottom: "10px",
              }}
            >
              {this.state.comments && this.state.comments.length > 0
                ? this.state.comments.map((comments) => (
                    <div>
                      <Avatar
                        style={{ color: "#ffffff", backgroundColor: "#144999" }}
                      >
                        {comments.avatar}
                      </Avatar>
                      &nbsp; <b>{comments.author}</b>&nbsp;&nbsp;
                      <span style={{ fontSize: "10px" }}>
                        {comments.datetime}
                      </span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {comments.status === "Unread" ? ( //Rutuja 2978 changed == to ===
                        <b>
                          <p style={{ textIndent: "50px", color: "#3a1e5f" }}>
                            {comments.content}
                          </p>
                        </b>
                      ) : (
                        <p style={{ textIndent: "50px" }}>{comments.content}</p>
                      )}
                    </div>
                  ))
                : ""}
            </p>
          </p>
        </Drawer>

        <Container>
          {/* //shivani-task 3173-added condition to hide chat option. */}
          {this.state.button === false ? (
            <Button
              tooltip="Chat With Us!"
              styles={{
                background: "linear-gradient(to right,#28166f,#007cc3)",
                color: lightColors.white,
              }}
              icon="fa fa-comments-o fa-3x"
              //rotate={true}
              onClick={this.showDrawer}
            />
          ) : (
            ""
          )}
        </Container>
      </div>
    );
  }
} // end of class CampaignSpecificationDashboardDetails

/* @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
CampaignSpecificationDashboardDetails.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
  withRouter(CampaignSpecificationDashboardDetails)
);
// export default CampaignSpecificationDashboardDetails;
