/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author Sanobar Golandaj
 *@fileName :
 *Desc:
 */

/*
@author:Raunak Thakkar
@Creation Date:01-08-2019
*Desc:The new UI for the review Leads with the Client Response tab 
*/

import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import Footer from "../layouts/footer";
import PublisherNavigation from "../layouts/navPage";
import { saveAs } from "file-saver";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { Tooltip, Table } from "antd";  //Aman-2835-(removed - Upload,Button,Icon,message Not in use)-Console Warnings - Advertiser - Settings
import { FaArrowAltCircleLeft } from "react-icons/fa";
import "./agencyReviewLead.css"; //sunita-task-3160-imported new css file
import { css } from "emotion";
// import { first } from "underscore"; //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //snehal-task-3724-vAPT URL changes
import { LeadReviewLoader } from "../loaders/LeadReviewLoader"; // Sandeep-task-3441-added loader
const Swal = require("sweetalert2");

const xlsx = require("xlsx");
//sunita-task-3270-added for table css
const tableCSS = css({
  backgroundColor: "white",
  fontFamily: "sans-serif",
  borderStyle: "bold",
  "& thead  > tr": {
    backgroundImage: "linear-gradient(to right,#28166f,#007cc3) !important",
    // backgroundColor:'#193D8F-#007CC3(linear gradient) !important',
    color: "white",
    fontSize: "12px",
    textAlign: "center",
    fontWeight: "500",
  },
  "& thead > tr >th": {
    textAlign: "center",
    color: "white",
    // backgroundColor:'#193D8F-#007CC3(linear gradient) !important',
  },
});

class AgencyReviewlead extends React.Component {
  constructor() {
    super();
    this.state = {
      fields: {},
      key: 1,
      errors: {},
      reerrors: {},
      campID: "",
      btnDisable: "disabled1",
      reviewLeadFlag: false,
      reviewlead: [],
      marketingChannelFlag: false,
      campaignLeadDetails: [],
      internalLeadDetails: [],
      uploadLeadFile: [],
      clientDownloadLead: [],
      status: "Accepted",
      internalstatus: "AgencyInternalReview",
      statusFlag: "",
      parentCampID: "",
      reallocationID: "",
      agencyID: "",
      userRole: "",
      diFlag: "",
      buttonFlag: false, //shivani-task 3089-used this flag to make submit button enable or disabled.
      tableState: { size: "small" }, //Array for table properties
      loading: false, //sunita-task-3270-added for loader
      pageSize: 100, //sunita-task-3270-added for pagination
      loading1: false, //sunita-task-3270-added for loader
      tabkey: "", //sunita-task-3270-added for fetching particular tab according to status
      leadQAReview: [], //new array added for qa review
      agencyReviewInternalLead: [], //new array added for internalreviewlead
      leadQAReviewSearch: [], //sunita-task-3270-added for searching table data for first tab
      tableSearchText: "", //sunita-task-3270-added variable for search
      internalLeadDetailsSearch: [], //sunita-task-3270-added for searching table data for 2nd tab
      tableSearchText1: "", //sunita-task-3270-added variable for search
      filesData1: "", //sunita-task-3270-added for file
      campaignLeadDetailsSearch: [], //shivani -task 3326 - added array for search functionality .
      tableSearchText2: "", //shivani -task 3326 - declare variable for search functionality
      agencyAcceptedLead: [], // shivani-task 3326 - declared array for iterate data .
      agencyAcceptedLeadByCampID: [], // shivani-task 3326 - declared array for iterate data .
      tabkey3: "3", //shivani-task 3326-declare this key to open the third tab.

      loader2: "", // Sandeep-task-3341-added code for loader
      btnFlag: false, // Sandeep-task-3652-Edited time save option issue--AQA lead review section
      jobTitleErrMsg: "none", //Sandeep-task-3659-added error msg  to display none
      jobFunctionErrMsg: "none", //Sandeep-task-3659-added error msg  to display none
      jobLevelErrMsg: "none", //Sandeep-task-3659-added error msg  to display none
      jobTitleErrMsg2: "none", //Sandeep-task-3659-added error msg  to display none
      jobFunctionErrMsg2: "none", //Sandeep-task-3659-added error msg  to display none
      jobLevelErrMsg2: "none", //Sandeep-task-3659-added error msg  to display none
      token: "", //Sandeep-task-3819-agency side-review leads-updated screen-FE
      csvFileData: [],  //rutuja - 4397  added new file for genrating csv
      downloadFilesLength: 0, //Nilesh-4785-Initialize with the default value
      thirdTabLeadsCount: []  //Nilesh-4785-Add for third tab leads count
    };

    this.submitInternalReviewLead = this.submitInternalReviewLead.bind(this);
    this.submitreviewlead = this.submitreviewlead.bind(this);
    this.handleDownloadLeadDetails = this.handleDownloadLeadDetails.bind(this);
    this.handleFileShowDownload = this.handleFileShowDownload.bind(this);
    this.handleClientDownloadLeadDetails = this.handleClientDownloadLeadDetails.bind(
      this
    );
    this.handleUploadLeadFile = this.handleUploadLeadFile.bind(this);
    this.handleChangeFileUpload = this.handleChangeFileUpload.bind(this);
    this.handleChangeClientResponseFileUpload = this.handleChangeClientResponseFileUpload.bind(
      this
    );
    this.uploadInternalLeadFile = this.uploadInternalLeadFile.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.downloadInternalLeadDetails = this.downloadInternalLeadDetails.bind(
      this
    );
    this.handleChangeInternalLead = this.handleChangeInternalLead.bind(this);
    this.openLinkInBrowser = this.openLinkInBrowser.bind(this);
    //sunita-task-3270-below all handle change are created as for pagination,showing 1st and 2nd tab left side camapign details and searching.
    this.handleChangePageSize = this.handleChangePageSize.bind(this);
    this.leadreviewDetails = this.leadreviewDetails.bind(this);
    this.leadreviewDetailsThird = this.leadreviewDetailsThird.bind(this); //shivani-3326 - to open third tab .
    this.agencyleadreviewDetails = this.agencyleadreviewDetails.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.handleChangeSearch1 = this.handleChangeSearch1.bind(this);
    this.handleChangeSearch2 = this.handleChangeSearch2.bind(this); //shivani-3326- binded search function .
    // this. handleChangeThirdTab = this. handleChangeThirdTab.bind(this); //shivani- 3326- binded third tab function .

    // User Story - 3441 - Sandeep - reference to control Loader
    this.loadingRef = React.createRef(); // shows the Loader
    this.closeLoadingRef = React.createRef(); // hides the Loader

    this.handleEditField = this.handleEditField.bind(this); //Sandeep-task-3516-added code for Editable field job title,job level,job function
    this.handleEditFirstTab = this.handleEditFirstTab.bind(this); //Sandeep-task-3516-added handle change for handleEditFirstTab
    this.handleEditForQA = this.handleEditForQA.bind(this); //Sandeep-task-3516-added handle change for handleEditForQA
    this.handleEditSecondTab = this.handleEditSecondTab.bind(this); //Sandeep-task-3516-added handle change for handleEditSecondTab
    this.saveFormFirstTab = this.saveFormFirstTab.bind(this); //Sandeep-task-3516-added handle change for saveFormFirstTab
    this.saveFormSecondTab = this.saveFormSecondTab.bind(this); //Sandeep-task-3516-added handle change for saveFormSecondTab
    this.backButtonHandleChange = this.backButtonHandleChange.bind(this);
    /* Sandeep-task-3516-added onClick event on back button */
    this.displayCampaignSpecification = this.displayCampaignSpecification.bind(
      this
    ); //sunita-task-3727-added handle change for displaying campaign specification
    
    this.fetchDownloadFilesLengthFirstTab = this.fetchDownloadFilesLengthFirstTab.bind(this);
    this.fetchDownloadFilesLengthSecondTab = this.fetchDownloadFilesLengthSecondTab.bind(this);
    this.fetchDownloadFilesLengthThirdTab = this.fetchDownloadFilesLengthThirdTab.bind(this);
  }

  /**
   * @author Sunita Landge - task 3727
   * @param  Description handle the campaignSpecification on agency review lead page
   * @return Description redirect to campaignSpecification page
   */

  displayCampaignSpecification(e) {
    e.preventDefault(); //Aman-2835-(added preventDefault)-Console Warnings - Agency - Advertiser Menu
    var campID = this.state.campID; //sandeep-task-3095-added URL parameter campID
    var parentCampID = this.state.parentCampID; //sandeep-task-3095-added URL parameter parentCampID
    var reallocationID = this.state.reallocationID; //sandeep-task-3095-added URL parameter reallocationID
    var tabkey = this.state.key;
    //karan-task-3715-replace query params
    // window.open('/campaignSpecificationDashboard?campID='+ campID + '&parentCampID=' + parentCampID + '&reallocationID=' + reallocationID+"&tabkey="+tabkey);
    console.log("karan - sess - ", {
      campID,
      parentCampID,
      reallocationID,
      tabkey,
    });
    sessionStorage.setItem("campID", campID);
    sessionStorage.setItem("parentCampID", parentCampID);
    sessionStorage.setItem("reallocationID", reallocationID);
    sessionStorage.setItem("tabkey", tabkey);
    window.open("/campaignSpecificationDashboard");
    //    window.location.href ="/campaignSpecificationDashboard?campID="+ campID + '&parentCampID=' + parentCampID + '&reallocationID=' + reallocationID+"&tabkey="+tabkey ;

    // ); //sunita-task-3099-added changes to open link in new tab
  }

  /**
   * @author : Sandeep Dhawale-task-3516
   * @description : handlechange for backbutton to navigate on same page from where user came .
   */
  backButtonHandleChange(e) {
    e.preventDefault(); //Aman-2835-(added preventDefault)-Console Warnings - Agency - Advertiser Menu
    if (this.state.saveEditedData === true) { //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
      Swal.fire({
        title: "Are you sure you want to discard this report?",
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
        confirmButtonText: "Discard",
      }).then((result) => {
        if (result.value) {
          window.location.href = "/newdashboard";
        } else if (result.dismiss === "close") {
          window.location.href = "#";
        }
      });
    } else {
      this.props.history.push("/newdashboard", {
        agencyID: this.state.agencyID,
      }); //karan-task-3684-replace query params
    }
  }

  /**
   * @author Shivani pathak 3326
   * @param  Description for searching campaign id and it's data.
   * @return return the searched data
   */

  handleChangeSearch2(e) {
    e.preventDefault();
    let currentList = [];
    // Variable to hold the filtered list before putting into state
    let newList = [];
    let filterData = e.target.value;
    // If the search bar isn't empty
    if (e.target.value === "") {
      newList = this.state.agencyAcceptedLead;
    } else {
      // Assign the original list to currentList
      currentList = this.state.agencyAcceptedLead;
      newList = currentList.filter((item) => {

        // change current item to lowercase
        const lc = item;
        //const lc = item.toLowerCase();
        // change search term to lowercase
        const filter = e.target.value.toLowerCase();
        // check to see if the current list item includes the search term
        // If it does, it will be added to newList. Using lowercase eliminates
        // issues with capitalization in search terms and search content
        var data = Object.values(lc);
        var dataFinal = data.toString();
        dataFinal = dataFinal.replace(",", " ");
        var dataToLower = dataFinal.toLowerCase();
        return dataToLower.includes(filter);
      });
    }
    // Set the filtered state based on what our rules added to newList
    this.setState({
      campaignLeadDetailsSearch: [...newList],
      tableSearchText2: filterData,
    });
  }


  /**
   * @author Sunita Landge
   * @param  Description for pagination
   * @return return as per page size record
   */
  /**
   * @author Sunita Landge
   * @param  Description for pagination
   * @return return as per page size record
   */

  handleChangePageSize(e) {
    var pageSize = e.target.value;
    this.setState({ pageSize: pageSize });
  } //end of handleChangePageSize

  /**
   * @author Shivani pathak 3326
   * @param  Description for filter the campID
   * @return return to iterate the data in respective of filtered campID .
   */
  leadreviewDetailsThird(e) {
    e.preventDefault(); //Priyanka-4584-removed default # in URL
    let campID = e.target.id;
    const { user } = this.props.auth;
    var userRole = user.role;
    this.setState({ campID, userRole });
    let agencyAcceptedLeadByCampID = this.state.agencyAcceptedLead.filter(
      (a) => {
        return a.campID == campID; //Aman-2835-(Not adding === "on 3rd tab getting issue to display camp info")-Console Warnings - Agency - Advertiser Menu
      }
    );
    this.setState({ agencyAcceptedLeadByCampID, campID });
    document.getElementById('abm1').value = "";//  remove comment vrushabh patil task 4434
    var filesData1 = this.state.filesData1;
    if (
      filesData1 === undefined ||
      filesData1 === "undefined" ||
      filesData1 === "" ||
      filesData1 === null
    ) {
      var label = document.getElementById("clientFilename");
      label.innerHTML = "No File Choosen";      
    //Aman-2835-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings - Agency - Advertiser Menu
      this.setState({errors: {...this.state.errors, clientuploadsuccess: ""}}); //this.state.errors.clientuploadsuccess = "";
      this.setState({reerrors: {...this.state.reerrors, clientfileError: ""}}); //this.state.reerrors.clientfileError = "";
      this.setState({reerrors: {...this.state.reerrors, clientfileError1: ""}}); //this.state.reerrors.clientfileError1 = "";
      
    }

       //Nilesh-4785- add below route showing lead's count as per the camp id
          fetch("/dashboardAdvertiser/getAcceptedLeadByAgency?campID=" + campID)
          .then(res => res.json())
          .then(thirdTabLeadsCount => {
    
            this.setState({ thirdTabLeadsCount: thirdTabLeadsCount, loading: false, reviewLeadFlag: false });
            
            // if (thirdTabLeadsCount.length > 0) {
            //   this.state.btnDisable = 'btn add-button';
            //   this.forceUpdate();
            // }
          }).catch(function (err) { console.log(err) });
  }

  /**
   * @author Sunita Landge
   * @param  Description for showing qa review status campaign id's data
   */

  //sunita-task- 3270-some api's are alredy present in this page only new api i have used for task 3270 is agencyReviewInternalLead and LeadQAReview

  leadreviewDetails(e) {
    //sunita-task-3324-added code for loader
    e.preventDefault();
    this.setState({ loading: true, loader2: "loader_review" }); // Sandeep-task-3341-added code for loader
    // let reviewlead=this.state.reviewlead;
    let campID = e.target.id;
    //let campID = this.state.campID;
    const { user } = this.props.auth;
    let agencyID = user.id;
    var userRole = user.role;
    let key = this.state.key;
    //Sandeep-task-3819-agency side-review leads-updated screen-FE
    this.setState({
      resultFirmoGraphic: "",
      resultDemoGraphic: "",
      resultOther: "",
      resultCustomQuestion: "",
      resultLinkdin: "",
      resultMonthlyAllocation: "",
      resultNotes: "",
      resultMultitouch: "",
    });
    let data1 = {
      campID: campID,
    };
    fetch("leadDownloadDetails/getLeadReviewLabel", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: this.state.token,
      },
      body: JSON.stringify(data1),
    })
      .then((res) => res.json())
      .then((getLeadReviewLabel) => {
        //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
        if (
          getLeadReviewLabel.automatic[0] === "undefined" ||
          getLeadReviewLabel.automatic[0] === undefined ||
          getLeadReviewLabel.automatic[0] === "" ||
          getLeadReviewLabel.automatic[0] === "NA"
        ) {
          this.setState({ resultFirmoGraphic: "NA" });
        } else {
          this.setState({ FirmographicLabel: getLeadReviewLabel.automatic[0] });
          this.setState({
            labelForFirmographic: this.state.FirmographicLabel.Firmographic,
          });
          const resultFirmoGraphic = this.state.labelForFirmographic
            .toString()
            .replace(/"/g, "");
          this.setState({ resultFirmoGraphic });
        }
        //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
        if (
          getLeadReviewLabel.automatic[1] === "undefined" ||
          getLeadReviewLabel.automatic[1] === undefined ||
          getLeadReviewLabel.automatic[1] === "" ||
          getLeadReviewLabel.automatic[1] === "NA"
        ) {
          this.setState({ resultDemoGraphic: "NA" });
        } else {
          this.setState({ DemographicLabel: getLeadReviewLabel.automatic[1] });
          this.setState({
            labelForDemographic: this.state.DemographicLabel.Demographic,
          });
          const resultDemoGraphic = this.state.labelForDemographic
            .toString()
            .replace(/"/g, "");
          this.setState({ resultDemoGraphic });
        }
        //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
        if (
          getLeadReviewLabel.automatic[2] === "undefined" ||
          getLeadReviewLabel.automatic[2] === undefined ||
          getLeadReviewLabel.automatic[2] === "" ||
          getLeadReviewLabel.automatic[2] === "NA"
        ) {
          this.setState({ resultOther: "NA" });
        } else {
          this.setState({ OtherLabel: getLeadReviewLabel.automatic[2] });
          this.setState({ labelForOther: this.state.OtherLabel.Other });
          const resultOther = this.state.labelForOther
            .toString()
            .replace(/"/g, "");
          this.setState({ resultOther });
        }
        //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
        if (
          getLeadReviewLabel.automatic[3] === "undefined" ||
          getLeadReviewLabel.automatic[3] === undefined ||
          getLeadReviewLabel.automatic[3] === "" ||
          getLeadReviewLabel.automatic[3] === "NA"
        ) {
          this.setState({ resultCustomQuestion: "NA" });
        } else {
          this.setState({
            CustomQuestionLabel: getLeadReviewLabel.automatic[3],
          });
          this.setState({
            labelForCustomQuestion: this.state.CustomQuestionLabel
              .CustomQuestions,
          });
          const resultCustomQuestion = this.state.labelForCustomQuestion
            .toString()
            .replace(/"/g, "");
          this.setState({ resultCustomQuestion });
        }
        //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
        if (
          getLeadReviewLabel.manual[0] === "undefined" ||
          getLeadReviewLabel.manual[0] === undefined ||
          getLeadReviewLabel.manual[0] === "" ||
          getLeadReviewLabel.manual[0] === "NA"
        ) {
          this.setState({ resultLinkdin: "NA" });
        } else {
          this.setState({ linkdinLabel: getLeadReviewLabel.manual[0] });
          this.setState({ labelForLinkdin: this.state.linkdinLabel.linkedIn });
          const resultLinkdin = this.state.labelForLinkdin
            .toString()
            .replace(/"/g, "");

          this.setState({ resultLinkdin });
        }
        //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
        if (
          getLeadReviewLabel.manual[1] === "undefined" ||
          getLeadReviewLabel.manual[1] === undefined ||
          getLeadReviewLabel.manual[1] === "" ||
          getLeadReviewLabel.manual[1] === "NA"
        ) {
          this.setState({ resultMonthlyAllocation: "NA" });
        } else {
          this.setState({
            monthlyAllocationLabel: getLeadReviewLabel.manual[1],
          });
          this.setState({
            labelForMonthlyAllocation: this.state.monthlyAllocationLabel
              .MonthlyAllocation,
          });
          const resultMonthlyAllocation = this.state.labelForMonthlyAllocation
            .toString()
            .replace(/"/g, "");

          this.setState({ resultMonthlyAllocation });
        }
        //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
        if (
          getLeadReviewLabel.manual[2] === "undefined" ||
          getLeadReviewLabel.manual[2] === undefined ||
          getLeadReviewLabel.manual[2] === "" ||
          getLeadReviewLabel.manual[2] === "NA"
        ) {
          this.setState({ resultNotes: "NA" });
        } else {
          this.setState({ NotesLabel: getLeadReviewLabel.manual[2] });
          this.setState({ labelForNotes: this.state.NotesLabel.Notes });
          const resultNotes = this.state.labelForNotes
            .toString()
            .replace(/"/g, "");
          this.setState({ resultNotes });
        }
         //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
        if (
          getLeadReviewLabel.Multitouch === "undefined" ||
          getLeadReviewLabel.Multitouch === undefined ||
          getLeadReviewLabel.Multitouch === ""
        ) {
        } else {
          this.setState({ resultMultitouch: getLeadReviewLabel.Multitouch });
        }
      });

    this.setState({ campID, agencyID, userRole });

    var diFlag;
    let data = { campID };

    fetch("/leadDownloadDetails/internalLeadDetailsQA", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((internalLeadDetails) => {
        //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
        if (internalLeadDetails[0].originalStatus === "DI QA ACCEPTED") {
          diFlag = "Yes";
          this.setState({ diFlag: diFlag });
        }

        //sunita-task-3324-added code for voiceloglink column

        // if ((internalLeadDetails.channel == "Telemarketing"  ) ||( internalLeadDetails.channel == "Email/Telemarketing" || internalLeadDetails.channel=="Email") && internalLeadDetails.callAudit=="Yes" ) {

        //   this.setState({ marketingChannelFlag: true })
        // }
        // else {

        //   this.setState({ marketingChannelFlag: false })
        // }

        // this.setState({ internalLeadDetails: internalLeadDetails,marketingChannelFlag:false })
        this.setState({
          internalLeadDetails: internalLeadDetails,
          loading: false,
          loader2: "",
        }); // Sandeep-task-3341-added code for loader
        this.setState({ statusFlag: internalLeadDetails[0].status });
        if (internalLeadDetails.length > 0) {     
          //Aman-2835-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings - Agency - Advertiser Menu
          this.setState({btnDisable: "btn add-button"}); //this.state.btnDisable = "btn add-button";
          this.forceUpdate();
        }
      })
      .catch(function (err) {
        console.log(err);
      });
    this.getCampaignInfo(campID); //sunita-task-3270-this function called for showing right side campaign details data.
    this.fun_Get_Lead_TableData(campID, key); //sunita-task-3270-called this fun as per requirement

    //sunita-task-3270-added for removing uploaded file name
    var filesData1 = this.state.filesData1;
    if (
      filesData1 === undefined ||
      filesData1 === "undefined" ||
      filesData1 === "" ||
      filesData1 === null
    ) {
      var label = document.getElementById("fileName");
      label.innerHTML = "No File Choosen";
      //Aman-2835-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings - Agency - Advertiser Menu
      this.setState({errors: {...this.state.errors, uploadsuccessinternal: ""}}); //this.state.errors.uploadsuccessinternal = "";
      this.setState({reerrors: {...this.state.reerrors, fileError: ""}}); //this.state.reerrors.fileError = ""; //sunita-task-3318-added for removing the error which came for uploaded file.
    }
  }

  //handle change for agency internal review status

  /**
   * @author Sunita Landge
   * @param  Description for showing agency internal review status campaign id's data
   */

  //sunita-task- 3270-some api's are alredy present in this page only new api i have used for task 3270 is agencyReviewInternalLead and LeadQAReview

  agencyleadreviewDetails(e) {
    e.preventDefault(); //Priyanka-4584-removed default # in URL
    //sunita-task-3324-added code for loader
    //this.state.loading1=true;
    //this.setState({loader2:"loader_review"})
    // let reviewlead=this.state.reviewlead;
    // let errors = {}; //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
    // var isExistfile2=false;
    let campID = e.target.id;
    const { user } = this.props.auth;
    let agencyID = user.id;
    var userRole = user.role;
    let key = this.state.key;

    // this.setState({ campID, agencyID, userRole, isExistfile2:false});
    this.setState({ campID, agencyID, userRole, loader2: "loader_review" }); // Sandeep-task-3341-added code for loader

    let data2 = { campID: campID }; //Priyanka --3720--removed params

    // when status gets as internalqareview
    fetch("/publisherReviewLead/agencyReviewInternalLead", {
      method: "POST",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify(data2),
    })
      .then((res) => res.json())
      .then((agencyReviewInternalLead) => {
        // console.log("agencyReviewInternalLead 2nd tab data==>" + JSON.stringify(agencyReviewInternalLead))
        //sunita-task-3324-added code for voiceloglink column
        // if ((agencyReviewInternalLead.marketingChannel == "TeleMarketing" &&agencyReviewInternalLead.callAudit=="Yes") || (agencyReviewInternalLead.marketingChannel == "Email/Telemarketing"||agencyReviewInternalLead.marketingChannel == "Email" )&& agencyReviewInternalLead.callAudit=="Yes") {

        //   if ((agencyReviewInternalLead.marketingChannel == "TeleMarketing" &&agencyReviewInternalLead.callAudit=="Yes") || ((agencyReviewInternalLead.marketingChannel == "Email/Telemarketing" &&agencyReviewInternalLead.callAudit=="Yes")||(agencyReviewInternalLead.marketingChannel == "Email" && agencyReviewInternalLead.callAudit=="Yes" ))) {

        //   this.setState({ marketingChannelFlag: true })
        // }
        // else {
        //   this.setState({ marketingChannelFlag: false })
        // }

        // this.setState({ agencyReviewInternalLead: agencyReviewInternalLead,marketingChannelFlag:false})
        this.setState({
          agencyReviewInternalLead: agencyReviewInternalLead,
          loader2: " ",
        }); // Sandeep-task-3341-added code for loader
      })
      .catch(function (err) {
        console.log(err);
      });

    this.getCampaignInfo(campID); //sunita-task-3270-this function called for showing right side campaign details data.
    this.fun_Get_Lead_TableData(campID, key); //sunita-task-3270-called this fun as per requirement

    //sunita-task-3270-added for removing uploaded file name
    var filesData1 = this.state.filesData1;
    if (
      filesData1 === undefined ||
      filesData1 === "undefined" ||
      filesData1 === "" ||
      filesData1 === null
    ) {
      var label = document.getElementById("fileName2");
      label.innerHTML = "No File Choosen";
     //Aman-2835-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings - Agency - Advertiser Menu
      this.setState({errors: {...this.state.errors, uploadsuccess: ""}}); //this.state.errors.uploadsuccess = "";
      this.setState({reerrors: {...this.state.reerrors, fileError: ""}}); //this.state.reerrors.fileError = ""; //sunita-task-3318-added for removing the error which came for uploaded file.
    }
  }

  handleSelect(key) {
    //sunita-task-3324-added code for loade
    this.setState({ loading: true, loading1: true }); //Sandeep-task-3441-added loader
    //  this.state.loading=true;
    //  this.state.loading1=true;

    this.setState({ key });
    //const { user } = this.props.auth; //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
    //const { id: agencyID, role: userRole } = user; //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
    //let data = { agencyID, userRole };		Priyanka --3720--removed params
    if (key === 1) { //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
      //sunita-task-3270-as per key i called below api's for showing data on left side as per tab
      // this.setState({loading:true});
      fetch("/publisherReviewLead/LeadQAReview", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        //body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((leadQAReview) => {
          if (leadQAReview.length > 0) {
            this.fun_Get_Lead_TableData(leadQAReview[0].campID, key); //sunita-task-3270-this function called for showing table data as per key
            this.getCampaignInfo(leadQAReview[0].campID); //sunita-task-3270-this function called for showing right side campaign details data.
            //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
            //sunita-task-3324-added code for voiceloglink column
            if (
              (leadQAReview.marketingChannel === "TeleMarketing" &&
                leadQAReview.callAudit === "Yes") ||
              ((leadQAReview.marketingChannel === "Email/Telemarketing" ||
                leadQAReview.marketingChannel === "Email") &&
                leadQAReview.callAudit === "Yes")
            ) {
              this.setState({ marketingChannelFlag: true });
            } else {
              this.setState({ marketingChannelFlag: false });
            }

            //sunita-task-3322-added for removing uploaded file name after changing the tab
            var filesData1 = this.state.filesData1;
            if (
              filesData1 === undefined ||
              filesData1 === "undefined" ||
              filesData1 === "" ||
              filesData1 === null
            ) {
              var label = document.getElementById("fileName");
              label.innerHTML = "No File Choosen";
              //Aman-2835-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings - Agency - Advertiser Menu
              this.setState({errors: {...this.state.errors, uploadsuccessinternal: ""}}); //this.state.errors.uploadsuccessinternal = "";
              this.setState({reerrors: {...this.state.reerrors, fileError: ""}}); //this.state.reerrors.fileError = ""; //sunita-task-3318-added for removing the error which came for uploaded file.
            }
          }

          // this.setState({ leadQAReview,loading:false,marketingChannelFlag:false})
          this.setState({ leadQAReview, loading: false }); //Sandeep-task-3441-added loader
        })
        .catch(function (err) {
          console.log(err);
        });
    }
    ///shivani-task 3326 added below code for , when user will visit on agencyReviewLead page by tab to tab , page should load with complete data .
    else if (key === 3) { //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
      //let data1 = {user: user,};		Priyanka --3720--removed params
      fetch("/agencyDashboard/getAgencyAcceptedLead", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        //body: JSON.stringify(data1),
      })
        .then((res) => res.json())
        .then((agencyAcceptedLead) => {
          if (agencyAcceptedLead.length > 0) {
            let campID = agencyAcceptedLead[0].campID;

            this.setState({
              agencyAcceptedLead: agencyAcceptedLead,
              agencyAcceptedLeadByCampID: [agencyAcceptedLead[0]],
              campID,
            });
          }
          var filesData1 = this.state.filesData1;
          if (
            filesData1 === undefined ||
            filesData1 === "undefined" ||
            filesData1 === "" ||
            filesData1 === null
          ) {
            var label = document.getElementById("clientFilename");
            label.innerHTML = "No File Choosen";
            //Aman-2835-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings - Agency - Advertiser Menu
            this.setState({errors: {...this.state.errors, clientuploadsuccess: ""}}); //this.state.errors.clientuploadsuccess = "";
            this.setState({reerrors: {...this.state.reerrors, clientfileError: ""}}); //this.state.reerrors.clientfileError = "";
            this.setState({reerrors: {...this.state.reerrors, clientfileError1: ""}}); //this.state.reerrors.clientfileError1 = "";
          }
          
          //Nilesh-4785- add below route showing lead's count as per the camp id
          fetch("/dashboardAdvertiser/getAcceptedLeadByAgency?campID=" + this.state.campID)
          .then(res => res.json())
          .then(thirdTabLeadsCount => {
    
            this.setState({ thirdTabLeadsCount: thirdTabLeadsCount, loading: false, reviewLeadFlag: false }); //sunita-task-3271-added code bcz as after uploading file status is showing in bold form when clicking on other campaign id's.

            // if (thirdTabLeadsCount.length > 0) {
            //   this.state.btnDisable = 'btn add-button';
            //   this.forceUpdate();
            // }
          }).catch(function (err) { console.log(err) });
          
        });
    } else {
      // this.setState({loading1:true});
      fetch("/publisherReviewLead/agencyReviewInternalLead", {
        method: "POST",
        headers: { "content-Type": "application/json" },
        //body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((agencyReviewInternalLead) => {
          if (agencyReviewInternalLead.length > 0) {
            this.fun_Get_Lead_TableData(
              agencyReviewInternalLead[0].campID,
              key
            ); //sunita-task-3270-this function called for showing table data as per key
            this.getCampaignInfo(agencyReviewInternalLead[0].campID); //sunita-task-3270-this function called for showing right side campaign details data.
          }
          //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
          //sunita-task-3324-added code for voiceloglink column
          if (
            (agencyReviewInternalLead.marketingChannel === "TeleMarketing" &&
              agencyReviewInternalLead.callAudit === "Yes") ||
            ((agencyReviewInternalLead.marketingChannel ===
              "Email/Telemarketing" ||
              agencyReviewInternalLead.marketingChannel === "Email") &&
              agencyReviewInternalLead.callAudit === "Yes")
          ) {
            this.setState({ marketingChannelFlag: true });
          } else {
            this.setState({ marketingChannelFlag: false });
          }

          //sunita-task-3322-added for removing uploaded file name after changing the tab
          var filesData1 = this.state.filesData1;

          if (
            filesData1 === undefined ||
            filesData1 === "undefined" ||
            filesData1 === "" ||
            filesData1 === null
          ) {
            var label = document.getElementById("fileName2");
            label.innerHTML = "No File Choosen";
            //Aman-2835-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings - Agency - Advertiser Menu
            this.setState({errors: {...this.state.errors, uploadsuccess: ""}}); //this.state.errors.uploadsuccess = "";
            this.setState({reerrors: {...this.state.reerrors, fileError: ""}}); //this.state.reerrors.fileError = ""; //sunita-task-3318-added for removing the error which came for uploaded file.
          }

          // this.setState({ agencyReviewInternalLead,loading1:false,marketingChannelFlag:false})
          this.setState({ agencyReviewInternalLead, loading1: false }); //Sandeep-task-3441-added loader
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }

  /**
   * @author Sunita Landge
   * @param  Description  new function created for showing lead's table data as per key on tab.
   */

  //showing table data as per key
  fun_Get_Lead_TableData(campID, key) {
    //sunita-task-3324-added code for loader
    // this.state.loading=true;
    // this.state.loading1=true;
    //Sandeep-task-3658-Misplacing the fields issue--AQA lead review section
    this.setState({
      loading: true,
      internalLeadDetails: "",
      campaignLeadDetails: "",
      loader2: "loader_review",
    });
    //Sandeep-task-3441-added loader
    //const { user } = this.props.auth; //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
    //const { id: agencyID, role: userRole } = user; //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
    var diFlag;
    let data = { campID }; //Priyanka --3720--removed params

    if (key == "1") { //Aman-2835-(Not adding === "on getting issue to load table data")-Console Warnings - Agency - Advertiser Menu
      fetch("/leadDownloadDetails/internalLeadDetailsQA", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((internalLeadDetails) => {
          //Sandeep-task-3516-added code to check jobLevel And Job Function for first tab
          let jobFunctionFlag = false,
            jobLevelFlag = false;

          for (let i = 0; i < internalLeadDetails.length; i++) {
            if (
              internalLeadDetails[i].hasOwnProperty("jobFunction") &&
              internalLeadDetails[i].jobFunction &&
              internalLeadDetails[i].jobFunction !== ""
            ) {
              jobFunctionFlag = true;
            }
            if (
              internalLeadDetails[i].hasOwnProperty("jobLevel") &&
              internalLeadDetails[i].jobLevel &&
              internalLeadDetails[i].jobLevel !== ""
            ) {
              jobLevelFlag = true;
            }
          }
          this.setState({ jobFunctionFlag, jobLevelFlag });
          //Aman-2835-(removed Not in use)-Console Warnings - Agency - Advertiser Menu
          if (internalLeadDetails[0].originalStatus === "DI QA ACCEPTED") {
            diFlag = "Yes";
            this.setState({ diFlag: diFlag });
          }

          //sunita-task-3324-added code for voiceloglink column.
          // if ((internalLeadDetails.channel == "Telemarketing") || (internalLeadDetails.channel == "Email/Telemarketing" || internalLeadDetails.channel=="Email")) {

          //   this.setState({ marketingChannelFlag: true })
          // }
          // else {
          //   this.setState({ marketingChannelFlag: false })
          // }

          // this.setState({ internalLeadDetails: internalLeadDetails ,loading:false,reviewLeadFlag:false}) //sunita-task-3318-added code bcz as after uploading file status is showing in bold form when clicking on other campaign id's.
          //Sandeep-task-3658-Misplacing the fields issue--AQA lead review section
          //Sandeep-task-3664-added code for error msg to make it blank
          this.setState({
            internalLeadDetails: internalLeadDetails,
            loading: false,
            reviewLeadFlag: false,
            // campaignLeadDetails: internalLeadDetails, //Nilesh-5496- Comment this line
            loader2: "",
            jobTitleErrMsg: "none",
            jobFunctionErrMsg: "none",
            jobLevelErrMsg: "none",
          }); //sunita-task-3318-added code bcz as after uploading file status is showing in bold form when clicking on other campaign id's.
          //sunita-task-3541-code added when we fill some rejection rejections and for some we have not filled then that time page get blank.
          this.setState({ statusFlag: internalLeadDetails[0].status });
          if (internalLeadDetails.length > 0) {
            //Aman-2835-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings - Agency - Advertiser Menu
            this.setState({btnDisable: "btn add-button"}); //this.state.btnDisable = "btn add-button";
            this.forceUpdate();
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    } else if(key == "2") { //Aman-2835-(Not adding === "on getting issue to load table data")-Console Warnings - Agency - Advertiser Menu
      // this.setState({loading1:true});
      fetch("/lead/campaignLeadDetails", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((campaignLeadDetails) => {
          //Sandeep-task-3516-added code to check jobLevel And Job Function for second tab
          let jobFunctionFlag = false,
            jobLevelFlag = false;
          for (let i = 0; i < campaignLeadDetails.length; i++) {
            if (
              campaignLeadDetails[i].hasOwnProperty("jobFunction") &&
              campaignLeadDetails[i].jobFunction &&
              campaignLeadDetails[i].jobFunction !== ""
            ) {
              jobFunctionFlag = true;
            }
            if (
              campaignLeadDetails[i].hasOwnProperty("jobLevel") &&
              campaignLeadDetails[i].jobLevel &&
              campaignLeadDetails[i].jobLevel !== ""
            ) {
              jobLevelFlag = true;
            }
          }
          this.setState({ jobFunctionFlag, jobLevelFlag });
          //sunita-task-3324-added code for voiceloglink column
          // if ((campaignLeadDetails.channel == "Telemarketing") || (campaignLeadDetails.channel == "Email/Telemarketing" ||campaignLeadDetails.channel=="Email")) {

          //   this.setState({ marketingChannelFlag: true })
          // }
          // else {

          //   this.setState({ marketingChannelFlag: false })
          // }
          // this.setState({ campaignLeadDetails: campaignLeadDetails,loading1:false,reviewLeadFlag:false }) //sunita-task-3318-added code bcz as after uploading file status is showing in bold form when clicking on other campaign id's.
          //Sandeep-task-3664-added code for error msg to make it blank
          this.setState({
            campaignLeadDetails: campaignLeadDetails,
            reviewLeadFlag: false,
            loader2: "",
            jobTitleErrMsg2: "none",
            jobFunctionErrMsg2: "none",
            jobLevelErrMsg2: "none",
          }); //sunita-task-3318-added code bcz as after uploading file status is showing in bold form when clicking on other campaign id's.
          if (campaignLeadDetails.length > 0) {
            //Aman-2835-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings - Agency - Advertiser Menu
            this.setState({btnDisable: "btn add-button"}); //this.state.btnDisable = "btn add-button";
            this.forceUpdate();
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    }
    else{
      //Nilesh-4785- add below route showing lead's count as per the camp id
         fetch("/dashboardAdvertiser/getAcceptedLeadByAgency?campID"+ campID)
         .then(res => res.json())
         .then(thirdTabLeadsCount => {
   
           this.setState({ thirdTabLeadsCount: thirdTabLeadsCount,loading: false, reviewLeadFlag: false }); //sunita-task-3271-added code bcz as after uploading file status is showing in bold form when clicking on other campaign id's.
          //  if (thirdTabLeadsCount.length > 0) {
          //    this.state.btnDisable = 'btn add-button';
          //    this.forceUpdate();
          //  }
         }).catch(function (err) { console.log(err) });
    }
  }

  /**
   * @author Sunita Landge
   * @param  Description for searching campaign id and it's data.
   * @return return the searched data
   */

  handleChangeSearch(e) {
    e.preventDefault();
    let currentList = [];
    // Variable to hold the filtered list before putting into state
    let newList = [];
    let filterData = e.target.value;
    // If the search bar isn't empty
    if (e.target.value === "") {
      newList = this.state.leadQAReview;
    } else {
      // Assign the original list to currentList
      currentList = this.state.leadQAReview;
      newList = currentList.filter((item) => {
        // change current item to lowercase
        const lc = item;
        //const lc = item.toLowerCase();
        // change search term to lowercase
        const filter = e.target.value.toLowerCase();
        // check to see if the current list item includes the search term
        // If it does, it will be added to newList. Using lowercase eliminates
        // issues with capitalization in search terms and search content
        var data = Object.values(lc);
        var dataFinal = data.toString();
        dataFinal = dataFinal.replace(",", " ");
        var dataToLower = dataFinal.toLowerCase();
        return dataToLower.includes(filter);
      });
    }
    // Set the filtered state based on what our rules added to newList
    this.setState({
      leadQAReviewSearch: [...newList],
      tableSearchText: filterData,
    });
  }

  /**
   * @author Sunita Landge
   * @param  Description for searching campaign id and it's data.
   * @return return the searched data
   */

  handleChangeSearch1(e) {
    e.preventDefault();
    let currentList = [];
    // Variable to hold the filtered list before putting into state
    let newList = [];
    let filterData = e.target.value;
    // If the search bar isn't empty
    if (e.target.value === "") {
      newList = this.state.agencyReviewInternalLead;
    } else {
      // Assign the original list to currentList
      currentList = this.state.agencyReviewInternalLead;
      newList = currentList.filter((item) => {
        // change current item to lowercase
        const lc = item;
        //const lc = item.toLowerCase();
        // change search term to lowercase
        const filter = e.target.value.toLowerCase();
        // check to see if the current list item includes the search term
        // If it does, it will be added to newList. Using lowercase eliminates
        // issues with capitalization in search terms and search content
        var data = Object.values(lc);
        var dataFinal = data.toString();
        dataFinal = dataFinal.replace(",", " ");
        var dataToLower = dataFinal.toLowerCase();
        return dataToLower.includes(filter);
      });
    }
    // Set the filtered state based on what our rules added to newList
    this.setState({
      internalLeadDetailsSearch: [...newList],
      tableSearchText1: filterData,
    });
  }

  openLinkInBrowser(e) {
    var leadInfoID = e.target.id;
    fetch("lead/getVoiceLogLink?leadInfoID=" + leadInfoID + "")
      .then((res) => res.json())
      .then((voiceLogDetails) => {
        this.setState({ voiceLogDetails: voiceLogDetails });
        window.open(voiceLogDetails[0].voiceLogLink, "_blank");
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  /**
   * @author Sunita Landge
   * @param  Description  new function created  for showing information of campaign details.
   */

  getCampaignInfo(campID) {
    fetch("/publisher/reviewlead?campID=" + campID + "")
      .then((res) => res.json())
      .then((reviewlead) => {
        //Aman-2835-(removed Not in use)-Console Warnings - Agency - Advertiser Menu
        if (
          (reviewlead[0].marketingChannel === "TeleMarketing" &&
            reviewlead[0].callAudit === "Yes") ||
          (reviewlead[0].marketingChannel === "Email/Telemarketing" &&
            reviewlead[0].callAudit === "Yes")
        ) {
          this.setState({ marketingChannelFlag: true });
        }
        // .then(res => res.json())
        // .then(reviewlead => {

        //   if ((reviewlead[0].marketingChannel == "TeleMarketing" && reviewlead[0].callAudit == "Yes") || reviewlead[0].marketingChannel == "Email/Telemarketing" && reviewlead[0].callAudit == "Yes"){
        //     this.setState({ marketingChannelFlag: true })
        //   }
        else {
          this.setState({ marketingChannelFlag: false });
        }
        // Saurabh – Task- 3726 – Adding Agency campaign ID (ANC side  Camp ID start date end date camp status is showing)
        var parentCampID = reviewlead[0].parentCampID;
        this.setState({
          reviewlead: reviewlead,
          campID: campID,
          parentCampID,
          buttonFlag: false,
        }); //sunita-task-3322-added here for enabling the button after uploading the file and clicking on another campaign id.
        //sunita-task-3318-added below code to remove uploaded file when clicking on any other campaign id.
        document.getElementById("abm1").value = "";
        document.getElementById("fileName").value = "";
        document.getElementById("fileName2").value = "";
        document.getElementsByClassName("successMessage").value = "";
        document.getElementsByClassName("errorMessage").value = "";
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  /**
   * @author Sunita Landge
   * @param  Description  new function created  for showing first tab information of campaign details.
   */

  fun_First_Tab_Info(campID, key) {
    //sunita-task-3324-added code for loader
    this.setState({ loading: true, loader2: "loader_review" }); //Sandeep task-3441-added code for loader
    // const { user } = this.props.auth;//Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
    // const { id: agencyID, role: userRole } = user; //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
    let data = { campID }; //Priyanka --3720--removed params
    fetch("/publisherReviewLead/LeadQAReview", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((leadQAReview) => {
        //sunita-task-3324-added code for voiceloglink column
        // if ((leadQAReview[0].marketingChannel == "TeleMarketing" && leadQAReview[0].callAudit=="Yes" )|| (leadQAReview[0].marketingChannel == "Email/Telemarketing" ||leadQAReview[0].marketingChannel=="Email") && leadQAReview[0].callAudit==="Yes"){

        //   this.setState({ marketingChannelFlag: true })
        // }
        // else {

        //   this.setState({ marketingChannelFlag: false })
        // }
        /*Following array and condition added to remove camp datails after submitting leads*/
        var campArray = leadQAReview.filter((a) => {
          return a.campID == campID; //Aman-2835-(Not adding === "on 1st tab getting issue to camp id")-Console Warnings - Agency - Advertiser Menu
        });
        if (campArray.length > 0) {
        } else {
          campID = leadQAReview[0].campID;
        }

        this.setState({ campID, leadQAReview, loading: false, loader2: "" }); //Sandeep-task-3441-added loader
        this.getCampaignInfo(campID); //sunita-task-3270-this function called for showing right side campaign details data.
        this.fun_Get_Lead_TableData(campID, key); //sunita-task-3270-this function called for showing table data as per key
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  /**
   * @author Sunita Landge
   * @param  Description  new function created  for showing first tab information of campaign details.
   */
  //mufiz1
  fun_Second_Tab_Info(campID, key) {

    //sunita-task-3324-added code for loader
    //this.state.loading1=true;
    this.setState({ loader2: "loader_review" }); //Sandeep-task-3441-added code for loader
    // this.setState({ loading: true, loading1: true });
    //const { user } = this.props.auth; //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
    //const { id: agencyID, role: userRole } = user; //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
    //let data = { agencyID, userRole };		Priyanka --3720--removed params
    this.getCampaignInfo(campID); //sunita-task-3270-this function called for showing right side campaign details data.
    this.fun_Get_Lead_TableData(campID, key); //sunita-task-3270-this function called for showing table data as per key
    fetch("/publisherReviewLead/agencyReviewInternalLead", {
      method: "POST",
      headers: { "content-Type": "application/json" },
      //body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((agencyReviewInternalLead) => {
        // sunita-task-3324-added code for voiceloglink column
        // if ((agencyReviewInternalLead[0].marketingChannel == "TeleMarketing" && agencyReviewInternalLead[0].callAudit=="Yes") || (agencyReviewInternalLead[0].marketingChannel == "Email/Telemarketing"||agencyReviewInternalLead[0].marketingChannel=="Email") &&agencyReviewInternalLead[0].callAudit=="Yes") {

        //   this.setState({ marketingChannelFlag: true })
        // }
        // else {
        //   this.setState({ marketingChannelFlag: false })
        // }

        this.setState({
          agencyReviewInternalLead,
          loading1: false,
          loader2: "",
        });
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  componentDidMount() {
    const { user } = this.props.auth;
    this.setState({ userRole: user.role });
  }
  componentWillMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      // karan-task-3715-replace query params
      var campID, parentCampID, key, reallocationID;
      if (this.props.location.state !== undefined) {
        campID = this.props.location.state.campID;
        parentCampID = this.props.location.state.parentCampID;
        key = +this.props.location.state.tabkey;
        reallocationID = this.props.location.state.reallocationID;
        this.setState({ campID });
      }
      const { user } = this.props.auth;
      var agencyID = user.id;
      // let key = +parsed.tabkey;
      // this.setState({campID : campID});//Aman-2835-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings - Agency - Advertiser Menu
      this.state.campID = campID; //Aman-4875-(removed the setState)AC-leads review - page crash issue.

      //sunita-task-3270-fetching the key to open the particular tab.
      //  let key=this.state.key;
      const token = user.token;
      // tabkey=this.state.tabkey3 ;
      // var reallocationID = parsed.reallocationID;
      //Sandeep-task-3819-agency side-review leads-updated screen-FE
      // this.setState({token : token});//Aman-2835-(Used setState() for Do not mutate state directly. Use setState())-Console Warnings - Agency - Advertiser Menu
      this.state.token = token;//Aman-4875-(removed the setState)AC-leads review - page crash issue.

      this.setState({
        parentCampID,
        reallocationID,
        agencyID,
        key,
        loading: true,
        campID,
      });
      // this.setState({ parentCampID, reallocationID, agencyID, key, loading: true, loading1: true, campID })

      //sunita-task-3270-as per below key it will open particular tab
      if (key == 1) { //Aman-2835-(Not adding === "on getting issue to load table data")-Console Warnings - Agency - Advertiser Menu
        this.fun_First_Tab_Info(campID, key); //sunita-task-3270-this function called for showing first tab info
        this.fun_getLeadReviewLable(campID); //Priyanka--3720--this function called for leads
      }
      // shivani-task 3326-Api call for showing the campaigns for lead review in left sdction on third  tab.
      else if (key == 3) { //Aman-2835-(Not adding === "on getting issue to load table data")-Console Warnings - Agency - Advertiser Menu
        //let data1 = {user: user};		Priyanka --3720--removed params
        fetch("/agencyDashboard/getAgencyAcceptedLead", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          //body: JSON.stringify(data1),
        })
          .then((res) => res.json())
          .then((agencyAcceptedLead) => {
            if (agencyAcceptedLead.length > 0) {
              campID = agencyAcceptedLead[0].campID;

              this.setState({
                agencyAcceptedLead: agencyAcceptedLead,
                agencyAcceptedLeadByCampID: [agencyAcceptedLead[0]],
                campID,
                parentCampID: agencyAcceptedLead[0].parentCampID, ////Priyanka--3720--accessing req details
                reallocationID: agencyAcceptedLead[0].reallocationID, //Priyanka--3720--accessing req details
              });
              this.fun_getLeadReviewLable(campID); ////Priyanka--3720--this function called for leads

              //Nilesh-4785- add below route showing lead's count as per the camp id
          fetch("/dashboardAdvertiser/getAcceptedLeadByAgency?campID=" + campID)
          .then(res => res.json())
          .then(thirdTabLeadsCount => {
    
            this.setState({ thirdTabLeadsCount: thirdTabLeadsCount, loading: false, reviewLeadFlag: false}); //sunita-task-3271-added code bcz as after uploading file status is showing in bold form when clicking on other campaign id's.
            // if (thirdTabLeadsCount.length > 0) {
            //   this.state.btnDisable = 'btn add-button';
            //   this.forceUpdate();
            // }
          }).catch(function (err) { console.log(err) });
            }
          }); //Sandeep-task-3441-added code for loader
      } else {
        this.fun_Second_Tab_Info(campID, key); //sunita-task-3270-this function called for showing second tab info
        //sunita-task-3270-as per below key it will open particular tab
        if (key == 1) { //Aman-2835-(Not adding === "on getting issue to load table data")-Console Warnings - Agency - Advertiser Menu
          this.fun_First_Tab_Info(campID, key); //sunita-task-3270-this function called for showing first tab info
        } else {
          this.fun_Second_Tab_Info(campID, key); //sunita-task-3270-this function called for showing second tab info
        }
        this.fun_getLeadReviewLable(campID); ////Priyanka--3720--this function called for leads
      }
    }
  }

  /**  3720
   * @author Priyanka Patil
   * @param  Description created function for pending status of API
   * @return Description lead details
   */

  fun_getLeadReviewLable(campID) {
    let data1 = {
      campID: this.state.campID,
    };
    fetch("leadDownloadDetails/getLeadReviewLabel", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: this.state.token,
      },
      body: JSON.stringify(data1),
    })
      .then((res) => res.json())
      .then((getLeadReviewLabel) => {
       //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
        if (
          getLeadReviewLabel.automatic[0] === "undefined" ||
          getLeadReviewLabel.automatic[0] === undefined ||
          getLeadReviewLabel.automatic[0] === "" ||
          getLeadReviewLabel.automatic[0] === "NA"
        ) {
          this.setState({ resultFirmoGraphic: "NA" });
        } else {
          this.setState({ FirmographicLabel: getLeadReviewLabel.automatic[0] });
          this.setState({
            labelForFirmographic: this.state.FirmographicLabel.Firmographic,
          });
          const resultFirmoGraphic = this.state.labelForFirmographic
            .toString()
            .replace(/"/g, "");
          this.setState({ resultFirmoGraphic });
        }
        //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
        if (
          getLeadReviewLabel.automatic[1] === "undefined" ||
          getLeadReviewLabel.automatic[1] === undefined ||
          getLeadReviewLabel.automatic[1] === "" ||
          getLeadReviewLabel.automatic[1] === "NA"
        ) {
          this.setState({ resultDemoGraphic: "NA" });
        } else {
          this.setState({ DemographicLabel: getLeadReviewLabel.automatic[1] });
          this.setState({
            labelForDemographic: this.state.DemographicLabel.Demographic,
          });
          const resultDemoGraphic = this.state.labelForDemographic
            .toString()
            .replace(/"/g, "");
          this.setState({ resultDemoGraphic });
        }
        //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
        if (
          getLeadReviewLabel.automatic[2] === "undefined" ||
          getLeadReviewLabel.automatic[2] === undefined ||
          getLeadReviewLabel.automatic[2] === "" ||
          getLeadReviewLabel.automatic[2] === "NA"
        ) {
          this.setState({ resultOther: "NA" });
        } else {
          this.setState({ OtherLabel: getLeadReviewLabel.automatic[2] });
          this.setState({ labelForOther: this.state.OtherLabel.Other });
          const resultOther = this.state.labelForOther
            .toString()
            .replace(/"/g, "");
          this.setState({ resultOther });
        }
        //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
        if (
          getLeadReviewLabel.automatic[3] === "undefined" ||
          getLeadReviewLabel.automatic[3] === undefined ||
          getLeadReviewLabel.automatic[3] === "" ||
          getLeadReviewLabel.automatic[3] === "NA"
        ) {
          this.setState({ resultCustomQuestion: "NA" });
        } else {
          this.setState({
            CustomQuestionLabel: getLeadReviewLabel.automatic[3],
          });
          this.setState({
            labelForCustomQuestion: this.state.CustomQuestionLabel
              .CustomQuestions,
          });
          const resultCustomQuestion = this.state.labelForCustomQuestion
            .toString()
            .replace(/"/g, "");
          this.setState({ resultCustomQuestion });
        }
        //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
        if (
          getLeadReviewLabel.manual[0] === "undefined" ||
          getLeadReviewLabel.manual[0] === undefined ||
          getLeadReviewLabel.manual[0] === "" ||
          getLeadReviewLabel.manual[0] === "NA"
        ) {
          this.setState({ resultLinkdin: "NA" });
        } else {
          this.setState({ linkdinLabel: getLeadReviewLabel.manual[0] });
          this.setState({ labelForLinkdin: this.state.linkdinLabel.linkedIn });
          const resultLinkdin = this.state.labelForLinkdin
            .toString()
            .replace(/"/g, "");

          this.setState({ resultLinkdin });
        }
        //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
        if (
          getLeadReviewLabel.manual[1] === "undefined" ||
          getLeadReviewLabel.manual[1] === undefined ||
          getLeadReviewLabel.manual[1] === "" ||
          getLeadReviewLabel.manual[1] === "NA"
        ) {
          this.setState({ resultMonthlyAllocation: "NA" });
        } else {
          this.setState({
            monthlyAllocationLabel: getLeadReviewLabel.manual[1],
          });
          this.setState({
            labelForMonthlyAllocation: this.state.monthlyAllocationLabel
              .MonthlyAllocation,
          });
          const resultMonthlyAllocation = this.state.labelForMonthlyAllocation
            .toString()
            .replace(/"/g, "");

          this.setState({ resultMonthlyAllocation });
        }
        //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
        if (
          getLeadReviewLabel.manual[2] === "undefined" ||
          getLeadReviewLabel.manual[2] === undefined ||
          getLeadReviewLabel.manual[2] === "" ||
          getLeadReviewLabel.manual[2] === "NA"
        ) {
          this.setState({ resultNotes: "NA" });
        } else {
          this.setState({ NotesLabel: getLeadReviewLabel.manual[2] });
          this.setState({ labelForNotes: this.state.NotesLabel.Notes });
          const resultNotes = this.state.labelForNotes
            .toString()
            .replace(/"/g, "");
          this.setState({ resultNotes });
        }
        //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
        if (
          getLeadReviewLabel.Multitouch === "undefined" ||
          getLeadReviewLabel.Multitouch === undefined ||
          getLeadReviewLabel.Multitouch === ""
        ) {
        } else {
          this.setState({ resultMultitouch: getLeadReviewLabel.Multitouch });
        }
      });
  }

  /* @author Narendra Phadke
   * @param  Description hanlde change status of the lead details
   * @return Description return accepted or rejected lead
   */

  // handleChange(i, e) {	
  //   const { name, value } = e.target;	
  //   let campaignLeadDetails = [...this.state.campaignLeadDetails];	
  //   campaignLeadDetails[i] = { ...campaignLeadDetails[i], [name]: value };	
  //   this.setState({ campaignLeadDetails });	
  // }

  // handleReasonChange(i, e) {	//4591-Nilesh new function for AC side Internal Review leads for 2nd tab status change 
  //   const { name, value } = e.target;
  //   let campaignLeadDetails = [...this.state.campaignLeadDetails];
  //   campaignLeadDetails[i] = { ...campaignLeadDetails[i], [name]: value };
  //   this.setState({ campaignLeadDetails });
  // }

  handleReasonChange = (absoluteIndex, event) => {
    const { name, value } = event.target;
    const updatedDetails = [...this.state.campaignLeadDetails];
  
    //Nilesh-4562- Update the specific field for the record at the absolute index
    updatedDetails[absoluteIndex][name] = value;
    
    //Nilesh-4562- If the "status" field is being changed and it's not "Rejected", clear the "reason" field
    if (name === "status" && value !== "Rejected") {
      updatedDetails[absoluteIndex].reason = "";
    }
  
    //Nilesh-4562- Update the state with the modified data source
    this.setState({ campaignLeadDetails: updatedDetails });
  };
  

  // handleChange(i, e) {//4591-Nilesh new function for AC side Internal Review leads for 1st tab status change 
  //   const { name, value } = e.target;
  //   let internalLeadDetails = [...this.state.internalLeadDetails];
  //   internalLeadDetails[i] = { ...internalLeadDetails[i], [name]: value };
  //   this.setState({ internalLeadDetails });
  // }

  handleChange = (absoluteIndex, event) => {
    const { name, value } = event.target; 
    const updatedDetails = [...this.state.internalLeadDetails];
  
    //Nilesh-4562- Update the specific record's field using the absolute index
    updatedDetails[absoluteIndex][name] = value;
  
    //Nilesh-4562- Update the state with the modified data source
    this.setState({ internalLeadDetails: updatedDetails });
  };

  
  /**
   * @author Somnath Keswad
   * @param  Description Change Status of lead
   * @return Description return status in array
   */
  // handleChangeInternalLead(i, e) {
  //   const { name, value } = e.target;
  //   let internalLeadDetails = [...this.state.internalLeadDetails];
  //   internalLeadDetails[i] = { ...internalLeadDetails[i], [name]: value };
  //   this.setState({ internalLeadDetails });
  // }
  
  handleChangeInternalLead = (absoluteIndex, event) => {
    const { value } = event.target; //Nilesh- 4562- Get the selected value
    const updatedDetails = [...this.state.internalLeadDetails]; //Nilesh-4562- Clone the data source

    //Nilesh-4562- Update the specific record's status using the absolute index
    updatedDetails[absoluteIndex].status = value;
    if (value !== "Rejected" || value !== "AgencyQARejected") {
      updatedDetails[absoluteIndex].reason = ""; // Reset the reason field
    }
    //Nilesh-4562-  Update the state with the modified data source
    this.setState({ internalLeadDetails: updatedDetails });
  };
  
  onTableChange = (pagination, filters, sorter) => {
    //Nilesh-4562- Update the current page in the state
    this.setState({
      tableState: {
        ...this.state.tableState,
        current: pagination.current, //Nilesh-4562- Update the current page
      },
    });
  };
  
  /**
   * @author Somnath Keswad
   * @param  Description Download QA Lead Status Lead File
   * @return Description return leads
   */
  downloadInternalLeadDetails(e) {
    let errors = {};
    var campID = this.state.campID;

    var parentCampID = this.state.parentCampID;

    //var reallocationID = this.state.reallocationID; //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu  
    var clientCampID = this.state.reviewlead[0].clientCampID; //4557-Nilesh add clientCampID in file downloaded name
    // let data = {
    //   campID: campID,
    //   leadDetails: this.state.campaignLeadDetails
    // }
    fetch("/leadDownloadDetails/downloadQALeadDetails?campID=" + campID + "")
      .then((res) => res.json())
      .then((downloadFiles) => {
        this.setState({ downloadFiles: downloadFiles });

        if (downloadFiles.success === true) {
          //errors['failureMsg']="No Data Exists"
          Swal.fire({
            type: "error",
            text: "No Data Exists!",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
          this.setState({ errors: errors });
        } else {
          //       / make the worksheet /
          var ws = xlsx.utils.json_to_sheet(downloadFiles);
          // / add to workbook /
          var wb = xlsx.utils.book_new();
          xlsx.utils.book_append_sheet(wb, ws, "Lead");

          // / write workbook /
          var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
         // var str = xlsx.write(wb, { bookType: "xlsx", type: "binary" }); //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
          // / generate a download /
          function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            //Aman-2835-(added '!==' and instead saw '!=')-Console Warnings - Agency - Advertiser Menu
            for (var i = 0; i !== s.length; ++i)
              view[i] = s.charCodeAt(i) & 0xff;
            return buf;
          }
          // var fileName = parentCampID + "_QA_Leads" + ".xlsx";
          var fileName = "QA_Leads_" + clientCampID + "_" + parentCampID + ".xlsx" //4557-Nilesh add clientCampID in file downloaded name
          saveAs(
            new Blob([s2ab(buf)], { type: "application/octet-stream" }),
            fileName
          );
        }
      });
  }

  /* @author Narendra Phadke
   * @param  Description Download the lead details
   * @return Description return excel file for download
   */
  handleDownloadLeadDetails(e) {
    let errors = {};
    var campID = this.state.campID;
    var parentCampID = this.state.parentCampID;
    //var reallocationID = this.state.reallocationID; //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
    var clientCampID = this.state.reviewlead[0].clientCampID; //4557-Nilesh add clientCampID in file downloaded name

    // let data = {
    //   campID: campID,
    //   leadDetails: this.state.campaignLeadDetails
    // }
    fetch("lead/downloadLeadDetails?campID=" + campID + "")
      .then((res) => res.json())
      .then((downloadFiles) => {
        this.setState({ downloadFiles: downloadFiles });
        if (downloadFiles.success === true) {
          //errors['failureMsg']="No Data Exists"
          Swal.fire({
            type: "error",
            text: "No Data Exists!",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
          this.setState({ errors: errors });
        } else {
          //       /* make the worksheet */
          var ws = xlsx.utils.json_to_sheet(downloadFiles);
          /* add to workbook */
          var wb = xlsx.utils.book_new();
          xlsx.utils.book_append_sheet(wb, ws, "Lead");

          // /* write workbook */
          var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
          //var str = xlsx.write(wb, { bookType: "xlsx", type: "binary" }); //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
          /* generate a download */
          function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            //Aman-2835-(added '!==' and instead saw '!=')-Console Warnings - Agency - Advertiser Menu
            for (var i = 0; i !== s.length; ++i)
              view[i] = s.charCodeAt(i) & 0xff;
            return buf;
          }
          // var fileName = parentCampID + "_Internal_Review_Lead" + ".xlsx";
          var fileName = "Internal_Review_Lead_" + clientCampID + "_" + parentCampID + ".xlsx" //4557-Nilesh add clientCampID in file downloaded name
          saveAs(
            new Blob([s2ab(buf)], { type: "application/octet-stream" }),
            fileName
          );
        }
      });
  }

  /* @author Narendra Phadke
   * @param  Description Download the lead details download only agency accepted for send to client
   * @return Description return excel file for download
   */
  handleClientDownloadLeadDetails(e) {
    e.preventDefault();
    //let errors = {}; //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu 
    // karan-task-3715-replace query params
    //var parsed = this.props.location.state; //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
    // var campID = parsed.campID;
    // var campID = this.state.campID;
    // if (campID==undefined || campID== null){
    var campID = this.state.campID;

    let agencyAcceptedLead = this.state.agencyAcceptedLead; //4577-Nilesh add clientCampID in file downloaded name
    let campDetails = agencyAcceptedLead.filter((el) => {
      return el.campID == campID; //Aman-2835-(Not adding === "on 1st tab getting issue to camp id")-Console Warnings - Agency - Advertiser Menu
    });

    //var parentCampID = this.state.parentCampID; //Priyanka--3720--accessing req details //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
    //var reallocationID = this.state.reallocationID; //Priyanka--3720--accessing req details //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
    // let data = {
    //   campID: campID,
    //   leadDetails: this.state.campaignLeadDetails,
    // }; //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
    //shivani-3326-changed text message on pop-Up .
    fetch("lead/clientDownloadLeadDetails?campID=" + campID + "")
      .then((res) => res.json())
      .then((clientDownloadLead) => {
        this.setState({ clientDownloadLead: clientDownloadLead });

        if (clientDownloadLead.success === true) {
          Swal.fire({
            type: "error",
            text: "Already Leads Downloaded!",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
        } else {
          //       /* make the worksheet */
          var ws = xlsx.utils.json_to_sheet(clientDownloadLead);
          /* add to workbook */
          var wb = xlsx.utils.book_new();
          xlsx.utils.book_append_sheet(wb, ws, "Lead");

          // /* write workbook */
          var buf = xlsx.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
          //var str = xlsx.write(wb, { bookType: "xlsx", type: "binary" }); //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
          /* generate a download */
          function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            //Aman-2835-(added '!==' and instead saw '!=')-Console Warnings - Agency - Advertiser Menu
            for (var i = 0; i !== s.length; ++i)
              view[i] = s.charCodeAt(i) & 0xff;
            return buf;
          }
          // var fileName = "Agency_Accepted_" + clientCampID + "_" + clientDownloadLead[0].parentCampID + ".xlsx" //4557-Nilesh add clientCampID in file downloaded name

          var fileName = "Agency_Accepted_" + campDetails[0].clientCampID + "_" + clientDownloadLead[0].parentCampID + ".xlsx" //4577-Nilesh add clientCampID in file downloaded name
          saveAs(
            new Blob([s2ab(buf)], { type: "application/octet-stream" }),
            fileName
          );
        }
      });
  }

  /* @author Narendra Phadke
   * @param  Description Download the lead details
   * @return Description return excel file for download
   */
  handleFileShowDownload(blob) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    let excelFileDownloadName = "ABC.xlsx";
    var newBlob = new Blob([this.state.downloadFiles], {
      type: "application/zip",
    });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    link.href = data;
    link.download = excelFileDownloadName;
    link.click();
  }

  /**
   * @author Narendra Phadke
   * @param  Description handle the upload lead
   * @return Description return All details of lead
   */
  // showfilename()
  // {

  // }
  async handleChangeFileUpload(e) {
    e.preventDefault();
    let errors = {};
    let reerrors = {};
    var campID = this.state.campID;
    //var names = []; //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
    //let filesData = e.target.files; //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
    let filesData1 = e.target.files[0];
    var ext;
    await this.fetchDownloadFilesLengthSecondTab()
    if (filesData1 == null) {
      ext = "";
      var label = document.getElementById("fileName2");
      label.innerHTML = "No File Choosen";
    } else {
      ext = filesData1.name.split(".").pop();
      let label = document.getElementById("fileName2"); //Aman-2835-(change var to let to resolve duplicate var issue)-Console Warnings - Agency - Advertiser Menu
      label.innerHTML = filesData1.name;
    }

    let message = "";

    //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
    //const { isAuthenticated, user } = this.props.auth;
    //const { id: agencyID, role: userRole } = user; //sunita-task-3270-sent data as per requirement to BE api  
    // var userID = user.id;
    // var userName = user.name;
    // var firstName = user.firstName;
    // var lastName = user.lastName;

    var data = new FormData();
    //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
    if (ext === "xlsx" || ext === "XLSX") {
      // e.target.value = null; //sunita-task-3324- added code for removing the alredy uploaded lead file when we click on another campaign id.
      data.append("campID", this.state.campID);
      data.append("message", message);
      data.append("file", filesData1);
      data.append("downloadFilesLength", this.state.downloadFilesLength);
      // data.append("userID", userID);
      // data.append("userName", userName);
      // data.append("user", JSON.stringify(user));
      // data.append("firstName", firstName);
      // data.append("lastName", lastName);
      fetch("/leadDownloadDetails/uploadReviewLead", {
        method: "POST",
        //mode:'no-cors',
        body: data,
      })

        .then((res) => res.json())
        .then((campaignLeadDetails) => {
          //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
          if(true === campaignLeadDetails.success){ //Nilesh-4785-validation for wrong leads count upload in file
            Swal.fire({
              text: "Uploaded leads count wrong please enter correct leads count",   
              type: "error",
              allowOutsideClick: false,
              heightAuto: false,
              preConfirm: () => {
                window.location.reload();
              },
            })
            return; 
          }

          if (campaignLeadDetails.length > 0) {  //4612-Nilesh-File validation for wrong status upload in file
            this.setState({
              campaignLeadDetails: campaignLeadDetails,
              reviewLeadFlag: true,
              buttonFlag: true,
            });

            //shivani-task 3089-added buttonFlag:true to make button disabled if i upload leads through excel file.
            //sunita-task-3270-added beow api for removing particular campaign id from left side after file upload
            //let data4 = { agencyID, userRole };		Priyanka --3720--removed params
            fetch("/publisherReviewLead/agencyReviewInternalLead", {
              method: "POST",
              headers: { "content-Type": "application/json" },
              //body: JSON.stringify(data4),
            })
              .then((res) => res.json())
              .then((agencyReviewInternalLead) => {
                this.setState({ agencyReviewInternalLead });
              })
              .catch(function (err) {
                console.log(err);
              });

            errors["uploadsuccess"] = "File Uploaded Successfully";

            if(campaignLeadDetails[0].status.toLowerCase() === "accepted"){ //Aman-4860-added this to generated CSV when the 0th status is accepted only

              //Nilesh-4858 This code was earlier on line number 1833 and is now add here
              data = {
                campID: campID,
                campaignLeadDetails: this.state.campaignLeadDetails,
                //user: user,		Priyanka --3720--removed params
                diFlag: this.state.diFlag,
              };
              //Nilesh-4862- comment generateCSV function
              // this.generateCSV(data); //rutuja - 4426 generate csv after uploading lead file
            }
        
            this.setState({ errors: errors, reerrors: reerrors });

          }
          //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
          else if(false === campaignLeadDetails.success){ //Nilesh-4771-validation for wrong camp ID upload in file
            Swal.fire({
              text: campaignLeadDetails.message,   
              type: "error",
              allowOutsideClick: false,
              heightAuto: false,
              preConfirm: () => {
                window.location.reload();
              },
            })
          } else {
            Swal.fire({
              text: "Please enter valid status against leads as per the given instructions on the screen", //4612-Nilesh-File validation for wrong status upload in file
              type: "error",
              allowOutsideClick: false,
              heightAuto: false,
              preConfirm: () => {
                window.location.reload();
              },
            })
          }


        })
        .catch(function (err) {
          console.log(err);
        });

      // errors["uploadsuccess"] = "File Uploaded Successfully";
      reerrors["fileError"] = "";

      this.setState({ errors: errors, reerrors: reerrors });
    } else {

      reerrors["fileError"] =
        "File Invalid Please Upload file with .xlsx format";
      errors["uploadsuccess"] = "";

      this.setState({ reerrors: reerrors, errors: errors });
    }

    
  }
  /**
   * @author Somnath Keswad
   * @param  Description Upload internal lead file
   * @return Description return updated leads
   */
  async uploadInternalLeadFile(e) {
    e.preventDefault();
    let errors = {};
    let reerrors = {};
    var campID = this.state.campID;
    //var names = [];//Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu 
    //let filesData = e.target.files; //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
    let filesData1 = e.target.files[0];
    // this.setState({filesData1});

    var ext;
   await this.fetchDownloadFilesLengthFirstTab()
    if (filesData1 == null) {
      ext = "";
      var label = document.getElementById("fileName");
      label.innerHTML = "No File Choosen";
    } else {
      ext = filesData1.name.split(".").pop();
      let label = document.getElementById("fileName"); //Aman-2835-(change var to let to resolve duplicate var issue)-Console Warnings - Agency - Advertiser Menu
      label.innerHTML = filesData1.name;
    }

    let message = "";
    //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
    //const { user } = this.props.auth;
    //const { id: agencyID, role: userRole } = user; //sunita-task-3270-sent data as per requirement to BE api
    // var userID = user.id;
    // var userName = user.name;
    // var firstName = user.firstName;
    // var lastName = user.lastName;

    var data = new FormData();
    //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
    if (ext === "xlsx" || ext === "XLSX") {
      data.append("campID", this.state.campID);
      data.append("message", message);
      data.append("file", filesData1);
      data.append("downloadFilesLength", this.state.downloadFilesLength);
      // data.append("user", JSON.stringify(user));
      // data.append("userID", userID); 
      // data.append("userName", userName);
      // data.append("firstName", firstName);
      // data.append("lastName", lastName);   Mufiz=4033-add in comment due to data visssible in payload
      fetch("/leadDownloadDetails/uploadInternalReviewLead", {
        method: "POST",
        //mode:'no-cors',
        body: data,
      })
        .then((res) => res.json())
        .then((internalLeadDetails) => {
         //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu 
     if(true === internalLeadDetails.success){ //Nilesh-4785-validation for wrong leads count add in file
            Swal.fire({
              text: "Leads count in uploaded file is greater than total leads count",   
              type: "error",
              allowOutsideClick: false,
              heightAuto: false,
              preConfirm: () => {
                window.location.reload();
              },
            })
            return; 
          }
          
          if (internalLeadDetails.length > 0) {     //4612-Nilesh-File validation for wrong status upload in file

            this.setState({
              internalLeadDetails: internalLeadDetails,
              reviewLeadFlag: true,
              buttonFlag: true,
            }); //shivani-task 3089-added buttonFlag:true to make button disabled if i upload leads through excel file.

            //sunita-task-3270-added beow api for removing particular campaign id from left side after file upload
            let data3 = { campID };
            fetch("/publisherReviewLead/LeadQAReview", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data3),
            })
              .then((res) => res.json())
              .then((leadQAReview) => {
                this.setState({ leadQAReview });
              })
              .catch(function (err) {
                console.log(err);
              });
            errors["uploadsuccessinternal"] = "File Uploaded Successfully";
            this.setState({ errors: errors });
          }  
          //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
          else if(false === internalLeadDetails.success){ //Nilesh-4771-validation for wrong camp ID upload in file
            Swal.fire({
              text: internalLeadDetails.message,   
              type: "error",
              allowOutsideClick: false,
              heightAuto: false,
              preConfirm: () => {
                window.location.reload();
              },
            })
          }
          else {
            Swal.fire({
              text: "Please enter valid status against leads as per the given instructions on the screen",  //4612-Nilesh-File validation for wrong status upload in file
              type: "error",
              allowOutsideClick: false,
              heightAuto: false,
              preConfirm: () => {
                window.location.reload();
              },
            })
          }
        })
        .catch(function (err) {
          console.log(err);
        });
      // errors["uploadsuccessinternal"] = "File Uploaded Successfully";
      reerrors["fileError"] = "";

      this.setState({ errors: errors, reerrors: reerrors });
    } else {
      reerrors["fileError"] =
        "File Invalid Please Upload file with .xlsx format";
      errors["uploadsuccessinternal"] = "";

      this.setState({ reerrors: reerrors, errors: errors });
    }
  }

  /**
   * @author Narendra Phadke
   * @param  Description handle the upload lead
   * @return Description return All details of lead
   */
  handleUploadLeadFile(e) {
    e.preventDefault();

    const { user } = this.props.auth; //Aman-2835-(removed isAuthenticated not in use)-Console Warnings - Agency - Advertiser Menu
    var userID = user.id;
    var userName = user.name;
    var firstName = user.firstName;
    var lastName = user.lastName;
    var data = new FormData();
    data.append("campID", this.state.campID);
    data.append("file", this.state.uploadLeadFile);
    data.append("userID", userID);
    data.append("userName", userName);
    data.append("firstName", firstName);
    data.append("lastName", lastName);
    fetch("/lead/uploadReviewLead", {
      method: "POST",
      //mode:'no-cors',
      body: data,
    })
      .then(function (response) {
        if (response.status >= 400) {
          throw new Error("Bad response from server");
        }
        return response.json();
      })
      .then(function (response) {
        //var campID=response.body.campID;
        console.log(response);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  /**
   * @author Narendra Phadke
   * @param  Description handle the View lead
   * @return Description return All details of lead
   */
  handleViewReviewLead(e) {
    e.preventDefault();

    // karan-task-3715-replace query params
    var campID;
    if (this.props.location.state !== undefined) {
      campID = this.props.location.state.campID;
    }
    //const { isAuthenticated, user } = this.props.auth; //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
    let data = {
      campID: campID,
      campaignLeadDetails: this.state.campaignLeadDetails,
      //user: user,		Priyanka --3720--removed params
    };
    fetch("/lead/displayLeadReview", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((campaignLeadDetails) =>
        this.setState({
          campaignLeadDetails: campaignLeadDetails,
          reviewLeadFlag: true,
          diFlag: campaignLeadDetails[0].diFlag,
        })
      )
      .catch(function (err) {
        console.log(err);
      });
  }

  fun_Third_Tab_Info(campID, key) { //Create a new function for Tab-3 under Bug-4033-mufiz khan

    this.setState({ loader2: "loader_review" })
    //const { user } = this.props.auth; //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
    //const { id: agencyID, role: userRole } = user; //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
    //let data = { agencyID, userRole };		Priyanka --3720--removed params
    this.getCampaignInfo(campID); //sunita-task-3270-this function called for showing right side campaign details data.
    this.fun_Get_Lead_TableData(campID, key);
    fetch("/agencyDashboard/getAgencyAcceptedLead", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      //body: JSON.stringify(data1),
    })
      .then((res) => res.json())
      .then((agencyAcceptedLead) => {
        this.setState({
          agencyAcceptedLead,
          loading1: false,
          loader2: "",
        });
      })
      .catch(function (err) {
        console.log(err);
      });
      fetch("/dashboardAdvertiser/getAcceptedLeadByAgency?campID=" + campID) //Nilesh-4806-Add route for total leads count 
          .then(res => res.json())
          .then(thirdTabLeadsCount => {
    
            this.setState({ thirdTabLeadsCount: thirdTabLeadsCount, loading: false, reviewLeadFlag: false });
            
            // if (thirdTabLeadsCount.length > 0) {
            //   this.state.btnDisable = 'btn add-button';
            //   this.forceUpdate();
            // }
          }).catch(function (err) { console.log(err) });
  }





  /**
   * @author Narendra Phadke
   * @param  Description handle the upload client response lead
   * @return Description return All details of lead
   */
  //shivani-task 3326 - changed in upload function
  async handleChangeClientResponseFileUpload(e) {
    // e.preventDefault();
    let errors = {};
    let reerrors = {};
    //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
    //const { isAuthenticated, user } = this.props.auth;
    //var parsed = this.props.location.state;

    // var campID = parsed.campID;
    var campID = this.state.campID;
    var reAllocationID = this.state.reAllocationID;
    //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
    // var userID = user.id;
    // var userName = user.name;
    // var firstName = user.firstName;
    // var lastName = user.lastName;
    let filesData1 = e.target.files[0];
    var ext;
   await this.fetchDownloadFilesLengthThirdTab();
    //let data11 = {user: user,};	Priyanka --3720--removed params
    if (filesData1 == null) {
      ext = "";
      var label = document.getElementById("clientFilename");
      label.innerHTML = "No File Choosen";
    } else {
      ext = filesData1.name.split(".").pop();
      let label = document.getElementById("clientFilename"); //Aman-2835-(change var to let to resolve duplicate var issue)-Console Warnings - Agency - Advertiser Menu
      label.innerHTML =
        '<li id="uploadNameTruncate">' + filesData1.name + "</li>";
    }
    var data = new FormData();
    //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
    if (ext === "xlsx" || ext === "XLSX") {
     // e.target.value = null; //shivani-task 3326-added code for removing the alredy uploaded lead file when we click on another campaign id.
      data.append("campID", campID);
      data.append("reAllocationID", reAllocationID);
      data.append("file", filesData1);
      data.append("downloadFilesLength", this.state.downloadFilesLength);
      // data.append("userID", userID);
      // data.append("userName", userName);
      // data.append("firstName", firstName);
      // data.append("lastName", lastName); mufiz-add in comment due to data pass in payload
      let self = this;
      fetch("/leadDownloadDetails/uploadClientReviewLead", {
        method: "POST",
        body: data,
      })
        .then(function (response) {
          if (response.status >= 400) {
            throw new Error("Bad response from server");
          }
          return response.json();
        })
        .then(function (response) {
          //shivani-task 3326-called api to render all updates campaign id's to review lead.
          
          let response11 = response.success;
          let response12 = response.message;  //4612-Nilesh-File validation for wrong status upload in file
          //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
          if("true1" === response.success){ //Nilesh-4785-validation for extra leads add in file
            Swal.fire({
              text: "Leads count in uploaded file is greater than total leads count",   
              type: "error",
              allowOutsideClick: false,
              heightAuto: false,
              preConfirm: () => {
                window.location.reload();
              },
            })
            return; 
          }  
          //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
          if (response11 === true) {
            fetch("/agencyDashboard/getAgencyAcceptedLead", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              //body: JSON.stringify(data11),
            })
              .then((res) => res.json())
              .then((agencyAcceptedLead) => {
                if (agencyAcceptedLead.length > 0) {
                  // campID=agencyAcceptedLead[0].campID;
                  //Sandeep-task-3706-STC-- Upload advertiser response---upload lead issue--Agency side
                  let agencyAcceptedLeadByCamp = [];
                  for (let i = 0; i < agencyAcceptedLead.length; i++) {
                    //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
                    if (campID === agencyAcceptedLead[i].campID) {
                      agencyAcceptedLeadByCamp = [agencyAcceptedLead[i]];
                      break;
                    }
                  }
                  self.setState({
                    agencyAcceptedLead: agencyAcceptedLead,
                    agencyAcceptedLeadByCampID: agencyAcceptedLeadByCamp,
                  });
                }
                errors["clientuploadsuccess"] = "File Uploaded Successfully";
                self.setState({ errors, reerrors });
              });
            //shivani-task 3326-added validations , for success , wrong formate and wrong file .
            // errors["clientuploadsuccess"] = "File Uploaded Successfully";
            reerrors["clientfileError"] = "";
            self.setState({ errors, reerrors });
          } else if (response12 === "wrong status") { //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu 
            Swal.fire({
              text: "Please enter valid status against leads as per the given instructions on the screen",   //4612-Nilesh-File validation for wrong status upload in file
              type: "error",
              allowOutsideClick: false,
              heightAuto: false,
              preConfirm: () => {
                window.location.reload();
              },
            })
          } else {
            // errors["clientuploadsuccess"] = "";
            // reerrors["clientfileError1"] =
            //   "Lead Reviewed Not Successfully- Please Verify Campaign ID and Lead Info ID In Lead File";
            // self.setState({ errors, reerrors });
            Swal.fire({
              text: response.message,   //Nilesh-4771-validation for wrong camp ID upload in file
              type: "error",
              allowOutsideClick: false,
              heightAuto: false,
              preConfirm: () => {
                window.location.reload();
              },
            })
          }
          console.log(response);
        })
        .catch(function (err) {
          console.log(err);
        });
    } else {
      reerrors["clientfileError"] =
        "File Invalid Please Upload file with .xlsx format";
      errors["clientuploadsuccess"] = "";
      this.setState({ reerrors: reerrors, errors: errors });
    }

  }
  /**
   * @author Somnath Keswad
   * @param  Description handle the upload lead from submit button on portal
   * @return Description return All details of lead
   */
  submitInternalReviewLead(e) { //4591
    e.preventDefault();//4591
    // var parsed = queryString.parse(this.props.location.search);
    var campID = this.state.campID;
    //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
    //const { user } = this.props.auth;
    //const { id: agencyID, role: userRole } = user; 
    //let errors = {};
    let data = {
      campID: campID,
      campaignLeadDetails: this.state.internalLeadDetails,
      //user: user,		Priyanka --3720--removed params
      diFlag: this.state.diFlag,
    };

    //fetch("/agency/internalReviewLeadSubmitDI", {
    // this.loadingRef.current.click(); //Sandeep-task-3441-added loader
    fetch("/leadDownloadDetails/internalReviewLeadSubmit", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((internalLeadDetails) => {
        // Sandeep-task-3516-added code to show Table data updated successfully alert
        let editedFlag = false; //Sandeep-task-3659-added key to display updated data
        for (let i = 0; i < internalLeadDetails.length; i++) {
          if (internalLeadDetails[i].alertKey) {
            //Sandeep-task-3659-added key to display updated data
            editedFlag = true; //Sandeep-task-3659-added key to display updated data
            break;
          }
        }
        this.setState({
          internalLeadDetails: internalLeadDetails,
          reviewLeadFlag: true,
        });
        // this.closeLoadingRef.current.click(); //Sandeep-task-3441-added loader
        if (internalLeadDetails.length > 0 && editedFlag === true) { //Aman-2835-(removed Not in use)-Console Warnings - Agency - Advertiser Menu
          //Sandeep-task-3659-added key to display updated data
          // errors["leaderror"]="Lead reviewed successfully";
          Swal.fire({
            text: "Table data updated successfully",
            type: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            preConfirm: () => {
              // window.location.reload();
            },
          });
          //sunita-task-3270-added beow api for removing particular campaign id from left side after submit
          let data3 = { campID };
          fetch("/publisherReviewLead/LeadQAReview", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data3),
          })
            .then((res) => res.json())
            .then((leadQAReview) => {
              this.setState({ leadQAReview });
            })
            .catch(function (err) {
              console.log(err);
            });

          this.setState({ btnDisable: "disabled1" });
        } else if (internalLeadDetails.length > 0 && editedFlag === false) { //Aman-2835-(removed Not in use)-Console Warnings - Agency - Advertiser Menu
          // var firstKey = 1; //Aman-2835-(commented not in use)-Console Warnings - Agency - Advertiser Menu
          //Sandeep-task-3659-added key to display updated data
          // errors["leaderror"]="Lead reviewed successfully";
          //rutuja 4528-ANC Login--Lead review--second tab page loading issue
          if (this.state.userRole === "ANC") {
            Swal.fire({
              text: "Lead reviewed successfully",
              type: "success",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              preConfirm: () => {

                this.setState({ key: 3 });
                this.fun_Third_Tab_Info(campID)
              },
            });

          }
          else {
            Swal.fire({
              text: "Lead reviewed successfully",
              type: "success",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              preConfirm: () => {
                // window.location.reload();
                this.setState({ key: 2 }); //mufiz-bug-4033-Prod Issue - Agency Side Lead Review - On submit the leads of selected campaign, it should move to new tab automatically
                this.fun_Second_Tab_Info(campID, this.state.key); //Nilesh-4806-Add key
                //this.fun_Get_Lead_TableData(campID, key)
              },
            });
          }
          //sunita-task-3270-added beow api for removing particular campaign id from left side after submit
          let data3 = { campID };
          fetch("/publisherReviewLead/LeadQAReview", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data3),
          })
            .then((res) => res.json())
            .then((leadQAReview) => {
              this.setState({ leadQAReview });
            })
            .catch(function (err) {
              console.log(err);
            });
          this.setState({ btnDisable: "disabled1" });
        } else {
          this.setState({ btnDisable: "btn add-button" });
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  } // end of submitInternalReviewLead

  /**
   * @author Sandeep Dhawale
   * @param  Description handle to edit job title,job Level,job Function
   * @return Description return all details of editable field
   */

  handleEditField(i, e) {
    const { name, value } = e.target;
    let internalLeadDetails = [...this.state.internalLeadDetails];
    internalLeadDetails[i] = {
      ...internalLeadDetails[i],
      [name]: value,
      editFlag: true,
      btnDisable: false,
      alertKey: "true",
    };
    for (let j = 0; j < internalLeadDetails.length; j++) {
      //Sandeep-task-3659-It acceptes the Numeric values too-btndisable issue
      //Aman-2835-(removed Not in use)-Console Warnings - Agency - Advertiser Menu
      if (j === i) {
        internalLeadDetails[j].btnDisable = false; //Sandeep-task-3659-It acceptes the Numeric values too-btndisable issue
        internalLeadDetails[j].editFlag = true; //Sandeep-task-3659-It acceptes the Numeric values too-btndisable issue
      } else {
        internalLeadDetails[j].btnDisable = true; //Sandeep-task-3659-It acceptes the Numeric values too-btndisable issue
        internalLeadDetails[j].editFlag = false; //Sandeep-task-3659-It acceptes the Numeric values too-btndisable issue
      }
    }
    this.setState({ internalLeadDetails });
  } //Function handleEditField

  /**
   * @author Sandeep Dhawale
   * @param  Description handle to edit job title,job Level,job Function
   * @return Description return all details of editable field
   */

  handleEditForQA(i, e) {
    const { name, value } = e.target;
    let campaignLeadDetails = [...this.state.campaignLeadDetails];
    campaignLeadDetails[i] = {
      ...campaignLeadDetails[i],
      [name]: value,
      editFlag: true,
      key: "1",
      btnDisable: false,
      alertKey: "true",
    };
    for (let j = 0; j < campaignLeadDetails.length; j++) {
      if (j === i) { //Aman-2835-(removed Not in use)-Console Warnings - Agency - Advertiser Menu
        campaignLeadDetails[j].btnDisable = false; //Sandeep-task-3659-It acceptes the Numeric values too-btndisable issue
        campaignLeadDetails[j].editFlag = true; //Sandeep-task-3659-It acceptes the Numeric values too-btndisable issue
      } else {
        campaignLeadDetails[j].btnDisable = true; //Sandeep-task-3659-It acceptes the Numeric values too-btndisable issue
        campaignLeadDetails[j].editFlag = false; //Sandeep-task-3659-It acceptes the Numeric values too-btndisable issue
      }
    }
    this.setState({ campaignLeadDetails });
  } //Function handleEditForQA

  /**
   * @author Sandeep Dhawale
   * @param  Description handle to edit job title,job Level,job Function
   * @return Description return all details of editable field
   */
  handleEditFirstTab(i, e) {
    const { name, value } = e.target;
    let internalLeadDetails = [...this.state.internalLeadDetails];
    internalLeadDetails[i] = {
      ...internalLeadDetails[i],
      [name]: value,
      key: "1",
    };
    this.setState({ internalLeadDetails, btnFlag: true }); //Sandeep-task-3652-Edited time save option issue--AQA lead review section
    this.handleEditField.bind(this, i);
  } //Function handleEditField
  /**
   * @author Sandeep Dhawale
   * @param  Description handle to edit job title,job Level,job Function
   * @return Description return all details of editable field
   */
  handleEditSecondTab(i, e) {
    const { name, value } = e.target;
    let campaignLeadDetails = [...this.state.campaignLeadDetails];
    campaignLeadDetails[i] = {
      ...campaignLeadDetails[i],
      [name]: value,
      key: "1",
    };
    this.setState({ campaignLeadDetails, btnFlag: true }); //Sandeep-task-3652-Edited time save option issue--AQA lead review section
    this.handleEditForQA.bind(this, i);
  } //Function handleEditSecondTab
  /**
   * @author Sandeep Dhawale
   * @param  Description added validation for job title,job Level,job Function data
   * @return Description return validate data for job title,job Level,job Function
   */
  saveFormSecondTab() {
    let saveEditedData = false;
    let errorMsgFlag = true;
    for (let i = 0; i < this.state.campaignLeadDetails.length; i++) {
      if (this.state.campaignLeadDetails[i].editFlag) {
        saveEditedData = true;
      }
      if (this.state.campaignLeadDetails[i].jobTitle.length > 0) {
        var pattern = new RegExp(/^[A-Za-z\n,| \-.&:()]+$/);
        if (!pattern.test(this.state.campaignLeadDetails[i].jobTitle)) {
          errorMsgFlag = false;
          this.setState({ jobTitleErrMsg2: "block" });
          break;
        } else {
          this.setState({ jobTitleErrMsg2: "none" });
        }
      } else {
        //Sandeep-task-3664-Blank data saving issue
        errorMsgFlag = false;
        this.setState({ jobTitleErrMsg2: "block" });
        break;
      }
    } //Sandeep-task-3659-It acceptes the Numeric values too
    for (let i = 0; i < this.state.campaignLeadDetails.length; i++) {
      //Sandeep-task-3659-It acceptes the Numeric values too
      if (this.state.campaignLeadDetails[i].jobFunction.length > 0) {
        let pattern = new RegExp(/^[A-Za-z\n,| \-.&:()]+$/); //Aman-2835-(change var to let to resolve duplicate var issue)-Console Warnings - Agency - Advertiser Menu
        if (!pattern.test(this.state.campaignLeadDetails[i].jobFunction)) {
          errorMsgFlag = false;
          this.setState({ jobFunctionErrMsg2: "block" });
          break;
        } else {
          this.setState({ jobFunctionErrMsg2: "none" });
        }
      } else {
        //Sandeep-task-3664-Blank data saving issue
        if (this.state.jobFunctionFlag === false) {
          //Sandeep-task-3664-Blank data saving issue
          this.setState({ jobFunctionErrMsg2: "none" }); //Sandeep-task-3664-Blank data saving issue
          break;
        } else {
          errorMsgFlag = false; //Sandeep-task-3664-Blank data saving issue
          this.setState({ jobFunctionErrMsg2: "block" }); //Sandeep-task-3664-Blank data saving issue
          break;
        }
      }
    } //Sandeep-task-3659-It acceptes the Numeric values too
    for (let i = 0; i < this.state.campaignLeadDetails.length; i++) {
      //Sandeep-task-3659-It acceptes the Numeric values too
      if (this.state.campaignLeadDetails[i].jobLevel.length > 0) {
        let pattern = new RegExp(/^[A-Za-z\n,| \-.&:()]+$/); //Aman-2835-(change var to let to resolve duplicate var issue)-Console Warnings - Agency - Advertiser Menu
        if (!pattern.test(this.state.campaignLeadDetails[i].jobLevel)) {
          errorMsgFlag = false;
          this.setState({ jobLevelErrMsg2: "block" });
          break;
        } else {
          this.setState({ jobLevelErrMsg2: "none" });
        }
      } else {
        //Sandeep-task-3664-Blank data saving issue
        if (this.state.jobLevelFlag === false) {
          //Sandeep-task-3664-Blank data saving issue
          this.setState({ jobLevelErrMsg2: "none" }); //Sandeep-task-3664-Blank data saving issue
          break;
        } else {
          errorMsgFlag = false; //Sandeep-task-3664-Blank data saving issue
          this.setState({ jobLevelErrMsg2: "block" }); //Sandeep-task-3664-Blank data saving issue
          break;
        }
      }
    }
    if (errorMsgFlag === true) {
      //Sandeep-task-3659-It acceptes the Numeric values too-btndisable issue
      for (let j = 0; j < this.state.campaignLeadDetails.length; j++) {
        //Aman-2835-(Not adding setState() after adding this getting error again")-Console Warnings - Agency - Advertiser Menu
        this.state.campaignLeadDetails[j].btnDisable = false; //Sandeep-task-3659-It acceptes the Numeric values too-btndisable issue
        this.state.campaignLeadDetails[j].editFlag = false; //Sandeep-task-3659-It acceptes the Numeric values too-btndisable issue
      }
    }
    // let campaignLeadDetails = [...this.state.campaignLeadDetails];
    this.setState({ saveEditedData, btnFlag: false, errorMsgFlag }); //Sandeep-task-3652-Edited time save option issue--AQA lead review section
  }

  /**
   * @author Sandeep Dhawale
   * @param  Description added validation for job title,job Level,job Function data
   * @return Description return validate data for job title,job Level,job Function
   */
  saveFormFirstTab() {
    let saveEditedData = false;
    let errorMsgFlag = true;
    for (let i = 0; i < this.state.internalLeadDetails.length; i++) {
      if (this.state.internalLeadDetails[i].editFlag) {
        saveEditedData = true;
      }
      if (this.state.internalLeadDetails[i].jobTitle.length > 0) {
        //Sandeep-task-3659-It acceptes the Numeric values too
        var pattern = new RegExp(/^[A-Za-z\n,| \-.&:()]+$/);
        if (!pattern.test(this.state.internalLeadDetails[i].jobTitle)) {
          errorMsgFlag = false;
          this.setState({ jobTitleErrMsg: "block" });
          break;
        } else {
          this.setState({ jobTitleErrMsg: "none" });
        }
      } else {
        //Sandeep-task-3664-Blank data saving issue
        errorMsgFlag = false;
        this.setState({ jobTitleErrMsg: "block" });
        break;
      }
    } //Sandeep-task-3659-It acceptes the Numeric values too
    for (let i = 0; i < this.state.internalLeadDetails.length; i++) {
      //Sandeep-task-3659-It acceptes the Numeric values too

      if (this.state.internalLeadDetails[i].jobFunction.length > 0) {
        let pattern = new RegExp(/^[A-Za-z\n,| \-.&:()]+$/); //Aman-2835-(change var to let to resolve duplicate var issue)-Console Warnings - Agency - Advertiser Menu
        if (!pattern.test(this.state.internalLeadDetails[i].jobFunction)) {
          errorMsgFlag = false;
          this.setState({ jobFunctionErrMsg: "block" });
          break;
        } else {
          this.setState({ jobFunctionErrMsg: "none" });
        }
      } else {
        //Sandeep-task-3664-Blank data saving issue
        if (this.state.jobFunctionFlag === false) {
          //Sandeep-task-3664-Blank data saving issue
          this.setState({ jobFunctionErrMsg: "none" }); //Sandeep-task-3664-Blank data saving issue
          break;
        } else {
          errorMsgFlag = false; //Sandeep-task-3664-Blank data saving issue
          this.setState({ jobFunctionErrMsg: "block" }); //Sandeep-task-3664-Blank data saving issue
          break;
        }
      }
    } //Sandeep-task-3659-It acceptes the Numeric values too
    for (let i = 0; i < this.state.internalLeadDetails.length; i++) {
      //Sandeep-task-3659-It acceptes the Numeric values too
      if (this.state.internalLeadDetails[i].jobLevel.length > 0) {
        let pattern = new RegExp(/^[A-Za-z\n,| \-.&:()]+$/); //Aman-2835-(change var to let to resolve duplicate var issue)-Console Warnings - Agency - Advertiser Menu
        if (!pattern.test(this.state.internalLeadDetails[i].jobLevel)) {
          errorMsgFlag = false;
          this.setState({ jobLevelErrMsg: "block" });
          break;
        } else {
          this.setState({ jobLevelErrMsg: "none" });
        }
      } else {
        //Sandeep-task-3664-Blank data saving issue
        if (this.state.jobLevelFlag === false) {
          //Sandeep-task-3664-Blank data saving issue
          this.setState({ jobLevelErrMsg: "none" }); //Sandeep-task-3664-Blank data saving issue
          break;
        } else {
          errorMsgFlag = false; //Sandeep-task-3664-Blank data saving issue
          this.setState({ jobLevelErrMsg: "block" }); //Sandeep-task-3664-Blank data saving issue
          break;
        }
      }
    }
    if (errorMsgFlag === true) {
      for (let j = 0; j < this.state.internalLeadDetails.length; j++) {
        //Sandeep-task-3659-It acceptes the Numeric values too-btndisable issue
        //Aman-2835-(Not adding setState() after adding this getting error again")-Console Warnings - Agency - Advertiser Menu
        this.state.internalLeadDetails[j].btnDisable = false; //Sandeep-task-3659-It acceptes the Numeric values too-btndisable issue
        this.state.internalLeadDetails[j].editFlag = false; //Sandeep-task-3659-It acceptes the Numeric values too-btndisable issue
      }
    }
    this.setState({ saveEditedData, btnFlag: false, errorMsgFlag }); //Sandeep-task-3652-Edited time save option issue--AQA lead review section
  }

  /**
   * @author Narendra Phadke
   * @param  Description handle the upload lead from submit button on portal
   * @return Description return All details of lead
   */
  submitreviewlead(e) {
    e.preventDefault();
    var campID = this.state.campID;
    //Aman-2835-(change var to let to resolve duplicate var issue)-Console Warnings - Agency - Advertiser Menu
    // const { user } = this.props.auth;
    // const { id: agencyID, role: userRole } = user;
    // let errors = {};

    let data = {
      campID: campID,
      campaignLeadDetails: this.state.campaignLeadDetails,
      //user: user,		Priyanka --3720--removed params
      diFlag: this.state.diFlag,
    };

    this.setState({
      csvFileData: this.state.campaignLeadDetails
    })
    // this.loadingRef.current.click(); //Sandeep-task-3441-added loader
    fetch("/leadDownloadDetails/uploadReviewLeadSubmit", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((campaignLeadDetails) => {
        // alert("campaignLeadDetails-- " + JSON.stringify(campaignLeadDetails))
        //Nilesh-4862- comment generateCSV function
        // this.generateCSV(data) //rutuja - 4479  issue while creating csv file
        let editedFlag = false; //Sandeep-task-3659-added key to display updated data
        for (let i = 0; i < campaignLeadDetails.length; i++) {
          if (campaignLeadDetails[i].alertKey)
            //Sandeep-task-3659-added key to display updated data
            editedFlag = true; //Sandeep-task-3659-added key to display updated data
          break;
        }
        this.setState({
          campaignLeadDetails: campaignLeadDetails,
          reviewLeadFlag: true,
          editedFlag,
        });
        // this.closeLoadingRef.current.click(); //Sandeep-task-3441-added loader
        //Aman-2835-(removed Not in use)-Console Warnings - Agency - Advertiser Menu
        if (campaignLeadDetails.length > 0 && editedFlag === true) {
          //Sandeep-task-3659-added key to display updated data
          Swal.fire({
            text: "Table data updated successfully",
            type: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,

            preConfirm: () => {
              this.setState({ key: 2 });
            },
          });

          //sunita-task-3270-added beow api for removing particular campaign id from left side after submit
          //let data4 = { agencyID, userRole };	Priyanka --3720--removed params
          fetch("/publisherReviewLead/agencyReviewInternalLead", {
            method: "POST",
            headers: { "content-Type": "application/json" },
            //body: JSON.stringify(data4),
          })
            .then((res) => res.json())
            .then((agencyReviewInternalLead) => {
              this.setState({ agencyReviewInternalLead });
            })
            .catch(function (err) {
              console.log(err);
            });

          this.setState({
            // errors:errors,
            btnDisable: "disabled1",
          });
        } else if (campaignLeadDetails.length > 0 && editedFlag === false) { //Aman-2835-(removed Not in use)-Console Warnings - Agency - Advertiser Menu
          //Sandeep-task-3659-added key to display updated data

          Swal.fire({
            text: "Lead reviewed successfully",
            type: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            preConfirm: () => {
              this.setState({ key: 3 }); //mufiz-bug-4033-Prod Issue - Agency Side Lead Review - On submit the leads of selected campaign, it should move to new tab automatically
              this.fun_Third_Tab_Info(campID)
            },
          });

          //sunita-task-3270-added beow api for removing particular campaign id from left side after submit
          //let data4 = { agencyID, userRole };		Priyanka --3720--removed params
          fetch("/publisherReviewLead/agencyReviewInternalLead", {
            method: "POST",
            headers: { "content-Type": "application/json" },
            //body: JSON.stringify(data4),
          })
            // this.generateCSV(data) //rutuja - 4397  added new file for genrating csv
            .then((res) => res.json())
            .then((agencyReviewInternalLead) => {
              this.setState({ agencyReviewInternalLead });


            })
            .catch(function (err) {
              console.log(err);
            });

          this.setState({
            // errors:errors,
            btnDisable: "disabled1",
          });
        } else {
          this.setState({
            btnDisable: "btn add-button",
          });
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  /**
     * @author Rutuja Jagtap
     * @param  Description 4397 handle the generating csv from submit button on portal
     * @return Description return All details of lead
     */

  //Nilesh-4862- comment generateCSV function
  // generateCSV(data) {
  //   //setTimeout(function(){  
  //   fetch("/generateCSV/csvGenearation", {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify(data),
  //   })
  //     .then((res) => res.json())
  //     .then();
  //   //}, 10000); //rutuja - 4445 commenting this and added set timeout at back end router 
  // }


  //Nilesh-4785- This fuction for validation of wrong leads count upload in file
  async fetchDownloadFilesLengthFirstTab() {
    const campID = this.state.campID;
  
    try {
      //Nilesh-4785-This route fetching total leads count
      const response = await fetch("/leadDownloadDetails/downloadQALeadDetails?campID=" + campID);
      const downloadFiles1 = await response.json();
  
      var downloadFilesLength = downloadFiles1.length;
      this.setState({ downloadFilesLength: downloadFilesLength });
   
    } catch (error) {
      console.error("Error fetching download leads length:", error);
    }
  }

  //Nilesh-4785- This fuction for validation of wrong leads count upload in file
  async fetchDownloadFilesLengthSecondTab() {
    const campID = this.state.campID;
  
    try {
     //Nilesh-4785-This route fetching total leads count
      const response = await fetch("lead/downloadLeadDetails?campID=" + campID);
      const downloadFiles1 = await response.json();
  
      var downloadFilesLength = downloadFiles1.length;
      this.setState({ downloadFilesLength: downloadFilesLength });
  
    } catch (error) {
      console.error("Error fetching download leads length:", error);
    }
  }
  
  // Nilesh-4785- This fuction for validation of wrong leads count upload in file
  async fetchDownloadFilesLengthThirdTab() {
    const campID = this.state.campID;
  
    try {
      //Nilesh-4785-This route fetching total leads count
      const response = await fetch("lead/clientDownloadLeadDetailsLength?campID=" + campID );
      const downloadFiles1 = await response.json();
      var downloadFilesLength = downloadFiles1.length;
      this.setState({ downloadFilesLength: downloadFilesLength });
  
    } catch (error) {
      console.error("Error fetching download leads length:", error);
    }
  }



  render() {
    //sunita-task-3270- below all columns1,columns2,columns3,columns4 are used for antd table
    let columns1 = [
      {
        title: "Lead ID",
        dataIndex: "leadInfoID",
        key: "leadInfoID",
        width: 100,
        align: "left", //Sandeep-task-3441
        fixed: "left", //Sandeep-task-3516-added code for fixed column from left side
        render: (text, record) => (
          <div>
            <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
              {record.leadInfoID}
            </span>
          </div>
        ),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: 200,
        align: "left", //Sandeep-task-3441
        fixed: "left", //Sandeep-task-3516-added code for fixed column from left side
        className: "emailcolor", // Sandeep-task-3651-Alignment and color variation issue--in AQA lead review section
        render: (text, record) => (
          <div>
            {/* <span style={{ fontSize: '11px',wordBreak: "break-all" }}> */}
            <span
              style={{
                fontSize: "13px",
                color: "#4F4F4F",
                wordBreak: "break-all",
              }}
            >
              {record.email}
            </span>
          </div>
        ),
      },

      {
        title: "Job Title", //Sandeep-task-3441-
        dataIndex: "jobTitle",
        key: "jobTitle",
        //  width: 220,
        align: "left", //Sandeep-task-3441
        editable: true,
        render: (text, record, i) =>
          //  this.renderColumns(text, record, 'jobTitle')

          this.props.auth.user.role === "AQA" && record.key ? (
            <div>
              <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                <input
                  type="textbox"
                  id="jobTitleQA"
                  name="jobTitle"
                  value={record.jobTitle}
                  jobTitle={record.jobTitle}
                  onChange={this.handleEditField.bind(this, i)}
                  class="form-control"
                />
                {/* onChange={this.handleEditReport.bind(this,i)} */}
              </span>
            </div>
          ) : (
            <div>
              <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                <input
                  type="textbox"
                  id="jobTitleQA"
                  name="jobTitle"
                  disabled
                  defaultValue={record.jobTitle}
                  jobTitle={record.jobTitle}
                  onChange={this.handleEditField.bind(this, i)}
                  class="form-control"
                />
              </span>
            </div>
          ),
      },

      {
        title: "Job Function", //Sandeep-task-3516-added job function column
        dataIndex: "jobFunction",
        key: "jobFunction",
        // width: 220,
        align: "left", //Sandeep-task-3441
        editable: true,
        // className:this.state.jobFunctionFlag?"show":"hide",
        render: (text, record, i) =>
          this.props.auth.user.role === "AQA" && record.key ? (
            <div>
              <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                <input
                  type="textbox"
                  id="jobFunctionQA"
                  name="jobFunction"
                  value={record.jobFunction}
                  jobTitle={record.jobFunction}
                  onChange={this.handleEditField.bind(this, i)}
                  class="form-control"
                />
                {/* onChange={this.handleEditReport.bind(this,i)} */}
              </span>
            </div>
          ) : (
            <div>
              <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                <input
                  type="textbox"
                  id="jobFunctionQA"
                  name="jobFunction"
                  disabled
                  value={record.jobFunction}
                  jobTitle={record.jobFunction}
                  onChange={this.handleEditField.bind(this, i)}
                  class="form-control"
                />
              </span>
            </div>
          ),
      },

      {
        title: "Job Level", //Sandeep-task-3516-added job Level column
        dataIndex: "jobLevel",
        key: "jobLevel",
        // width: 220,
        align: "left", //Sandeep-task-3441
        editable: true,
        // className:this.state.jobLevelFlag?"show":"hide",
        render: (text, record, i) =>
          this.props.auth.user.role === "AQA" && record.key ? (
            <div>
              <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                <input
                  type="textbox"
                  id="jobLevelQA"
                  name="jobLevel"
                  value={record.jobLevel}
                  jobTitle={record.jobLevel}
                  onChange={this.handleEditField.bind(this, i)}
                  class="form-control"
                />
                {/* onChange={this.handleEditReport.bind(this,i)} */}
              </span>
            </div>
          ) : (
            <div>
              <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                <input
                  type="textbox"
                  id="jobLevelQA"
                  name="jobLevel"
                  disabled
                  value={record.jobLevel}
                  jobTitle={record.jobLevel}
                  onChange={this.handleEditField.bind(this, i)}
                  class="form-control"
                />
              </span>
            </div>
          ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        //  width: 220,
        align: "left", //Sandeep-task-3441
        render: (text, record, i) => {
          const { reviewLeadFlag, statusFlag } = this.state;
          const userRole = this.props.auth.user.role;
          const pageSize = this.state.pageSize; //Nilesh-4562- Number of rows per page
          const currentPage = this.state.tableState.current || 1; //Nilesh-4562- Current page (default to 1)
          const absoluteIndex = (currentPage - 1) * pageSize + i; //Nilesh-4562- Calculate the absolute index
        
          //Nilesh- 4562- Create function to render the dropdown based on conditions
          const renderDropdown = (options) => (
            <select
              style={{ height: "60%", fontSize: "14px", color: "#555555" }}
              id="status"
              name="status"
              value={record.status}
              onChange={(e) => this.handleChangeInternalLead(absoluteIndex, e)} //Nilesh-4562- Pass the absolute index
              className="form-control"
            >
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
        
            </select>
          );
        
      
          //Nilesh-4562- Determine content based on conditions
          if (reviewLeadFlag) {
            return (
              <span style={{ fontSize: "14px", color: "#555555 " }}>
                <label>{record.status}</label>
              </span>
            );
          } else if (userRole === "AQA") {
            return renderDropdown([
              { value: "AgencyInternalReview", label: "AgencyInternalReview" },
              { value: "AgencyQARejected", label: "AgencyQARejected" },
            ]);
          } else if (statusFlag === "AgencyInternalReview") {
            return renderDropdown([
              { value: "AgencyInternalReview", label: "AgencyInternalReview" },
              { value: "Rejected", label: "Rejected" },
            ]);
          } else {
            return renderDropdown([
              { value: "DI QA Accepted", label: "DI QA Accepted" },
              { value: "Rejected", label: "Rejected" },
            ]);
          }
        },
      }
      ,
      {
        title: "Reason For Rejection", //Sandeep-task-3441
        dataIndex: "reason",
        key: "reason",
        // width: 220,
        align: "left", //Sandeep-task-3441
        render: (text, record, i) => {
          const pageSize = this.state.pageSize; 
          const currentPage = this.state.tableState.current || 1; 
          const absoluteIndex = (currentPage - 1) * pageSize + i;
      
          return (
            <div>
              <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
              {this.state.reviewLeadFlag === true ? (
                <input
                  type="textbox" 
                  id={`reason-${absoluteIndex}`} //Nilesh-4562- Unique ID for the input
                  name="reason"
                  disabled={!(record.status === "Rejected" || record.status === "AgencyQARejected")} //Nilesh-4562- Enable for "Rejected" or "AgencyQARejected"
                  value={record.reason || ""} //Nilesh-4562- Bind to the dataset value
                  onChange={(e) => this.handleChange(absoluteIndex, e)} //Nilesh-4562- Pass absoluteIndex to the handler
                  className="form-control"
                />
              ) : (
                <input
                  type="textbox" 
                  id={`reason-${absoluteIndex}`} 
                  name="reason"
                  disabled={!(record.status === "Rejected" || record.status === "AgencyQARejected")}
                  value={record.reason || ""}
                  onChange={(e) => this.handleChange(absoluteIndex, e)}
                  className="form-control"
              />
              )}
              </span>
            </div>
          );
        },
      },

      {
        title: "Action", //Sandeep-task-3516-added action column
        dataIndex: "Action",
        key: "Action",
        width: 70,
        align: "center",
        fixed: "right", //Sandeep-task-3516-added code for fixed column from right side
        render: (text, record, i) => (
          <div
          //Nilesh-5493- Fixed Alignment for edit action
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}>
            {/* sandeep-task- It acceptes the Numeric values too -btnDisable issue     */}
            {this.props.auth.user.role === "AQA" ? (
              record.btnDisable === true && !record.editFlag ? ( //Aman-2835-(removed Not in use)-Console Warnings - Agency - Advertiser Menu
                <span style={{ float: "left" }}>
                  <Tooltip placement="top" title="Edit">
                    <img
                      style={{ cursor: "not-allowed" }}
                      src="material-edit.png"
                      alt=""
                      jobTitle={record.jobTitle}
                      jobLevel={record.jobLevel}
                      jobFunction={record.jobFunction}
                      //  onClick={this.handleEditFirstTab.bind(this,i)}//Sandeep-task-3659-button disable issue
                      width="20"
                      height="20"
                    />
                  </Tooltip>
                </span>
              ) : (
                <span style={{ float: "left" }}>
                  {record.editFlag ? (
                    <span style={{ float: "left" }}>
                      <button
                        type="button"
                        className="btn"
                        id={record.leadInfoID}
                        onClick={this.saveFormFirstTab}
                        //Nilesh-5493- Fixed Alignment for edit action
                        style={{
                          width: "60px", // Fixed width for consistency
                          padding: "5px 10px", // Consistent padding
                          fontSize: "12px", // Small font size
                          backgroundColor: "#144999",
                          color: "#fff",
                          cursor: "pointer"
                        }}
                      >
                        Save
                      </button>
                    </span>
                  ) : (
                    <Tooltip placement="top" title="Edit">
                      <img
                        //Nilesh-5505 - Change the image colour
                        style={{ cursor: "pointer",
                        filter: "brightness(0) saturate(100%) invert(33%) sepia(82%) saturate(2896%) hue-rotate(184deg) brightness(98%) contrast(92%)"}}
                        src="material-edit.png"
                        alt=""
                        id={record.leadInfoID}
                        jobTitle={record.jobTitle}
                        jobLevel={record.jobLevel}
                        jobFunction={record.jobFunction}
                        onClick={this.handleEditFirstTab.bind(this, i)}
                        width="20"
                        height="20"
                      />
                    </Tooltip>
                  )}
                </span>
              )
            ) : (
              <span style={{ float: "left" }}>
                <Tooltip placement="top" title="Edit">
                  <img
                    style={{ cursor: "not-allowed" }}
                    src="material-edit.png"
                    alt=""
                    jobTitle={record.jobTitle}
                    jobLevel={record.jobLevel}
                    jobFunction={record.jobFunction}
                    // onClick={this.handleEditFirstTab.bind(this,i)////Sandeep-task-3659-button disable issue
                    width="20"
                    height="20"
                  />
                </Tooltip>
              </span>
            )}
          </div>
        ),
      },
    ];
    columns1 = columns1.filter((a) => {
      if (!this.state.jobLevelFlag) {
        return a.dataIndex !== "jobLevel"; //Aman-2835-(added '!==' and instead saw '!=')-Console Warnings - Agency - Advertiser Menu
      } else {
        return a;
      }
    }); //Sandeep-task-3516-added code to hide and show column for job Level

    columns1 = columns1.filter((a) => {
      if (!this.state.jobFunctionFlag) {
        return a.dataIndex !== "jobFunction"; //Aman-2835-(added '!==' and instead saw '!=')-Console Warnings - Agency - Advertiser Menu
      } else {
        return a;
      }
    }); //Sandeep-task-3516-added code to hide and show column for job Function

    // const columns1
    let columns2 = [
      {
        title: "Lead ID",
        dataIndex: "leadInfoID",
        key: "leadInfoID",
        width: 90,
        align: "left", //Sandeep-task-3441
        fixed: "left", //Sandeep-task-3516-added code for fixed column from left side
        render: (text, record) => (
          <div>
            <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
              {record.leadInfoID}
            </span>
          </div>
        ),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: 200,
        align: "left", //Sandeep-tsk-3441
        fixed: "left", //Sandeep-task-3516-added code for fixed column from left side
        className: "emailcolor", // Sandeep-task-3651-Alignment and color variation issue--in AQA lead review section
        render: (text, record) => (
          <div>
            <span
              style={{
                fontSize: "13px",
                color: "#4F4F4F",
                wordBreak: "break-all",
              }}
            >
              {record.email}
            </span>
          </div>
        ),
      },

      {
        title: "Voice Log Link",
        dataIndex: "voiceLogLink",
        key: "voiceLogLink",
        // width: 90,//Sandeep-task-3656-commented this code bcz of alignment issue
        align: "left", //Sandeep-task-3441

        render: (text, record) => (
          <div>
            <span style={{ fontSize: "13px", color: "#1890FF" }}>
              {/* {record.callAudit}
              {record.channel} */}
              {this.state.marketingChannelFlag === true ? (
                //sunita-task-3324-added condition as per channel for showing voicelog link column
                record.channel === "Telemarketing" ||
                  record.Channel === "Telemarketing" ||
                  ((record.channel === "Email/Telemarketing" ||
                    record.Channel === "Email/Telemarketing" ||
                    record.channel === "Email" ||
                    record.Channel === "Email") &&
                    record.callAudit === "Yes") ? (
                  <a
                    href={"#/"} //Aman-2835-(href attribute is required added "#/")-Console Warnings - Agency - Advertiser Menu
                    onClick={this.openLinkInBrowser}
                    id={record.leadInfoID}
                  >
                    Link
                  </a>
                ) : (
                  <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                    No Link Available
                  </span>
                )
              ) : (
                ""
              )}
            </span>
          </div>
        ),
      },
      {
        title: "Job Title", //Sandeep-task-3441-
        dataIndex: "jobTitle",
        key: "jobTitle",
        //  width: 220,
        align: "left", //Sandeep-task-3441
        editable: true,
        render: (text, record, i) =>
          this.props.auth.user.role === "AQA" && record.key ? (
            <div>
              <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                <input
                  type="textbox"
                  id="jobTitleQA"
                  name="jobTitle"
                  value={record.jobTitle}
                  jobTitle={record.jobTitle}
                  onChange={this.handleEditField.bind(this, i)}
                  class="form-control"
                />
              </span>
            </div>
          ) : (
            <div>
              <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                <input
                  type="textbox"
                  id="jobTitleQA"
                  name="jobTitle"
                  disabled
                  defaultValue={record.jobTitle}
                  jobTitle={record.jobTitle}
                  onChange={this.handleEditField.bind(this, i)}
                  class="form-control"
                />
              </span>
            </div>
          ),
      },

      {
        title: "Job Function", //Sandeep-task-3516-added job Function
        dataIndex: "jobFunction",
        key: "jobFunction",
        // width: 220,
        align: "left",
        editable: true,
        // className:this.state.jobFunctionFlag?"show":"hide",
        render: (text, record, i) =>
          this.props.auth.user.role === "AQA" && record.key ? (
            <div>
              <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                <input
                  type="textbox"
                  id="jobFunctionQA"
                  name="jobFunction"
                  value={record.jobFunction}
                  jobTitle={record.jobFunction}
                  onChange={this.handleEditField.bind(this, i)}
                  class="form-control"
                />
                {/* onChange={this.handleEditReport.bind(this,i)} */}
              </span>
            </div>
          ) : (
            <div>
              <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                <input
                  type="textbox"
                  id="jobFunctionQA"
                  name="jobFunction"
                  disabled
                  value={record.jobFunction}
                  jobTitle={record.jobFunction}
                  onChange={this.handleEditField.bind(this, i)}
                  class="form-control"
                />
              </span>
            </div>
          ),
      },

      {
        title: "Job Level", //Sandeep-task-3516--added job Level column
        dataIndex: "jobLevel",
        key: "jobLevel",
        // width: 220,
        align: "left", //Sandeep-task-3441
        editable: true,
        // className:this.state.jobLevelFlag?"show":"hide",
        render: (text, record, i) =>
          this.props.auth.user.role === "AQA" && record.key ? (
            <div>
              <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                <input
                  type="textbox"
                  id="jobLevelQA"
                  name="jobLevel"
                  value={record.jobLevel}
                  jobTitle={record.jobLevel}
                  onChange={this.handleEditField.bind(this, i)}
                  class="form-control"
                />
                {/* onChange={this.handleEditReport.bind(this,i)} */}
              </span>
            </div>
          ) : (
            <div>
              <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                <input
                  type="textbox"
                  id="jobLevelQA"
                  name="jobLevel"
                  disabled
                  value={record.jobLevel}
                  jobTitle={record.jobLevel}
                  onChange={this.handleEditField.bind(this, i)}
                  class="form-control"
                />
              </span>
            </div>
          ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        // width: 90,//Sandeep-task-3656-commented this code bcz of alignment issue
        align: "left", //Sandeep-task-3441
        render: (text, record, i) => {
          const { reviewLeadFlag, statusFlag } = this.state;
          const userRole = this.props.auth.user.role;
          const pageSize = this.state.pageSize; //Nilesh-4562- Number of rows per page
          const currentPage = this.state.tableState.current || 1; //Nilesh-4562- Current page (default to 1)
          const absoluteIndex = (currentPage - 1) * pageSize + i; //Nilesh-4562- Calculate the absolute index
        
          //Nilesh- 4562- Create function to render the dropdown based on conditions
          const renderDropdown = (options) => (
            <select
              style={{ height: "60%", fontSize: "14px", color: "#555555" }}
              id="status"
              name="status"
              value={record.status}
              onChange={(e) => this.handleChangeInternalLead(absoluteIndex, e)} //Nilesh-4562- Pass the absolute index
              className="form-control"
            >
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
        
            </select>
          );
        
      
          //Nilesh-4562- Determine content based on conditions
          if (reviewLeadFlag) {
            return (
              <span style={{ fontSize: "14px", color: "#555555 " }}>
                <label>{record.status}</label>
              </span>
            );
          } else if (userRole === "AQA") {
            return renderDropdown([
              { value: "AgencyInternalReview", label: "AgencyInternalReview" },
              { value: "AgencyQARejected", label: "AgencyQARejected" },
            ]);
          } else if (statusFlag === "AgencyInternalReview") {
            return renderDropdown([
              { value: "AgencyInternalReview", label: "AgencyInternalReview" },
              { value: "Rejected", label: "Rejected" },
            ]);
          } else {
            return renderDropdown([
              { value: "DI QA Accepted", label: "DI QA Accepted" },
              { value: "Rejected", label: "Rejected" },
            ]);
          }
        },
      },

      {
        title: "Reason For Rejection", //Sandeep-task-3441
        dataIndex: "reason",
        key: "reason",
        // width: 90,//Sandeep-task-3656-commented this code bcz of alignment issue
        align: "left", //Sandeep-task-3341
        render: (text, record, i) => {
          const pageSize = this.state.pageSize; 
          const currentPage = this.state.tableState.current || 1; 
          const absoluteIndex = (currentPage - 1) * pageSize + i;       
          return (
            <div>
              <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
              {this.state.reviewLeadFlag === true ? (
                <input
                  type="textbox"
                  id={`reason-${absoluteIndex}`} //Nilesh-4562- Unique ID for the input
                  name="reason"
                  disabled={!(record.status === "Rejected" || record.status === "AgencyQARejected")} //Nilesh-4562- Enable for "Rejected" or "AgencyQARejected"
                  value={record.reason || ""} //Nilesh-4562- Bind to the dataset value
                  onChange={(e) => this.handleChange(absoluteIndex, e)} //Nilesh-4562- Pass absoluteIndex to the handler
                  className="form-control"
                />
              ) : (
                <input
                  type="textbox"
                  id={`reason-${absoluteIndex}`}
                  name="reason"
                  
                  disabled={!(record.status === "Rejected" || record.status === "AgencyQARejected")} // Enable for "Rejected" or "AgencyQARejected"
                  value={record.reason || ""}
                  onChange={(e) => this.handleChange(absoluteIndex, e)}
                  className="form-control"
              />
              )}
              </span>
            </div>
          );
        },
      },

      {
        title: "Action", //Sandeep-task-3516--added action column
        dataIndex: "Action",
        key: "Action",
        width: 70,
        align: "center",
        fixed: "right", //Sandeep-task-3516-added code for fixed column from right side
        render: (text, record, i) => (
          <div
          //Nilesh-5493- Fixed Alignment for edit action
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%", // Ensure it takes the full height of the row
          }}>
            {/* sandeep-task- It acceptes the Numeric values too -btnDisable issue     */}
            {this.props.auth.user.role === "AQA" ? (
              record.btnDisable === true && !record.editFlag ? ( //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
                <span style={{ float: "left" }}>
                  <Tooltip placement="top" title="Edit">
                    <img
                      style={{ cursor: "not-allowed" }}
                      src="material-edit.png"
                      alt=""
                      jobTitle={record.jobTitle}
                      jobLevel={record.jobLevel}
                      jobFunction={record.jobFunction}
                      //  onClick={this.handleEditFirstTab.bind(this,i)//Sandeep-task-3659-button disable issue
                      width="20"
                      height="20"
                    />
                  </Tooltip>
                </span>
              ) : (
                <span style={{ float: "left" }}>
                  {record.editFlag ? (
                    <span style={{ float: "left" }}>
                      <button
                        type="button"
                        className="btn"
                        id={record.leadInfoID}
                        onClick={this.saveFormFirstTab}
                        //Nilesh-5493- Fixed Alignment for edit action
                        style={{
                          width: "60px", // Fixed width for consistency
                          padding: "5px 10px", // Consistent padding
                          fontSize: "12px", // Small font size
                          backgroundColor: "#144999",
                          color: "#fff",
                          cursor: "pointer"
                        }}
                      >
                        Save
                      </button>
                    </span>
                  ) : (
                    <Tooltip placement="top" title="Edit">
                      <img
                        //Nilesh-5505 - Change the image colour
                        style={{ cursor: "pointer",
                        filter: "brightness(0) saturate(100%) invert(33%) sepia(82%) saturate(2896%) hue-rotate(184deg) brightness(98%) contrast(92%)"}}
                        src="material-edit.png"
                        alt=""
                        id={record.leadInfoID}
                        jobTitle={record.jobTitle}
                        jobLevel={record.jobLevel}
                        jobFunction={record.jobFunction}
                        onClick={this.handleEditFirstTab.bind(this, i)}
                        width="20"
                        height="20"
                      />
                    </Tooltip>
                  )}
                </span>
              )
            ) : (
              <span style={{ float: "left" }}>
                <Tooltip placement="top" title="Edit">
                  <img
                    style={{ cursor: "not-allowed" }}
                    src="material-edit.png"
                    alt=""
                    jobTitle={record.jobTitle}
                    jobLevel={record.jobLevel}
                    jobFunction={record.jobFunction}
                    // onClick={this.handleEditFirstTab.bind(this,i)}//Sandeep-task-3659-button disable issue
                    width="20"
                    height="20"
                  />
                </Tooltip>
              </span>
            )}
          </div>
        ),
      },
    ];

    columns2 = columns2.filter((a) => {
      if (!this.state.jobLevelFlag) {
        return a.dataIndex !== "jobLevel"; //Aman-2835-(added '!==' and instead saw '!=')-Console Warnings - Agency - Advertiser Menu
      } else {
        return a;
      }
    }); //Sandeep-task-3516-added code to hide and show column for job Level

    columns2 = columns2.filter((a) => {
      if (!this.state.jobFunctionFlag) {
        return a.dataIndex !== "jobFunction"; //Aman-2835-(added '!==' and instead saw '!=')-Console Warnings - Agency - Advertiser Menu
      } else {
        return a;
      }
    }); //Sandeep-task-3516-added code to hide and show column for job Function

    function onChange(pagination, filters, sorter) { }

    // const columns2
    let columns3 = [
      {
        title: "Lead ID",
        dataIndex: "leadInfoID",
        key: "leadInfoID",
        width: 90,
        align: "left", //Sandeep-task-3441
        fixed: "left", //Sandeep-task-3516-added code for fixed column from left side
        render: (text, record) => (
          <div>
            <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
              {record.leadInfoID}
            </span>
          </div>
        ),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: 200,
        align: "left", //Sandeep-task-3441
        fixed: "left", //Sandeep-task-3516-added code for fixed column from left side
        className: "emailcolor", // Sandeep-task-3651-Alignment and color variation issue--in AQA lead review section
        render: (text, record) => (
          <div>
            <span
              style={{
                fontSize: "13px",
                color: "#4F4F4F",
                wordBreak: "break-all",
              }}
            >
              {record.email}
            </span>
          </div>
        ),
      },

      {
        title: "Job Title", //Sandeep-task-3441
        dataIndex: "jobTitle",
        key: "jobTitle",
        // width: 90,
        align: "left", //Sandeep-task-3441
        render: (text, record, i) =>
          this.props.auth.user.role === "AQA" && record.key ? (
            <div>
              <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                <input
                  type="textbox"
                  id="jobTitleQA"
                  name="jobTitle"
                  value={record.jobTitle}
                  jobTitle={record.jobTitle}
                  onChange={this.handleEditForQA.bind(this, i)}
                  class="form-control"
                />
                {/* {record.jobTitle} */}
              </span>
            </div>
          ) : (
            <div>
              <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                <input
                  type="textbox"
                  id="jobTitleQA"
                  name="jobTitle"
                  disabled
                  value={record.jobTitle}
                  jobTitle={record.jobTitle}
                  onChange={this.handleEditForQA.bind(this, i)}
                  class="form-control"
                />
                {/* {record.jobTitle} */}
              </span>
            </div>
          ),
      },

      {
        title: "Job Function", //Sandeep-task-3516-added job function column
        dataIndex: "jobFunction",
        key: "jobFunction",
        // width: 90,
        align: "left",
        render: (text, record, i) =>
          this.props.auth.user.role === "AQA" && record.key ? (
            <div>
              <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                <input
                  type="textbox"
                  id="jobFunctionQA"
                  name="jobFunction"
                  value={record.jobFunction}
                  jobTitle={record.jobFunction}
                  onChange={this.handleEditForQA.bind(this, i)}
                  class="form-control"
                />
                {/* {record.jobTitle} */}
              </span>
            </div>
          ) : (
            <div>
              <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                <input
                  type="textbox"
                  id="jobFunctionQA"
                  name="jobFunction"
                  disabled
                  value={record.jobFunction}
                  jobTitle={record.jobFunction}
                  onChange={this.handleEditForQA.bind(this, i)}
                  class="form-control"
                />
                {/* {record.jobTitle} */}
              </span>
            </div>
          ),
      },

      {
        title: "Job Level", //Sandeep-task-3516-added job level column
        dataIndex: "jobLevel",
        key: "jobLevel",
        // width: 90,
        align: "left",
        render: (text, record, i) =>
          this.props.auth.user.role === "AQA" && record.key ? (
            <div>
              <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                <input
                  type="textbox"
                  id="jobLevelQA"
                  name="jobLevel"
                  value={record.jobLevel}
                  jobTitle={record.jobLevel}
                  onChange={this.handleEditForQA.bind(this, i)}
                  class="form-control"
                />
                {/* {record.jobTitle} */}
              </span>
            </div>
          ) : (
            <div>
              <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                <input
                  type="textbox"
                  id="jobLevelQA"
                  name="jobLevel"
                  disabled
                  value={record.jobLevel}
                  jobTitle={record.jobLevel}
                  onChange={this.handleEditForQA.bind(this, i)}
                  class="form-control"
                />
                {/* {record.jobTitle} */}
              </span>
            </div>
          ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        // width: 90,
        align: "left", //Sandeep-task-3441
        render: (text, record, i) => {
          const pageSize = this.state.pageSize; //Nilesh-4562- Number of rows per page
          const currentPage = this.state.tableState.current || 1; //Nilesh-4562- Current page
          const absoluteIndex = (currentPage - 1) * pageSize + i; //Nilesh-4562- Calculate absolute index
      
          return (
            <div>
              <span style={{ fontSize: "14px", color: "#555555" }}>
                {this.state.reviewLeadFlag === true ? (
                  <label>{record.status}</label>
                ) : (
                  <select
                    style={{ height: "60%", fontSize: "14px", color: "#555555" }}
                    id="status"
                    name="status"
                    value={record.status} //Nilesh-4562- Bind to the dataset value
                    onChange={(e) => this.handleReasonChange(absoluteIndex, e)} //Nilesh-4562- Pass absolute index
                    className="form-control"
                  >
                    <option value="Accepted">Accepted</option>
                    <option value="Rejected">Rejected</option>
                  </select>
                )}
              </span>
            </div>
          );
        },
      },

      {
        title: "Reason For Rejection", //Sandeep-task-3441
        dataIndex: "reason",
        key: "reason",
        // width: 90,
        align: "left", //Sandeep-task-3441
        render: (text, record, i) => {
          const pageSize = this.state.pageSize; // Number of rows per page
          const currentPage = this.state.tableState.current || 1; // Current page (default to 1)
          const absoluteIndex = (currentPage - 1) * pageSize + i; // Calculate the absolute index
      
          return (
            <div>
              <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
              {this.state.reviewLeadFlag === true ? (
                <input
                  type="textbox" // Use "text" for the input type
                  id={`reason-${absoluteIndex}`} // Unique ID for the input
                  name="reason"
                  disabled={record.status !== "Rejected"} // Enable only when status is "Rejected"
                  value={record.reason || ""} // Bind to the dataset value
                  onChange={(e) => this.handleReasonChange(absoluteIndex, e)} // Pass absoluteIndex to the handler
                  className="form-control"
                />
              ) : (
                <input
                  type="textbox" // Use "text" for the input type
                  id={`reason-${absoluteIndex}`} // Unique ID for the input
                  name="reason"
                  
                  disabled={record.status !== "Rejected"} // Enable only when status is "Rejected"
                  value={record.reason || ""} // Bind to the dataset value
                  onChange={(e) => this.handleReasonChange(absoluteIndex, e)} // Pass absoluteIndex to the handler
                  className="form-control"
              />
              )}
              </span>
            </div>
          );
        },
      },
      {
        title: "Action", //Sandeep-task-3516-added action column
        dataIndex: "Action",
        key: "Action",
        width: 80,
        align: "center", //Sandeep-task-3441
        fixed: "right", //Sandeep-task-3516-added code for fixed column from right side
        render: (text, record, i) => (
          //  sandeep-task- It acceptes the Numeric values too -btnDisable issue
          <div
          //Nilesh-5493- Fixed Alignment for edit action
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%", // Ensure it takes the full height of the row
          }}>
            {this.props.auth.user.role === "AQA" ? (
              record.btnDisable === true && !record.editFlag ? ( //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
                <span style={{ float: "left" }}>
                  <Tooltip placement="top" title="Edit">
                    <img
                      style={{ cursor: "not-allowed" }}
                      src="material-edit.png"
                      alt=""
                      jobTitle={record.jobTitle}
                      jobLevel={record.jobLevel}
                      jobFunction={record.jobFunction}
                      //  onClick={this.handleEditSecondTab.bind(this,i)}//Sandeep-task-3659-button disable issue
                      width="20"
                      height="20"
                    />
                  </Tooltip>
                </span>
              ) : (
                <span style={{ float: "left" }}>
                  {record.editFlag ? (
                    <span style={{ float: "left" }}>
                      <button
                        type="button"
                        className="btn"
                        id={record.leadInfoID}
                        onClick={this.saveFormSecondTab}
                        //Nilesh-5493- Fixed Alignment for edit action
                        style={{
                          width: "60px", // Fixed width for consistency
                          padding: "5px 10px", // Consistent padding
                          fontSize: "12px", // Small font size
                          backgroundColor: "#144999",
                          color: "#fff",
                          cursor: "pointer"
                        }}
                      >
                        Save
                      </button>
                    </span>
                  ) : (
                    <Tooltip placement="top" title="Edit">
                      <img
                        //Nilesh-5505 - Change the image colour 
                        style={{ cursor: "pointer",
                        filter: "brightness(0) saturate(100%) invert(33%) sepia(82%) saturate(2896%) hue-rotate(184deg) brightness(98%) contrast(92%)"}}
                        src="material-edit.png"
                        alt=""
                        id={record.leadInfoID}
                        jobTitle={record.jobTitle}
                        jobLevel={record.jobLevel}
                        jobFunction={record.jobFunction}
                        onClick={this.handleEditSecondTab.bind(this, i)}
                        width="20"
                        height="20"
                      />
                    </Tooltip>
                  )}
                </span>
              )
            ) : (
              <span style={{ float: "left" }}>
                <Tooltip placement="top" title="Edit">
                  <img
                    style={{ cursor: "not-allowed" }}
                    src="material-edit.png"
                    alt=""
                    jobTitle={record.jobTitle}
                    jobLevel={record.jobLevel}
                    jobFunction={record.jobFunction}
                    // onClick={this.handleEditSecondTab.bind(this,i)}//Sandeep-task-3659-button disable issue
                    width="20"
                    height="20"
                  />
                </Tooltip>
              </span>
            )}
          </div>
        ),
      },
    ];

    columns3 = columns3.filter((a) => {
      if (!this.state.jobLevelFlag) {
        return a.dataIndex !== "jobLevel";//Aman-2835-(added '!==' and instead saw '!=')-Console Warnings - Agency - Advertiser Menu
      } else {
        return a;
      }
    }); //Sandeep-task-3516-added code to hide and show column for job Level

    columns3 = columns3.filter((a) => {
      if (!this.state.jobFunctionFlag) {
        return a.dataIndex !== "jobFunction"; //Aman-2835-(added '!==' and instead saw '!=')-Console Warnings - Agency - Advertiser Menu
      } else {
        return a;
      }
    }); //Sandeep-task-3516-added code to hide and show column for job Function

    // const columns3
    let columns4 = [
      {
        title: "Lead ID",
        dataIndex: "leadInfoID",
        key: "leadInfoID",
        width: 90,
        align: "left", //Sandeep-task-3441
        fixed: "left", //Sandeep-task-3516-added code for fixed column from left side
        render: (text, record) => (
          <div>
            <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
              {record.leadInfoID}
            </span>
          </div>
        ),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: 200,
        align: "left", //Sandeep-tsk-3441
        fixed: "left", //Sandeep-task-3516-added code for fixed column from left side
        className: "emailcolor", // Sandeep-task-3651-Alignment and color variation issue--in AQA lead review section
        render: (text, record) => (
          <div>
            <span
              style={{
                fontSize: "13px",
                color: "#4F4F4F",
                wordBreak: "break-all",
              }}
            >
              {record.email}
            </span>
          </div>
        ),
      },

      {
        title: "Voice Log Link",
        dataIndex: "voiceLogLink",
        key: "voiceLogLink",
        // width: 90,//Sandeep-task-3656-commented this code bcz of alignment issue
        align: "left", //Sandeep-task-3441

        render: (text, record) => (
          <div>
            <span style={{ fontSize: "13px", color: "#1890FF" }}>
              {/* {record.callAudit}
            {record.channel} */}
              {this.state.marketingChannelFlag === true ? (
                //sunita-task-3324-added condition as per channel for showing voicelog link column
                record.channel === "Telemarketing" ||
                  record.Channel === "Telemarketing" ||
                  ((record.channel === "Email/Telemarketing" ||
                    record.Channel === "Email/Telemarketing" ||
                    record.channel === "Email" ||
                    record.Channel === "Email") &&
                    record.callAudit === "Yes") ? (
                  <a
                    href={"#/"} // Aman-2835-(href attribute is required added "#/")-Console Warnings - Agency - Advertiser Menu
                    onClick={this.openLinkInBrowser}
                    id={record.leadInfoID}
                  >
                    Link
                  </a>
                ) : (
                  <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                    No Link Available
                  </span>
                )
              ) : (
                ""
              )}
            </span>
          </div>
        ),
      },

      {
        title: "Job Title", //Sandeep-task-3541
        dataIndex: "jobTitle",
        key: "jobTitle",
        // width: 90,
        align: "left", //Sandeep-task-3441
        render: (text, record, i) =>
          this.props.auth.user.role === "AQA" && record.key ? (
            <div>
              <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                <input
                  type="textbox"
                  id="jobTitleQA"
                  name="jobTitle"
                  value={record.jobTitle}
                  jobTitle={record.jobTitle}
                  onChange={this.handleEditForQA.bind(this, i)}
                  class="form-control"
                />
                {/* {record.jobTitle} */}
              </span>
            </div>
          ) : (
            <div>
              <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                <input
                  type="textbox"
                  id="jobTitleQA"
                  name="jobTitle"
                  disabled
                  value={record.jobTitle}
                  jobTitle={record.jobTitle}
                  onChange={this.handleEditForQA.bind(this, i)}
                  class="form-control"
                />
                {/* {record.jobTitle} */}
              </span>
            </div>
          ),
      },

      {
        title: "Job Function", //Sandeep-task-3516-added job function column
        dataIndex: "jobFunction",
        key: "jobFunction",
        // width: 90,
        align: "left",
        render: (text, record, i) =>
          this.props.auth.user.role === "AQA" && record.key ? (
            <div>
              <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                <input
                  type="textbox"
                  id="jobFunctionQA"
                  name="jobFunction"
                  value={record.jobFunction}
                  jobTitle={record.jobFunction}
                  onChange={this.handleEditForQA.bind(this, i)}
                  class="form-control"
                />
                {/* {record.jobTitle} */}
              </span>
            </div>
          ) : (
            <div>
              <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                <input
                  type="textbox"
                  id="jobFunctionQA"
                  name="jobFunction"
                  disabled
                  value={record.jobFunction}
                  jobTitle={record.jobFunction}
                  onChange={this.handleEditForQA.bind(this, i)}
                  class="form-control"
                />
                {/* {record.jobTitle} */}
              </span>
            </div>
          ),
      },

      {
        title: "Job Level", //Sandeep-task-3516-added JobLevel column
        dataIndex: "jobLevel",
        key: "jobLevel",
        // width: 90,
        align: "left",
        render: (text, record, i) =>
          this.props.auth.user.role === "AQA" && record.key ? (
            <div>
              <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                <input
                  type="textbox"
                  id="jobLevelQA"
                  name="jobLevel"
                  value={record.jobLevel}
                  jobTitle={record.jobLevel}
                  onChange={this.handleEditForQA.bind(this, i)}
                  class="form-control"
                />
                {/* {record.jobTitle} */}
              </span>
            </div>
          ) : (
            <div>
              <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                <input
                  type="textbox"
                  id="jobLevelQA"
                  name="jobLevel"
                  disabled
                  value={record.jobLevel}
                  jobTitle={record.jobLevel}
                  onChange={this.handleEditForQA.bind(this, i)}
                  class="form-control"
                />
                {/* {record.jobTitle} */}
              </span>
            </div>
          ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        // width: 90,//Sandeep-task-3656-commented this code bcz of alignment issue
        align: "left", //Sandeep-task-3441
        render: (text, record, i) => {
          const pageSize = this.state.pageSize; // Number of rows per page
          const currentPage = this.state.tableState.current || 1; // Current page
          const absoluteIndex = (currentPage - 1) * pageSize + i; // Calculate absolute index
      
          return (
            <div>
              <span style={{ fontSize: "14px", color: "#555555" }}>
                {this.state.reviewLeadFlag === true ? (
                  <label>{record.status}</label>
                ) : (
                  <select
                    style={{ height: "60%", fontSize: "14px", color: "#555555" }}
                    id="status"
                    name="status"
                    value={record.status} // Bind to the dataset value
                    onChange={(e) => this.handleReasonChange(absoluteIndex, e)} // Pass absolute index
                    className="form-control"
                  >
                    <option value="Accepted">Accepted</option>
                    <option value="Rejected">Rejected</option>
                  </select>
                )}
              </span>
            </div>
          );
        },
      },

      {
        title: "Reason For Rejection", //Sandeep-task-3441-
        dataIndex: "reason",
        key: "reason",
        // width: 90,//Sandeep-task-3656-commented this code bcz of alignment issue
        align: "left", //Sandeep-task-3441
        render: (text, record, i) => {
          const pageSize = this.state.pageSize; // Number of rows per page
          const currentPage = this.state.tableState.current || 1; // Current page (default to 1)
          const absoluteIndex = (currentPage - 1) * pageSize + i; // Calculate the absolute index
      
          return (
            <div>
              <span style={{ fontSize: "13px", color: "#4F4F4F" }}>
                {/* {alert("this.state.reviewLeadFlag-->" + this.state.reviewLeadFlag)} */}
              {this.state.reviewLeadFlag === true ? (
                <input
                  type="textbox" // Use "text" for the input type
                  id={`reason-${absoluteIndex}`} // Unique ID for the input
                  name="reason"
                  disabled={record.status !== "Rejected"} // Enable only when status is "Rejected"
                  value={record.reason || ""} // Bind to the dataset value
                  onChange={(e) => this.handleReasonChange(absoluteIndex, e)} // Pass absoluteIndex to the handler
                  className="form-control"
                />
              ) : (
                <input
                  type="text" // Use "text" for the input type
                  id={`reason-${absoluteIndex}`} // Unique ID for the input
                  name="reason"
                  
                  disabled={record.status !== "Rejected"} // Enable only when status is "Rejected"
                  value={record.reason || ""} // Bind to the dataset value
                  onChange={(e) => this.handleReasonChange(absoluteIndex, e)} // Pass absoluteIndex to the handler
                  className="form-control"
              />
              )}
              </span>
            </div>
          );
        },
      },

      {
        title: "Action", //Sandeep-task-3516-added action column
        dataIndex: "Action",
        key: "Action",
        width: 80,
        align: "center",
        fixed: "right", //Sandeep-task-3516-added code for fixed column from right side
        render: (text, record, i) => (
          <div
          //Nilesh-5493- Fixed Alignment for edit action
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%", // Ensure it takes the full height of the row
          }}>
            {/* sandeep-task- It acceptes the Numeric values too -btnDisable issue     */}
            {this.props.auth.user.role === "AQA" ? (
              record.btnDisable === true && !record.editFlag ? ( //Aman-2835-(added '===' and instead saw '==')-Console Warnings - Agency - Advertiser Menu
                <span style={{ float: "left" }}>
                  <Tooltip placement="top" title="Edit">
                    <img
                      style={{ cursor: "not-allowed" }}
                      src="material-edit.png"
                      alt=""
                      jobTitle={record.jobTitle}
                      jobLevel={record.jobLevel}
                      jobFunction={record.jobFunction}
                      //  onClick={this.handleEditSecondTab.bind(this,i)}//Sandeep-task-3659-button disable issue
                      width="20"
                      height="20"
                    />
                  </Tooltip>
                </span>
              ) : (
                <span style={{ float: "left" }}>
                  {record.editFlag ? (
                    <span style={{ float: "left" }}>
                      <button
                        type="button"
                        className="btn"
                        id={record.leadInfoID}
                        onClick={this.saveFormSecondTab}
                        //Nilesh-5493- Fixed Alignment for edit action
                        style={{
                          width: "60px", // Fixed width for consistency
                          padding: "5px 10px", // Consistent padding
                          fontSize: "12px", // Small font size
                          backgroundColor: "#144999",
                          color: "#fff",
                          cursor: "pointer"
                        }}
                      >
                        Save
                      </button>
                    </span>
                  ) : (
                    <Tooltip placement="top" title="Edit">
                      <img
                        //Nilesh-5505 - Change the image colour
                        style={{ cursor: "pointer",
                        filter: "brightness(0) saturate(100%) invert(33%) sepia(82%) saturate(2896%) hue-rotate(184deg) brightness(98%) contrast(92%)"}}
                        src="material-edit.png"
                        alt=""
                        id={record.leadInfoID}
                        jobTitle={record.jobTitle}
                        jobLevel={record.jobLevel}
                        jobFunction={record.jobFunction}
                        onClick={this.handleEditSecondTab.bind(this, i)}
                        width="20"
                        height="20"
                      />
                    </Tooltip>
                  )}
                </span>
              )
            ) : (
              <span style={{ float: "left" }}>
                <Tooltip placement="top" title="Edit">
                  <img
                    style={{ cursor: "not-allowed" }}
                    src="material-edit.png"
                    alt=""
                    jobTitle={record.jobTitle}
                    jobLevel={record.jobLevel}
                    jobFunction={record.jobFunction}
                    // onClick={this.handleEditSecondTab.bind(this,i)}////Sandeep-task-3659-button disable issue
                    width="20"
                    height="20"
                  />
                </Tooltip>
              </span>
            )}
          </div>
        ),
      },
    ];
    columns4 = columns4.filter((a) => {
      if (!this.state.jobLevelFlag) {
        return a.dataIndex !== "jobLevel"; //Aman-2835-(added '!==' and instead saw '!=')-Console Warnings - Agency - Advertiser Menu
      } else {
        return a;
      }
    }); //Sandeep-task-3516-added code to hide and show column for job Level

    columns4 = columns4.filter((a) => {
      if (!this.state.jobFunctionFlag) {
        return a.dataIndex !== "jobFunction";//Aman-2835-(added '!==' and instead saw '!=')-Console Warnings - Agency - Advertiser Menu
      } else {
        return a;
      }
    }); //Sandeep-task-3516-added code to hide and show column for job Function

    function onChange1(pagination, filters, sorter) { }

    return (
      <div>
        <LeadReviewLoader
          loadingRef={this.loadingRef}
          closeLoadingRef={this.closeLoadingRef}
          message="Please wait. Saving the data...."
        />
        {/* Sandeep-task-3341-added code for loader */}
        <PublisherNavigation />

        <div
          class="container-fluid"
          id="tabsContainer"
          style={{ paddingTop: "85px" }}
        >
          <Tabs activeKey={this.state.key} onSelect={this.handleSelect}>
            <Tab eventKey={1} title="Internal Leads Review By Agency">
              {/* onSubmit={this.submitInternalReviewLead} */}
              {/* encType="multipart/form-data"  */}
              {/* <form name="InternalReviewlead"> */}{" "}
              {/*sunita-task-3727 commented this form tag as due to this facing blank page issue */}
              {/* =====================================  sunita-task-3270 commented code from this place and addded above as per new screen requirement */}
              <div
                class="row"
                style={{ marginTop: "10px", marginBottom: "6px" }}
              >
                {/*sunita-task-3270-added css as per requirement */}
                <div class="col-md-1 col-sm-1 col-xs-1">
                  {/* href={'newdashboard?agencyID=' + this.state.agencyID} */}
                  {/* Sandeep-task-3516-added onClick event on back button */}
                  <a
                    class="TabOne"
                    href={"#/"}
                    onClick={this.backButtonHandleChange}
                    style={{ color: "#056eb8" }}
                  >
                    <FaArrowAltCircleLeft
                      size={32}
                      style={{ float: "left" }}
                      title="Back to Dashboard"
                      q
                    />
                  </a>
                </div>
                {/* <div class="col-md-11"> */}
                <div class="col-md-8 col-sm-10 col-xs-10">
                  {" "}
                  {/*sunita-task-3727-added column as per requirement */}
                  <center>
                    <label
                      id="labelinternallead"
                      style={{
                        fontSize: "28px",
                        fontFamily: "roboto",
                        fontWeight: "500",
                        color: "#193D8F",
                        textAlign: "center",
                      }}
                    >
                      Internal Lead Review
                    </label>
                  </center>{" "}
                  {/*sunita-task-3270-added css as per requirement */}
                </div>
              </div>
              <hr style={{ borderColor: "#1890ff" }} />
              {/* Sandeep-task-3819-agency side-review leads-updated screen-FE */}
              {/*Priyanka--3979--changed css as per requirement */}
              <div class="row" style={{ height: "40px" }}>
                <div
                  class="col-12"
                  style={{
                    float: "right",
                    backgroundColor: "#FFFFFF",
                    marginTop: "-10px",
                  }}
                >
                  {this.state.reviewlead.map((reviewlead) => (
                    <span>
                      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "600",
                          }}
                        >
                          Campaign ID :
                        </label>
                        &nbsp;<span>{reviewlead.parentCampID}</span>
                      </div>

                      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "600",
                          }}
                        >
                          Campaign Name :
                        </label>
                        &nbsp;
                        <span className="reviewcampdata1">
                          {reviewlead.campaignName}
                        </span>
                      </div>

                      {/* Reff(3819)---agency side---review lead page responsive issue */}
                      {this.state.resultMultitouch ? (
                        <div
                          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
                        //Chaitanya-task-4342-Prod issue-Agency Login-lead review-Multitouch alignment issue
                        // style={{ marginLeft: "-56px" }}
                        >
                          <label
                            style={{
                              fontSize: "14px",
                              color: "black",
                              fontWeight: "600",
                            }}
                          >
                            MultiTouch :
                          </label>
                          &nbsp;
                          <span style={{ color: "orange" }}>
                            {this.state.resultMultitouch}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}

                      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "600",
                          }}
                        >
                          Campaign Status :{" "}
                        </label>
                        &nbsp;<span>{reviewlead.campaignStatus}</span>
                      </div>

                      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "600",
                          }}
                        >
                          Start Date :{" "}
                        </label>
                        &nbsp;<span>{reviewlead.startDate}</span>
                      </div>

                      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "600",
                          }}
                        >
                          End Date :{" "}
                        </label>
                        &nbsp; <span>{reviewlead.endDate}</span>
                      </div>
                    </span>
                  ))}
                </div>
              </div>
              <hr style={{ borderColor: "#1890ff" }} />
              {/*Priyanka--3979--changed css as per requirement */}
              <div class="row leadReviewCampSpecs" >   {/*Chaitanya-task-4342-Prod issue-Agency Login-alignment issue*/}
                <div
                  class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6"
                  align="left"
                  style={{ color: "#000080", fontSize: "18px" }}
                >
                  <a
                    href={"#/"} //Aman-2835-(href attribute is required added "#/")-Console Warnings - Agency - Advertiser Menu
                    style={{ color: "#1890ff", cursor: "pointer" }}
                    data-toggle="modal"
                    data-target="#offlineLeadReview"
                  >
                    <u style={{ color: " #1890ff" }}> Offline Lead Review</u>
                  </a>
                  &nbsp;
                </div>

                {/*sunita-task-3727-added below code as per new requirement for opening the specification page */}
                {/* Reff(3819)---agency side---review lead page responsive issue */}
                {/*Priyanka--3979--changed css as per requirement */}
                <div
                  class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6"
                  align="right"
                  style={{ color: "#000080", fontSize: "18px" }}
                >
                  <a
                    href={"#/"} //Aman-2835-(href attribute is required added "#/")-Console Warnings - Agency - Advertiser Menu
                    style={{ color: "#1890ff", cursor: "pointer" }}
                    onClick={this.displayCampaignSpecification}
                  >
                    <u style={{ color: " #1890ff" }}> Campaign Specification</u>
                  </a>
                  &nbsp;
                </div>
              </div>
              <div>
                <div
                  class="modal fade"
                  id="offlineLeadReview"
                  role="dialog"
                  data-backdrop="static"
                  data-keyboard="false"
                  style={{ backgroundColor: "rgba(0,0,0,.4)" }}
                >
                  {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 "> */}

                  <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                      <div
                        class="modal-header custom-classPopup"
                        style={{ textAlign: "end" }}
                      >
                        <h4 class="modal-title" style={{ color: "#144999" }}>
                          <font color="white">
                            Offline Lead Review Instructions (Campaign ID:&nbsp;
                            {this.state.campID})
                          </font>
                        </h4>

                        <button
                          type="button"
                          class="cancel-button"
                          style={{ fontSize: 15, float: "right" }}
                          data-dismiss="modal"
                          onClick={this.handleRefreshPage}
                        >
                          &times;
                        </button>
                        {/* <div> */}
                        {/*sunita-task-3270-added below changes as per new requirement screen*/}
                        {/* <h5>
															<span
																style={{
																	fontWeight: "bold",
																	color: "#F28C0F",
																}}>
																Offline Lead Review Instructions :-{" "}
															</span>
															<br />
														</h5> */}
                      </div>

                      {this.state.reerrors.fileError ? (
                        <div
                          className="errorMessage"
                          style={{
                            marginLeft: "17px",
                            backgroundColor: "rgba(255, 0, 0, 0.2)",
                            marginRight: "21px",
                            display: "flex",
                          }}
                        >
                          <img
                            style={{ marginLeft: "36px" }}
                            src="warning_error.png"
                            width="16px"
                            height="14px"
                            alt="" //Aman-2835-(alt="" attribute is required added)-Console Warnings - Agency - Advertiser Menu
                          />
                          <span style={{ marginRight: "442px" }}>
                            {this.state.reerrors.fileError}
                          </span>
                          <br />
                        </div>
                      ) : (
                        ""
                      )}
                      <div style={{ marginLeft: "16px" }}>
                        <span>
                          1. Click on <b>"Download Leads"</b> to get Lead's
                          File.
                        </span>{" "}
                        <br />
                        {this.props.auth.user.role === "AQA" ? (
                          <span>
                            2. After reviewing the lead, Please update status as
                            <b>"AgencyInternalReview" OR "AgencyQARejected"</b>
                            in Status column in Lead's File.
                          </span>
                        ) : (
                          <span>
                            2. After reviewing the lead, Please update status as{" "}
                            <b>"AgencyInternalReview" OR "Rejected"</b>
                            in Status column in Lead's File.
                          </span>
                        )}
                        {/* </div> */}
                        <div>
                          <span>
                            3. If you rejecting the lead then please{" "}
                            <b>add reason of rejection</b> in reason column in
                            Lead's File.
                          </span>
                          <br />
                          <span>
                            4. Click on <b>"Upload Lead"</b> to upload reviewed
                            Lead's File.
                          </span>
                        </div>
                        {/* Sandeep-task-3652-Edited time save option issue--AQA lead review section  */}
                        <div>
                          <span>
                            5.After click on <b>"Edit"</b> can edit only
                            <b> Job Function,Job Level and Job Title</b>.
                          </span>
                        </div>
                      </div>
                      {/* </div> */}

                      <div
                        class="row"
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          marginLeft: "16px",
                        }}
                      >
                        {/*sunita-task-3160-added new row and below columns as per requirement changes. */}
                        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                          <button
                            class="btn add-button"
                            type="button"
                            onClick={this.downloadInternalLeadDetails}
                            style={{
                              backgroundColor: "#056eb8",
                              color: "white",
                              height: "32px",
                              fontFamily: "sans-serif",
                              fontSize: "14px",
                              fontWeight: "400",
                              borderRadius: "4px",
                            }}
                          >
                            <i
                              class="fa fa-cloud-download"
                              aria-hidden="true"
                            />{" "}
                            Download QA Leads
                          </button>
                        </div>

                        <div
                          className="col-xs-12 col-sm-12 col-md-7 col-lg-7 "
                          id="lead_button1"
                          style={{ marginTop: "1px", textAlign: "right" }}
                        >
                          {/*sunita-task-3160-changed the column size as per requirement */}

                          <label
                            class=" btn btn-defaulttab  "
                            style={{
                              height: "32px",
                              fontFamily: "sans-serif",
                              fontSize: "14px",
                              fontWeight: "400",
                              borderRadius: "4px",
                              backgroundColor: "transparent",
                              color: "#193D8F",
                              border: "1px solid #193D8F",
                            }}
                          >
                            Upload Lead
                            <input
                              style={{ display: "none" }}
                              type="file"
                              id="abm1"
                              name="abmFiles"
                              key={this.state.filesData1} //sunita-task-3270-added for file
                              onChange={this.uploadInternalLeadFile}
                            />
                          </label>

                          <span id="fileName">&nbsp;No File Choosen</span>
                          <div
                            className="successMessage"
                            style={{ marginRight: "175px" }}
                          >
                            {this.state.errors.uploadsuccessinternal}
                          </div>
                          <div
                            className="errorMessage"
                            style={{ marginLeft: "72px" }}
                          >
                            {this.state.reerrors.fileError
                              ? "File Invalid"
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* sunita-task-3270-added below code as per new requirement for search */}
              <div class="card card-signin my-1">
                {" "}
                {/*sunita-task-3324-below all changes i have done as per new screen */}
                <div class="card-body" style={{ padding: "5px" }}>
                  {/*sunita-task-3270-added css as per requirement */}

                  {/* <div class="row"> */}
                  {/* <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" style={{ marginBottom: '0px' }}>
                    <h5 className="campLeadHeading" style={{ color: '#124E9C', paddingTop: '5px', fontSize: '15px', paddingBottom: '10px', marginBottom: '0px' }}>Campaigns for Lead Review :</h5>
                  </div> */}
                  <div
                    class="col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 campaignslists "
                    style={{
                      background: "#FFFFFF",
                      borderRight: "2px solid #eee",
                      padding: "0px",
                      height: "90px",
                    }}
                  >
                    {/* </div> */}
                    <div>
                      {/* //sunita-task 3324- i have added black color for the label as per @Tanuja's suggetion (it was blue in screen)*/}
                      <center>
                        <label
                          className="campLeadHeading"
                          style={{
                            fontSize: "17px",
                            color: "#14254A",
                            marginTop: "10px",
                            fontWeight: "500",
                            fontFamily: "roboto",
                          }}
                        >
                          Campaigns for Lead Review :
                        </label>
                      </center>
                    </div>
                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <span>
                          <input
                            type="text"
                            className="inputstyle2" //Chaitanya-task-4342-Prod issue-Agency Login-lead review-alignment issue
                            name="search"
                            placeholder="Search Campaign"
                            style={{
                              backgroundImage: "url(searchIcon.png)",
                              backgroundPosition: "15px 6px",
                              backgroundSize: "15px 15px",
                              backgroundRepeat: "no-repeat",
                              width: "280px",
                              height: "28px",
                              fontSize: "12px",
                              // float: 'left',
                              // marginLeft: '17px',
                              border: "1px solid #CCCCCC",
                              padding: "12px 20px 15px 36px",
                              backgroundColor: "#FFFFFF",
                              // marginBottom: '5px',
                              // borderBottom: '1px solid #eee',
                              fontFamily: "sans-serif",
                              borderRadius: "2px",
                            }}
                            onChange={this.handleChangeSearch}
                            onPressEnter={this.handleChangeSearch}
                          />
                        </span>
                      </div>
                    </div>

                    <br />
                    <hr />

                    {/* ========================================== */}
                    {/* <div class="card card-signin my-1"> */}
                    {/* <div class="card card-signin my-1">
                  <div class="card-body" style={{ padding: '5px' }} >sunita-task-3270-added css as per requirement */}

                    {/* <div class="row">   */}

                    {/* sunita-task-3270-added below condition as search table data */}
                    {/* <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" style={{ overflowY: 'scroll', height: '1680px', paddingLeft: '10px', paddingRight: '10px', borderRight: '2px solid #eee' }}> sunita-task-3270-added col and it's data as per requirement */}
                    <div
                      style={{
                        overflowY: "scroll",
                        height: "1604px",
                        background: "#FFFFFF",
                        padding: "0px",
                        borderRight: "2px solid #eee",
                        marginTop: "-9px",
                      }}
                    >
                      {" "}
                      {/*sunita-task-3270-added col and it's data as per requirement */}
                      {this.state.tableSearchText === "" ? (
                        <div>
                          {this.state.leadQAReview.map((leadQAReview) => (
                            <div
                              style={{
                                borderBottom: "1px solid #eee",
                                padding: "5px 15px",
                              }}
                              className="campNames"
                            >
                              <a
                                href={"#/"}//Aman-2835-(href attribute is required added "#/")-Console Warnings - Agency - Advertiser Menu
                                className="reviewcampdata"
                                id={leadQAReview.campID}
                                onClick={this.leadreviewDetails}
                              >
                                {leadQAReview.campID} -{" "}
                                {leadQAReview.clientCampID} -{" "}
                                {/* Saurabh – Task- 3726 – Adding Agency campaign ID i.e clientCampID */}
                                {leadQAReview.campaignName}{" "}
                              </a>
                            </div>
                          ))}
                        </div>
                      ) : this.state.leadQAReviewSearch.length > 0 ? (
                        <div>
                          {this.state.leadQAReviewSearch.map(
                            (leadQAReviewSearch) => (
                              <div
                                style={{
                                  borderBottom: "1px solid #eee",
                                  padding: "5px 15px",
                                }}
                                className="campNames"
                              >
                                <a
                                  href={"#/"}//Aman-2835-(href attribute is required added "#/")-Console Warnings - Agency - Advertiser Menu
                                  className="reviewcampdata"
                                  id={leadQAReviewSearch.campID}
                                  onClick={this.leadreviewDetails}
                                >
                                  {leadQAReviewSearch.campID} -{" "}
                                  {leadQAReviewSearch.clientCampID} -{" "}
                                  {/* Saurabh – Task- 3726 – Adding Agency campaign ID i.e clientCampID for Search */}
                                  {leadQAReviewSearch.campaignName}{" "}
                                </a>
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <center>
                          <label
                            style={{
                              color: "red",
                              fontSize: "medium",
                              marginTop: "34px",
                            }}
                          >
                            No Data Exist
                          </label>
                        </center>
                      )}
                    </div>
                  </div>

                  <div
                    className="col-xs-12 col-sm-9 col-md-9 col-lg-9 col-xl-9"
                    id="campaignData1"
                    style={{ background: "#FFFFFF", paddingTop: "20px" }}
                  >
                    {/*sunita-task-3270-added before whole data in this column as per requirement */}

                    {/* <div style={{ MozBoxShadow: '3px 3px 5px 0px #e6e6ff', WebkitBoxShadow: '3px 3px 5px 1px #e6e6ff', boxShadow: '3px 3px 5px 0px #e6e6ff' }}> */}
                    {/* <div style={{ paddingLeft: "1px", paddingTop: "10px", paddingBottom: "10px" }}> */}
                    <div
                      class="row"
                      style={{
                        border: "1px solid #1890ff ",
                        marginTop: "-14px",
                        opacity: "23.5px",
                      }}
                    >
                      {/* Sandeep-task-3819-agency side-review leads-updated screen-FE */}
                      <div className="col-xs-12 col-sm-12 col-md-9 col-lg-10 col-xl-10">
                        <span
                          style={{
                            fontWeight: "bold",
                            // marginTop: "115px",
                            color: "black",
                            fontSize: "16px",
                            fontFamily: "Roboto",
                            borderBottom: "2px solid black",
                          }}
                        >
                          QA-Automation-Technology Touch
                        </span>
                        &nbsp;&nbsp;
                        <small style={{ color: "#1890ff" }}>
                          Below Parameters are validated for this campaign
                        </small>
                        <br />
                        <div className="row" style={{ marginLeft: "1px" }}>
                          <span>
                            <img
                              style={{ float: "left", marginTop: "5px" }}
                              src="right_icon.png"
                              width="20px"
                              height="10px"
                              alt="" //Aman-2835-(alt="" attribute is required added)-Console Warnings - Agency - Advertiser Menu
                            />
                            &nbsp;
                            <span style={{ fontFamily: "Roboto" }}>
                              Firmographic
                            </span>
                            &nbsp;:&nbsp;
                            <span>{this.state.resultFirmoGraphic}</span>
                          </span>
                        </div>
                        <div className="row" style={{ marginLeft: "1px" }}>
                          <span>
                            <img
                              style={{ float: "left", marginTop: "5px" }}
                              src="right_icon.png"
                              width="20px"
                              height="10px"
                              alt="" //Aman-2835-(alt="" attribute is required added)-Console Warnings - Agency - Advertiser Menu
                            />
                            &nbsp;
                            <span>Demographic</span>&nbsp;:&nbsp;
                            <span>{this.state.resultDemoGraphic}</span>
                          </span>
                        </div>
                        <div className="row" style={{ marginLeft: "1px" }}>
                          <span>
                            <img
                              style={{ float: "left", marginTop: "5px" }}
                              src="right_icon.png"
                              width="20px"
                              height="10px"
                              alt="" //Aman-2835-(alt="" attribute is required added)-Console Warnings - Agency - Advertiser Menu
                            />
                            &nbsp;
                            <span style={{ width: "82px" }}>Other</span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;
                            <span>{this.state.resultOther}</span>
                          </span>
                        </div>
                        <div className="row" style={{ marginLeft: "1px" }}>
                          <span>
                            <img
                              style={{ float: "left", marginTop: "5px" }}
                              src="right_icon.png"
                              width="20px"
                              height="10px"
                              alt="" //Aman-2835-(alt="" attribute is required added)-Console Warnings - Agency - Advertiser Menu
                            />
                            &nbsp;
                            <span>Custom Questions</span>&nbsp;:&nbsp;
                            <span>{this.state.resultCustomQuestion}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* Sandeep-task-3819-agency side-review leads-updated screen-FE */}
                    <div
                      class="row"
                      style={{
                        border: "1px solid #1890ff",
                        marginTop: "5px",
                        opacity: "23.5px",
                      }}
                    >
                      <div className="col-xs-12 col-sm-12 col-md-9 col-lg-10 col-xl-10">
                        <span
                          style={{
                            fontWeight: "bold",
                            // marginTop: "115px",
                            color: "black",
                            fontSize: "16px",
                            fontFamily: "Roboto",
                            marginBottom: "5px",
                            borderBottom: "2px solid black",
                          }}
                        >
                          QA-Manual-Human Touch
                        </span>
                        &nbsp;&nbsp;
                        <small style={{ color: "#1890ff" }}>
                          Below Parameters are validated for this campaign
                        </small>
                        <br />
                        <div className="row" style={{ marginLeft: "1px" }}>
                          <span>
                            <img
                              style={{ float: "left", marginTop: "5px" }}
                              src="right_icon.png"
                              width="20px"
                              height="10px"
                              alt="" //Aman-2835-(alt="" attribute is required added)-Console Warnings - Agency - Advertiser Menu
                            />
                            &nbsp;
                            <span
                              style={{ width: "118px", fontFamily: "Roboto" }}
                            >
                              Linkdin Validation
                            </span>
                            &nbsp;&nbsp;&nbsp;:&nbsp;
                            <span>{this.state.resultLinkdin}</span>
                          </span>
                        </div>
                        {/* <b>Linkdin Validation:Job Title,Job Role,Job Level,Current Company</b><br/> */}
                        <div className="row" style={{ marginLeft: "1px" }}>
                          <span>
                            <img
                              style={{ float: "left", marginTop: "5px" }}
                              src="right_icon.png"
                              width="20px"
                              height="10px"
                              alt="" //Aman-2835-(alt="" attribute is required added)-Console Warnings - Agency - Advertiser Menu
                            />
                            &nbsp;
                            <span>Monthly Allocation</span>&nbsp;:&nbsp;
                            <span>{this.state.resultMonthlyAllocation}</span>
                          </span>
                        </div>
                        {/* <b>Monthly Allocation:Target for this month is -300 Delivered 200 Balance 100</b><br/> */}
                        <div className="row" style={{ marginLeft: "1px" }}>
                          <span>
                            <img
                              style={{ float: "left", marginTop: "5px" }}
                              src="right_icon.png"
                              width="20px"
                              height="10px"
                              alt="" //Aman-2835-(alt="" attribute is required added)-Console Warnings - Agency - Advertiser Menu
                            />
                            &nbsp;
                            <span style={{ width: "118px" }}>Notes</span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;
                            <span>{this.state.resultNotes}</span>
                          </span>
                        </div>
                        {/* <b>Notes:Check the Other Specification note that says- AAAAAAAAAA</b> */}
                      </div>
                    </div>
                    <br />

                    <div class="row" style={{ marginBottom: "6px" }}>
                      <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                        <span
                          style={{
                            fontWeight: "bold",
                            marginTop: "115px",
                            color: "#056eb8",
                          }}
                        >
                          Online Lead Review & Instant Status Update:-
                          <br />
                        </span>
                        <span>
                          Total Leads :{this.state.internalLeadDetails.length}
                        </span>
                        <div
                          style={{
                            fontSize: "18px",
                            color: "green",
                            textAlign: "center",
                          }}
                        >
                          {this.state.errors.leaderror}
                        </div>
                        <div
                          style={{
                            fontSize: "20px",
                            color: "red",
                            textAlign: "center",
                          }}
                        >
                          {this.state.errors.failureMsg}
                        </div>
                      </div>

                      <div
                        className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"  //Chaitanya-task-4342-Prod issue-Agency Login-lead review-alignment issue
                        style={{ marginTop: "4px" }}
                      >
                        {" "}
                        {this.state.buttonFlag === true ||
                          this.state.btnFlag === true ||
                          this.state.errorMsgFlag === false ? (
                          <button
                            type=""
                            button
                            class="btn add-button"
                            id="buttonone"
                            style={{
                              align: "right",
                              paddingBottom: "6px",
                              fontFamily: "sans-serif",
                              fontSize: "14px",
                              fontWeight: "400",
                              borderRadius: "4px",
                            }}
                            disabled
                            className={this.state.btnDisable}
                          >
                            Submit
                          </button>
                        ) : (
                          <button
                            type="button"
                            class="btn add-button"
                            id="buttonone"
                            style={{
                              align: "right",
                              paddingBottom: "6px",
                              fontFamily: "sans-serif",
                              fontSize: "14px",
                              fontWeight: "400",
                              borderRadius: "4px",
                            }}
                            className={this.state.btnDisable}
                            onClick={this.submitInternalReviewLead}
                          >
                            Submit
                          </button>
                        )
                          // type="submit"
                        }
                      </div>
                    </div>

                    {/* Sandeep-task-3659-added code to show error msg */}
                    <span
                      style={{
                        display: this.state.jobTitleErrMsg,
                        width: "254px",
                        marginLeft: "4px",
                      }}
                      className="errorMessage"
                    >
                      Please enter valid values for job title
                    </span>
                    <span
                      style={{
                        display: this.state.jobLevelErrMsg,
                        width: "254px",
                        marginLeft: "4px",
                      }}
                      className="errorMessage"
                    >
                      Please enter valid values for job level
                    </span>
                    <span
                      style={{
                        display: this.state.jobFunctionErrMsg,
                        width: "254px",
                        marginLeft: "4px",
                      }}
                      className="errorMessage"
                    >
                      Please enter valid values for job function
                    </span>

                    {/*sunita-task-3270-added below antd table as per requirement before here used is normal bootstrap table */}
                    <div id={this.state.loader2} />
                    {/* Sandeep-task-3341-added code for loader  */}
                    {/* <Form form={form} component={false}> */}
                    <Table
                      {...this.state.tableState}
                      id="myTable"
                      bordered
                      dataSource={this.state.internalLeadDetails}
                      onChange={this.onTableChange} //Nilesh-4562- call onTableChange function
                      columns={
                        this.state.marketingChannelFlag === true
                          ? columns2
                          : columns1
                      }
                      //loading={this.state.loading}
                      // className="ant-table-thead"
                      // className={tableCSS}
                      className={`${tableCSS} "ant-table-thead"`}//Aman-2835-(changed 2 className in One)-Console Warnings - Agency - Advertiser Menu
                      // scroll={{ x: 1375, y: 4000 }}
                      //y: 1333
                      // scroll={{ x: 1375, y: 1333 }}
                      scroll={{ x: 1375, y: 450 }} //Nilesh-5493- Change y axis scroll
                      pagination={{
                        pageSize: this.state.pageSize,
                        position: "bottom",
                      }}
                    />
                    {/* </Form> */}
                    {/* sunita-task-3270-added below code for paginaion */}
                    <div class="row">
                      <div
                        class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
                        style={{
                          marginTop: "-52px",
                          marginLeft: "5px",
                          fontSize: "13px",
                          color: "#4F4F4F",
                          fontFamily: "sans-serif",
                        }}
                      >
                        &nbsp;Records per page:&nbsp;
                        <select
                          defaultValue={this.state.pageSize}
                          onChange={this.handleChangePageSize}
                          id="pacing1"
                          class="input-small"
                          className="form-control"
                          name="pacing"
                          style={{
                            width: "71px",
                            height: "30px",
                            display: "initial",
                            fontSize: "13px",
                            fontFamily: "sans-serif",
                            color: "#4F4F4F",
                          }}
                        >
                          <option value="100" selected>
                            100
                          </option>
                          <option value="200">200</option>
                          <option value="300">300</option>
                        </select>
                      </div>
                    </div>

                    <br />

                    {/* Sandeep-task-3652-Edited time save option issue--AQA lead review section   */}
                    {this.state.buttonFlag === true ||
                      this.state.btnFlag === true ||
                      this.state.errorMsgFlag === false ? ( //shivani-task 3089-added condition for buttonFlag:true to make button disable if i upload leads through excel file.
                      <div
                        class="col"
                        align="right"
                        style={{ paddingBottom: "20px" }}
                      >
                        <button
                          class="btn add-button"
                          type="button"
                          disabled
                          className={this.state.btnDisable}
                          style={{
                            fontFamily: "sans-serif",
                            fontSize: "14px",
                            fontWeight: "400",
                            borderRadius: "4px",
                          }}
                        >
                          Submit
                        </button>
                        {/* type="submit" */}
                      </div>
                    ) : (
                      <div
                        class="col"
                        align="right"
                        style={{ paddingBottom: "20px" }}
                      >
                        <button
                          class="btn add-button"
                          type="button"
                          className={this.state.btnDisable}
                          style={{
                            fontFamily: "sans-serif",
                            fontSize: "14px",
                            fontWeight: "400",
                            borderRadius: "4px",
                          }}
                          onClick={this.submitInternalReviewLead}
                        //Sandeep-task-3516-added submitInternalReviewLead handle change on onClick event
                        >
                          Submit
                        </button>
                        {/* type="submit" */}
                      </div>
                    )}
                    <br />
                  </div>

                  {/* </div> */}
                </div>
              </div>
              {/* </form> */}
            </Tab>

            {this.state.userRole === "AC" || this.state.userRole === "AQA" ? (
              <Tab eventKey={2} title="Leads Review By Agency">
                {/* encType="multipart/form-data" */}
                {/* <form name="reviewlead"> */}{" "}
                {/*sunita-task-3727-commented the form tag as facing blank page issue */}
                {/* onSubmit={this.submitreviewlead} */}
                {/* <div class="card card-signin my-1">
                    <div class="card-body" > */}
                <div
                  class="row"
                  style={{ marginTop: "10px", marginBottom: "-13px" }}
                >
                  <div class="col-md-1 col-sm-1 col-xs-1">
                    {/* IoIosArrowDropleftCircle */}
                    {/* href={'newdashboard?agencyID=' + this.state.agencyID} */}
                    {/* Sandeep-task-3516-added onClick event on back button */}
                    <a
                      href={"#/"}
                      onClick={this.backButtonHandleChange}
                      style={{ color: "#056eb8" }}
                    >
                      <FaArrowAltCircleLeft
                        size={32}
                        style={{ float: "left" }}
                        title="Back to Dashboard"
                      />
                    </a>
                  </div>
                  {/* <div class="col-md-11"> */}
                  <div class="col-md-8 col-sm-7 col-xs-7">
                    {" "}
                    {/*sunita-task-3727-added column as per requirement */}
                    <center>
                      <label
                        id="labelleadreviewSecondTab"
                        style={{
                          fontSize: "28px",
                          fontFamily: "roboto",
                          fontWeight: "500",
                          color: "#193D8F",
                          textAlign: "center",
                        }}
                      >
                        Lead Review
                      </label>
                    </center>{" "}
                    {/*sunita-task-3270-added css as per requirement */}
                    <br />
                  </div>
                  {/*sunita-task-3727-added below code as per new requirement for opening the specification page */}
                  <div
                    class="col-sm-4 col-xs-4 col-md-3 col-lg-3"
                    align="right"
                    style={{
                      color: "#000080",
                      fontSize: "18px",
                      paddingLeft: "12px",
                    }}
                  >
                    <button
                      class="btn add-button"
                      onClick={this.displayCampaignSpecification}
                    >
                      {" "}
                      Campaign Specification
                    </button>
                    &nbsp;
                  </div>
                </div>
                {/* <hr/> */}
                {/* sunita-task-3270-added below code as per new requirement for search */}
                <div class="card card-signin my-1">
                  {" "}
                  {/*sunita-task-3324-added below code as per new screen requirement */}
                  <div class="card-body" style={{ padding: "5px" }}>
                    {/* <div class="row"> */}
                    {/* <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" style={{ marginBottom: '0px' }}> */}
                    {/* <h5 className="campLeadHeading" style={{ color: '#124E9C', paddingTop: '5px', fontSize: '15px', paddingBottom: '10px', marginBottom: '0px' }}>Campaigns for Lead Review :</h5> */}
                    {/* </div> */}
                    {/* </div> */}
                    <div
                      class="col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 campaignslists "
                      style={{
                        background: "#FFFFFF",
                        borderRight: "2px solid #eee",
                        padding: "0px",
                        height: "90px",
                      }}
                    >
                      <div>
                        <center>
                          <label
                            className="campLeadHeading1"  //Chaitanya-task-4342-Prod issue-Agency Login-lead review-alignment issue
                            style={{
                              fontSize: "17px",
                              color: "#14254A",
                              marginTop: "10px",
                              fontWeight: "500",
                              fontFamily: "roboto",
                              // marginLeft: "-63px",
                            }}
                          >
                            Campaigns for Lead Review :
                          </label>
                        </center>
                      </div>

                      <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                          <span>
                            <input
                              type="text"
                              className="inputstyle2"
                              name="search"
                              placeholder="Search Campaign"
                              style={{
                                backgroundImage: "url(searchIcon.png)",
                                backgroundPosition: "15px 6px",
                                backgroundSize: "15px 15px",
                                backgroundRepeat: "no-repeat",
                                width: "280px",
                                height: "28px",

                                fontSize: "12px",
                                // float: 'left',
                                // marginLeft: "17px",
                                border: "1px solid #CCCCCC",
                                padding: "12px 20px 15px 36px",
                                backgroundColor: "#FFFFFF",
                                // marginBottom: '5px',
                                // borderBottom: '1px solid #eee',
                                fontFamily: "sans-serif",
                                borderRadius: "2px",
                              }}
                              onChange={this.handleChangeSearch1}
                              onPressEnter={this.handleChangeSearch1}
                            />
                          </span>
                        </div>
                      </div>
                      <br />
                      <hr />

                      {/* <div class="card card-signin my-1">
                    <div class="card-body" style={{ padding: '5px' }} > */}

                      {/* sunita-task-3270-added below condition as search table data */}
                      {/* <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" style={{ overflowY: 'scroll', height: '1680px', borderRight: '2px solid #eee', paddingLeft: '10px', paddingRight: '10px' }}> sunita-task-3270-added col and it's data as per requirement */}
                      {/*sunita-task-3324-added below css as per new screen */}
                      <div
                        style={{
                          overflowY: "scroll",
                          height: "1604px",
                          paddingLeft: "10px",
                          padding: "0px",
                          borderRight: "2px solid #eee",
                          marginTop: "-9px",
                        }}
                      >
                        {" "}
                        {/*sunita-task-3270-added col and it's data as per requirement */}
                        {this.state.tableSearchText1 === "" ? (
                          <div>
                            {this.state.agencyReviewInternalLead.map(
                              (agencyReviewInternalLead) => (
                                <div
                                  style={{
                                    borderBottom: "1px solid #eee",
                                    padding: "5px 15px",
                                  }}
                                  className="campNames"
                                >
                                  <a
                                    href={"#/"}//Aman-2835-(href attribute is required added "#/")-Console Warnings - Agency - Advertiser Menu
                                    className="reviewcampdata"
                                    id={agencyReviewInternalLead.campID}
                                    onClick={this.agencyleadreviewDetails}
                                  >
                                    {agencyReviewInternalLead.campID} -{" "}
                                    {agencyReviewInternalLead.clientCampID} -{" "}
                                    {/* {alert("clientCampID => " +agencyReviewInternalLead.clientCampID)} */}
                                    {/* Saurabh – Task- 3726 – Adding Agency campaign ID i.e clientCampID */}
                                    {agencyReviewInternalLead.campaignName}{" "}
                                  </a>
                                </div>
                              )
                            )}
                          </div>
                        ) : this.state.internalLeadDetailsSearch.length > 0 ? (
                          <div>
                            {this.state.internalLeadDetailsSearch.map(
                              (internalLeadDetailsSearch) => (
                                <div
                                  style={{
                                    borderBottom: "1px solid #eee",
                                    padding: "5px 15px",
                                  }}
                                  className="campNames"
                                >
                                  <a
                                    href={"#/"} //Aman-2835-(href attribute is required added "#/")-Console Warnings - Agency - Advertiser Menu    
                                    className="reviewcampdata"
                                    id={internalLeadDetailsSearch.campID}
                                    onClick={this.agencyleadreviewDetails}
                                  >
                                    {internalLeadDetailsSearch.campID} -{" "}
                                    {internalLeadDetailsSearch.clientCampID} -{" "}
                                    {/* Saurabh – Task- 3726 –Adding Agency campaign ID i.e clientCampID for Search */}
                                    {internalLeadDetailsSearch.campaignName}{" "}
                                  </a>
                                </div>
                              )
                            )}
                          </div>
                        ) : (
                          <center>
                            <label
                              style={{
                                color: "red",
                                fontSize: "medium",
                                marginTop: "34px",
                              }}
                            >
                              No Data Exist
                            </label>
                          </center>
                        )}
                      </div>
                    </div>

                    <div
                      className="col-xs-12 col-sm-9 col-md-9 col-lg-9 col-xl-9"
                      style={{ background: "#FFFFFF", paddingTop: "20px" }}
                      // style={{ background: "#FFFFFF", paddingTop: "20px" }} //Aman-2835-(commented duplicate style from the div)-Console Warnings - Agency - Advertiser Menu
                    >
                      {/*sunita-task-3270-added before whole data in this column as per requirement */}

                      {/* <div style={{ MozBoxShadow: '3px 3px 5px 0px #e6e6ff', WebkitBoxShadow: '3px 3px 5px 1px #e6e6ff', boxShadow: '3px 3px 5px 0px #e6e6ff' }}> */}
                      {/* <div style={{ paddingLeft: "1px", paddingTop: "10px", paddingBottom: "10px" }}> */}
                      <div>
                        {this.state.reviewlead.map((reviewlead) => (
                          <div class="row" style={{ marginTop: "-9px" }}>
                            {/*sunita-task-3324-added below all css as per requirement */}
                            {/* <div class="col-sm-2"> */}
                            {/*sunita-task-3270-added below changes as per new requirement for alignment*/}
                            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                              <label
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "600",
                                }}
                              >
                                Campaign ID :
                              </label>
                              &nbsp;<span>{reviewlead.parentCampID}</span>
                            </div>
                            {/* <div class="col-sm-3 col-lg-2" > */}
                            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                              <label
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "600",
                                }}
                              >
                                Campaign Name :
                              </label>
                              &nbsp;
                              <span className="reviewcampdata1">
                                {reviewlead.campaignName}
                              </span>
                            </div>

                            {/* <div class="col-sm-2 col-lg-3"> */}
                            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                              <label
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "600",
                                }}
                              >
                                Campaign Status :{" "}
                              </label>
                              &nbsp;<span>{reviewlead.campaignStatus}</span>
                            </div>
                            {/* <div class="col-sm-3"> */}
                            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                              <label
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "600",
                                }}
                              >
                                Start Date :{" "}
                              </label>
                              &nbsp;<span>{reviewlead.startDate}</span>
                            </div>

                            {/* <div class="col-sm-2"> */}
                            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                              <label
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "600",
                                }}
                              >
                                End Date :{" "}
                              </label>
                              &nbsp;<span>{reviewlead.endDate}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                      <br />
                      <hr />

                      {/* <br /> */}

                      <div class="row">
                        {/* className=" table table-bordered table-striped "  */}
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 ">
                          {/*sunita-task-3160-added column as per requirement */}
                          <div>
                            {/*sunita-task-3270-added below changes as per new requirement screen*/}
                            <h5>
                              <span
                                style={{
                                  fontWeight: "bold",
                                  color: "#F28C0F",
                                }}
                              >
                                Offline Lead Review Instructions:-{" "}
                              </span>
                              <br />
                            </h5>
                            <span>
                              1. Click on{" "}
                              <b> "Download Internal Review Leads"</b> to get
                              Lead's File.
                            </span>{" "}
                            <br />
                            <span>
                              2. After reviewing the lead, Please update status
                              as <b>"Accepted" OR "Rejected"</b>
                              in Status column in Lead's File.
                            </span>
                          </div>
                          <div>
                            <span>
                              3. If you rejecting the lead then please{" "}
                              <b> add reason of rejection</b> in reason column
                              in Lead's File.
                            </span>
                            <br />
                            <span>
                              4. Click on <b>"Upload Lead"</b> to upload
                              reviewed Lead's File.
                            </span>
                          </div>
                          {/* Sandeep-task-3652-Edited time save option issue--AQA lead review section  */}
                          <div>
                            <span>
                              5.After click on <b>"Edit"</b> can edit only
                              <b> Job Function,Job Level and Job Title</b>.
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="row"
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                      >
                        {/*sunita-3160-added row and below columns as per requirement */}
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                          <button
                            class="btn add-button"
                            type="button"
                            onClick={this.handleDownloadLeadDetails}
                            style={{
                              backgroundColor: "#056eb8",
                              color: "white",
                              height: "32px",
                              fontFamily: "sans-serif",
                              fontSize: "14px",
                              fontWeight: "400",
                              borderRadius: "4px",
                            }}
                          >
                            <i
                              class="fa fa-cloud-download"
                              aria-hidden="true"
                            />{" "}
                            Download Internal Review Leads
                          </button>
                        </div>

                        <div
                          className="col-xs-12 col-sm-12 col-md-12 col-lg-8 "
                          id="lead_button2"
                          style={{ marginTop: "1px" }}
                        >
                          {/*sunita-3160-added column and css as per requirement */}
                          {/* <p>Instruction :- Click below to upload review file.</p>  */}
                          <label
                            class=" btn btn-defaulttab"
                            style={{
                              height: "32px",
                              fontFamily: "sans-serif",
                              fontSize: "14px",
                              fontWeight: "400",
                              borderRadius: "4px",
                              backgroundColor: "transparent",
                              color: "#193D8F",
                              border: "1px solid #193D8F",
                            }}
                          >
                            Upload Lead
                            <input
                              style={{ display: "none" }}
                              type="file"
                              id="abm1"
                              name="abmFiles"
                              key={this.state.filesData1} //sunita-task-3270-added for file
                              onChange={this.handleChangeFileUpload}
                            />
                          </label>
                          <span id="fileName2">&nbsp;No File Choosen</span>
                          <div className="successMessage">
                            {this.state.errors.uploadsuccess}
                          </div>
                          <div className="errorMessage">
                            {this.state.reerrors.fileError}
                          </div>
                          {/* <Upload  onChange={this.handleupload}>
                        <Button>
                            <Icon type="upload" /> Click to Upload
                        </Button>
                    </Upload> */}
                        </div>
                      </div>

                      <div class="row" style={{ marginBottom: "6px" }}>
                        {/*sunita-task-3160-added row and column as per requirement */}
                        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-9 col-xl-10">
                          <span
                            style={{
                              fontWeight: "bold",
                              marginTop: "115px",
                              color: "#056eb8",
                            }}
                          >
                            Online Lead Review & Instant Status Update:-
                            <br />
                          </span>
                          <span>
                            Total Leads :{this.state.campaignLeadDetails.length}
                          </span>
                          <div
                            style={{
                              fontSize: "18px",
                              color: "green",
                              textAlign: "center",
                            }}
                          >
                            {this.state.errors.leaderror}
                          </div>
                          <div
                            style={{
                              fontSize: "20px",
                              color: "red",
                              textAlign: "center",
                            }}
                          >
                            {this.state.errors.failureMsg}
                          </div>
                        </div>

                        <div
                          className="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-2 submitBtn2"
                          style={{ marginTop: "4px" }}
                        >
                          {" "}
                          {/*sunita-task-3160-addeed column and below button as per requirement */}
                          {/*sunita-task-3181-added below condition for disabling the button when data is not present */}
                          {this.state.buttonFlag === true ||
                            this.state.campaignLeadDetails.length === 0 ||
                            this.state.btnFlag === true ||
                            this.state.errorMsgFlag === false ? ( //shivani-task 3089-added condition for buttonFlag:true to make button disable if i upload leads through excel file.
                            // Sandeep-task-3652-Edited time save option issue--AQA lead review section
                            <button
                              type="button"
                              class="btn add-button"
                              id="buttonTwo"
                              style={{
                                align: "right",
                                paddingBottom: "6px",
                                fontFamily: "sans-serif",
                                fontSize: "14px",
                                fontWeight: "400",
                                borderRadius: "4px",
                              }}
                              disabled
                              className={this.state.btnDisable}
                            >
                              Submit To Advertiser
                            </button>
                          ) : (
                            // type="submit"
                            <button
                              type="button"
                              class="btn add-button"
                              id="buttonTwo"
                              style={{
                                align: "right",
                                paddingBottom: "6px",
                                fontFamily: "sans-serif",
                                fontSize: "14px",
                                fontWeight: "400",
                                borderRadius: "4px",
                              }}
                              className={this.state.btnDisable}
                              onClick={this.submitreviewlead}
                            //Sandeep-task-3516-added submitreviewlead handle change on onClick event
                            >
                              Submit To Advertiser
                            </button>
                          )
                            // type="submit"
                          }
                        </div>
                      </div>
                      {/* Sandeep-task-3659-added code  to show error msg */}
                      <span
                        style={{
                          display: this.state.jobTitleErrMsg2,
                          width: "254px",
                          marginLeft: "4px",
                        }}
                        className="errorMessage"
                      >
                        Please enter valid values for job title
                      </span>
                      <span
                        style={{
                          display: this.state.jobLevelErrMsg2,
                          width: "254px",
                          marginLeft: "4px",
                        }}
                        className="errorMessage"
                      >
                        Please enter valid values for job level
                      </span>
                      <span
                        style={{
                          display: this.state.jobFunctionErrMsg2,
                          width: "254px",
                          marginLeft: "4px",
                        }}
                        className="errorMessage"
                      >
                        Please enter valid values for job function
                      </span>

                      {/* sunita-task-3270-added antd table as per new requirement and removed the normal bootstrap table*/}
                      <div id={this.state.loader2} />
                      {/* Sandeep-task-3341-added code for loader  */}
                      <Table
                        {...this.state.tableState}
                        id="myTable"
                        bordered
                        dataSource={this.state.campaignLeadDetails}
                        onChange={this.onTableChange} //Nilesh-4562- call onTableChange function
                        // columns={this.state.marketingChannelFlag===true?columns3:columns2}
                        columns={
                          this.state.marketingChannelFlag === true
                            ? columns4
                            : columns3
                        }
                        // columns={columns2}
                        //loading={this.state.loading1}
                        // className="ant-table-thead"
                        // className={tableCSS}
                        className={`${tableCSS} "ant-table-thead"`}//Aman-2835-(changed 2 className in One)-Console Warnings - Agency - Advertiser Menu
                        scroll={{ x: 1375, y: 450 }}//Nilesh-5493- Change y axis scroll
                        pagination={{
                          pageSize: this.state.pageSize,
                          position: "bottom",
                        }}
                      />

                      {/* sunita-task-3270-added below code for paginaion */}
                      <div class="row">
                        <div
                          class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
                          style={{
                            marginTop: "-52px",
                            marginLeft: "5px",
                            fontSize: "13px",
                            color: "#4F4F4F",
                            fontFamily: "sans-serif",
                          }}
                        >
                          &nbsp;Records per page:&nbsp;
                          <select
                            defaultValue={this.state.pageSize}
                            onChange={this.handleChangePageSize}
                            id="pacing1"
                            class="input-small"
                            className="form-control"
                            name="pacing"
                            style={{
                              width: "71px",
                              height: "30px",
                              display: "initial",
                              fontSize: "13px",
                              color: "#4F4F4F",
                              fontFamily: "sans-serif",
                            }}
                          >
                            <option value="100" selected>
                              100
                            </option>
                            <option value="200">200</option>
                            <option value="300">300</option>
                          </select>
                        </div>
                      </div>

                      <br />

                      {/* end of col */}
                      {/*sunita-task-3181-added below condition for disabling the button when data is not available */}
                      {this.state.buttonFlag === true ||
                        this.state.campaignLeadDetails.length === 0 ||
                        this.state.btnFlag === true ||
                        this.state.errorMsgFlag === false ? ( //shivani-task 3089-added condition for buttonFlag:true to make button disable if i upload leads through excel file.
                        // Sandeep-task-3652-Edited time save option issue--AQA lead review section
                        <div
                          class="col"
                          align="right"
                          style={{ paddingBottom: "20px" }}
                        >
                          <button
                            class="btn add-button"
                            type="button"
                            disabled
                            className={this.state.btnDisable}
                            style={{
                              fontFamily: "sans-serif",
                              fontSize: "14px",
                              fontWeight: "400",
                              borderRadius: "4px",
                            }}
                          >
                            Submit To Advertiser
                          </button>
                          {/* type="submit" */}
                        </div>
                      ) : (
                        <div
                          class="col"
                          align="right"
                          style={{ paddingBottom: "20px" }}
                        >
                          <button
                            class="btn add-button"
                            type="button"
                            className={this.state.btnDisable}
                            style={{
                              fontFamily: "sans-serif",
                              fontSize: "14px",
                              fontWeight: "400",
                              borderRadius: "4px",
                            }}
                            onClick={this.submitreviewlead}
                          //Sandeep-task-3516-added submitreviewlead handle change on onClick event
                          >
                            Submit To Advertiser
                          </button>
                          {/* type="submit" */}
                        </div>
                      )}

                      <br />
                    </div>
                  </div>
                </div>
                {/* </form> */}
              </Tab>
            ) : (
              ""
            )}
            {/* //shivani-task 3326- changed complete render part for third tab as per given screen . */}
            <Tab
              eventKey={3}
              title="Leads Review By Advertiser"
              style={{ backgroundcolor: "144c9b" }}
            >
              {/* <form name="reviewlead" onClick={this.handleChangeThirdTab}  encType="multipart/form-data"   > */}
              {/* <form name="reviewlead" onSubmit={this.submitreviewlead} encType="multipart/form-data" > */}
              {/* <div class="card card-signin my-1">
                <div class="card-body" > */}

              <div class="row" style={{ marginTop: "15px" }}>
                <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1 ">
                  {/* IoIosArrowDropleftCircle */}
                  {/* {'newdashboard?agencyID=' + this.state.agencyID} */}
                  {/* Sandeep-task-3516-added onClick event on back button */}
                  <a
                    href={"#/"}
                    onClick={this.backButtonHandleChange}
                    style={{ color: "#056eb8" }}
                  >
                    <FaArrowAltCircleLeft
                      size={32}
                      style={{ float: "left" }}
                      title="Back to Dashboard"
                    />
                  </a>
                </div>
                <div class="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-xs-11" style={{ textAlign: 'center' }} > {/*Chaitanya-task-4342-Prod issue-Agency Login-lead review-alignment issue*/}
                  <label
                    style={{
                      fontSize: "28px",
                      fontFamily: "roboto",
                      fontWeight: "500",
                      color: "#193D8F",
                    }}
                    // id="labelleadreview"
                    align="center"
                  >
                    Advertiser Response
                  </label>
                </div>

                <br />
                <br />
              </div>
              <div
                class="card card-signin my-1"
                style={{ marginBottom: "50px" }}
              >
                <div class="card-body" style={{ padding: "5px" }}>
                  <div
                    class="col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 "
                    style={{
                      background: "#FFFFFF",
                      borderRight: "2px solid #eee",
                      padding: "0px",
                    }}
                  >
                    <div>
                      {/* //shivani-task 3206- i have added black color for the label as per @Tanuja's suggetion (it was blue in screen)*/}
                      <center>
                        <label
                          // id="Camp_revw_lab1"
                          className="campLeadHeading1"  //Chaitanya-task-4342-Prod issue-Agency Login-lead review-alignment issue
                          style={{
                            fontSize: "17px",
                            color: "#14254A",
                            marginTop: "10px",
                            fontWeight: "500",
                            fontFamily: "roboto",
                          }}
                        >
                          Campaigns for Lead Review :
                        </label>
                      </center>
                    </div>
                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <span>
                          <input
                            type="text"
                            id="searchLeadId3"
                            name="search"
                            placeholder="Search Campaign"
                            style={{
                              backgroundImage: "url(searchIcon.png)",
                              backgroundPosition: "15px 6px",
                              backgroundSize: "15px 15px",
                              backgroundRepeat: "no-repeat",
                              width: "280px",
                              height: "28px",
                              fontSize: "12px",
                              // float: 'left',
                              // marginLeft: "22px",
                              border: "1px solid #CCCCCC",
                              padding: "12px 20px 15px 36px",
                              backgroundColor: "#FFFFFF",
                              // marginBottom: '5px',
                              fontFamily: "sans-serif",
                              borderRadius: "2px",
                            }}
                            onChange={this.handleChangeSearch2}
                            onPressEnter={this.handleChangeSearch2}
                          />
                        </span>
                      </div>
                    </div>
                    <br />
                    <hr />
                    <div
                      style={{
                        overflowY: "scroll",
                        background: "#FFFFFF",
                        borderRight: "2px solid #eee",
                        padding: "0px",
                        marginTop: "-9px",
                        height: "313px",
                      }}
                    >
                      {/* //id-name-link */}

                      {this.state.tableSearchText2 === "" ? (
                        <div>
                          {this.state.agencyAcceptedLead.map(
                            (agencyAcceptedLead) => (
                              <div
                                style={{
                                  borderBottom: "1px solid #eee",
                                  padding: "5px 15px",
                                }}
                                className="campNames"
                              >
                                <a
                                  href={"#/"} //Aman-2835-(href attribute is required added "#/")-Console Warnings - Agency - Advertiser Menu
                                  id={agencyAcceptedLead.campID}
                                  onClick={this.leadreviewDetailsThird}
                                  className="campclickelli"
                                >
                                  {agencyAcceptedLead.campID} -{" "}
                                  {agencyAcceptedLead.clientCampID} -{" "}
                                  {/* Saurabh – Task- 3726 –Adding Agency campaign ID i.e clientCampID */}
                                  {agencyAcceptedLead.campaignName}{" "}
                                </a>
                              </div>
                            )
                          )}
                        </div>
                      ) : this.state.campaignLeadDetailsSearch.length > 0 ? (
                        <div>
                          {this.state.campaignLeadDetailsSearch.map(
                            (campaignLeadDetailsSearch) => (
                              <div
                                style={{
                                  borderBottom: "1px solid #eee",
                                  padding: "5px 15px",
                                }}
                                className="campNames"
                              >
                                <a
                                  href={"#/"} //Aman-2835-(href attribute is required added "#/")-Console Warnings - Agency - Advertiser Menu
                                  id={campaignLeadDetailsSearch.campID}
                                  onClick={this.leadreviewDetailsThird}
                                  className="campclickelli"
                                >
                                  {campaignLeadDetailsSearch.campID} -{" "}
                                  {campaignLeadDetailsSearch.clientCampID} -{" "}
                                  {/* Saurabh – Task- 3726 –Adding Agency campaign ID i.e clientCampID for Search */}
                                  {campaignLeadDetailsSearch.campaignName}{" "}
                                </a>
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <center>
                          <label
                            style={{
                              color: "red",
                              fontSize: "medium",
                              marginTop: "34px",
                            }}
                          >
                            No Data Exist
                          </label>
                        </center>
                      )}
                    </div>
                  </div>
                  <div
                    className="col-xs-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 "
                    id="for-sm-mb1"
                    style={{ background: "#FFFFFF", paddingTop: "20px" }}
                  >
                    <div>
                      {/* agencyAcceptedLeadByCampID */}
                      {this.state.agencyAcceptedLeadByCampID.map(
                        (agencyAcceptedLeadByCampID) => (
                          <div class="row" style={{ marginTop: "-9px" }}>
                            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                              <label
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "600",
                                  marginRight: "5px",
                                }}
                              >
                                Campaign ID :
                              </label>
                              {agencyAcceptedLeadByCampID.campID}
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                              <label
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "600",
                                  marginRight: "5px",
                                }}
                              >
                                Campaign Name :
                              </label>
                              <span id="nameTruncate">
                                {agencyAcceptedLeadByCampID.campaignName}
                              </span>
                            </div>
                            <br />
                            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                              <label
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "600",
                                  marginRight: "5px",
                                }}
                              >
                                Campaign Status:{" "}
                              </label>
                              {agencyAcceptedLeadByCampID.campaignStatus}
                            </div>
                            <br />

                            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                              <label
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "600",
                                  marginRight: "5px",
                                }}
                              >
                                Start Date :{" "}
                              </label>
                              {agencyAcceptedLeadByCampID.startDate}
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                              <label
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "600",
                                  marginRight: "5px",
                                }}
                              >
                                End Date :{" "}
                              </label>
                              {agencyAcceptedLeadByCampID.endDate}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                    <br />
                    <hr />
                    {/* <div class="row"> */}
                    {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-7 ">
                        <p>
                          <span
                            style={{ fontWeight: "bold", color: "#F28C0F" }}
                          >
                            Instruction :-{" "}
                          </span>
                          Click on <b>"Download Advertiser Response"</b> to get
                          advertiser review file.
                        </p>

                        <button
                          class="btn add-button"
                          type="button"
                          onClick={this.handleClientDownloadLeadDetails}
                          style={{
                            backgroundColor: "#056eb8",
                            color: "white",
                          }}
                        >
                          <i class="fa fa-cloud-download" aria-hidden="true" />{" "}
                          Download Advertiser Response
                        </button>
                      </div> */}
                    {/* <div
                        className="col-xs-12 col-sm-12 col-md-6 col-lg-5 "
                        style={{ paddingBottom: "30px", marginBottom: "75px" }}
                      >
                        <p>
                          <span
                            style={{ fontWeight: "bold", color: "#F28C0F" }}
                          >
                            Instruction :-{" "}
                          </span>
                          Click below to upload review file.
                        </p> */}
                    {/* <div  > */}
                    {/* <label
                          class=" btn btn-defaulttab  "
                          style={{
                            height: "32px",
                            fontFamily: "sans-serif",
                            fontSize: "14px",
                            fontWeight: "400",
                            borderRadius: "4px",
                            backgroundColor: "transparent",
                            color: "#193D8F",
                            border: "1px solid #193D8F",
                            marginRight: "5px",
                          }}
                        >
                          Upload Lead
                          <input
                            style={{ display: "none" }}
                            type="file"
                            id="abm1"
                            name="abmFiles"
                            key={this.state.filesData1}
                            onChange={this.handleChangeClientResponseFileUpload}
                          />
                        </label> */}
                    {/* <span id="clientFilename">&nbsp;No File Choosen</span> */}
                    {/* {JSON.stringify(this.state.errors)} */}

                   {/* Nilesh-4766- Add screen for instructions */}
                    <div class="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 ">
                        
                        <div> 
                          <h5><span style={{ fontWeight: "bold", color: "#F28C0F" }}>Offline Lead Review Instructions :- </span><br /></h5>
                          <span>1. Click on <b>"Download Leads"</b> to get Lead's File.</span><br />
                          <span>2. After reviewing the lead, Please update status as <b> "Accepted" OR "Rejected" </b>
                            in a advertiser's status column in Lead's File.</span>
                        </div>
                        <div>
                          <span>3. If you rejecting the lead then please <b>add reason of rejection</b> in reason column in Lead's File.</span><br />
                          <span>4. Click on <b>"Upload Lead"</b> to upload reviewed Lead's File.</span>
                        </div>
                      </div>
                    </div>
                    <div class="row" style={{ marginTop: '10px', marginBottom: '10px' }}>{/*sunita-task-3160-added row and column as per requirement */}
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                        <button
                          class="btn add-button"
                          type="button"
                          onClick={this.handleClientDownloadLeadDetails}
                          style={{
                            backgroundColor: "#056eb8",
                            color: "white",
                          }}
                        >
                          <i class="fa fa-cloud-download" aria-hidden="true" />{" "}
                          Download Advertiser Response
                        </button>
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 " id="lead_button1" style={{ marginTop: '1px', marginInline: "auto"}}>
                        <label class=" btn btn-defaulttab" style={{ height: '32px', fontFamily: 'sans-serif', fontSize: '14px', fontWeight: '400', borderRadius: '4px', backgroundColor: 'transparent', color: '#193D8F', border: '1px solid #193D8F' }}>Upload Lead
                          <input
                            style={{ display: "none" }}
                            type="file"
                            id="abm1"
                            name="abmFiles"
                            key={this.state.filesData1}
                            onChange={this.handleChangeClientResponseFileUpload}
                          />
                        </label>
                        <span id="clientFilename"> No File Choosen</span>
                        {/* <div className="successMessage">{this.state.errors.successMessage}</div>
                          <div className="errorMessage">{this.state.errors.errorMessage}</div> */}
                        {/* <Upload  onChange={this.handleupload}>
                        <Button>
                            <Icon type="upload" /> Click to Upload
                        </Button>
                    </Upload> */}
                     <div className="successMessage">
                      {this.state.errors.clientuploadsuccess}
                    </div>
                    <div className="errorMessage">
                      {this.state.reerrors.clientfileError}
                    </div>
                    <div className="errorMessage">
                      {this.state.reerrors.clientfileError1}
                    </div>
                      </div>
                    </div>
                    {/* <div className="successMessage">
                      {this.state.errors.clientuploadsuccess}
                    </div>
                    <div className="errorMessage">
                      {this.state.reerrors.clientfileError}
                    </div>
                    <div className="errorMessage">
                      {this.state.reerrors.clientfileError1}
                    </div> */}

                    {/* <div class="row" style={{ marginBottom: '6px' }}>
                      <div className="col-xs-12 col-sm-12 col-md-9 col-lg-10 col-xl-10" >
                        <span style={{ fontWeight: "bold", marginTop: '115px', color: "#056eb8" }}>Online Lead Review & Instant Status Update:-<br />
                        </span>
                        <span>Total Leads : {this.state.campaignLeadDetails.length}</span>
                      </div>
                    </div> */}
                    {/* </div> */}
                    <div>
                          <span>
                           {/* Nilesh-4785-Showing respective camp total leads count */}
                            Total Leads :{this.state.thirdTabLeadsCount.length}
                          </span>
                    </div>
                  </div>

                  <div
                    style={{
                      fontSize: "18px",
                      color: "red",
                      textAlign: "center",
                    }}
                  >
                    {this.state.errors.failureMsg2}
                  </div>
                </div>
              </div>
              {/* </div> */}
              {/* </form> */}
              {/* <hr/> */}
            </Tab>
          </Tabs>
        </div>

        <Footer />
      </div>
    );
  }
}
/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
AgencyReviewlead.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(withRouter(AgencyReviewlead));
