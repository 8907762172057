/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*
@author:Sanobar Golandaj
@Creation Date:12-09-2019
@Description:UI for (Dashboard) campaign specification
*/

import React, { Component } from "react";
// import Navigation from '../layouts/navPage';
import PublisherNavigation from "../layouts/publisherNavPage";
import Footer from "../layouts/footer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import CampaignSpecificationPublisherDashboardDetails from "./campaignSpecificationPublisherDashboardDetails";
// import { Alert } from "antd";//kiran-4746-removing console warning-no used
import { fetch_custom as fetch } from "../../configration/fetch_default_headers";//snehal-task-3766-specification VAPT api call
// import { json } from "body-parser";
// const queryString = require("query-string");
// var greeting = "";
// var abmFile = [];
// var suppressionFile = [];
// var execlusionFile = [];
// var otherFile = [];
// var countryArray = [];
// var jobLevelArray = [];
// var jobFunctionArray = [];
// var employeeSizeArray = [];
// var companyRevenueArray = [];//kiran-4746-removing console warning-no used
class CampaignSpecificationPublisherDashboard extends Component {
	constructor() {
		super();
		this.state = {
			campID: "",
			parentCampID: "",
			reallocationID: "",
			pID: "",
			cancelTab: "",
			tab: "", //sunita-task-3104-added for getting tab value
			campaignDetails: [], //sandeep-task-3064 added campaignDetails array
			isLeadUploadButtonDisable: false, //sandeep-task-3064 added isLeadUploadButtonDisable
			button1: false,
		};
		this.handleBackButtonToDashboard =this.handleBackButtonToDashboard.bind(this);
		this.handleFileDownload = this.handleFileDownload.bind(this);
		this.handleFileShowDownload = this.handleFileShowDownload.bind(this);
		this.handlePDFFileShowDownload = this.handlePDFFileShowDownload.bind(this);
		this.handleLeadUpload = this.handleLeadUpload.bind(this); //sandeep-task-3064-added handleLeadUpload function
	}
	//sonali-4032-componentDidMount changed to will mount
	componentWillMount() {
	
		if (!this.props.auth.isAuthenticated) {
			this.props.history.push("/userLogin");
		} else {
				//Sonali-3945-VAPT-accessing parameters from props.location
				var campIDTemp;//sonali-4032--storing campID in temp variable as it is giving error in set state
				if (this.props.location.state !== undefined) {
					const {
						cancelKey,campID,parentCampID,reallocationID,leadkey,tab
					} = this.props.location.state;
					campIDTemp=campID;//sonali-4032--storing campID in temp variable as it is giving error in set state
					this.setState({cancelKey,campID,parentCampID,reallocationID,leadkey,tab,key3:leadkey})
				
				}else if (sessionStorage.getItem("campID") !== undefined) {//sonali-4032--addin session variables
					
					var campID = sessionStorage.getItem("campID");
					var parentCampID = sessionStorage.getItem("parentCampID");
					var reallocationID = sessionStorage.getItem("reallocationID");
					campIDTemp=campID;
					this.setState({campID,parentCampID,reallocationID})
				  } else {
					if (sessionStorage.getItem("parentCampID") === undefined) {
					  sessionStorage.clear();
					}
				  }
				//Sonali-4032-commenting below code
			//var parsed = queryString.parse(this.props.location.search);
			// var campID = this.props.location.state.campID;//Sonali-3945-VAPT-accessing parameters from props.location
			// var cancelTab = this.props.location.state.cancelKey;//Sonali-3945-VAPT-accessing parameters from props.location
			// var parentCampID = this.props.location.state.parentCampID;//Sonali-3945-VAPT-accessing parameters from props.location
			// var reallocationID = this.props.location.state.reallocationID;//Sonali-3945-VAPT-accessing parameters from props.location
			// //shivani-task 3204 - passed this key to url for camp spec page back button when user will redirected from publisherDeliverLead .
			// var key3 = this.props.location.state.leadkey;//Sonali-3945-VAPT-accessing parameters from props.location
			// this.setState({ key3: key3 });
			// 	//Sonali-3945-VAPT-accessing parameters from props.location
			// 	var tab = this.props.location.state.tab; //sandeep-task-3064-getting tab value form url
		
			const {  user } = this.props.auth; //kiran-4746-removing console warning-no used
			var pID = user.id;
			
			this.setState({  pID }); //sunita-task-3104-added for getting updated value
			let isLeadUploadButtonDisable = false; //sunita-task-3104-used this variable for disabling or enabling the upload lead button
			let data = { campID: campIDTemp//Sonali-4032-setting campIDTemp to campID
				//pID: pID 
			}; //sunita-task-3104-pass data as per BE requirement.
		
			//sonali-3945-removing pID from above data
			fetch("agencyCreativeReviewBE/checkCreativeApproval", {
				//sunita-task-3104-as per requirement called this api for getting values required for upload lead button
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((campaignDetails) => {
				
					//sunita-task-3104-below all conditions used for enabling or disabling the upload lead button
					if (
						campaignDetails[0].status === "LP_Pending" &&
						campaignDetails[0].isApproveCreative === false &&
						campaignDetails[0].campaignStatus !== "Cancel Campaign"
					) {
						isLeadUploadButtonDisable = true;
					} else if (
						campaignDetails[0].status === "Accept" &&
						campaignDetails[0].isApproveCreative === true &&
						campaignDetails[0].campaignStatus !== "Cancel Campaign"
					) {
						isLeadUploadButtonDisable = false;
					}
					//shivani-task 3162- to make lead upload button disabled when there is  no creative upload and approved.
					else if (
						campaignDetails[0].creativeApprovalRequiredFor === "Advertiser" &&
						campaignDetails[0].marketingChannel === "Email/Telemarketing" &&
						campaignDetails[0].status === "Cancel" &&
						campaignDetails[0].isApproveCreative === false &&
						campaignDetails[0].campaignStatus !== "Cancel Campaign"
					) {
						isLeadUploadButtonDisable = true;
					} else if (
						campaignDetails[0].creativeApprovalRequiredFor === "Agency" &&
						campaignDetails[0].marketingChannel === "Email/Telemarketing" &&
						campaignDetails[0].status === "Cancel" &&
						campaignDetails[0].isApproveCreative === false &&
						campaignDetails[0].campaignStatus !== "Cancel Campaign"
					) {
						isLeadUploadButtonDisable = true;
					} else {
						//Aman-5037 added condtion to enable button for allocating the Completed campaign to live 
						if (
							campaignDetails[0].campaignStatus === "Active" &&
							campaignDetails[0].isApproveCreative === true
						) {
							isLeadUploadButtonDisable = false;
						}else{
						//sunita-task-3358- uncommented the below condition-as it's failing for normal campaign when i created the campaign without channel,call audit and creative.
						isLeadUploadButtonDisable = true; //shivani-task 3162- Commented this to make button enable for normal campaigns( without selecting any creative approvals) & both the conditions as mentioned above are working....
						}
					}

					this.setState({
						campaignDetails: campaignDetails,
						isLeadUploadButtonDisable,
					}); //sunita-task-3104 -for getting updated values.
				});

			this.setState({
				pID: pID,
				parentCampID: parentCampID,
				reallocationID: reallocationID,
			});

		//Sonali-4065-passing the campID Temp as campID is undefined
			fetch("campaign/CampaignDetails?campID=" + campIDTemp)
				.then((res) => res.json())
				.then((campaignDetails) => {
					this.setState({ campaignName: campaignDetails[0].campaignName });
				})
				.catch(function (err) {
					console.log(err);
				});
		}
	}

	handleBackButtonToDashboard(e) {
		////shivani-task 3105-Added condition by parsing key to nevigate user on correct url according to conditions.////
		e.preventDefault();

		
		//var parsed = queryString.parse(this.props.location.search);
			//Sonali-3945-VAPT-accessing parameters from location.state

		var key = this.state.key;
		var campID = this.state.campID; //sandeep-task-3095-added URL parameter campID
		var parentCampID = this.state.parentCampID; //sandeep-task-3095-added URL parameter parentCampID
		var reallocationID = this.state.reallocationID;
		//shivani-task 3204 - passed this key to url for camp spec page back button when user will redirected from publisherDeliverLead .
		var key3 = this.state.leadkey;
		this.setState({
			campID: campID,
			parentCampID: parentCampID,
			reallocationID: reallocationID,
			key3: key3,
		});

		if (key === "Assign" || key === "Counter") {
			this.props.history.push("/newPublisherDashboard"); //sonali-task-3945-replace query params
		}
		//shivani-task 3204 - added this url for camp spec page back button when user will redirected from publisherDeliverLead .
		else if (key3 === "lead") {
			this.props.history.push("/publisherDeliverLead",{campID:campID,parentCampID:parentCampID,reallocationID:reallocationID}); //karan-task-3717-replace query params

			// window.location.href =
			// 	"/publisherDeliverLead?campID=" +
			// 	campID +
			// 	"&parentCampID=" +
			// 	parentCampID +
			// 	"&reallocationID=" +
			// 	reallocationID;
		} else {
			this.props.history.push("/publisherCampaignList"); //karan-task-3717-replace query params
		}
	}

	/**
	 * @author Sandeep Dhawale
	 * @param  Description handle the publisherDeliverLead
	 * @return Description return  onclick it redirect to publisherDeliverLead
	 */

	handleLeadUpload(e) {
		//var parsed = queryString.parse(this.props.location.search);
		var campID = this.props.location.state.campID; //sandeep-task-3064-added URL parameter campID
		var parentCampID = this.props.location.state.parentCampID; //sandeep-task-3064-added URL parameter parentCampID
		var reallocationID = this.props.location.state.reallocationID; //sandeep-task-3064-added URL parameter reallocationID

		this.props.history.push("/publisherDeliverLead",{campID:campID,parentCampID:parentCampID,reallocationID:reallocationID}); //sonali-task-3716-replace query params

		// window.location.href =
		// 	"/publisherDeliverLead?campID=" +
		// 	campID +
		// 	"&parentCampID=" +
		// 	parentCampID +
		// 	"&reallocationID=" +
		// 	reallocationID;
	}

	/**
	 * @author Somnath Keswad
	 * @param  Description handle the Campaign Modal Details PDF
	 * @return Description return All steps campaign Details PDF
	 */
	handleFileDownload(e) {
		var campID = this.state.campID;
		// let downloadFileName = campID + "-CampaignSpecification.pdf";
		// var countryName = countryArray.join("");
		//var jobLevel = jobLevelArray.join("");
		//var companyRevenue = companyRevenueArray.join("");
		// var jobFunction = jobFunctionArray.join("");
		// var employeeSize = employeeSizeArray.join("");
		// var abmFileName = abmFile.join("");
		// var suppressionFileName = suppressionFile.join("");
		// var execlusionFileName = execlusionFile.join("");
		// var otherFileName = otherFile.join(""); //kiran-4746-removing console warning-no used
 
		/**
		 * @author Narendra Phadke
		 * @param  Description handle the Campaign related Files
		 * @return Description return All The File Download in Zip
		 */

		var formData = new FormData();
		formData.append("campID", campID);
		//const {  user } = this.props.auth;
		//var userID = user.id;//kiran-4746-removing console warning-no used

		//This api is used for dynamic pdf genration
		//sonali-3945-removing userID from follwing route call
		fetch(
			"publisher/downloadDynamicPDFCampaignDetailsforPublisher?campID=" +
				campID +
				"&campName=" +
				this.state.campaignName +
				"&cancelTab=" +
				this.state.cancelKey//sonali-4113-replaced cancelTab by cancelKey
		)
			.then((r) => r.blob())
			.then((downloadFiles) => this.setState({ downloadFiles: downloadFiles }))
			.then(this.handlePDFFileShowDownload);
			//Sonali-3945-removing userID from following route call
		fetch(
			"publisher/downloadCampaignDetails?campID=" +
				campID +
				"&campName=" +
				this.state.campaignName 
		)
			.then((r) => r.blob())
			.then((downloadFiles) => this.setState({ downloadFiles: downloadFiles }))
			.then(this.handleFileShowDownload);
	} /** End of handle File Download */

	handleFileShowDownload(blob) {
		// It is necessary to create a new blob object with mime-type explicitly set
		// otherwise only Chrome works like it should
		// Chaitanya-5540-Changed this.state.parentCampID to this.state.campID

		let zipFileName =
			this.state.campID +
			"-" +
			this.state.reallocationID +
			"-" +
			this.state.campaignName +
			".zip";

		var newBlob = new Blob([this.state.downloadFiles], {
			type: "application/zip",
		});
		// IE doesn't allow using a blob object directly as link href
		// instead it is necessary to use msSaveOrOpenBlob
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(newBlob);
			return;
		}

		// For other browsers:
		// Create a link pointing to the ObjectURL containing the blob.
		const data = window.URL.createObjectURL(newBlob);
		var link = document.createElement("a");
		link.href = data;
		link.download = zipFileName;
		link.click();
	}

	/**
	 * @author Narendra Phadke
	 * @param  Description handle the Campaign Specification Dynamic PDF Details
	 * @return Description return pdf file campaign details
	 */
	handlePDFFileShowDownload(blob) {
		// It is necessary to create a new blob object with mime-type explicitly set
		// otherwise only Chrome works like it should
		// Chaitanya-5540--Changed this.state.parentCampID to this.state.campID

		let zipFileDownloadName =
			this.state.campID +
			"-" +
			this.state.reallocationID +
			"-" +
			this.state.campaignName +
			"-Campaign Specification.pdf";

		var newBlob = new Blob([this.state.downloadFiles], {
			type: "application/pdf",
		});

		// IE doesn't allow using a blob object directly as link href
		// instead it is necessary to use msSaveOrOpenBlob
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(newBlob);
			return;
		}

		// For other browsers:
		// Create a link pointing to the ObjectURL containing the blob.
		const data = window.URL.createObjectURL(newBlob);
		var link = document.createElement("a");
		link.href = data;
		link.download = zipFileDownloadName;
		link.click();
	}

	render() {
	//Sanjana Godbole-3728-VAPT--publisher side--Home--URL links And Routes-FE
	//Sonali-4032-getting parameters from session strorage
	if(this.props.location.state!==undefined ){
		this.state.campID=this.props.location.state.campID;
		this.state.parentCampID=this.props.location.state.parentCampID
		this.state.reallocationID=this.props.location.state.reallocationID;
		}
		else if (sessionStorage.getItem("campID") !== undefined) {
		  this.state.campID = sessionStorage.getItem("campID");
		  this.state.parentCampID = sessionStorage.getItem("parentCampID");
		  this.state.reallocationID = sessionStorage.getItem("reallocationID");
		}
	


		const { user } = this.props.auth; //kiran-4746-removing console warning-no used

		return (
			<div>
				<PublisherNavigation />
				{/* Nilesh-4839 Add display, flexDirection,flex, backgroundColor, paddingTop, position, top and zIndex in below div */}
				<div style={{ paddingTop: "110px", display: "flex", flexDirection: "column" }}>
					<div class="container-fluid fixed-top"style={{
						flex: "0 0 auto",
						backgroundColor:"rgb(245 245 245)",
						paddingTop: "10px",
						position: "sticky",
						top: "79px",
						zIndex: "999"
					}}>
						<div class="row" style={{ paddingBottom: "20px" }}>
							<div class="col-lg-4">
								{/*sunita-task-3104-changed as per alignment */}
								{/* kiran-4746-removing console warning-added "#/" */}
								<a href="#/" onClick={this.handleBackButtonToDashboard}>
									<FaArrowAltCircleLeft
										size={32}
										style={{ float: "left", color: "#056eb8" }}
										title="Back to publisherCampaignList"
									/>
								</a>{" "}
								{/* //shivani-task 3105-added onClick function to nevigate on correct url. */}
							</div>

							<div class="col-lg-4">
								{/* snehal-task-3561-Reff(3428)----spec and cust mapping format issue  */}
								<label
									style={{
										color: "#193D8F",
										fontSize: "28px",
										paddingLeft: "100px",
										fontWeight: "500",
										fontFamily: "roboto",
										//paddingLeft: "120px", {/* kiran-4746-removing console warning-remove duplicate attribute */}
									}}>
									Campaign ID:{this.state.campID}
									{/* Chaitanya-5540-Changed this.state.parentCampID to this.state.campID*/}
								</label>
							</div>

							<div class="col-lg-4">
							{/* kiran-4746-removing console warning-changed == to === */}
								{this.state.tab === "Active" ? (
									// Sandeep-task-3266-added code to disable Lead Upload button for PQA
									user.role !== "PQA" ? (
										//sunita-task-3104-used when it gets tab active as per that upload lead button will show
										<div class="col-lg-3 offset-6">
											{/*sunita-task-3104-changed as per alignemnt */}
											<button
												type="button"
												class="btn add-button"
												id="cmd"
												onClick={this.handleLeadUpload}
												disabled={this.state.isLeadUploadButtonDisable}>
												Lead Upload
											</button>
										</div>
									) : (
										<div class="col-lg-3 offset-6">
											{/* Sandeep-task-3266-added code to disable Lead Upload button for PQA  */}
											<button
												type="button"
												class="btn add-button"
												id="cmd"
												onClick={this.handleLeadUpload}
												disabled>
												Lead Upload
											</button>
										</div>
									)
								) : (
									""
								)}
								{/* kiran-4746-removing console warning-changed == to === */}
								{this.state.tab === "Active" ? ( //sunita-task-3104-used when it gets tab active as per that download button will show
									<div class="col-lg-3">
										<button
											type="button"
											class="btn add-button"
											id="cmd"
											onClick={this.handleFileDownload}
											style={{ marginLeft: "5px" }}>
											Download
										</button>
									</div>
								) : (
									<button
										type="button"
										class="btn add-button"
										id="cmd"
										onClick={this.handleFileDownload}
										style={{ float: "right" }}>
										Download
									</button>
								)}
							</div>
						</div>
					</div>

{/* Sonali-4113-passing all keys to details page */}
					<CampaignSpecificationPublisherDashboardDetails
						greeting={this.state.campID}
						parentCampID={this.state.parentCampID}
						cancelKey={this.state.cancelKey}
						leadkey={this.state.parentCampID}
						tab={this.state.tab}
						key3={this.state.key3}
					/>

					<div class="container-fluid" style={{ paddingBottom: "70px" }}>
						<div class="row">
							<div class="col-lg-5" style={{ paddingTop: "20px" }}>
								<button
									type="button"
									class="btn add-button"
									id="cmd"
									style={{ float: "left" }}
									onClick={this.handleFileDownload}>
									Download
								</button>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
CampaignSpecificationPublisherDashboard.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(
	withRouter(CampaignSpecificationPublisherDashboard)
);
