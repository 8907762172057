/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
import React from "react";
//import { BrowserRouter as Router, Route, Link } from "react-router-dom";  //Chaitanya-4751-Removed Console Warning
//import { DropdownMenu, MenuItem, Card } from "react-bootstrap-dropdown-menu"; //Chaitanya-4751-Removed Console Warning

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
//import Footer from "../layouts/footer"; //Chaitanya-4751-Removed Console Warning
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; //Akash-bug-4139
// const queryString = require("query-string");//Somnath Task-3936, Remove unused require
import { Tooltip } from "antd"; // Aman -Task-4724- (added this Tooltip CLIENT SWITCH in nav bar)- AC/ANC/AQA-Login - Client Switch option

class Navigation extends React.Component {
  constructor() {
    super();
    this.state = {
      tabkey1: "3",
      agencyID: "",
      data: [],
      oldvalue: this.filterData,
    };
  }
  createCampaignInDetails(e) {
    e.preventDefault();

    window.location.href = "/welcomePage";
    localStorage.removeItem("activeKey");
  }
  /**
   * @author Narendra Phadke
   * @param  Description handle the logout authentication
   * @return Description return All details of authentication
   */
  pendingAllocation(e) {
    window.location.reload();
    window.location.href = "/allocatingCampaign#3";
    localStorage.removeItem("activeKey");
  }

  /**
   * @author Supriya Gore
   * @param  Description handle the incomplete Campaign Tab
   * @return Description return All details of incomplete Campaign
   */
  incompleteCampaign(e) {
    window.location.reload();
    window.location.href = "/incompleteCampaignTab";
    localStorage.removeItem("activeKey");
  }

  /**
   * @author Narendra Phadke
   * @param  Description handle the ABM upgrade
   * @return Description return All details of ABM upgrade
   */
  abmUpgrade(e) {
    window.location.reload();
    window.location.href = "/domainName";
    localStorage.removeItem("activeKey");
  }

  createXLSCampaign(e) {
    window.location.reload();
    window.location.href = "/createXLSCampaign";
    localStorage.removeItem("activeKey");
  }

  onLogout(e) {
    e.preventDefault();
    //Sonali-3809-logout from LiByAi page--placing removeCookies function befor userLogout
    fetch("/removeCookie", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((response) => {})
      .catch((e) => console.log(e)); //Somnath Task-3936, Add catch block to catch error

    //const { isAuthenticated, user } = this.props.auth;  //Chaitanya-4751-Removed Console Warning
    let data = {
      //user: user, //Akash-bug-4139 removed user
    };
    fetch("users/userLogout", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {})
      .catch((e) => console.log(e)); //Somnath Task-3936, Add catch block to catch error

    this.props.logoutUser(this.props.history);
    localStorage.removeItem("activeKey");
  }

  onHome(e) {
    e.preventDefault();
    const { user } = this.props.auth; //Chaitanya-4751-Removed Console Warning
    this.props.history.push("/newdashboard", { agencyID: user.id }); //karan-task-3684-replace query params
    localStorage.removeItem("activeKey");
  }

  //Aman-task-4724-(Added this function to navgate one to other page)-AC/ANC/AQA-Login - Client Switch option
  onClientSwitch(e) {
    e.preventDefault();
    this.props.history.push("/SwitchClient");
  }

  //Chaitanya-5426-Added this function to navigate DC dashboard
  onDCHome(e) {
    e.preventDefault();
    const { user } = this.props.auth;
    //Priyanka-5465-Changed page name
    this.props.history.push("/qualityDashboard", { agencyID: user.id }); //snehal-task-3768-vAPT URL changes
    localStorage.removeItem("activeKey");
  }
  //Chaitanya-5426-Added this function to navigate DC Review
  onDCReview(e) {
    e.preventDefault();
    const { user } = this.props.auth;
    //Priyanka-5465-Changed page name
    this.props.history.push("/qualityLeadsReview", { agencyID: user.id }); //snehal-task-3768-vAPT URL changes
    localStorage.removeItem("activeKey");
  }

  onHome1(e) {
    e.preventDefault();
    const { user } = this.props.auth; //Chaitanya-4751-Removed Console Warning
    this.props.history.push("/advertiserDashboard", { advertiserID: user.id }); //snehal-task-3768-vAPT URL changes
    localStorage.removeItem("activeKey");
  }

  onEmailConfigAgency(e) {
    e.preventDefault();
    //const { isAuthenticated, user } = this.props.auth;  //Chaitanya-4751-Removed Console Warning
    this.props.history.push("/agencyEmailConfig"); // karan-task-3723-replace query params
    localStorage.removeItem("activeKey");
  }

  onAddUser(e) {
    e.preventDefault();
    //const { isAuthenticated, user } = this.props.auth;  //Chaitanya-4751-Removed Console Warning
    this.props.history.push("/addUserAgency"); // karan-task-3723-replace query params
    localStorage.removeItem("activeKey");
  }
  onHelpOption(e) {
    e.preventDefault();
    //const { isAuthenticated, user } = this.props.auth;  //Chaitanya-4751-Removed Console Warning
    this.props.history.push("/helpOption"); // karan-task-3723-replace query params
  }

  onAgencyInformation(e) {
    e.preventDefault();
    //const { isAuthenticated, user } = this.props.auth;  //Chaitanya-4751-Removed Console Warning
    this.props.history.push("/agencyInformation"); // karan-task-3723-replace query params
    localStorage.removeItem("activeKey");
  }

  onAddUserAdvertiser(e) {
    e.preventDefault();
    //const { isAuthenticated, user } = this.props.auth;  //Chaitanya-4751-Removed Console Warning
    // window.location.href = "/addUserAdvertiser?advertiserID=" + user.id;
    this.props.history.push("/addUserAdvertiser"); // kiran- task 3867- VAPT-Advertiser side-adv dashboard and specification -URL links & API -FE
    localStorage.removeItem("activeKey");
  }
  advertiserInfo(e) {
    e.preventDefault();
    //const { isAuthenticated, user } = this.props.auth;  //Chaitanya-4751-Removed Console Warning
    // window.location.href = "/advertiserInformation?advertiserID=" + user.id;
    this.props.history.push("/advertiserInformation"); //kiran- task 3867- VAPT-Advertiser side-adv dashboard and specification -URL links & API -FE
    localStorage.removeItem("activeKey");
  }

  onEmailConfigAdvertiser(e) {
    e.preventDefault();
    //const { isAuthenticated, user } = this.props.auth;  //Chaitanya-4751-Removed Console Warning
    // window.location.href = "/advertiserEmailConfig?advertiserID=" + user.id;
    this.props.history.push("/advertiserEmailConfig"); //kiran- task 3867- VAPT-Advertiser side-adv dashboard and specification -URL links & API -FE
    localStorage.removeItem("activeKey");
  }

  onChangePasswordAgency(e) {
    e.preventDefault();
    //const { isAuthenticated, user } = this.props.auth;  //Chaitanya-4751-Removed Console Warning
    this.props.history.push("/changePasswordAgency"); // karan-task-3723-replace query params
    localStorage.removeItem("activeKey");
  }

  onChangePasswordAdvertiser(e) {
    e.preventDefault();
    //const { isAuthenticated, user } = this.props.auth;  //Chaitanya-4751-Removed Console Warning
    // window.location.href = "/changePasswordAdvertiser?advertiserID=" + user.id;
    this.props.history.push("/changePasswordAdvertiser"); //kiran- task 3867- VAPT-Advertiser side-adv dashboard and specification -URL links & API -FE
    localStorage.removeItem("activeKey");
  }

  onChangeAgencyInvoiceList(e) {
    e.preventDefault();
    //const { isAuthenticated, user } = this.props.auth;  //Chaitanya-4751-Removed Console Warning
    this.props.history.push("/agencyInvoicesList"); ////Akash-task-3722-VAPT-Agency side---Finance Invoice--URL lINKS & API-FE
    localStorage.removeItem("activeKey");
  }

  uploadSuppression(e) {
    e.preventDefault();
    //const { isAuthenticated, user } = this.props.auth;  //Chaitanya-4751-Removed Console Warning
    this.props.history.push("/uploadCommonSuppression"); // karan-task-3723-replace query params
  }
  /**
   * @author : Chaitanya Shinde
   * @description : task-3982-add custom region
   * @return : redirects to /addCustomRegion route
   */
  addCustomCountry(e) {
    e.preventDefault();
    //const { isAuthenticated, user } = this.props.auth;  //Chaitanya-4751-Removed Console Warning
    this.props.history.push("/addCustomRegion"); //chaitanya-task-3982-add custom region
  }
  /**
   * @author : Karan Jagtap
   * @description : task-3521- Used to navigate to RFP Dashboard page
   * @return : redirects to /rfpDashboard route
   */
  rfpDashboard(e) {
    e.preventDefault(); //Somnath Task-3936, Add preventDefault method
    this.props.history.push("/rfpDashboard"); //karan-task-3803-replace query params
  }

  /**
   * @author : Karan Jagtap
   * @description : task-3636- Used to navigate to RFP Progress page
   * @return : redirects to /rfpProgress route
   */
  rfpProgress(e) {
    e.preventDefault(); //Sonali Task-4030, Add preventDefault method
    this.props.history.push("/rfpProgress"); //karan-task-3803-replace query params
  }

  // rfpCreate(e) {
  // 	e.preventDefault();
  // //Somnath Task-3936, Add URL in history Object
  // this.props.history.push(`/requestForProposal2`)
  // }

  rfpCreate(e) {
    e.preventDefault();
    const { user } = this.props.auth; //Chaitanya-4751-Removed Console Warning
    this.props.history.push("/requestForProposal2", { agencyID: user.id }); //sonali-task-3716-replace query params
  }

  rfpExsisting(e) {
    e.preventDefault();
    const { user } = this.props.auth; //Chaitanya-4751-Removed Console Warning
    // window.location.href =
    // 	"/requestForProposalExsisting?agencyID=" +
    // 	user.id +
    // 	"&fromDashBoard=true";
    this.props.history.push("/requestForProposalExsisting", {
      agencyID: user.id,
      fromDashBoard: true,
    }); //sonali-task-3716-replace query params
  }

  //shivani-3517-added jobLevel Mappping option.
  onJobLevelMapping(e) {
    e.preventDefault();
    //const { isAuthenticated, user } = this.props.auth;  //Chaitanya-4751-Removed Console Warning
    this.props.history.push("/jobLevelMapping"); //karan-task-3803-replace query params
    localStorage.removeItem("activeKey");
  }
  rfpAnalysis(e) {
    e.preventDefault();
    const { user } = this.props.auth; //Chaitanya-4751-Removed Console Warning
    this.props.history.push("/rfpAnalysis", { agencyID: user.id }); //sonali-task-3716-replace query params
  }
  /**
   * @author Snehal More
   * @param  Description createXLSRfp
   * @return Description redirect to createXLSRfp page(//* snehal-task-3509Project's(DI) - Create RFP through XLS - FE )
   */

  // createXLSRfp(e) {
  // 	// const {  user } = this.props.auth;
  // 	window.location.reload();
  // 	window.location.href = "/createXLSRfp";//Somnath Task-3936, Remove agencyID from URL
  // }

  createXLSRfp(e) {
    e.preventDefault();
    const { user } = this.props.auth; //Chaitanya-4751-Removed Console Warning
    this.props.history.push("/createXLSRfp", { agencyID: user.id }); //sonali-task-3716-replace query params
  }

  customizeMappin(e) {
    e.preventDefault();
    //const { isAuthenticated, user } = this.props.auth;  //Chaitanya-4751-Removed Console Warning
    this.props.history.push("/customMapping"); // karan-task-3723-replace query params
  }

  onCampaignProgress(e) {
    e.preventDefault();
    const { user } = this.props.auth; //Chaitanya-4751-Removed Console Warning
    this.props.history.push("/campaignList", { agencyID: user.id }); //karan-task-3684-replace query params
    // Aman - 4566 - Prod Issue- AC/PC-Campaign Progress- All tabs - Search button - page gets hang
    //window.location.href = "/campaignList"; //mufiz-tak-4349-prod issue-Agency side -campaign progress--page issue
    window.location.reload(); //4349-Nilesh page refresh
  }
  /**
   * @author Shivani Pathak - 3326
   * @param  Description handle the url .
   * @return It will nevigate to agencyReviewLead with 3  rd tab .
   */
  forAdvertiserResponse(e) {
    const { user } = this.props.auth;
    var agencyID = user.id;
    var key = this.state.tabkey1;
    this.setState({ agencyID, key });
    this.props.history.push("/agencyReviewLead", {
      agencyID,
      tabkey: key,
    }); //karan-task-3715-replace query params
  }

  /**
   * @author : Karan Jagtap
   * @description : task-3648- Used to navigate to Add Publisher Page
   * @return : redirects to /addPublisherRequest route
   */
  onAddPublisherClick = (e) => {
    e.preventDefault();
    this.props.history.push("/addPublisherRequest"); // karan-task-3723-replace query params
  };

  /**
   * @author : Karan Jagtap
   * @description : task-3515- Used to navigate to Advertiser RFP Reminder Setup
   * @return : redirects to /rfpReminderSetup route
   */
  onRFPReminderSetup = (e) => {
    window.location.href = "/rfpReminderSetup";
  };
  /**
   * @author : Rutuja Jagtap
   * @description : task-4517- Used to navigate to ADD AQA role
   * @return : redirects to /addAQA route
   */
  addAQARole(e) {
    e.preventDefault();
    //const { isAuthenticated, user } = this.props.auth;  //Chaitanya-4751-Removed Console Warning
    //Priyanka-5469-changed path from addAQARole to addUserAgency
    this.props.history.push("/addUserAgency"); // karan-task-3723-replace query params
  }

  //4466-Nilesh Added new option in setting dropdown
  onLinkCampaign(e) {
    e.preventDefault();
    //const { isAuthenticated, user } = this.props.auth;  //Chaitanya-4751-Removed Console Warning
    this.props.history.push("/linkCampaign");
    localStorage.removeItem("activeKey");
  }
  //Chaitanya-4972-Used to navigate to Reviewed Leads File Report for DC login
  onDCReport(e) {
    e.preventDefault();
    this.props.history.push("/reviewedLeadsFileReport");
    localStorage.removeItem("activeKey");
  }
  //Chaitanya-5074-Used to navigate to Reviewed Leads File Report for DIQA login
  onDiqaReport(e) {
    e.preventDefault();
    this.props.history.push("/diqaReviewedLeadsFileReport");
    localStorage.removeItem("activeKey");
  }
  /**
   * @author : Rutuja Jagtap
   * @description : task-4975- Used to navigate to email configuration page at DIQA
   * @return : redirects to /diqaEmailConfig page
   */
  onEmailConfigDIQA(e) {
    e.preventDefault();
    //const { isAuthenticated, user } = this.props.auth;  //Chaitanya-4751-Removed Console Warning
    // window.location.href = "/advertiserEmailConfig?advertiserID=" + user.id;
    this.props.history.push("/diqaEmailConfig"); //kiran- task 3867- VAPT-Advertiser side-adv dashboard and specification -URL links & API -FE
    localStorage.removeItem("activeKey");
  }

  /**
   * @author : Chaitanya Shinde - 5043
   * @description : Used for add User DIQA at DIQA TL
   * @return : redirect to addUserDIQA page
   */
  addUserDIQA(e) {
    e.preventDefault();
    this.props.history.push("/addUserDIQA");
    localStorage.removeItem("activeKey");
  }

  /**
   * @author : Nilesh Zinzurao - 5024
   * @description : Used for navigate to change password page at DIQA
   * @return : redirect to /changePasswordDIQA page
   */
  onChangePasswordDIQA(e) {
    e.preventDefault();
    this.props.history.push("/changePasswordDIQA");
    localStorage.removeItem("activeKey");
  }

  //Aman Task - 4724 -(for getting the user info from the Token)-AC/ANC/AQA-Login - Client Switch option
  componentWillMount() {
    var x = localStorage.getItem("jwtToken");
    var base64Url = x.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    var oldData = JSON.parse(jsonPayload);
    var old_info = oldData.userDetails;
    const filterData = old_info;
    var data = filterData;
    this.setState({ data: data });
  }
  render() {
    /**
     * @author Narendra Phadke
     * @param  Description handle the login authentication
     * @return Description return All details of authentication
     */
    const { isAuthenticated, user } = this.props.auth;

    const authLinks = (
      <ul class="navbar-nav navbar-right">
        {user.role === "AC" || user.role === "ANC" || user.role === "AQA" ? (
          <li class="nav-item">
            <a
              class="abc nav-link"
              style={{ color: "aliceblue" }}
              href="#/" //Chaitanya-4751-Removed Console Warning
              onClick={this.onHome.bind(this)}
            >
              {" "}
              <span
                class="glyphicon glyphicon-home"
                style={{ color: "aliceblue" }}
              ></span>
              &nbsp;HOME
            </a>
          </li>
        ) : user.role === "DC" ? ( //Nilesh-5007- Added DIQA Commercial role.
          //Chaitanya-5426- Add Home menu bar at DIQA Commercial side
          <li class="nav-item">
            <a
              class="abc nav-link"
              style={{ color: "aliceblue" }}
              href="#/"
              onClick={this.onDCHome.bind(this)}
            >
              {" "}
              <span
                class="glyphicon glyphicon-home"
                style={{ color: "aliceblue" }}
              ></span>
              &nbsp;HOME
            </a>
          </li>
        ) : user.role === "DIQA" ? ( //Nilesh-5007- Added DIQA Commercial role.
          ""
        ) : user.role === "AC-ACCOUNT" ? ( //Somnath Task-4085, Add AC Account Role.
          ""
        ) : (
          <li class="nav-item">
            <a
              class="abc nav-link"
              style={{ color: "aliceblue" }}
              href="#/" //Chaitanya-4751-Removed Console Warning
              onClick={this.onHome1.bind(this)}
            >
              {" "}
              <span
                class="glyphicon glyphicon-home"
                style={{ color: "aliceblue" }}
              ></span>
              &nbsp;HOME
            </a>
          </li>
        )}
        {/* &nbsp;&nbsp; */}
        {/* Created by Raunak Thakkar against 3010  */}
        {user.role === "AC" ? (
          <li class="nav-item dropdown ">
            <a class=" nav-link dropdown-toggle dropbtn" id="navbardrop">
              RFP
            </a>
            <div class="dropdown-content dropdown-menu ">
              {/* karan-task-3521-menu item for dashboard */}
              <a
                href="#/" //Chaitanya-4751-Removed Console Warning
                onClick={this.rfpDashboard.bind(this)}
                className="dropdown-item "
              >
                RFP Dashboard
              </a>
              {/* karan-task-3636-menu item for progress */}
              <a
                href="#/" //Chaitanya-4751-Removed Console Warning
                onClick={this.rfpProgress.bind(this)}
                className="dropdown-item "
              >
                RFP Progress
              </a>
              <a
                href="#/" //Chaitanya-4751-Removed Console Warning
                onClick={this.rfpCreate.bind(this)}
                className="dropdown-item "
              >
                Create New RFP
              </a>{" "}
              {/* changed by Raunak Thakkar against 3010 */}
              <a
                href="#/" //Chaitanya-4751-Removed Console Warning
                onClick={this.rfpExsisting.bind(this)}
                className="dropdown-item "
              >
                Existing RFP
              </a>
              <a
                href="#/" //Chaitanya-4751-Removed Console Warning
                onClick={this.rfpAnalysis.bind(this)}
                className="dropdown-item "
              >
                {" "}
                RFP Analysis
              </a>
              <a
                href="#/" //Chaitanya-4751-Removed Console Warning
                onClick={this.createXLSRfp.bind(this)}
                className="dropdown-item "
              >
                {" "}
                Create RFP By XLSX
              </a>{" "}
              {/* snehal-task-3509Project's(DI) - Create RFP through XLS - FE */}
            </div>
          </li>
        ) : (
          ""
        )}
        {user.role === "AC" || user.role === "ADV" || user.role === "AQA" ? (
          <li class="nav-item dropdown ">
            <a class=" nav-link dropdown-toggle dropbtn" id="navbardrop">
              {/* data-toggle="dropdown" */}
              CAMPAIGN
            </a>

            {/* snehal-task3612-Akshay sir---1)On-boarding Agreement, 2)Agency side-menu/sub menu changes (arrow changes ) */}
            <div class="dropdown-content dropdown-menu ">
              {user.role === "AC" ? (
                <ul class="nav navbar-nav">
                  <li class="dropdown dropright">
                    <a
                      class="dropdown-toggle widthissue_camp"
                      data-toggle="dropdown"
                      href="#/" //Chaitanya-4751-Removed Console Warning
                      style={{
                        color: "black",
                        padding: "10px 5px 3px",
                        width: "200px",
                      }}
                    >
                      &nbsp;&nbsp;&nbsp;Create Campaign&nbsp; &nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                    </a>
                    <ul
                      class="dropdown-menu nav submenu"
                      style={{ width: "170px" }}
                    >
                      <li>
                        <a
                          className="widthissue_camp_submenu"
                          href="#/" //Chaitanya-4751-Removed Console Warning
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push("/welcomePage");
                          }} // karan-task-3933-vapt header and query params
                          style={{
                            fontSize: "13px",
                            color: "black",
                            width: "170px",
                          }}
                        >
                          Create Campaign In Detail&nbsp;
                        </a>
                      </li>
                      <li>
                        <a
                          className="widthissue_camp_submenu"
                          href="#/" //Chaitanya-4751-Removed Console Warning
                          onClick={this.createXLSCampaign.bind(this)}
                          style={{
                            fontSize: "13px",
                            color: "black",
                            width: "170px",
                          }}
                        >
                          Create Campaign By XLSX&nbsp;
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              ) : (
                ""
              )}
              {user.role === "ADV" ? (
                // <div class="collapse navbar-collapse" id="navbar-collapse-1">
                <ul class="nav navbar-nav">
                  <li class="dropdown">
                    <a
                      class="dropdown-toggle"
                      data-toggle="dropdown"
                      href="#/" //Chaitanya-4751-Removed Console Warning
                      style={{
                        fontSize: "14px",
                        fontFamily: "Arial",
                        width: "158px",
                        padding: "5px 5px 3px",
                        color: "black",
                      }}
                    >
                      &nbsp;&nbsp;Create Campaign
                    </a>

                    <ul class="dropdown-menu nav submenu">
                      {/* <li class="kopie"><a href="#">Dropdown</a></li> */}

                      {/* <li style={{color:'black'}}><a style={{fontSize:'13px',color:'black'}}>Detail Campaign Creation</a></li> */}
                      <li>
                        <a
                          href="./quickUpload"
                          style={{ fontSize: "13px", color: "black" }}
                        >
                          Quick Upload
                        </a>
                      </li>

                      {/* <li class="dropdown dropdown-submenu"><a href="#" class="dropdown-toggle" data-toggle="dropdown">Dropdown Link 4</a> */}
                      {/* <ul class="dropdown-menu">
                                    <li class="kopie"><a href="#">Dropdown Link 4</a></li>
									<li><a href="#">Dropdown Submenu Link 4.1</a></li>
									<li><a href="#">Dropdown Submenu Link 4.2</a></li>
									<li><a href="#">Dropdown Submenu Link 4.3</a></li>
									<li><a href="#">Dropdown Submenu Link 4.4</a></li> */}

                      {/* </ul> */}
                      {/* </li> */}
                    </ul>
                  </li>
                </ul>
              ) : (
                // </div>

                ""
              )}

              {/* { user.role==="AC"?

          <a href="#" onClick={this.pendingAllocation.bind(this)} className="dropdown-item " >Edit Campaign</a> 

          :""} */}
              {/* Sandeep-task-3228-added code for AQA */}
              {user.role === "AQA" ? (
                <a
                  id="myBtnn"
                  href="#/" //Chaitanya-4751-Removed Console Warning
                  onClick={this.onCampaignProgress.bind(this)}
                  class="dropdown-item"
                >
                  Campaign Progress
                </a>
              ) : (
                ""
              )}

              {user.role === "AC" ? (
                <span>
                  <a
                    id="myBtnn"
                    href="#/" //Chaitanya-4751-Removed Console Warning
                    onClick={this.onCampaignProgress.bind(this)}
                    class="dropdown-item"
                  >
                    Campaign Progress
                  </a>
                  <a
                    href="#/" //Chaitanya-4751-Removed Console Warning
                    onClick={this.incompleteCampaign.bind(this)}
                    className="dropdown-item "
                  >
                    Incomplete Campaign
                  </a>
                  {/* //shivani-3245-added sub menu as "ABM" Upgrade */}

                  <a
                    href="#/" //Chaitanya-4751-Removed Console Warning
                    onClick={this.abmUpgrade.bind(this)}
                    className="dropdown-item "
                  >
                    ABM Upgrade
                  </a>
                </span>
              ) : (
                ""
              )}
              {/* {user.role==="AC"? commented by Raunak thakkar against 3010
          <a className="dropdown-item" href="./createCampaign">Create Campaign-Old Flow</a>
          :''} */}
              {/* {user.role==="ADV"?
          <span> <a href="#" onClick={this.rfpCreate.bind(this)} className="dropdown-item " >RFP Create</a>
          <a className="dropdown-item" href="./quickUpload">Quick Upload</a>
        </span>:''} */}
              {/*<a className="dropdown-item" href="/">View Campaign</a>
                <a className="dropdown-item" href="./campaignSetup">Campaign Setup</a> */}
            </div>
          </li>
        ) : (
          ""
        )}
        {/* &nbsp;&nbsp; */}
        {user.role === "ADV" ? (
          <li class="nav-item dropdown">
            <a
              class="abc nav-link dropdown-toggle dropbtn"
              href="#/" //Chaitanya-4751-Removed Console Warning
              id="navbardrop"
              data-toggle="dropdown"
            >
              VIEW CAMPAIGN
            </a>
            <div class="dropdown-content dropdown-menu">
              <span>
                <a className="dropdown-item" href="./advertiserTabs">
                  View Campaign
                </a>
              </span>
            </div>
          </li>
        ) : (
          ""
        )}
        {/* Sandeep-task-3448-added report menu for advertiser */}
        {/* &nbsp;&nbsp; */}
        {user.role === "ADV" ? (
          <li class="nav-item dropdown">
            <a
              class="abc nav-link dropdown-toggle dropbtn"
              href="#/" //Chaitanya-4751-Removed Console Warning
              id="navbardrop"
              data-toggle="dropdown"
            >
              REPORT
            </a>
            <div class="dropdown-content dropdown-menu">
              <span>
                <a className="dropdown-item" href="./advertiserMyReports">
                  My Reports
                </a>
              </span>
            </div>
          </li>
        ) : (
          ""
        )}
        {/* <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" >
           PUBLISHER
            </a>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <a className="dropdown-item" href="/">Create Publisher</a>
            <a className="dropdown-item" href="/">Edit Publisher</a>
            <a className="dropdown-item" href="/">Delete Publisher</a>
          </div>
        </li>
        &nbsp;&nbsp; */}
        {/* {user.role==="AC" || user.role==="ANC"?
         <li class="nav-item dropdown">
         <a class="abc nav-link dropdown-toggle dropbtn"  id="navbardrop" >
         {/* data-toggle="dropdown" 
          ALLOCATION
         </a>
         <div class="dropdown-content dropdown-menu">
         {/* <a class="dropdown-item" href="#" onClick={this.onCampaignProgress.bind(this)}>Campaign Progress</a> 
           <a class="dropdown-item" href="./allocatingCampaign"> Allocate Campaign & Progress Monitoring</a>
           
          
                 {/* <a className="dropdown-item" href="/">
                   De-allocate Publisher
                 </a>
                 <a className="dropdown-item" href="./assignedCampaign">
                   Assigned Campaign
                 </a> 
         </div>
       </li>:""} 
      */}
        {/* &nbsp;&nbsp; */}
        {/* //shivani -task -3326- added new dropdown option in advertiser for AC role . */}
        {user.role === "AC" ? (
          <li class="nav-item dropdown">
            <a class="abc nav-link dropdown-toggle dropbtn" id="navbardrop">
              {/* data-toggle="dropdown" */}
              ADVERTISER
            </a>
            <div class="dropdown-content dropdown-menu">
              <a class="dropdown-item" href="./addAdvertiser">
                {" "}
                Add Advertiser
              </a>
              <a
                class="dropdown-item"
                onClick={this.forAdvertiserResponse.bind(this)}
              >
                {" "}
                Upload Advertiser Response{" "}
              </a>
              {/* <a class="dropdown-item" href="./agencyReviewLead"> Upload Advertiser Response </a> */}
            </div>
          </li>
        ) : (
          ""
        )}
        {/* //shivani -task -3326- added new dropdown option in advertiser for AQA role . */}
        {user.role === "AQA" ? (
          <li class="nav-item dropdown">
            <a class="abc nav-link dropdown-toggle dropbtn" id="navbardrop">
              {/* data-toggle="dropdown" */}
              ADVERTISER
            </a>
            <div class="dropdown-content dropdown-menu">
              {/* <a class="dropdown-item" href="./addAdvertiser"> Add Advertiser</a>  */}
              <a
                class="dropdown-item"
                onClick={this.forAdvertiserResponse.bind(this)}
              >
                {" "}
                Upload Advertiser Response{" "}
              </a>
              {/* <a class="dropdown-item" href="./agencyReviewLead"> Upload Advertiser Response </a> */}
            </div>
          </li>
        ) : (
          ""
        )}
        {/* &nbsp;&nbsp; */}
        {user.role === "AC" || user.role === "ANC" || user.role === "AQA" ? (
          <li class="nav-item dropdown">
            <a class="abc nav-link dropdown-toggle dropbtn" id="navbardrop">
              {/* data-toggle="dropdown" */}
              REPORT
            </a>
            <div class="dropdown-content dropdown-menu">
              {/* <a class="dropdown-item" href="./reportByPublisher">By Publisher</a>
          <a className="dropdown-item" href="./byCampaignEndDate">By CampaignEndDate</a>
          <a className="dropdown-item" href="./byPublisherEndDate">By PublisherEndDate</a> */}
              {/* //Shivani Pathak-task 3354-added dropdown option "MyReports" */}
              <a className="dropdown-item" href="./agencyMyReports">
                My Reports
              </a>
              <a className="dropdown-item" href="./listGDPRPublisher">
                List GDPR Publisher
              </a>
              <a className="dropdown-item" href="./campaignTraceReport">
                Campaign Tracing
              </a>
              {/* <a className="dropdown-item" href="./listAllCampaign">All Campaign</a>     changed by Raunak Thakkar against 3010 */}
              {/* <a className="dropdown-item" href="./leadDownload">Lead Download</a>       changed by Raunak Thakkar against 3010  */}
              <a className="dropdown-item" href="./lpDataTracking">
                LP Data Tracking
              </a>
              <a className="dropdown-item" href="./salesforceLead">
                Salesforce Lead Status
              </a>{" "}
              {/*changed by Raunak Thakkar against 3010*/}
              <a className="dropdown-item" href="./marketoLead">
                Marketo Lead Status
              </a>{" "}
              {/*changed by Raunak Thakkar against 3010*/}
              <a className="dropdown-item" href="./hubspotLead">
                Hubspot Lead Status
              </a>
              {/*changed by Raunak Thakkar against 3010*/}
              <a className="dropdown-item" href="./captureLandingPage">
                Capture Landing Page
              </a>
              {/* <a className="dropdown-item" href="./publisherAnalysisDashbord">Publisher Dashboard</a> */}
              {/* Rutuja 4439 -Added Lead Report By campaign */}
              <a className="dropdown-item" href="./leadReportByCampaign">
                Lead Report By Campaign
              </a>
              {/* Chaitanya-4470-Added Lead Report By Client */}
              <a className="dropdown-item" href="./leadReportClient">
                Lead Report By Client
              </a>
            </div>
          </li>
        ) : (
          ""
        )}
        {/* &nbsp;&nbsp; */}
        {/* {user.role==="AC" || user.role==="ANC" || user.role==="AQA"? */}
        {/* // <li class="nav-item dropdown"> */}
        {/* <a class="abc nav-link dropdown-toggle dropbtn"  id="navbardrop" > */}
        {/* data-toggle="dropdown" */}
        {/* DASHBOARDS  */}
        {/* </a> */}
        {/* <div class="dropdown-content dropdown-menu"> */}
        {/* <a class="dropdown-item" href="./publisherAnalysisDashbord">Publisher Analysis Dashboard</a>     changed by Raunak Thakkar against 3010 */}
        {/* <a className="dropdown-item" href="./agencyReport2">Delivery Dashboard</a> */}
        {/* <a className="dropdown-item" href="./agencyReport1">Weekly Dashboard</a>        changed by Raunak Thakkar against 3010*/}
        {/* <a className="dropdown-item" href="./rfpAnalysis">RFP Analysis</a> */}
        {/* </div> */}
        {/* </li> */}
        {/* :""} */}
        {/* &nbsp;&nbsp; */}
        {user.role === "AC" || user.role === "AC-ACCOUNT" ? ( //Somnath Task-4085, Add AC_ACCOUNT Role
          <li class="nav-item dropdown">
            <a class="abc nav-link dropdown-toggle dropbtn" id="navbardrop">
              {/* data-toggle="dropdown" */}
              FINANCE
            </a>
            <div class="dropdown-content dropdown-menu">
              <a
                class="dropdown-item"
                href="#/" //Chaitanya-4751-Removed Console Warning
                onClick={this.onChangeAgencyInvoiceList.bind(this)}
              >
                Invoice
              </a>
              {/*<a className="dropdown-item" href="./agencyReport2">Delivery Dashboard</a>
          <a className="dropdown-item" href="./agencyReport1">Weekly Dashboard</a> */}
            </div>
          </li>
        ) : (
          ""
        )}
        {/* &nbsp;&nbsp; */}
        {/* Chaitanya-5426- Add Review menu bar at DIQA Commercial side*/}
        {user.role === "DC" ? (
          <li class="nav-item">
            <a
              class="abc nav-link dropbtn"
              href="#/"
              onClick={this.onDCReview.bind(this)}
            >
              REVIEW
            </a>
          </li>
        ) : (
          ""
        )}
        {/* Nilesh-5007- Add reports menu bar at DIQA Commercial side*/}
        {user.role === "DC" ? (
          <li class="nav-item dropdown">
            <a
              //href="#/"
              class="abc nav-link dropdown-toggle dropbtn"
              id="navbardrop"
            >
              REPORTS
            </a>
            <div class="dropdown-content dropdown-menu">
              {/* Chaitanya-4972- Add DIQA Reviewed Lead Details menu at DIQA Commercial side*/}
              <a
                class="dropdown-item"
                href="#/"
                onClick={this.onDCReport.bind(this)}
              >
                DIQA Reviewed Lead Details
              </a>
            </div>
          </li>
        ) : (
          ""
        )}
        {/* Chaitanya-5074- Add reports menu bar at DIQA side*/}
        {user.role === "DIQA" ? (
          <li class="nav-item dropdown">
            <a class="abc nav-link dropdown-toggle dropbtn" id="navbardrop">
              REPORTS
            </a>
            <div class="dropdown-content dropdown-menu">
              {/* Chaitanya-5074- Add reports menu bar at DIQA side*/}
              <a
                class="dropdown-item"
                href="#/"
                onClick={this.onDiqaReport.bind(this)}
              >
                DIQA Reviewed Lead Details
              </a>
            </div>
          </li>
        ) : (
          ""
        )}
        {/* Aman -Task-4724- (added this new CLIENT SWITCH button in nav bar)- AC/ANC/AQA-Login - Client Switch option */}
        {this.state.data.length > 1 ? (
          <>
            {user.role === "AC" ||
            user.role === "ANC" ||
            user.role === "AQA" ? (
              <li class="nav-item">
                <a
                  className="abc nav-link"
                  style={{ color: "aliceblue" }}
                  href="#/" //Chaitanya-4751-Removed Console Warning
                  onClick={this.onClientSwitch.bind(this)}
                >
                  {" "}
                  <Tooltip placement="bottom" title="Switch Login">
                    <span
                      className="glyphicon glyphicon-transfer"
                      style={{ color: "aliceblue" }}
                    ></span>
                  </Tooltip>
                </a>
              </li>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        &nbsp;&nbsp;
        {user.role === "AC" ||
        user.role === "ANC" ||
        user.role === "AQA" ||
        user.role === "ADV" ||
        user.role === "AC-ACCOUNT" || //Somnath Task-4085, Add AC_ACCOUNT Role
        user.role === "DIQA" || //Rutuja 4975, Add DIQA Role
        user.role === "DC" ? ( //Nilesh-5007- Add DIQA Commercial role.
          <li class="nav-item dropdown">
            <a
              href="#/" //Chaitanya-4751-Removed Console Warning
              class="abc nav-link dropdown-toggle dropbtn"
              style={{ color: "aliceblue" }}
              id="navbardrop"
              data-toggle="dropdown"
            >
              <span
                class="	glyphicon glyphicon-cog"
                style={{ color: "aliceblue" }}
              ></span>
              {/* MY ACCOUNT */}
            </a>
            <div class="dropdown-content dropdown-menu">
              {user.role === "AC" ||
              user.role === "ANC" ||
              user.role === "AQA" ||
              user.role === "AC-ACCOUNT" ? ( //Somnath Task-4085, Add AC_ACCOUNT Role
                <div>
                  <a
                    class="dropdown-item"
                    href="#/" //Chaitanya-4751-Removed Console Warning
                    onClick={this.onChangePasswordAgency.bind(this)}
                  >
                    Change Password
                  </a>

                  {user.role === "AQA" ? (
                    <a
                      class="dropdown-item"
                      href="#/" //Chaitanya-4751-Removed Console Warning
                      onClick={this.onEmailConfigAgency.bind(this)}
                    >
                      Email Notification{/*vrushabh patil change hedding*/}
                    </a>
                  ) : (
                    ""
                  )}
                  {user.role === "AQA" || user.role === "AC-ACCOUNT" ? ( //Somnath Task-4085, Add AC_ACCOUNT Role
                    ""
                  ) : (
                    <a
                      class="dropdown-item"
                      href="#/" //Chaitanya-4751-Removed Console Warning
                      onClick={this.onEmailConfigAgency.bind(this)}
                    >
                      Email Notification
                    </a>
                  )}

                  {/* karan-task-3515- added RFP REminder menu item */}
                  {user.role === "AC-ACCOUNT" ? (
                    "" //Somnath Task-4085, Add AC_ACCOUNT Role
                  ) : (
                    <a
                      class="dropdown-item"
                      href="#/" //Chaitanya-4751-Removed Console Warning
                      onClick={this.onRFPReminderSetup}
                    >
                      RFP Reminder Setup
                    </a>
                  )}

                  {user.role === "AC" ? (
                    <div>
                      <a
                        class="dropdown-item"
                        href="#/" //Chaitanya-4751-Removed Console Warning
                        onClick={this.onAddUser.bind(this)}
                      >
                        Add User
                      </a>
                      <a
                        class="dropdown-item"
                        href="#/" //Chaitanya-4751-Removed Console Warning
                        onClick={this.onHelpOption.bind(this)}
                      >
                        Help
                      </a>
                      <a
                        class="dropdown-item"
                        href="#/" //Chaitanya-4751-Removed Console Warning
                        onClick={this.onAgencyInformation.bind(this)}
                      >
                        Agency Information
                      </a>
                      {/* //shivani-3517-added jobLevel Mappping option. */}
                      <a
                        class="dropdown-item"
                        href="#/" //Chaitanya-4751-Removed Console Warning
                        onClick={this.onJobLevelMapping.bind(this)}
                      >
                        Job Level Mapping
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                  {user.role === "ANC" ? (
                    <div>
                      <a
                        class="dropdown-item"
                        href="#/" //Chaitanya-4751-Removed Console Warning
                        onClick={this.onAddUser.bind(this)}
                      >
                        Add User
                      </a>
                      <a
                        class="dropdown-item"
                        href="#/" //Chaitanya-4751-Removed Console Warning
                        onClick={this.onAgencyInformation.bind(this)}
                      >
                        Agency Information
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* karan-task-3648-add publisher page menu */}
                  {user.role === "AC-ACCOUNT" ? (
                    "" //Somnath Task-4085, Add AC_ACCOUNT Role
                  ) : (
                    <a
                      class="dropdown-item"
                      href="#/" //Chaitanya-4751-Removed Console Warning
                      onClick={this.onAddPublisherClick}
                    >
                      Add Publisher
                    </a>
                  )}
                </div>
              ) : (
                ""
              )}
              {/* rutuja task-4517- Used to navigate to ADD AQA role */}
              {user.role === "AQA" ? (
                <a
                  class="dropdown-item"
                  href="#/" //Chaitanya-4751-Removed Console Warning
                  onClick={this.addAQARole.bind(this)}
                >
                  Add User
                </a>
              ) : (
                ""
              )}
              {user.role === "ADV" ? (
                <div>
                  <a
                    class="dropdown-item"
                    href="#/" //Chaitanya-4751-Removed Console Warning
                    onClick={this.onChangePasswordAdvertiser.bind(this)}
                  >
                    Change Password
                  </a>
                  <a
                    class="dropdown-item"
                    href="#/" //Chaitanya-4751-Removed Console Warning
                    onClick={this.onEmailConfigAdvertiser.bind(this)}
                  >
                    Email Notification
                  </a>
                  <a
                    class="dropdown-item"
                    href="#/" //Chaitanya-4751-Removed Console Warning
                    onClick={this.onAddUserAdvertiser.bind(this)}
                  >
                    Add User
                  </a>
                  <a
                    class="dropdown-item"
                    href="#/" //Chaitanya-4751-Removed Console Warning
                    onClick={this.advertiserInfo.bind(this)}
                  >
                    Advertiser Information
                  </a>
                </div>
              ) : (
                ""
              )}

              {/* {user.role==="DI"?
          <div><a class="dropdown-item" href="#" >Change Password</a>
          <a class="dropdown-item" href="#" >Email Notification</a>
          <a class="dropdown-item" href="#" >Add User</a>
          <a class="dropdown-item" href="#" >Help</a>
          </div>
          :""} */}
              {/* snehal-task3612-Akshay sir---1)On-boarding Agreement, 2)Agency side-menu/sub menu changes  */}
              {user.role === "AC" || user.role === "ANC" ? (
                <a
                  href="#/" //Chaitanya-4751-Removed Console Warning
                  onClick={this.customizeMappin.bind(this)}
                  className="dropdown-item "
                >
                  Create Customize Mapping
                </a> //changed by Raunak Thakkar against 3010
              ) : (
                ""
              )}
              {/* Chaitanya-4939-Removed Upload Supporting Documents Option from ANC Role */}
              {user.role === "AC" ? (
                <a
                  href="#/" //Chaitanya-4751-Removed Console Warning
                  onClick={this.uploadSuppression.bind(this)}
                  className="dropdown-item "
                >
                  Upload Common Supporting Documents
                  {/* Chaitanya-4813-updated submenu */}
                </a>
              ) : (
                ""
              )}
              {/* task-3982-add custom region */}
              {/* Chaitanya-5546-Removed Custom Region option from settings*/}
              {/* {user.role === "AC" || user.role === "ANC" ? (
                <a
                  href="#/" //Chaitanya-4751-Removed Console Warning
                  onClick={this.addCustomCountry.bind(this)}
                  className="dropdown-item "
                >
                  Add Custom Region
                </a>
              ) : (
                ""
              )} */}
              {/* 4466-Nilesh Added new option in setting dropdown  */}
              {user.role === "AC" ? (
                <a
                  class="dropdown-item"
                  href="onLinkCampaign"
                  onClick={this.onLinkCampaign.bind(this)}
                >
                  Link Client's Campaigns
                </a>
              ) : (
                //Rutuja -4975 added setting option at diqa login
                ""
              )}

              {user.role === "DIQA" || user.role === "DC" ? ( //Nilesh-5007- Add DIQA Commercial role.
                <>
                  <a
                    class="dropdown-item"
                    href="onLEmailConfigDIQA"
                    onClick={this.onEmailConfigDIQA.bind(this)}
                  >
                    Email Notification
                  </a>
                  {/* Nilesh - 5024 - Add Change Password sub menu at DIQA role */}
                  <a
                    class="dropdown-item"
                    href="onChangePasswordDIQA"
                    onClick={this.onChangePasswordDIQA.bind(this)}
                  >
                    Change Password
                  </a>
                </>
              ) : (
                ""
              )}

              {/* Chaitanya-5043- Add Change Password and Add User section at DIQA Commercial side*/}
              {user.role === "DC" ? (
                <a
                  class="dropdown-item"
                  href="addUserDIQA"
                  onClick={this.addUserDIQA.bind(this)}
                >
                  Add User
                </a>
              ) : (
                ""
              )}
            </div>
          </li>
        ) : (
          ""
        )}
        &nbsp;&nbsp;
        <li class="nav-item">
          <a
            class="abc nav-link"
            style={{ color: "aliceblue" }}
            href="#/" //Chaitanya-4751-Removed Console Warning
            onClick={this.onLogout.bind(this)}
          >
            {" "}
            <span
              class="glyphicon glyphicon-log-out"
              style={{ color: "aliceblue" }}
            ></span>
          </a>
        </li>
      </ul>
    );

    const guestLinks = (
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          &nbsp;&nbsp;
          {/* <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle dropbtn" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
         LEADS
          </a>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <a className="dropdown-item" href="/">Upload Leads</a>
            <a className="dropdown-item" href="/">Download Leads</a>
            <a className="dropdown-item" href="/">Review/Approve Leads</a>
            </div>
        </li>
        &nbsp;&nbsp;
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle dropbtn" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
         DASHBOARDS
          </a>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <a className="dropdown-item" href="/">Campaign (monthwise, Yearwise)</a>
            <a className="dropdown-item" href="/">Tracking Campaign (publisherwise)</a>
            <a className="dropdown-item" href="/">Campaign (Clientwise)</a>
            <a className="dropdown-item" href="/">Leads Status (Campaign/Publisher wise)</a>
            </div>
        </li>
        &nbsp;&nbsp;
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle dropbtn" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        APPLICATION USERS
          </a>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <a className="dropdown-item" href="/">Create Users</a>
            <a className="dropdown-item" href="/">Disable Users</a>
          </div>
        </li>
        &nbsp;&nbsp;
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle dropbtn" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
       LANDING PAGE
          </a>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <a className="dropdown-item" href="/">Upload Landing Page</a>
            <a className="dropdown-item" href="/">Review/Approve Landing Page</a>
          </div>
        </li> */}
        </li>
      </ul>
    );
    return (
      <div>
        <nav
          className="navbar navbar-expand-md navbar-dark navbar-fixed-top shadow"
          id="navbar_navpage_font_css"
        >
          {/* <a className="logo">DEMAND INTEGRATE</a> */}
          {/* <img src="DI.png" alt="Demand Integrate" height="40px" width="50px"></img>  
         <img src="DI-font.png" alt="Demand Integrate" height="62px" width="200px" style={{marginLeft:"-20px"}}/> */}

          <img
            src="DemandIntegrateLogo_White.png"
            alt="Demand Integrate"
            height="50px"
            width="200px"
            id="navbar_navpage_logo_css"
          ></img>

          {/* <span style={{margin:'-5px'}} >   
   {user.role==="AC"? 
    // <h5 style={{fontWeight:'15px',fontStyle:'bold',color:'white'}}>Welcome to Agency<br/>[{user.name}]<br/>has login as Agenecy Commercial</h5>
   <h5></h5>
     :''}
    {user.role==="ANC"?
    <h5 style={{fontWeight:'15px',fontStyle:'bold',color:'white'}}>Welcome to Agency<br/>[{user.name}]<br/>has login as Agenecy Non-Commercial</h5>
   
    :''}
     {user.role==="ADV"? 
    <h5 style={{fontWeight:'15px',fontStyle:'bold',color:'white'}}>Welcome to Advertiser<br/>[{user.name}]<br/>has login as Advertiser</h5>
   
     :''}</span>  */}

          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {user.role === "AC" ? (
              <h5
                style={{
                  fontWeight: "10px",
                  fontStyle: "bold",
                  color: "white",
                  textAlign: "right",
                }}
              >
                Welcome &nbsp;{" "}
                <b style={{ color: "#00FF00" }}>
                  {" "}
                  {user.firstName}&nbsp; {user.lastName}
                </b>
                &nbsp;- Agency Commercial
              </h5>
            ) : (
              ""
            )}
            {user.role === "ANC" ? (
              <h5
                style={{
                  fontWeight: "10px",
                  fontStyle: "bold",
                  color: "white",
                  textAlign: "right",
                }}
              >
                Welcome &nbsp;
                <u style={{ color: "#00FF00" }}>
                  {user.firstName}&nbsp; {user.lastName}
                </u>
                &nbsp;- Agency Non-Commercial
              </h5>
            ) : (
              ""
            )}
            {user.role === "ADV" ? (
              <h5
                style={{
                  fontWeight: "10px",
                  fontStyle: "bold",
                  color: "white",
                  textAlign: "right",
                }}
              >
                Welcome&nbsp;&nbsp;
                <u style={{ color: "#00FF00" }}>
                  {user.firstName}&nbsp; {user.lastName}
                </u>
                &nbsp;- Advertiser
              </h5>
            ) : (
              ""
            )}
            {user.role === "AQA" ? (
              <h5
                style={{
                  fontWeight: "10px",
                  fontStyle: "bold",
                  color: "white",
                  textAlign: "right",
                }}
              >
                Welcome &nbsp;{" "}
                <b style={{ color: "#00FF00" }}>
                  {" "}
                  {user.firstName}&nbsp; {user.lastName}
                </b>
                &nbsp;- Agency Quality Assurance
              </h5>
            ) : (
              ""
            )}
            {user.role === "DIQA" ? (
              <h5
                style={{
                  fontWeight: "10px",
                  fontStyle: "bold",
                  color: "white",
                  textAlign: "right",
                }}
              >
                Welcome &nbsp;{" "}
                <b style={{ color: "#00FF00" }}>
                  {" "}
                  {user.firstName}&nbsp; {user.lastName}
                </b>
                &nbsp;- DI Quality Analyst
              </h5>
            ) : (
              ""
            )}
            {/* Nilesh-5007- Add Title section at DIQA Commercial side*/}
            {user.role === "DC" ? (
              <h5
                style={{
                  fontWeight: "10px",
                  fontStyle: "bold",
                  color: "white",
                  textAlign: "right",
                }}
              >
                Welcome &nbsp;{" "}
                <b style={{ color: "#00FF00" }}>
                  {" "}
                  {user.firstName}&nbsp; {user.lastName}
                </b>
                &nbsp;- DIQA Commercial
              </h5>
            ) : (
              ""
            )}
            {/* added code for DI Quality Analyst */}
            {user.role === "PQA" ? (
              <h5
                style={{
                  fontWeight: "10px",
                  fontStyle: "bold",
                  color: "white",
                  textAlign: "right",
                }}
              >
                Welcome &nbsp;{" "}
                <b style={{ color: "#00FF00" }}>
                  {" "}
                  {user.firstName}&nbsp; {user.lastName}
                </b>
                &nbsp;- Publisher Quality Assurance
              </h5>
            ) : (
              ""
            )}
            {/*  4092- Agency Accounting login - Username and Role-Sanjana Godbole*/}
            {user.role === "AC-ACCOUNT" ? (
              <h5
                style={{
                  fontWeight: "10px",
                  fontStyle: "bold",
                  color: "white",
                  textAlign: "right",
                }}
              >
                Welcome &nbsp;{" "}
                <b style={{ color: "#00FF00" }}>
                  {" "}
                  {user.firstName}&nbsp; {user.lastName}
                </b>
                &nbsp;- Agency Accounting
              </h5>
            ) : (
              ""
            )}
            {isAuthenticated ? authLinks : guestLinks}
          </div>
        </nav>
        {/* <br></br>  <br></br><br></br><br></br><br></br>
     <Footer/> */}
      </div>
    );
  }
}
/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
Navigation.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(withRouter(Navigation));
