/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Sanjana Shende
 *@fileName :customMapping.js
 *Desc: Add Custom Mapping
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../login/authentication";
import { withRouter } from "react-router-dom";
import Footer from "../layouts/footer";
import Navigation from "../layouts/navPage";
import "./labelDI.css";
import { MDBBtn, CardBody } from "mdbreact";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { fetch_custom as fetch } from "../../configration/fetch_default_headers"; // karan-task-3723-vapt header and query params
import { Tooltip, Icon } from "antd"; //Aman-5006 - Added to show message on Button 'Add More Extra Fields'
import "./customMapping.css";

// import Select from 'react-select';
const Swal = require("sweetalert2");

class CustomMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientDetails: [],
      advertiserMapping: [],
      customMapping: [],
      agencyAdvertiserMap: [],
      mappingFormat: [],
      advertiserID: "",
      errors: {},
      length: 0,
      clientNameDisplay: "none",
      format: "Format 1",
      userID: "",
      validationDisplay: "none", //snehal-task-3090-Add validation for customize mapping- User can't add alias name only number
      validationDisplay1: "none",
      validationDisplay2: "none", //rutuja task - 4779 added error message for all fields are mandetory
      validationDisplay3: "none", //Rutuja task - 4832 added error message for similar system fields
      workPhoneFormatError: false, //karan-task-3477-work phone format invalid input error
      duplicateWorkPhoneFormatError: false, //karan-task-3477-work phone format duplicate input error
      sameValue: false, //Sanjana-task-3897-Same Value found error
      difields: [], //Rutuja task -4832
      systemFields: [], //Rutuja task -4832
      duplicateAliasName: [], //Rutuja task -4832
      nonEnglishChecked: "No", //Chaitanya-4962
      isCheckednonEnglish: false, //Chaitanya-4962
      customMappingOtherField: [], //Aman-5006 - added to store new  row value
      otherFieldCounter: 20, //Aman-5006 - Counter for generating unique extra field names
      stectedClientID: "", //Aman 5086 - added to set client Id
      oldcustomMapping: [],//Aman 5144 - To set old customMapping to track customMapping Chnages in mapping_log table
      customMappingStatus: "edit", //Aman 5144 - to store customMappingStatus value
      searchQuery: '', // Rutuja 3478 Add a state property for the search input
      advertiserMapping: [], // Rutuja 3478 Example advertiserMapping state
      addedFields: 0, // Rutuja 3478 
      loader_progress: "" ,// //Priyanka-5549-Added loader
    };
    this.handleClientChange = this.handleClientChange.bind(this);
    this.agencyMappinHandleChange = this.agencyMappinHandleChange.bind(this);
    this.getMappin = this.getMappin.bind(this);
    this.handleFormatChange = this.handleFormatChange.bind(this);
    this.validateForm = this.validateForm.bind(this); //snehal-task-3090-Add validation for customize mapping- User can't add alias name only number
    //  this.displayMapping=this.displayMapping.bind(this);
    this.addCustomMappingField = this.addCustomMappingField.bind(this); //Aman-5006 - to bind addCustomMappingField
    this.removeCustomMappingField = this.removeCustomMappingField.bind(this); //Aman-5006 - to bind removeCustomMappingField
    this.handleViewFormat = this.handleViewFormat.bind(this); // Rutuja 3478 handle change for view format
    this.handleChangeSearch = this.handleChangeSearch.bind(this); // Rutuja 3478 handle change for search
  } // end of constructor

  handleFormatChange(e) {
    e.preventDefault();
    //Rutuja 4843 - Added Below message to none
    this.setState({
      validationDisplay: "none",
      validationDisplay1: "none",
      validationDisplay2: "none",
      validationDisplay3: "none",
      systemFields: "",
      duplicateAliasName: "",
      customMappingOtherField: "", //Aman-5006 - added to set customMappingOtherField value empty on formate change
      customMappingStatus: "edit" //Aman-5205-AC-Customize Mapping-set that satus to edit
    });
    this.state.advertiserMapping = [];
    this.state.format = "";
    const { user } = this.props.auth;
    var userID = user.id;
    var advertiserID = this.state.advertiserID;
    var format = e.target.value;
    const parts = format.split(" "); // Splits the string into an array by space
    const formatValue = parts[1]; // Get the second part
    document.getElementById("checkboxtick").disabled = false; //Chaitanya-4962-checkbox value update
    this.setState({ format: format, advertiserID: advertiserID, formatValue: formatValue }, function () {
      let data = {
        advertiserID: advertiserID,
        format: this.state.format,
      }; // karan-task-3723-vapt header and query params
      fetch("campaign/getAgencyAdvertiserCustomizedMap", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((advertiserMapping) => {
          //Aman-5144 added condition set the value of customMapping Status to pass in DB for mapping_log
          if (advertiserMapping.length === 0) {
            var customMappingStatus = "create";
            this.setState({ customMappingStatus: customMappingStatus });
          }
          if (advertiserMapping.length > 0) {
            var customMapping = advertiserMapping.filter(
              (a) => a.format == this.state.format
            );
            //Aman-5144 added condition set the value of customMapping Status to pass in DB for mapping_log
            if (customMapping.length === 0) {
              var customMappingStatus = "create";
              this.setState({ customMappingStatus: customMappingStatus });
            }
            this.setState({ oldcustomMapping: customMapping }); //Aman-5144 - set select customize mapping format to track in the mapping_log table  
            //Aman-5006 -added to Iterate over each object in the customMapping array
            customMapping.forEach((obj) => {
              //Aman-5006 - Iterate over the keys of the current object
              Object.keys(obj).forEach((key) => {
                //Aman-5006 - Check if the key starts with "extra" and the value is null, then delete the key
                if (key.startsWith("extra") && obj[key] === null) {
                  delete obj[key];
                }
              });
            });

            //Aman-5006 - Initialize customMappingOtherField array get extra other Fields
            const customMappingOtherField = [];

            //Aman-5006 - Extract values from "extra21" to "extra30"
            if (customMapping[0]) {
              for (let i = 21; i <= 30; i++) {
                const key = `extra${i}`;
                const updatedKey = `Extra ${i}`;
                const value = customMapping[0][key];
                if (value !== null && value !== undefined && value !== "") {
                  customMappingOtherField.push({ [updatedKey]: value });
                }
              }
            }

            if (customMapping.length === 0) {
              var agencyAdvertiserMap = [
                {
                  pID: "Publisher ID",
                  campID: "Campaign ID",
                  campaignName: "campaign Name",
                  leadInteractionDate: "Lead Interaction Date",
                  firstName: "First Name",
                  lastName: "Last Name",
                  companyName: "Company Name",
                  linkedInCompanyName: "LinkedIn Company Name",
                  email: "Email",
                  workPhone: "Work Phone",
                  workPhoneFormat: "91-XXXXXXXXXX",
                  jobTitle: "Job Title",
                  linkedInJobTitle: "LinkedIn Job Title",
                  address: "Address",
                  country: "Country",
                  city: "City",
                  state: "State",
                  zipCode: "Zip Code",
                  companyEmployeeSize: "Company Employee Size",
                  companyRevenue: "company Revenue",
                  industry: "Industry",
                  assetName: "Asset Name",
                  assetNameTouch1: "Asset Name Touch 1",
                  assetTimestampTouch1: "Asset Timestamp Touch 1",
                  assetNameTouch2: "Asset Name Touch 2",
                  assetTimestampTouch2: "Asset Timestamp Touch 2",
                  assetNameTouch3: "Asset Name Touch 3",
                  assetTimestampTouch3: "Asset Timestamp Touch 3",
                  street: "Street",
                  ip: "IP",
                  supportDocID: "Asset ID",
                  jobLevel: "Job Level",
                  jobFunction: "Job Function",
                  channel: "Channel",
                  extra1: "Extra 1",
                  extra2: "Extra 2",
                  extra3: "Extra 3",
                  extra4: "Extra 4",
                  extra5: "Extra 5",
                  extra6: "Extra 6",
                  extra7: "Extra 7",
                  extra8: "Extra 8",
                  extra9: "Extra 9",
                  extra10: "Extra 10",
                  extra11: "Extra 11",
                  extra12: "Extra 12",
                  extra13: "Extra 13",
                  extra14: "Extra 14",
                  extra15: "Extra 15",
                  extra16: "Extra 16",
                  extra17: "Extra 17",
                  extra18: "Extra 18",
                  extra19: "Extra 19",
                  extra20: "Extra 20",
                  domain: "Domain",
                  alternatePhoneNo: "Alternate Phone No",
                  comments: "Comments",
                  linkedIn: "LinkedIn",
                },
              ]; //Sandeep-task-3158-added code for extra 15 fields

              var customMapping = [
                {
                  pID: "",
                  campID: "",
                  campaignName: "",
                  leadInteractionDate: "",
                  firstName: "",
                  lastName: "",
                  companyName: "",
                  linkedInCompanyName: "",
                  email: "",
                  workPhone: "",
                  workPhoneFormat: "",
                  jobTitle: "",
                  linkedInJobTitle: "",
                  address: "",
                  country: "",
                  city: "",
                  state: "",
                  zipCode: "",
                  companyEmployeeSize: "",
                  companyRevenue: "",
                  industry: "",
                  assetName: "",
                  assetNameTouch1: "",
                  assetTimestampTouch1: "",
                  assetNameTouch2: "",
                  assetTimestampTouch2: "",
                  assetNameTouch3: "",
                  assetTimestampTouch3: "",
                  street: "",
                  ip: "",
                  supportDocID: "",
                  jobLevel: "",
                  jobFunction: "",
                  channel: "",
                  extra1: "",
                  extra2: "",
                  extra3: "",
                  extra4: "",
                  extra5: "",
                  extra6: "",
                  extra7: "",
                  extra8: "",
                  extra9: "",
                  extra10: "",
                  extra11: "",
                  extra12: "",
                  extra13: "",
                  extra14: "",
                  extra15: "",
                  extra16: "",
                  extra17: "",
                  extra18: "",
                  extra19: "",
                  extra20: "", //Sandeep-task-3158-added code for extra 15 fields
                  domain: "",
                  alternatePhoneNo: "",
                  comments: "",
                  linkedIn: "",
                },
              ];
              this.state.advertiserMapping.length = 0;
              this.state.agencyAdvertiserMap = [...advertiserMapping];
              this.state.advertiserMapping = [...customMapping];

              //Chaitanya-4962-When nonEnglishCheck value is Yes isCheckednonEnglish will be true
              if (customMapping[0].nonEnglishCheck == "Yes") {
                this.state.isCheckednonEnglish = true;
              }

              this.setState({
                advertiserMapping: customMapping,
                agencyAdvertiserMap: agencyAdvertiserMap,
                advertiserID: this.state.advertiserID,
                length: advertiserMapping.length,
                length: this.state.length,
                nonEnglishChecked: customMapping[0].nonEnglishCheck, //Chaitanya-4962-getting nonEnglishCheck value from BE
                customMappingOtherField: customMappingOtherField //Aman 5006 - setting the customMappingOtherField value
              });
            } else {
              //Chaitanya-4962-When nonEnglishCheck value is Yes isCheckednonEnglish will be true
              if (customMapping[0].nonEnglishCheck == "Yes") {
                this.state.isCheckednonEnglish = true;
              }
              this.state.agencyAdvertiserMap = [...advertiserMapping];
              this.state.advertiserMapping = [...customMapping];
              this.setState({
                advertiserMapping: customMapping,
                agencyAdvertiserMap: this.state.advertiserMapping,
                nonEnglishChecked: customMapping[0].nonEnglishCheck, //Chaitanya-4962-getting nonEnglishCheck value from BE
                customMappingOtherField: customMappingOtherField //Aman 5006 - setting the customMappingOtherField value
              });
            }
          }
        });
      this.setState({ userID: userID, advertiserID: this.state.advertiserID });
    });
  }
  handleClientChange(e) {
    document.getElementById("clientName").style.border = "1px solid lightgray";
    this.setState({ clientNameDisplay: "none", customMappingOtherField: "", customMappingStatus: "edit" }); //Aman 5006 - setting the customMappingOtherField value empty //Aman-5205-AC-Customize Mapping-set that satus to edit
    this.setState({ validationDisplay1: "none", duplicateAliasName: "" }); //kiran - 4299-error msg disabled on handled change //Rutuja task 4832 added duplicate alais name array
    this.setState({ validationDisplay2: "none" }); //rutuja task - 4779 added error message for all fields are mandetory
    this.setState({ validationDisplay3: "none", systemFields: "" }); //Rutuja task-4832 error  message and array for similar system fields
    const { user } = this.props.auth;
    var userID = user.id;
    var advertiserID = e.target.value;

    //Priyanka-5549-Added loader
    this.setState({ stectedClientID: advertiserID, loader_progress: "loader_campaign_list" }); //Aman 5086 - added to set client Id
    var format = this.state.format;
    document.getElementById("checkboxtick").disabled = false; //Chaitanya-4962-checkbox value update
    let data = {
      advertiserID: advertiserID,
      format: this.state.format,
    }; // karan-task-3723-vapt header and query params
    fetch("campaign/getAgencyAdvertiserCustomizedMap", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((advertiserMapping) => {
        this.setState({ loader_progress: "" }); //Priyanka-5549-Added loader
        //Aman-5144 added condition set the value of customMapping Status to pass in DB for mapping_log
        if (advertiserMapping.length === 0) {
          var customMappingStatus = "create";
          this.setState({ customMappingStatus: customMappingStatus });
        }
        if (advertiserMapping.length > 0) {
          this.state.mappingFormat = [...advertiserMapping];
          this.setState({ mappingFormat: this.state.mappingFormat });
          this.state.agencyAdvertiserMap = [...advertiserMapping]; //Sandeep-task-2879 added code advertiserMapping data appended into agencyAdvertiserMap array
          var customMapping = this.state.agencyAdvertiserMap.filter(
            (a) => a.format == this.state.format
          ); //sandeep-task-2879-changing datatype let to var
          //Aman-5144 added condition set the value of customMapping Status to pass in DB for mapping_log
          if (customMapping.length === 0) {
            var customMappingStatus = "create";
            this.setState({ customMappingStatus: customMappingStatus });
          }
          this.setState({ oldcustomMapping: customMapping }); //Aman-5144 - set select customize mapping format to track in the mapping_log table  
          //Aman 5006 - added to Iterate over each object in the customMapping array
          customMapping.forEach((obj) => {
            //Aman 5006 - Iterate over the keys of the current object
            Object.keys(obj).forEach((key) => {
              //Aman 5006 - Check if the key starts with "extra" and the value is null, then delete the key
              if (key.startsWith("extra") && obj[key] === null) {
                delete obj[key];
              }
            });
          });
          //Aman 5006 - added Initialize customMappingOtherField array to store extra fileds data
          const customMappingOtherField = [];
          //Aman 5006 - Extract values from "extra21" to "extra30"
          if (customMapping[0]) {
            for (let i = 21; i <= 30; i++) {
              const key = `extra${i}`;
              const updatedKey = `Extra ${i}`;
              const value = customMapping[0][key];
              if (value !== null && value !== undefined && value !== "") {
                customMappingOtherField.push({ [updatedKey]: value });
              }
            }
          }
          if (customMapping.length === 0) {
            //Sandeep-task-2879 added code to handle format table display issue
            var agencyAdvertiserMap = [
              {
                pID: "Publisher ID",
                campID: "Campaign ID",
                campaignName: "campaign Name",
                leadInteractionDate: "Lead Interaction Date",
                firstName: "First Name",
                lastName: "Last Name",
                companyName: "Company Name",
                linkedInCompanyName: "LinkedIn Company Name",
                email: "Email",
                workPhone: "Work Phone",
                workPhoneFormat: "91-XXXXXXXXXX",
                jobTitle: "Job Title",
                linkedInJobTitle: "LinkedIn Job Title",
                address: "Address",
                country: "Country",
                city: "City",
                state: "State",
                zipCode: "Zip Code",
                companyEmployeeSize: "Company Employee Size",
                companyRevenue: "company Revenue",
                industry: "Industry",
                assetName: "Asset Name",
                assetNameTouch1: "Asset Name Touch 1",
                assetTimestampTouch1: "Asset Timestamp Touch 1",
                assetNameTouch2: "Asset Name Touch 2",
                assetTimestampTouch2: "Asset Timestamp Touch 2",
                assetNameTouch3: "Asset Name Touch 3",
                assetTimestampTouch3: "Asset Timestamp Touch 3",
                street: "Street",
                ip: "IP",
                supportDocID: "Asset ID",
                jobLevel: "Job Level",
                jobFunction: "Job Function",
                channel: "Channel",
                extra1: "Extra 1",
                extra2: "Extra 2",
                extra3: "Extra 3",
                extra4: "Extra 4",
                extra5: "Extra 5",
                extra6: "Extra 6",
                extra7: "Extra 7",
                extra8: "Extra 8",
                extra9: "Extra 9",
                extra10: "Extra 10",
                extra11: "Extra 11",
                extra12: "Extra 12",
                extra13: "Extra 13",
                extra14: "Extra 14",
                extra15: "Extra 15",
                extra16: "Extra 16",
                extra17: "Extra 17",
                extra18: "Extra 18",
                extra19: "Extra 19",
                extra20: "Extra 20", //Sandeep-task-3158-added code for extra 15 fields
                domain: "Domain",
                alternatePhoneNo: "Alternate Phone No",
                comments: "Comments",
                linkedIn: "LinkedIn",
              },
            ];
            //sandeep task-2879 addded array to store agencyAdvertiserMapping data
            var customMapping = [
              {
                pID: "",
                campID: "",
                campaignName: "",
                leadInteractionDate: "",
                firstName: "",
                lastName: "",
                companyName: "",
                linkedInCompanyName: "",
                email: "",
                workPhone: "",
                workPhoneFormat: "",
                jobTitle: "",
                linkedInJobTitle: "",
                address: "",
                country: "",
                city: "",
                state: "",
                zipCode: "",
                companyEmployeeSize: "",
                companyRevenue: "",
                industry: "",
                assetName: "",
                assetNameTouch1: "",
                assetTimestampTouch1: "",
                assetNameTouch2: "",
                assetTimestampTouch2: "",
                assetNameTouch3: "",
                assetTimestampTouch3: "",
                street: "",
                ip: "",
                supportDocID: "",
                jobLevel: "",
                jobFunction: "",
                channel: "",
                extra1: "",
                extra2: "",
                extra3: "",
                extra4: "",
                extra5: "",
                extra6: "",
                extra7: "",
                extra8: "",
                extra9: "",
                extra10: "",
                extra11: "",
                extra12: "",
                extra13: "",
                extra14: "",
                extra15: "",
                extra16: "",
                extra17: "",
                extra18: "",
                extra19: "",
                extra20: "", //Sandeep-task-3158-added code for extra 15 fields
                domain: "",
                alternatePhoneNo: "",
                comments: "",
                linkedIn: "",
              },
            ];
            //sandeep task-2879 addded array to store customMapping  data
            this.state.advertiserMapping.length = 0;
            this.state.agencyAdvertiserMap = [...advertiserMapping];
            this.state.advertiserMapping = [...customMapping];
            //Chaitanya-4962-When nonEnglishCheck value is Yes isCheckednonEnglish will be true
            if (customMapping[0].nonEnglishCheck == "Yes") {
              this.state.isCheckednonEnglish = true;
            }

            this.setState({
              advertiserMapping: customMapping,
              agencyAdvertiserMap: agencyAdvertiserMap,
              length: advertiserMapping.length,
              nonEnglishChecked: customMapping[0].nonEnglishCheck, //Chaitanya-4962-getting nonEnglishCheck value from BE
              customMappingOtherField: customMappingOtherField //Aman 5006 -set customMappingOtherField value
            }); //sandeep -task-2879 removing advertiserID  from setState as there is no use of that field and length has duplicate value so length also removed from setState
          } else {
            this.state.agencyAdvertiserMap = [...advertiserMapping];
            this.state.advertiserMapping = [...customMapping];
            this.setState({
              advertiserMapping: customMapping,
              agencyAdvertiserMap: this.state.advertiserMapping,
            });
          }
          this.state.advertiserMapping = [...customMapping];
          //Chaitanya-4962-When nonEnglishCheck value is Yes isCheckednonEnglish will be true
          if (customMapping[0].nonEnglishCheck == "Yes") {
            this.state.isCheckednonEnglish = true;
          }
          this.setState({
            advertiserMapping: customMapping,
            advertiserID: this.state.advertiserID,
            length: advertiserMapping.length,
            nonEnglishChecked: customMapping[0].nonEnglishCheck, //Chaitanya-4962-getting nonEnglishCheck value from BE
            customMappingOtherField: customMappingOtherField //Aman 5006 -set customMappingOtherField value
          });
        } else {
          //Sandeep-task-3158-added code for extra 15 fields
          var agencyAdvertiserMap = [
            {
              pID: "Publisher ID",
              campID: "Campaign ID",
              campaignName: "campaign Name",
              leadInteractionDate: "Lead Interaction Date",
              firstName: "First Name",
              lastName: "Last Name",
              companyName: "Company Name",
              linkedInCompanyName: "LinkedIn Company Name",
              email: "Email",
              workPhone: "Work Phone",
              workPhoneFormat: "91-XXXXXXXXXX",
              jobTitle: "Job Title",
              linkedInJobTitle: "LinkedIn Job Title",
              address: "Address",
              country: "Country",
              city: "City",
              state: "State",
              zipCode: "Zip Code",
              companyEmployeeSize: "Company Employee Size",
              companyRevenue: "company Revenue",
              industry: "Industry",
              assetName: "Asset Name",
              assetNameTouch1: "Asset Name Touch 1",
              assetTimestampTouch1: "Asset Timestamp Touch 1",
              assetNameTouch2: "Asset Name Touch 2",
              assetTimestampTouch2: "Asset Timestamp Touch 2",
              assetNameTouch3: "Asset Name Touch 3",
              assetTimestampTouch3: "Asset Timestamp Touch 3",
              street: "Street",
              ip: "IP",
              supportDocID: "Asset ID",
              jobLevel: "Job Level",
              jobFunction: "Job Function",
              channel: "Channel",
              extra1: "Extra 1",
              extra2: "Extra 2",
              extra3: "Extra 3",
              extra4: "Extra 4",
              extra5: "Extra 5",
              extra6: "Extra 6",
              extra7: "Extra 7",
              extra8: "Extra 8",
              extra9: "Extra 9",
              extra10: "Extra 10",
              extra11: "Extra 11",
              extra12: "Extra 12",
              extra13: "Extra 13",
              extra14: "Extra 14",
              extra15: "Extra 15",
              extra16: "Extra 16",
              extra17: "Extra 17",
              extra18: "Extra 18",
              extra19: "Extra 19",
              extra20: "Extra 20",
              domain: "Domain",
              alternatePhoneNo: "Alternate Phone No",
              comments: "Comments",
              linkedIn: "LinkedIn",
            },
          ];
          this.state.agencyAdvertiserMap = [...advertiserMapping];

          this.setState({
            advertiserMapping: advertiserMapping,
            agencyAdvertiserMap: agencyAdvertiserMap,
            advertiserID: this.state.advertiserID,
            length: advertiserMapping.length,
            nonEnglishChecked: "No", //Chaitanya-4984-Non English check box value will be refresh for Newly created Advertiser
          });
        }
      });
    //Rutuja 3478 fetching selected client name 
    fetch("campaign/getAdvName", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((advertiserName) => {
        this.setState({ advertiserName: advertiserName });
      })

    this.setState({
      userID: userID, advertiserID: advertiserID, searchQuery: '',
    });
  }
  //snehal-task-3090-Add validation for customize mapping- User can't add alias name only number(added function for validation)
  validateForm() {
    //Aman 5006 - map method to Remove spaces from the key  names and convert them into lowercase
    var customMappingExtraField = [];
    if (Array.isArray(this.state.customMappingOtherField) && this.state.customMappingOtherField.length > 0) {
      customMappingExtraField = this.state.customMappingOtherField.map(obj => {
        const newObj = {};
        for (let key in obj) {
          const newKey = key.toLowerCase().replace(/\s+/g, '');
          newObj[newKey] = obj[key];
        }
        return newObj;
      });
    }

    //Aman 5006 - Merge the array of objects into a single object
    customMappingExtraField = Object.assign({}, ...customMappingExtraField);

    //Aman 5006 - Extract only values of ExtraFieldvalues
    const ExtraFieldvalues = Object.values(customMappingExtraField);

    const {
      pID,
      campID,
      campaignName,
      leadInteractionDate,
      firstName,
      lastName,
      companyName,
      linkedInCompanyName,
      email,
      workPhone,
      workPhoneFormat,
      jobTitle,
      linkedInJobTitle,
      address,
      country,
      city,
      state,
      zipCode,
      companyEmployeeSize,
      companyRevenue,
      industry,
      assetName,
      assetNameTouch1,
      assetTimestampTouch1,
      assetNameTouch2,
      assetTimestampTouch2,
      assetNameTouch3,
      assetTimestampTouch3,
      street,
      ip,
      supportDocID,
      jobLevel,
      jobFunction,
      channel,
      extra1,
      extra2,
      extra3,
      extra4,
      extra5,
      extra6,
      extra7,
      extra8,
      extra9,
      extra10,
      extra11,
      extra12,
      extra13,
      extra14,
      extra15,
      extra16,
      extra17,
      extra18,
      extra19,
      extra20, //Sandeep-task-3158-added code for extra 15 fields
      domain,
      alternatePhoneNo,
      comments,
      linkedIn,
    } = this.state.agencyAdvertiserMap[0];

    //Aman 5006 - taking value of extra other Fields from customMappingExtraField
    const { extra21, extra22, extra23, extra24, extra25, extra26, extra27, extra28, extra29, extra30 } = customMappingExtraField;

    let formIsvalid = true;
    //Sanjana-3897-Customize mapping validation for duplicate alias name
    let aliasname = false;
    let workPhoneFormatValue = false;
    let sameValue = false;
    let allvalue = false; //4779
    let difieldValue = false; //rutuja 4832 for similar system fields
    const agencyAdvertiserMap = this.state.agencyAdvertiserMap[0] //Aman 5006 - added to take all fileds value from agencyAdvertiserMap

    //Aman 5006 - Define the keys to be removed from agencyAdvertiserMap (getting issue to handle duplicate  values in setState)
    const keysToRemove = ["created", "lastUpdated", "nonEnglishCheck", "extra21", "extra22", "extra23", "extra24", "extra25", "extra26", "extra27", "extra28", "extra29", "extra30"]; //Aman-5204-added nonEnglishCheck to resolve the issue of Duplicate alias 
    //Aman 5006 - Remove the specified keys from the object and filter null values
    const filteredMap = Object.fromEntries(
      Object.entries(agencyAdvertiserMap)
        .filter(([key, value]) => !keysToRemove.includes(key) && value !== null)
    );
    //Sanjana-3897-Customize mapping validation for duplicate alias name
    let arrOne = Object.values(filteredMap).filter(value => value !== null && value !== ""); //Aman 5006 - chnaged the this.state.agencyAdvertiserMap[0] with filteredMap to get Filter out null and empty string values
    // arrOne.splice(-2); //kiran- 4299-custom mapping edited  //Aman 5006 - commneted it is removing  last two element from filteredMap

    //Aman 5006 - added to Merge arrOne and ExtraFieldvalues arrays 
    const mergedArray = arrOne.concat(ExtraFieldvalues);

    //  var pattern = new RegExp(/^(|[0-9]\d*)$/);
    //var pattern = new RegExp(/^(|[_0-9!@#$%^&*(),.":{}\]\[+=~|<>?/;'~`-]\d*)$/); //Kiran-4848 changed pattern for not accepting only these symbols
    var pattern;
    //Chaitanya-4962-Changed pattern for Non English Checked
    if (this.state.nonEnglishChecked == "Yes") {
      pattern = /^([0-9]\d*)$/;
    } else {
      pattern = /^([0-9]\d*|[\W_]+)$/; //kiran-4861-changed pattern not accepting multiple special symbols and also for space
    }
    var duplicateAliasName = []; // Rutuja task 4832-declared the array for stroing duplcate values

    //Rutuja Task -4779 Added validation if duplicate alias name found also hange the below code for showing proper error messages
    for (let i = 0; i < mergedArray.length; i++) { //Aman 5006 - replace the arrOne with mergedArray to get both value of arrone and other extra fileds
      if (mergedArray[i] === "") { //Aman 5006 - replace the arrOne with mergedArray to get both duplicate value from fileds
        allvalue = false; //Aman-4971-chnaged the flag true to false to accpet blank filed for edit mapping fileds
        this.setState({ validationDisplay2: "none" }); //Aman-4971-chnaged the flag Block to none to do not show error msg for blank fileds
      }
    }
    if (allvalue !== true) {
      if (
        !pattern.test(pID) &&
        !pattern.test(campID) &&
        !pattern.test(leadInteractionDate) &&
        !pattern.test(firstName) &&
        !pattern.test(lastName) &&
        !pattern.test(campaignName) &&
        !pattern.test(companyName) &&
        !pattern.test(linkedInCompanyName) &&
        !pattern.test(email) &&
        !pattern.test(workPhone) &&
        !pattern.test(workPhoneFormat) &&
        !pattern.test(jobTitle) &&
        !pattern.test(linkedInJobTitle) &&
        !pattern.test(address) &&
        !pattern.test(country) &&
        !pattern.test(city) &&
        !pattern.test(state) &&
        !pattern.test(zipCode) &&
        !pattern.test(companyEmployeeSize) &&
        !pattern.test(companyRevenue) &&
        !pattern.test(industry) &&
        !pattern.test(assetName) &&
        !pattern.test(assetNameTouch1) &&
        !pattern.test(assetTimestampTouch1) &&
        !pattern.test(assetNameTouch2) &&
        !pattern.test(assetTimestampTouch2) &&
        !pattern.test(assetNameTouch3) &&
        !pattern.test(assetTimestampTouch3) &&
        !pattern.test(street) &&
        !pattern.test(ip) &&
        !pattern.test(supportDocID) &&
        !pattern.test(jobLevel) &&
        !pattern.test(jobFunction) &&
        !pattern.test(channel) &&
        !pattern.test(extra1) &&
        !pattern.test(extra2) &&
        !pattern.test(extra3) &&
        !pattern.test(extra4) &&
        !pattern.test(extra5) &&
        !pattern.test(extra6) &&
        !pattern.test(extra7) &&
        !pattern.test(extra8) &&
        !pattern.test(extra9) &&
        !pattern.test(extra10) &&
        !pattern.test(extra11) &&
        !pattern.test(extra12) &&
        !pattern.test(extra13) &&
        !pattern.test(extra14) &&
        !pattern.test(extra15) &&
        !pattern.test(extra16) &&
        !pattern.test(extra17) &&
        !pattern.test(extra18) &&
        !pattern.test(extra19) &&
        !pattern.test(extra20) &&
        !pattern.test(domain) &&
        !pattern.test(alternatePhoneNo) &&
        !pattern.test(comments) &&
        !pattern.test(linkedIn) &&
        !pattern.test(extra21) && //Aman 5006 - added to extra21 to extra30 to check it value with pattern
        !pattern.test(extra22) &&
        !pattern.test(extra23) &&
        !pattern.test(extra24) &&
        !pattern.test(extra25) &&
        !pattern.test(extra26) &&
        !pattern.test(extra27) &&
        !pattern.test(extra28) &&
        !pattern.test(extra29) &&
        !pattern.test(extra30)
      ) {
        aliasname = false;
        for (let i = 0; i < mergedArray.length; i++) { //Aman 5006 - replace the arrOne with mergedArray to get both duplicate value from fileds
          for (let k = i + 1; k < mergedArray.length; k++) { //Aman 5006 - replace the arrOne with mergedArray to get both duplicate value from fileds
            //Rutuja TAsk-4779 comparing two obj by converting to lower case
            let obj1 = mergedArray[i].toString(); //Aman 5006 - replace the arrOne with mergedArray to get both duplicate value from fileds
            let obj2 = mergedArray[k].toString(); //Aman 5006 - replace the arrOne with mergedArray to get both duplicate value from fileds
            //Aman-4981-(added new condtion to handle empty filed)- Customize Mapping - Showing duplicate error for two blank alias name
            if (
              obj1.toLowerCase() !== obj2.toLowerCase() ||
              (obj1.toLowerCase() === "" && obj2.toLowerCase() === "")
            ) {
              //Ignore
            } else {
              //Rutuja task 4832 For showing array if duplicate fields found
              duplicateAliasName.push(mergedArray[i]); //Aman 5006 - replace the arrOne with mergedArray to store duplicateAliasName of both
              duplicateAliasName = [...new Set(duplicateAliasName)];

              sameValue = true;
              this.setState({
                validationDisplay1: "block",
                duplicateAliasName: duplicateAliasName,
              }); // Rutuja 4832 set the value of duplicate alais name
            }
          }
        }

        //task 4779 taking for loop inside if statement for printing one error at once
      } else {
        aliasname = true;
        this.setState({ validationDisplay: "block" });
      }
    }
    //Rutuja Task 4832: similar system fields declared in the array
    //Aman-4971-commented the below values from the array to accept those values in other filed as well as metioend in boards list
    var difields = [
      {
        pID: "Publisher ID",
        campID: "Campaign ID",
        // campaignName: "campaign Name",
        leadInteractionDate: "Lead Interaction Date",
        firstName: "First Name",
        lastName: "Last Name",
        companyName: "Company Name",
        linkedInCompanyName: "LinkedIn Company Name",
        email: "Email",
        workPhone: "Work Phone",
        // workPhoneFormat: "91-XXXXXXXXXX",
        jobTitle: "Job Title",
        linkedInJobTitle: "LinkedIn Job Title",
        address: "Address",
        country: "Country",
        city: "City",
        state: "State",
        zipCode: "Zip Code",
        companyEmployeeSize: "Company Employee Size",
        companyRevenue: "company Revenue",
        industry: "Industry",
        assetName: "Asset Name",
        // assetNameTouch1: "Asset Name Touch 1",
        // assetTimestampTouch1: "Asset Timestamp Touch 1",
        // assetNameTouch2: "Asset Name Touch 2",
        // assetTimestampTouch2: "Asset Timestamp Touch 2",
        // assetNameTouch3: "Asset Name Touch 3",
        // assetTimestampTouch3: "Asset Timestamp Touch 3",
        // street: "Street",
        // ip: "IP",
        // supportDocID: "Asset ID",
        jobLevel: "Job Level",
        jobFunction: "Job Function",
        // channel: "Channel",
        // extra1: "Extra 1",
        // extra2: "Extra 2",
        // extra3: "Extra 3",
        // extra4: "Extra 4",
        // extra5: "Extra 5",
        // extra6: "Extra 6",
        // extra7: "Extra 7",
        // extra8: "Extra 8",
        // extra9: "Extra 9",
        // extra10: "Extra 10",
        // extra11: "Extra 11",
        // extra12: "Extra 12",
        // extra13: "Extra 13",
        // extra14: "Extra 14",
        // extra15: "Extra 15",
        // extra16: "Extra 16",
        // extra17: "Extra 17",
        // extra18: "Extra 18",
        // extra19: "Extra 19",
        // extra20: "Extra 20",
        // domain: "Domain",
        // alternatePhoneNo: "Alternate Phone No",
        // comments: "Comments",
        // linkedIn: "LinkedIn",
      },
    ];
    this.state.difields = [...difields];
    this.setState({ difields: this.state.difields });
    let fieldsvalues = Object.values(this.state.difields[0]);
    let fieldskeys = Object.keys(this.state.difields[0]);
    let custommappingkeys = Object.keys(this.state.agencyAdvertiserMap[0]);
    let custommappingvalues = Object.values(this.state.agencyAdvertiserMap[0]);

    var systemFields = [];
    //Rutuja 4832 added for loop for checking if DI fields are using in other fields or not e.g if publisher id is the DI field still it is used under campaign ID fields the it will show error

    let fieldsvaluesNonEnglish = Object.values(this.state.difields[0]);
    let fieldskeysNonEnglish = Object.keys(this.state.difields[0]);
    let custommappingkeysNonEnglish = Object.keys(
      this.state.agencyAdvertiserMap[0]
    );
    let custommappingvaluesNonEnglish = Object.values(
      this.state.agencyAdvertiserMap[0]
    );
    //Chaitanya-4962-added for loop for checking if DI fields are using in other fields or not e.g if publisher id is the DI field For Non-english check in if condition
    //still it is used under campaign ID fields the it will show error for Non-English fields

    if (this.state.nonEnglishChecked == "Yes") {
      //Rutuja task 5193 concatinating extra 21-30 fields
      //Rutuja task 5230 added if condition only concat if extra fields are added
      if (this.state.customMappingOtherField.length > 0) {
        custommappingvaluesNonEnglish = custommappingvaluesNonEnglish.concat(
          this.state.customMappingOtherField.reduce((acc, obj) => {
            return acc.concat(Object.values(obj));
          }, [])
        );
        custommappingkeysNonEnglish = custommappingkeysNonEnglish.concat(
          this.state.customMappingOtherField.reduce((acc, obj) => {
            return acc.concat(Object.keys(obj));
          }, [])
        );
        // custommappingkeysNonEnglish = custommappingkeysNonEnglish.concat((Object.keys(this.state.customMappingOtherField))); 
      }

      for (let i = 0; i < custommappingvaluesNonEnglish.length; i++) {
        let obj1 = custommappingvaluesNonEnglish[i];
        let obj2 = custommappingkeysNonEnglish[i];

        for (let j = 0; j < fieldsvaluesNonEnglish.length; j++) {
          let obj3 = fieldsvaluesNonEnglish[j];
          let obj4 = fieldskeysNonEnglish[j];

          if (obj1 === obj3) {
            if (obj2 !== obj4) {
              difieldValue = true;
              systemFields.push(custommappingvaluesNonEnglish[i]);
              systemFields = [...new Set(systemFields)];
              this.setState({
                validationDisplay3: "block",
                systemFields: systemFields,
              });
            }
          }
        }
        //If status leadinfoid ,reason and agencycampid is used in custom mapping then also error shows becz this fields are di standered fields
        if (
          obj1 === "status" ||
          obj1 === "leadinfoid" ||
          obj1 === "reason" ||
          obj1 === "agencycampid"
        ) {
          difieldValue = true;
          systemFields.push(custommappingvaluesNonEnglish[i]);
          systemFields = [...new Set(systemFields)];
          this.setState({
            validationDisplay3: "block",
            systemFields: systemFields,
          });
        }
      }
    } else {
      //Rutuja task 5193 concatinating extra 21-30 fields 
      //Rutuja task 5230 added if condition only concat if extra fields are added
      if (this.state.customMappingOtherField.length > 0) {
        // custommappingvalues = custommappingvalues.concat(Object.values(this.state.customMappingOtherField));
        // custommappingkeys = custommappingkeys.concat(Object.keys(this.state.customMappingOtherField));
        custommappingvalues = custommappingvalues.concat(
          this.state.customMappingOtherField.reduce((acc, obj) => {
            return acc.concat(Object.values(obj));
          }, [])
        );
        custommappingkeys = custommappingkeys.concat(
          this.state.customMappingOtherField.reduce((acc, obj) => {
            return acc.concat(Object.keys(obj));
          }, [])
        );
      }
      for (let i = 0; i < custommappingvalues.length; i++) {
        let obj1 = custommappingvalues[i];
        if (obj1 !== null && obj1 !== undefined) {
          obj1 = obj1.toString().replace(/\s/g, "").toLowerCase();
        }

        let obj2 = custommappingkeys[i]
        if (obj2 !== null && obj2 !== undefined) {
          obj2 = obj2.toString().replace(/\s/g, "").toLowerCase();
        }


        for (let j = 0; j < fieldsvalues.length; j++) {
          let obj3 = fieldsvalues[j]
            .toString()
            .replace(/\s/g, "")
            .toLowerCase();
          let obj4 = fieldskeys[j].toString().replace(/\s/g, "").toLowerCase();

          if (obj1 === obj3) {
            if (obj2 !== obj4) {
              difieldValue = true;
              systemFields.push(custommappingvalues[i]);
              systemFields = [...new Set(systemFields)];
              this.setState({
                validationDisplay3: "block",
                systemFields: systemFields,
              });
            }
          }
        }
        //If status leadinfoid ,reason and agencycampid is used in custom mapping then also error shows becz this fields are di standered fields
        if (
          obj1 === "status" ||
          obj1 === "leadinfoid" ||
          obj1 === "reason" ||
          obj1 === "agencycampid"
        ) {
          difieldValue = true;
          systemFields.push(custommappingvalues[i]);
          systemFields = [...new Set(systemFields)];
          this.setState({
            validationDisplay3: "block",
            systemFields: systemFields,
          });
        }
      }
    }

    // for (let i = 0; i < custommappingvalues.length; i++)
    // {

    // 	let obj1 = custommappingvalues[i].toString().replace(/\s/g, '').toLowerCase();
    // 	let obj2 = custommappingkeys[i].toString().replace(/\s/g, '').toLowerCase();

    // 	for(let j=0;j < fieldsvalues.length;j++)
    // 	{
    // 		let obj3 = fieldsvalues[j].toString().replace(/\s/g, '').toLowerCase();
    // 		let obj4 = fieldskeys[j].toString().replace(/\s/g, '').toLowerCase();

    // 		if(obj1 === obj3)
    // 		{

    // 				if (obj2 !== obj4)
    // 				{
    // 					difieldValue = true;
    // 					systemFields.push(custommappingvalues[i])
    // 					systemFields = [...new Set(systemFields)];
    // 					this.setState({ validationDisplay3: "block",systemFields:systemFields });
    // 				}
    // 		}

    // 	}
    // 	//If status leadinfoid ,reason and agencycampid is used in custom mapping then also error shows becz this fields are di standered fields
    // 	if((obj1 === "status")||(obj1==="leadinfoid")||(obj1==="reason")||(obj1==="agencycampid"))
    // 		 {
    // 			difieldValue = true;
    // 			systemFields.push(custommappingvalues[i])
    // 			systemFields = [...new Set(systemFields)];
    // 			this.setState({ validationDisplay3: "block" ,systemFields:systemFields});
    // 		}
    // }

    //karan-task-3477-work phone format new validations-START
    let workPhoneFormatPattern = new RegExp(
      //  /^(\d)+(-|_|\s){1}(x|X)(x|X|\s|-)*$/
      // /^[(\d)|()(x|X|\s)+][^|](?!.*\+)(?!.*\*)(?!.*\!)(?!.*\@)(?!.*\#)(?!.*\$)(?!.*\%)(?!.*\^)(?!.*\<)(?!.*\?)(?!.*\>)(?!.*\&)(?!.*\:)(?!.*\;)(?!.*\')(?!.*\")(?!.*\|)+(?!.*\/)(?!.*\`)(?!.*\₹)(?!.*\€)(?!.*\=)(?!.*\{)(?!.*\})(?!.*\[)(?!.*\])(?!.*\~)[^(|)](?!.*\\)[^|](-|_|()|.|x|X|\s){1}(.|x|X|-)(.|x|X|\s|-)*$/
      //   /^[(\d)|()(x|X|\s)+]+(x|X|\s)(?!.*[a-wyzA-WYZ])(?!.*\*)(?!.*\!)(?!.*\@)(?!.*\#)(?!.*\$)(?!.*\%)(?!.*\^)(?!.*\<)(?!.*\?)(?!.*\>)(?!.*\&)(?!.*\:)(?!.*\;)(?!.*\')(?!.*\")(?!.*\|)+(?!.*\/)(?!.*\`)(?!.*\₹)(?!.*\€)(?!.*\=)(?!.*\{)(?!.*\})(?!.*\[)(?!.*\])(?!.*\~)[^(|)](?!.*\\)[^|](-|_|()|.|x|X|\s)(?!.*[a-wyzA-WYZ]){1}(.|x|X|-)(.|x|X|\s|-)*$/
      /^(?!.*\(\()(?!.*\-\-)(?!.*\+\+)(?!.*\)\))[(\d)|()(x|X|\s)+-]+(x|X|\s)(?!.*[a-wyzA-WYZ])(?!.*\(\()(?!.*\*)(?!.*\!)(?!.*\@)(?!.*\#)(?!.*\$)(?!.*\%)(?!.*\^)(?!.*\<)(?!.*\?)(?!.*\>)(?!.*\&)(?!.*\:)(?!.*\;)(?!.*\')(?!.*\")(?!.*\|)+(?!.*\/)(?!.*\`)(?!.*\₹)(?!.*\€)(?!.*\=)(?!.*\_)(?!.*\{)(?!.*\})(?!.*\[)(?!.*\])(?!.*\~)[^(|)](?!.*\\)[^|](-|_|()|.|x|X|\s)(?!.*[a-wyzA-WYZ]){1}(.|x|X|-)(.|x|X|\s|-)*$/
    ); //karan-task-3477-work phone format regex
    //  kiran-4671-work phone format  pattern changed for new requirement
    //kiran-4688,4689 changed regex pattern for not accepting other special symbols, alphabets,_ , ((,)) other than x|X
    let workPhoneFormatArr = workPhoneFormat
      .split(/\||\n/) //karan-task-3477-to split the array using | or new line
      .filter((obj) => obj.trim().length !== 0); //karan-task-3477-removing empty data
    let validCounter = 0;
    let workPhoneFormatTemp = "";
    let duplicateWorkPhoneFormatErrorTemp = false;
    let workPhoneFormatSet = new Set(workPhoneFormatArr);
    if (workPhoneFormatSet.size !== workPhoneFormatArr.length) {
      duplicateWorkPhoneFormatErrorTemp = true; //karan-task-3477-error if duplicates found
    } else if (
      workPhoneFormatSet.size === workPhoneFormatArr.length &&
      workPhoneFormatArr.length !== 0
    ) {
      //karan-task-3477-ignore case and then compare
      let tempArr = [...workPhoneFormatSet];
      for (let i = 0; i < workPhoneFormatArr.length; i++) {
        let obj1 = workPhoneFormatArr[i];
        for (let j = 0; j < tempArr.length; j++) {
          let obj2 = tempArr[j];
          if (obj1.toLowerCase() === obj2.toLowerCase() && i !== j) {
            duplicateWorkPhoneFormatErrorTemp = true;
            break;
          }
        }
      }
    } else if (workPhoneFormatArr.length === 0) {
      //karan-task-3477-this is for empty data
      // workPhoneFormat = false;
      workPhoneFormatValue = false; //Rutuja task 4844 commented above line as we have to workphoneformat value rather than workPhoneFormat
      //Aman-4971-chnaged the flag Block to none to do not show error msg for blank fileds
      this.setState({ validationDisplay2: "none" }); //Rutuja task 4844 change display message from validationDisplay to validationDisplay2
    }
    for (let i = 0; i < workPhoneFormatArr.length; i++) {
      let obj = workPhoneFormatArr[i].trim();
      if (workPhoneFormatPattern.test(obj) && obj !== "") {
        //karan-task-3477-validating format
        validCounter++;
        if (workPhoneFormatTemp === "") {
          workPhoneFormatTemp += obj;
        } else {
          workPhoneFormatTemp += "|" + obj;
        }
      }
    }
    if (validCounter === workPhoneFormatArr.length) {
      let agencyAdvertiserMapTemp = [...this.state.agencyAdvertiserMap];
      agencyAdvertiserMapTemp[0] = {
        ...agencyAdvertiserMapTemp[0],
        workPhoneFormat: workPhoneFormatTemp,
      };
      if (duplicateWorkPhoneFormatErrorTemp) {
        workPhoneFormatValue = true;
      }
      this.setState({
        workPhoneFormatError: false, //karan-task-3477-resolving error states
        duplicateWorkPhoneFormatError: duplicateWorkPhoneFormatErrorTemp, //karan-task-3477-resolving error states
        agencyAdvertiserMap: agencyAdvertiserMapTemp, //karan-task-3477-generating main array again
      });
    } else {
      workPhoneFormatValue = true;
      this.setState({
        workPhoneFormatError: true, //karan-task-3477-resolving error states
        duplicateWorkPhoneFormatError: duplicateWorkPhoneFormatErrorTemp, //karan-task-3477-resolving error states
      });
    }
    //karan-task-3477-work phone format new validations-END
    //Sanjana-3897-Customize mapping validation for duplicate alias name
    if (
      sameValue == true ||
      aliasname == true ||
      workPhoneFormatValue == true ||
      allvalue == true ||
      difieldValue == true
    ) {
      //4779
      formIsvalid = false;
    }

    document.body.scrollTop = (0, 0);
    document.documentElement.scrollTop = (0, 0);
    return formIsvalid;
  }
  getMappin() {
    //Rutuja 4832
    this.setState({
      validationDisplay: "none",
      validationDisplay1: "none",
      validationDisplay2: "none",
      validationDisplay3: "none",
      systemFields: "",
      duplicateAliasName: "",
    });
    var e = document.getElementById("clientName");
    var strUser = e.options[e.selectedIndex].text;
    let errors = {};
    if (strUser === "Select Advertiser" || strUser === "") {
      this.setState({ clientNameDisplay: "block" });
      document.getElementById("clientName").style.border = "1px solid red";
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0; //kiran-4846-added scrollTop
    } else {
      //snehal-task-3090-Add validation for customize mapping- User can't add alias name only number(call for validation)
      if (this.validateForm() === false) {
      } else {
        document.getElementById("clientName").style.border =
          "1px solid lightgray";
        this.setState({ clientNameDisplay: "none" });
        const { user } = this.props.auth;
        let data = {
          advertiserID: this.state.advertiserID,
          mappingValues: this.state.agencyAdvertiserMap,
          format: this.state.format,
          nonEnglishChecked: this.state.nonEnglishChecked, //Chaitanya-4962-Set Non English Checked value
          customMappingExtraField: this.state.customMappingOtherField, //Aman 5006 - set the customMappingOtherField value
          oldcustomMapping: this.state.oldcustomMapping,  //Aman-5144 added old customMapping to pass in DB for mapping_log
          customMappingStatus: this.state.customMappingStatus //Aman-5144 added pass the value of customMapping Status to pass in DB for mapping_log
        }; // karan-task-3723-vapt header and query params
        fetch("/campaign/setAgencyDeliveryFormatMapping", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((response) => {

            Swal.fire({
              text: "Customize Mapping Done",
              type: "success",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              preConfirm: () => {
                window.location.reload();
              },
            });
          });
      }
    }
  } // end of getMapp
  agencyMappinHandleChange(e) {


    const { name, value } = e.target;
    let agencyAdvertiserMap = [...this.state.agencyAdvertiserMap];
    agencyAdvertiserMap[0] = { ...agencyAdvertiserMap[0], [name]: value };
    this.state.agencyAdvertiserMap = [...agencyAdvertiserMap];

    //Rutuja 3478 if Extra 21-30 are changed then calling function handleFieldChange for setting value in customMappingOtherFields
    const extra = ["extra21", "extra22", "extra23", "extra24", "extra25", "extra26", "extra27", "extra28", "extra29", "extra30"]
    if (extra.includes(name)) {
      const formattedName = name.replace(/extra(\d+)/, (_, num) => `Extra ${num}`);

      this.handleFieldChange(formattedName, value); // Call handleFieldChange with the name and value
    }

    this.setState({
      agencyAdvertiserMap: agencyAdvertiserMap,
      validationDisplay: "none",
      // validationDisplay1:"none", //4832
      validationDisplay2: "none",
      //  validationDisplay3:"none", //Rutuja task-4832
      //  systemFields:"", //4832
      //  duplicateAliasName:"",//4832
    });
  }
  //Chaitanya-4962-Function for non-english checkbox checked
  nonEnglishChange = () => {
    this.setState(
      {
        isCheckednonEnglish: !this.state.isCheckednonEnglish,
      },
      function () {
        if (this.state.isCheckednonEnglish == false) {
          this.setState({
            nonEnglishChecked: "No",
          });
        } else {
          this.setState({
            nonEnglishChecked: "Yes",
          });
        }
      }
    );
  };

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/userLogin");
    } else {
      const { user } = this.props.auth;
      var userID = user.id;
      fetch("/campaign/getAgencyAdvertiser") // karan-task-3723-vapt header and query params
        .then((res) => res.json())
        .then((clientDetails) => {
          this.setState({ clientDetails: clientDetails });
        });
    }
  }

  /**
 * @author Aman Shaikh - 5006
 * @param  Description  handling extra FieldChange
 * @return Description handling extra FieldChange and update the current value
 */

  //Rutuja 3478 changed below function 
  handleFieldChange(fieldName, value) {
    this.setState((prevState) => {
      const updatedMapping = [...prevState.customMappingOtherField];

      // Find the object that contains the field name as a key and update its value
      const fieldIndex = updatedMapping.findIndex((item) => Object.keys(item)[0] === fieldName);

      if (fieldIndex !== -1) {
        updatedMapping[fieldIndex][fieldName] = value;
      } else {
        console.warn(`Field name "${fieldName}" does not exist in customMappingOtherField.`);
      }

      return { customMappingOtherField: updatedMapping };
    }, () => {
      console.log("Updated customMappingOtherField:", JSON.stringify(this.state.customMappingOtherField));
    });
  }

  /**
 * @author Aman Shaikh - 5006
 * @param  Description  handling to add extra
 * @return Description handling to add extra in format ad bottom
 */
  // addCustomMappingField() {
  //   //Aman 5006 - Increment the counter to generate unique field names
  //   this.setState((prevState) => {
  //     //Aman 5006 - Array containing all the expected Extra values from 21 to 30
  //     const expectedExtras = Array.from({ length: 10 }, (_, index) => `Extra ${index + 21}`);

  //     //Aman 5006 - Get the existing Extra values from the state
  //     let existingExtras = []; //Aman-5133-(added array to set array)AC-Settings-Customize Mapping-Page crash issue
  //     if (Array.isArray(prevState.customMappingOtherField)) { //Aman-5133-(added to check array is empty or not)AC-Settings-Customize Mapping-Page crash issue
  //       existingExtras = prevState.customMappingOtherField.map(obj => Object.keys(obj)[0]);
  //     } else {}

  //     //Aman 5006 - Find the next missing Extra value
  //     const nextMissingExtra = expectedExtras.find(extra => !existingExtras.includes(extra));

  //     //Aman 5006 - If there is a missing Extra value, add it to customMappingOtherField
  //     if (nextMissingExtra) {
  //       const newField = { [nextMissingExtra]: nextMissingExtra };
  //       return {
  //         otherFieldCounter: prevState.otherFieldCounter + 1,
  //         customMappingOtherField: [
  //           ...prevState.customMappingOtherField,
  //           newField
  //         ],
  //       };
  //     } else {
  //       //Aman 5006 - If all Extra values are present, return the previous state
  //       return prevState;
  //     }
  //   }, () => {
  //   });
  //   console.log("customMappingOtherField"+JSON.stringify(this.state.customMappingOtherField))
  // }
  addCustomMappingField() {
    // Aman 5006 - Increment the counter to generate unique field names
    this.setState((prevState) => {
      // Aman 5006 - Array containing all the expected Extra values from 21 to 30
      const expectedExtras = Array.from({ length: 10 }, (_, index) => `Extra ${index + 21}`);

      // Aman 5006 - Get the existing Extra values from the state
      let existingExtras = [];
      if (Array.isArray(prevState.customMappingOtherField)) {
        existingExtras = prevState.customMappingOtherField.map(obj => Object.keys(obj)[0]);
      }

      // Aman 5006 - Find the next missing Extra value
      const nextMissingExtra = expectedExtras.find(extra => !existingExtras.includes(extra));



      // Aman 5006 - If there is a missing Extra value, add it to customMappingOtherField
      if (nextMissingExtra) {
        const newField = { [nextMissingExtra]: nextMissingExtra };
        return {
          otherFieldCounter: prevState.otherFieldCounter + 1,
          customMappingOtherField: [
            ...prevState.customMappingOtherField,
            newField
          ],
        };
      } else {
        // Aman 5006 - If all Extra values are present, return the previous state
        return prevState;
      }
    }, () => {
    });

  }


  /**
* @author Aman Shaikh - 5006
* @param  Description  handling to remove extra
* @return Description handling to remove added extra filed from format
*/
  removeCustomMappingField(index, e) {
    e.preventDefault();
    //Aman 5006 - Remove the item at the specified index from customMappingOtherField array
    const updatedCustomMappingOtherField = [...this.state.customMappingOtherField];
    updatedCustomMappingOtherField.splice(index, 1);
    //Aman 5006 - Update the state to remove the item
    this.setState({ customMappingOtherField: updatedCustomMappingOtherField });
  }
  //Rutuja 3478 added fuction when open and close the toggle
  //    toggleAccordion = (event) => {
  //      // Toggle the active class on the parent element of the clicked header
  //      const accordion = event.currentTarget.parentElement;
  //      const content = accordion.querySelector('.accordion-content');
  //      const toggleIcon = accordion.querySelector('.toggle-icon');

  //      // Toggle accordion content visibility
  //      accordion.classList.toggle('active');

  //      // Change the icon based on the accordion state
  //      if (accordion.classList.contains('active')) {
  //        toggleIcon.src = 'up-arrow.png'; // Open state icon
  //      } else {
  //        toggleIcon.src = 'down.png'; // Closed state icon
  //      }
  //  };
  //Rutuja 5524 changed toggleAccordion when search the fields then open that particular toggle 
  toggleAccordion = (event) => {
    // Toggle manually by clicking
    const accordion = event.currentTarget.parentElement;
    const content = accordion.querySelector('.accordion-content');
    const toggleIcon = accordion.querySelector('.toggle-icon');

    accordion.classList.toggle('active');
    if (accordion.classList.contains('active')) {
      content.style.display = 'block';
      toggleIcon.src = 'up-arrow.png'; // Open state icon
    } else {
      content.style.display = 'none';
      toggleIcon.src = 'down.png'; // Closed state icon
    }
  };

  //Rutuja 3478 added function view format
  handleViewFormat(e) {
    e.preventDefault(); // Prevent default behavior if necessary
  }
  //Rutuja 3478 added function for handle change search
  //  handleChangeSearch = (event) => {
  //    // Update searchQuery state on input change
  //    this.setState({ searchQuery: event.target.value });
  //  };
  //Rutuja 5524 changed handle search when search the fields then open that particular toggle 

  handleChangeSearch = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    this.setState({ searchQuery });

    // Automatically open matching accordions
    const accordions = document.querySelectorAll('.accordion');
    accordions.forEach((accordion) => {
      const content = accordion.querySelector('.accordion-content');
      const toggleIcon = accordion.querySelector('.toggle-icon');

      // Get all table rows in the accordion content
      const rows = content.querySelectorAll('tbody tr');
      let matchFound = false;

      rows.forEach((row) => {
        const rowText = row.innerText.toLowerCase(); // Combine all text in the row
        if (rowText.includes(searchQuery)) {
          matchFound = true; // If a match is found, set the flag
        }
      });

      if (searchQuery === '') {
        // Close all accordions if search query is empty
        accordion.classList.remove('active');
        content.style.display = 'none';
        toggleIcon.src = 'down.png'; // Closed state icon
      } else if (matchFound) {
        // Open accordion if there's a match in the table rows
        accordion.classList.add('active');
        content.style.display = 'block';
        toggleIcon.src = 'up-arrow.png'; // Open state icon
      } else {
        // Close accordion if no match is found
        accordion.classList.remove('active');
        content.style.display = 'none';
        toggleIcon.src = 'down.png'; // Closed state icon
      }
    });
  };








  //Rutuja 3478 reder row displaying table when open the toggle
  renderRow = (label, fieldName, data) => {
    //Declared the array for adding * after thier names in the table
    const requiredFields = ['firstName',
      'pID', 'campID', 'leadInteractionDate',
      'lastName',
      'companyName',
      'linkedInCompanyName',
      'email',
      'workPhone',
      'jobTitle',
      'linkedInJobTitle',
      'address',
      'country',
      'state',
      'city',
      'zipCode',
      'companyEmployeeSize',
      'industry',
      'assetName'];


    const value =
      this.state.agencyAdvertiserMap.length === 0
        ? data[fieldName]
        : this.state.agencyAdvertiserMap[0][fieldName];
    //Rutuja 5523 if length agencyAdvertiserMap is 0 then pass data[fieldName] otherwise pass agencyAdvertiserMap[0][fieldName] as alias name
    const alias = this.state.agencyAdvertiserMap.length === 0
      ? data[fieldName]
      : this.state.agencyAdvertiserMap[0][fieldName];

    const isScrollable = fieldName === 'workPhoneFormat' && value?.length > 150; // Rutuja 5544 add scroll for workphoneformat when there is length is > 150

    return (
      <tr key={fieldName}>
        <td >
          {label}
          {requiredFields.includes(fieldName) && <span style={{ color: 'red' }}> *</span>}
        </td> {/* Add asterisk for required fields */}
        {/* //Rutuja 5544 added scroll bar to workphoneformat */}
        <td
          style={
            isScrollable
              ? {
                maxHeight: '100px',
                overflowY: 'auto',
                wordBreak: 'break-word',
                display: 'inline-block',
                width: '100%',
              }
              : {}
          }
        >
          {value || label}
        </td>
        <td>
          {/* for workphineformat used text area */}
          {fieldName === 'workPhoneFormat' ? (
            <textarea
              name={fieldName}
              value={alias} //Rutuja 5523 changed default value to Value and passed alias 
              onChange={this.agencyMappinHandleChange}
            />
          ) : (
            <input
              name={fieldName}
              value={alias} //Rutuja 5523 changed default value to Value and passed alias 
              onChange={this.agencyMappinHandleChange}
            />
          )}
        </td>
      </tr>
    );
  };
  //Rutuja 3478 render table will provide which value should display in the table
  renderTable = () => {
    const defaultFields = [
      { label: 'First Name', fieldName: 'firstName' },
      { label: 'Last Name', fieldName: 'lastName' },
      { label: 'Company Name', fieldName: 'companyName' },
      { label: 'LinkedIn Company Name', fieldName: 'linkedInCompanyName' },
      { label: 'Email', fieldName: 'email' },
      { label: 'Work Phone', fieldName: 'workPhone' },
      { label: 'Work Phone Format', fieldName: 'workPhoneFormat' },
      { label: 'Alternate Phone No', fieldName: 'alternatePhoneNo' }, //Rutuja 5534 added alternatephone number
    ];

    const mappingData = this.state.advertiserMapping[0] || {};
    const { searchQuery, agencyAdvertiserMap } = this.state; //Rutuja 5524 added agencyAdvertiserMap for taking alias name for search query


    //  const filteredFields = defaultFields.filter(({ label, fieldName }) =>
    //    label.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //    (mappingData[fieldName] || '').toLowerCase().includes(searchQuery.toLowerCase())
    //  );
    //Rutuja 5524 added alias in search query
    const filteredFields = defaultFields.filter(({ label, fieldName }) => {
      const alias = agencyAdvertiserMap.length === 0
        ? mappingData[fieldName]
        : agencyAdvertiserMap[0][fieldName];

      // Match search query against label, field value, or alias
      return (
        label.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (mappingData[fieldName] || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (alias || '').toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    return (
      <table className="details-table">
        <thead>
          <tr>
            <th rowspan="2">System Field</th>
            <th colSpan="2">Mapping Field Alias</th>
          </tr>
          <tr>
            <th>Current</th>
            <th>Alias <Tooltip title="By default, the current field name will be used as Alias unless user enter value under Alias" placement="right"><Icon type="question-circle" /></Tooltip></th>
          </tr>
        </thead>
        <tbody>
          {filteredFields.map(({ label, fieldName }) =>
            this.renderRow(label, fieldName, mappingData)
          )}
        </tbody>
      </table>
    );
  };
  //Rutuja 3478 render table will provide which value should display in the table
  renderTable1 = () => {
    const defaultFields = [
      { label: 'Publisher ID', fieldName: 'pID' },
      { label: 'Campaign ID', fieldName: 'campID' },
      { label: 'Campaign Name', fieldName: 'campaignName' },
      { label: 'Lead Interaction Date', fieldName: 'leadInteractionDate' },
      { label: 'Company Employee Size', fieldName: 'companyEmployeeSize' },
      { label: 'Company Revenue', fieldName: 'companyRevenue' },
      { label: 'Industry', fieldName: 'industry' },
      { label: 'Job Title', fieldName: 'jobTitle' },
      { label: 'LinkedIn Job Title', fieldName: 'linkedInJobTitle' },
      { label: 'Job Level', fieldName: 'jobLevel' },
      { label: 'Job Function', fieldName: 'jobFunction' },
      { label: 'LinkedIn', fieldName: 'linkedIn' },
      { label: 'City', fieldName: 'city' },
      { label: 'State', fieldName: 'state' },
      { label: 'Zip Code', fieldName: 'zipCode' },
      { label: 'Address', fieldName: 'address' },
      { label: 'Country', fieldName: 'country' },
      { label: 'Street', fieldName: 'street' },
    ];

    const mappingData = this.state.advertiserMapping[0] || {};
    const { searchQuery, agencyAdvertiserMap } = this.state; //Rutuja 5524 added agencyAdvertiserMap for taking alias name for search query


    //  const filteredFields = defaultFields.filter(({ label, fieldName }) =>
    //    label.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //    (mappingData[fieldName] || '').toLowerCase().includes(searchQuery.toLowerCase())
    //  );
    //Rutuja 5524 added alias in search query
    const filteredFields = defaultFields.filter(({ label, fieldName }) => {
      const alias = agencyAdvertiserMap.length === 0
        ? mappingData[fieldName]
        : agencyAdvertiserMap[0][fieldName];

      // Match search query against label, field value, or alias
      return (
        label.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (mappingData[fieldName] || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (alias || '').toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    return (
      <table className="details-table">
        <thead>
          <tr>
            <th rowspan="2">System Field</th>
            <th colSpan="2">Mapping Field Alias</th>
          </tr>
          <tr>
            <th>Current</th>
            <th>Alias <Tooltip title="By default, the current field name will be used as Alias unless user enter value under Alias" placement="right"><Icon type="question-circle" /></Tooltip></th>
          </tr>
        </thead>
        <tbody>
          {filteredFields.map(({ label, fieldName }) =>
            this.renderRow(label, fieldName, mappingData)
          )}
        </tbody>
      </table>
    );
  };
  //Rutuja 3478 render table will provide which value should display in the table
  renderTable2 = () => {
    const defaultFields = [
      { label: 'Asset Name', fieldName: 'assetName' },
      { label: 'Asset ID', fieldName: 'supportDocID' },
      { label: 'Asset Name Touch 1', fieldName: 'assetNameTouch1' },
      { label: 'Asset Name Touch 2', fieldName: 'assetNameTouch2' },
      { label: 'Asset Name Touch 3', fieldName: 'assetNameTouch3' },
      { label: 'Asset Timestamp Touch 1', fieldName: 'assetTimestampTouch1' },
      { label: 'Asset Timestamp Touch 2', fieldName: 'assetTimestampTouch2' },
      { label: 'Asset Timestamp Touch 3', fieldName: 'assetTimestampTouch3' },
    ];


    const mappingData = this.state.advertiserMapping[0] || {};
    const { searchQuery, agencyAdvertiserMap } = this.state; //Rutuja 5524 added agencyAdvertiserMap for taking alias name for search query


    //  const filteredFields = defaultFields.filter(({ label, fieldName }) =>
    //    label.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //    (mappingData[fieldName] || '').toLowerCase().includes(searchQuery.toLowerCase())
    //  );
    //Rutuja 5524 added alias in search query
    const filteredFields = defaultFields.filter(({ label, fieldName }) => {
      const alias = agencyAdvertiserMap.length === 0
        ? mappingData[fieldName]
        : agencyAdvertiserMap[0][fieldName];

      // Match search query against label, field value, or alias
      return (
        label.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (mappingData[fieldName] || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (alias || '').toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    return (
      <table className="details-table">
        <thead>
          <tr>
            <th rowspan="2">System Field</th>
            <th colSpan="2">Mapping Field Alias</th>
          </tr>
          <tr>
            <th>Current</th>
            <th>Alias <Tooltip title="By default, the current field name will be used as Alias unless user enter value under Alias" placement="right"><Icon type="question-circle" /></Tooltip></th>
          </tr>
        </thead>
        <tbody>
          {filteredFields.map(({ label, fieldName }) =>
            this.renderRow(label, fieldName, mappingData)
          )}
        </tbody>
      </table>
    );
  };
  //Rutuja 3478 render table will provide which value should display in the table

  renderTable3 = () => {

    const defaultFields = [
      { label: 'Extra 1', fieldName: 'extra1' },
      { label: 'Extra 2', fieldName: 'extra2' },
      { label: 'Extra 3', fieldName: 'extra3' },
      { label: 'Extra 4', fieldName: 'extra4' },
      { label: 'Extra 5', fieldName: 'extra5' },
      { label: 'Extra 6', fieldName: 'extra6' },
      { label: 'Extra 7', fieldName: 'extra7' },
      { label: 'Extra 8', fieldName: 'extra8' },
      { label: 'Extra 9', fieldName: 'extra9' },
      { label: 'Extra 10', fieldName: 'extra10' },
      { label: 'Extra 11', fieldName: 'extra11' },
      { label: 'Extra 12', fieldName: 'extra12' },
      { label: 'Extra 13', fieldName: 'extra13' },
      { label: 'Extra 14', fieldName: 'extra14' },
      { label: 'Extra 15', fieldName: 'extra15' },
      { label: 'Extra 16', fieldName: 'extra16' },
      { label: 'Extra 17', fieldName: 'extra17' },
      { label: 'Extra 18', fieldName: 'extra18' },
      { label: 'Extra 19', fieldName: 'extra19' },
      { label: 'Extra 20', fieldName: 'extra20' },
    ];


    // Combine the extra fields from customMappingOtherField (Extra 21 to Extra 30)

    const extraFields =
      Array.isArray(this.state.customMappingOtherField)
        ? this.state.customMappingOtherField.map((field) => {
          const key = Object.keys(field)[0];  // Get the key
          if (key) {
            // Remove spaces and convert to lowercase
            const formattedKey = key.replace(/\s+/g, '').toLowerCase();
            return { label: key, fieldName: formattedKey };
          }

          return null;  // Return null if key is undefined
        }).filter(item => item !== null) // Filter out null values from the array
        : [];

    // Combine default fields and custom extra fields
    const allFields = [...defaultFields, ...extraFields];
    // Mapping data (e.g., advertiser mapping)
    const mappingData = this.state.agencyAdvertiserMap[0] || {};
    const customMappingOtherField = Array.isArray(this.state.customMappingOtherField)
      ? this.state.customMappingOtherField
      : [];

    customMappingOtherField.forEach(field => {
      const label = Object.keys(field)[0];  // Extract the "Extra 21" key
      const fieldName = `extra${label.split(' ')[1]}`;  // Convert "Extra 21" to "extra21"
      mappingData[fieldName] = field[label];  // Add to mappingData, e.g., { extra21: "Extra 21" }

    });
    const { searchQuery } = this.state;
    //  const filteredFields = allFields.filter(({ label, fieldName }) =>
    //    label.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //    (mappingData[fieldName] || '').toLowerCase().includes(searchQuery.toLowerCase())
    //  );

    //Rutuja 5524 added alias in search query
    const filteredFields = allFields.filter(({ label, fieldName }) => {
      const alias = mappingData[`${fieldName}Alias`] || ''; // Get alias value
      return (
        label.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (mappingData[fieldName] || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        alias.toLowerCase().includes(searchQuery.toLowerCase()) // Include alias in filtering
      );
    });
    return (
      <table className="details-table">
        <thead>
          <tr>
            <th rowSpan="2">System Field</th>
            <th colSpan="2">Mapping Field Alias</th>
          </tr>
          <tr>
            <th>Current</th>
            <th>Alias <Tooltip title="By default, the current field name will be used as Alias unless user enter value under Alias" placement="right"><Icon type="question-circle" /></Tooltip></th>
          </tr>
        </thead>
        <tbody>
          {filteredFields.map(({ label, fieldName }) =>
            this.renderRow(label, fieldName, mappingData)
          )}
        </tbody>
      </table>
    );
  };


  //Rutuja 3478 render table will provide which value should display in the table
  renderTable4 = () => {
    const defaultFields = [
      { label: 'IP', fieldName: 'ip' },
      { label: 'Domain', fieldName: 'domain' },
      { label: 'Comments', fieldName: 'comments' },
      { label: 'Channel', fieldName: 'channel' },
    ];


    const mappingData = this.state.advertiserMapping[0] || {};
    const { searchQuery, agencyAdvertiserMap } = this.state; //Rutuja 5524 added agencyAdvertiserMap for taking alias name for search query


    //  const filteredFields = defaultFields.filter(({ label, fieldName }) =>
    //    label.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //    (mappingData[fieldName] || '').toLowerCase().includes(searchQuery.toLowerCase())
    //  );
    //Rutuja 5524 added alias in search query
    const filteredFields = defaultFields.filter(({ label, fieldName }) => {
      const alias = agencyAdvertiserMap.length === 0
        ? mappingData[fieldName]
        : agencyAdvertiserMap[0][fieldName];

      // Match search query against label, field value, or alias
      return (
        label.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (mappingData[fieldName] || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (alias || '').toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    return (
      <table className="details-table">
        <thead>
          <tr>
            <th rowspan="2">System Field</th>
            <th colSpan="2">Mapping Field Alias</th>
          </tr>
          <tr>
            <th>Current</th>
            <th>Alias <Tooltip title="By default, the current field name will be used as Alias unless user enter value under Alias" placement="right"><Icon type="question-circle" /></Tooltip></th>
          </tr>
        </thead>
        <tbody>
          {filteredFields.map(({ label, fieldName }) =>
            this.renderRow(label, fieldName, mappingData)
          )}
        </tbody>
      </table>
    );
  };

  //Rutuja 3478 declared  fieldsToDisplay array for showing only fields in array after clicking on view format
  render() {
    const fieldsToDisplay = [
      { key: "firstName", label: "First Name" },
      { key: "lastName", label: "Last Name" },
      { key: "companyName", label: "Company Name" },
      { key: "linkedInCompanyName", label: "LinkedIn Company Name" },
      { key: "email", label: "Email" },
      { key: "workPhone", label: "Work Phone" },
      { key: "workPhoneFormat", label: "Work Phone Format" },
      { key: "alternatePhoneNo", label: "Alternate Phone No" }, //Rutuja 5534 added Alternate Phone No
      { key: "pID", label: "Publisher ID" },
      { key: "campID", label: "Campaign ID" },
      { key: "campaignName", label: "Campaign Name" },
      { key: "leadInteractionDate", label: "Lead Interaction Date" },
      { key: "companyEmployeeSize", label: "Company Employee Size" },
      { key: "companyRevenue", label: "Company Revenue" },
      { key: "industry", label: "Industry" },
      { key: "jobTitle", label: "Job Title" },
      { key: "linkedInJobTitle", label: "LinkedIn Job Title" },
      { key: "jobLevel", label: "Job Level" },
      { key: "jobFunction", label: "Job Function" },
      { key: "linkedIn", label: "LinkedIn" },
      { key: "city", label: "City" },
      { key: "state", label: "State" },
      { key: "zipCode", label: "Zip Code" },
      { key: "address", label: "Address" },
      { key: "country", label: "Country" },
      { key: "street", label: "Street" },
      { key: "assetName", label: "Asset Name" },
      { key: "supportDocID", label: "Asset ID" },
      { key: "assetNameTouch1", label: "Asset Name Touch 1" },
      { key: "assetNameTouch2", label: "Asset Name Touch 2" },
      { key: "assetNameTouch3", label: "Asset Name Touch 3" },
      { key: "assetTimestampTouch1", label: "Asset Timestamp Touch 1" },
      { key: "assetTimestampTouch2", label: "Asset Timestamp Touch 2" },
      { key: "assetTimestampTouch3", label: "Asset Timestamp Touch 3" },
      { key: "extra1", label: "Extra 1" },
      { key: "extra2", label: "Extra 2" },
      { key: "extra3", label: "Extra 3" },
      { key: "extra4", label: "Extra 4" },
      { key: "extra5", label: "Extra 5" },
      { key: "extra6", label: "Extra 6" },
      { key: "extra7", label: "Extra 7" },
      { key: "extra8", label: "Extra 8" },
      { key: "extra9", label: "Extra 9" },
      { key: "extra10", label: "Extra 10" },
      { key: "extra11", label: "Extra 11" },
      { key: "extra12", label: "Extra 12" },
      { key: "extra13", label: "Extra 13" },
      { key: "extra14", label: "Extra 14" },
      { key: "extra15", label: "Extra 15" },
      { key: "extra16", label: "Extra 16" },
      { key: "extra17", label: "Extra 17" },
      { key: "extra18", label: "Extra 18" },
      { key: "extra19", label: "Extra 19" },
      { key: "extra20", label: "Extra 20" },
      { key: "extra21", label: "Extra 21" },
      { key: "extra22", label: "Extra 22" },
      { key: "extra23", label: "Extra 23" },
      { key: "extra24", label: "Extra 24" },
      { key: "extra25", label: "Extra 25" },
      { key: "extra26", label: "Extra 26" },
      { key: "extra27", label: "Extra 27" },
      { key: "extra28", label: "Extra 28" },
      { key: "extra29", label: "Extra 29" },
      { key: "extra30", label: "Extra 30" },
      { key: "ip", label: "IP" },
      { key: "domain", label: "Domain" },
      { key: "comments", label: "Comments" },
      { key: "channel", label: "Channel" }
    ];

    return (
      <div>
        <Navigation />


        {/* rutuja 5544 added overflow auto */}
        <div class="container-fluid" style={{
          paddingTop: '10px', marginBottom: '90px', zIndex: "1", overflow: 'auto',
          height: '100vh',
        }}>
          <div
            style={{
              color: "#056eb8",
              flex: "0 0 auto",
              position: "sticky",
              top: "85px", // Adjust for container's sticky position
              zIndex: "1000",
              marginBottom: '140px', //Rutuja 5544 changed botton margin from 60 to 140
              flex: "0 0 auto",
              backgroundColor: "rgb(245 245 245)",
            }}
          >
            <div
              class="container-fluid fixed-top col-xs-12 col-sm-12 col-md-1 col-lg-1"
              style={{
                color: "#056eb8",
                flex: "0 0 auto",
                backgroundColor: "rgb(245 245 245)",
                position: "sticky",
              }}
            >
              {/* Chaitanya-4984-Changed alignment of Back button */}
              <a
                href="#/"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push("/newdashboard", {
                    agencyID: this.state.agencyID,
                  });
                }}
                style={{
                  color: "#056eb8",
                  flex: "0 0 auto",
                  backgroundColor: "rgb(245 245 245)",
                  position: "sticky",
                }}
              >
                <FaArrowAltCircleLeft
                  size={32}
                  style={{ marginLeft: "15%", marginTop: "5%" }}
                  title="Back to Dashboard"
                />
              </a>
            </div>
            <div

              id="labelDI"
              class="container-fluid fixed-top col-xs-12 col-sm-12 col-md-11 col-lg-11"
              style={{
                paddingRight: "10%",
                justifyContent: "center", // Horizontal centering
                alignItems: "center",
                flex: "0 0 auto",
                backgroundColor: "rgb(245 245 245)",
                position: "sticky",
              }}
            >
              Customized Column Mapping
            </div>
          </div>
          <div className="card card-signin my-1"
            style={{
              //  marginLeft: "8%", // Adjust for consistent left spacing
              //  marginRight: "5%",
              marginTop: "10%",
              position: "sticky", // Sticky positioning
              top: "100px", // Rutuja 5544 changed top to 100px Adjust to prevent overlapping with container
              zIndex: 999, // Slightly lower z-index to prioritize header
              background: "#fff",
              width: "85%",
              marginLeft: "auto",
              marginRight: "auto",

            }}>
            <div className="card-body" style={{ padding: "10px" }} >

              {this.state.length === 0 ? (
                <span>
                  <div
                    className="d-flex flex-wrap align-items-center"
                    style={{
                      gap: "10px", padding: "10px", marginTop: "-20px",
                      marginBottom: "-20px", marginLeft: "35px"
                    }} >
                    {/* Advertiser Name */}
                    <div className="d-flex flex-column" id="adv">
                      <label id="label" style={{ fontWeight: "bold" }}>Advertiser Name <span style={{ color: 'red' }}> *</span></label>
                      <select
                        id="clientName"
                        name="clientName"
                        onChange={this.handleClientChange}
                        className="form-control"
                        style={{ width: "90%" }}
                        value={this.state.stectedClientID || ""}
                        required
                      >
                        <option value="" disabled selected>
                          Select Advertiser
                        </option>
                        {this.state.clientDetails.map((clientDetails) => (
                          <option key={clientDetails.clientID} value={clientDetails.clientID}>
                            {clientDetails.clientName}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* Format */}
                    <div className="d-flex flex-column" id="dropdown">
                      <label id="label" style={{ fontWeight: "bold" }}>Format</label>
                      <select
                        id="format"
                        name="format"
                        className="form-control"
                        style={{ height: "34px" }}
                        value={this.state.format}
                        onChange={this.handleFormatChange}
                        required
                      >
                        <option value="Format 1">Format 1</option>
                      </select>
                    </div>

                    {/* View Format */}
                    <div className="d-flex align-items-center" style={{ marginTop: "20px" }} id="save 1" >
                      <img
                        src="View_Format.png"
                        alt="View Format"
                        width="30" //Rutuja 5523 
                        height="30" //Rutuja 5523
                        style={{
                          filter: "grayscale(50%)", // Faint grayscale effect
                          opacity: "0.5", marginLeft: "35px"
                        }} //new bug  50

                      />
                      {/* Rutuja task 5523 changed marginright size  */}
                      <label style={{ fontWeight: "bold", marginRight: "50px", fontSize: "14px", marginTop: "5px" }} >View Format</label>
                    </div>

                    {/* Multilingual Campaign */}
                    <div className="d-flex align-items-center" id="save" style={{ marginTop: "15px" }}>
                      <input
                        type="checkbox"
                        checked={this.state.nonEnglishChecked === "Yes"}
                        onChange={this.nonEnglishChange}
                        id="checkboxtick"
                        style={{ height: "20px", width: "20px" }}
                        disabled
                      />
                      <label style={{ fontWeight: "bold", marginLeft: "10px", fontSize: "14px", marginTop: "10px" }}>
                        Multilingual Format
                      </label>
                    </div>

                    {/* Save Mapping Button */}
                    <div className="d-flex align-items-center" id="save" style={{ marginTop: "50px" }}>
                      <button
                        className="btn add-button"
                        style={{ width: "130px" }}
                        onClick={this.getMappin}
                      >
                        Save Mapping
                      </button>
                    </div>

                  </div>

                </span>
              ) : (<span>
                <div
                  className="d-flex flex-wrap align-items-center"
                  style={{
                    gap: "10px",
                    padding: "10px",
                    marginLeft: "35px",
                    marginTop: "-20px",
                    marginBottom: "-20px"
                  }}
                >
                  {/* Advertiser Name */}
                  <div className="d-flex flex-column" id="adv">
                    <label id="label" style={{ fontWeight: "bold" }}>
                      Advertiser Name <span style={{ color: 'red' }}> *</span>
                    </label>
                    <select
                      id="clientName"
                      name="clientName"
                      onChange={this.handleClientChange}
                      className="form-control"
                      value={this.state.stectedClientID || ""}
                      style={{ height: "34px", width: "90%" }}//Rutuja 5523 added width select box
                      required
                    >
                      <option value="" disabled selected>
                        Select Advertiser
                      </option>
                      {this.state.clientDetails.map((clientDetails) => (
                        <option key={clientDetails.clientID} value={clientDetails.clientID}>
                          {clientDetails.clientName}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Format */}
                  <div className="d-flex flex-column" id="dropdown" style={{ width: "15%" }}>
                    <label id="label" style={{ fontWeight: "bold" }}>
                      Format
                    </label>
                    <select
                      id="format"
                      name="format"
                      class="form-control"
                      style={{ height: "34px" }}
                      value={this.state.format}
                      onChange={this.handleFormatChange}
                      required
                    >
                      {this.state.mappingFormat.map((mappingFormat) => {
                        return (
                          <option value={mappingFormat.format}>
                            {mappingFormat.format}
                          </option>
                        );
                      })}
                      <option
                        value={
                          "Format " + (this.state.mappingFormat.length + 1)
                        }
                      >
                        Format {this.state.mappingFormat.length + 1}
                      </option>
                    </select>

                  </div>
                  {/* Rutuja task 5523 added condtion if new format then disabled the view format */}
                  {this.state.mappingFormat.length + 1 == this.state.formatValue ? (

                    <div className="d-flex align-items-center" style={{ marginTop: "22px" }} id="save 1" >
                      <img
                        src="View_Format.png"
                        alt="View Format"
                        width="30" //Rutuja 5523 changes img size
                        height="30"//Rutuja 5523 changes img size
                        style={{
                          filter: "grayscale(50%)", // Faint grayscale effect
                          opacity: "0.5", marginLeft: "35px"
                        }}

                      />
                      {/* //Rutuja 5523 changes marginright and font size */}
                      <label style={{ fontWeight: "bold", marginRight: "50px", fontSize: "14px", marginTop: "5px" }} >View Format</label>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center" id="save 1" style={{ marginTop: "22px" }}>
                      <img
                        src="View_Format.png"
                        alt="View Format"
                        width="30"
                        height="30"
                        style={{ cursor: "pointer", marginLeft: "35px" }}
                        data-toggle="modal"
                        data-target="#customizeMappingAdd"
                        onClick={this.handleViewFormat}
                      />
                      <label

                        style={{ fontWeight: "bold", cursor: "pointer", marginRight: "50px", fontSize: "14px", marginTop: "5px" }}
                        data-toggle="modal"
                        data-target="#customizeMappingAdd"
                        onClick={this.handleViewFormat}

                      >
                        View Format
                      </label>

                      {/* RUtuja 3478 added Modal after clicking on view format modal will open */}
                      <div
                        className="modal fade"
                        id="customizeMappingAdd"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="customizeMappingLabel"
                        aria-hidden="true"
                        style={{ marginTop: "200px" }}>
                        <div class="modal-dialog modal-md">
                          <div class="modal-content" style={{
                            border: "2px solid lightgrey", // Black border around modal
                            borderRadius: "3px", // Optional: Adds rounded corners
                          }}>
                            <div class="modal-header custom-classPopup">
                              <h4 style={{ color: "#ffffff" }}>
                                Customize mapping
                                <button
                                  type="button"
                                  class="cancel-button"
                                  data-dismiss="modal"
                                  style={{
                                    fontSize: 15,
                                    color: "#ffffff",
                                    float: "right",
                                  }}
                                >
                                  &times;
                                </button>
                              </h4>
                            </div>
                            <br />
                            <h5 style={{ textAlign: "center" }}>&nbsp; {this.state.advertiserName}&nbsp; - &nbsp; {this.state.format} </h5>

                            <div
                              align="center"
                              style={{
                                overflowY: "scroll",
                                height: "250px",
                                position: " relative",
                              }}>

                              <table
                                class="table table-bordered"
                                id="myTable3"
                                style={{
                                  width: "85%",
                                  position: " relative",
                                }}>
                                <thead>
                                  <tr>
                                    <th
                                      style={{
                                        position: "sticky",
                                        color: "#707070",
                                        height: "35px",
                                        backgroundColor:
                                          " rgba(126, 127, 129, 0.22)",
                                      }}>
                                      &nbsp;System Field
                                    </th>
                                    <th
                                      style={{
                                        position: "sticky",
                                        color: "#707070",
                                        height: "35px",
                                        backgroundColor:
                                          " rgba(126, 127, 129, 0.22)",
                                      }}>
                                      &nbsp;Mapping Alias Field{" "}
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {this.state.advertiserMapping.map((mappingFormat, index) =>
                                    fieldsToDisplay
                                      .filter((field) => mappingFormat[field.key]) // Filter out fields with null or empty values
                                      .map((field) => (
                                        <tr key={`${index}-${field.key}`}>
                                          <td>&nbsp;{field.label}</td>
                                          <td
                                            style={{
                                              paddingBottom: "10px",
                                            }}
                                          >
                                            {mappingFormat[field.key]} {/* Display the field value */}
                                          </td>
                                        </tr>
                                      ))
                                  )}
                                </tbody>


                              </table>
                            </div>
                            <br />
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>)}




                  {/* Multilingual Campaign */}
                  <div className="d-flex align-items-center" id="save" style={{ marginTop: "18px" }}>
                    <input
                      type="checkbox"
                      checked={this.state.nonEnglishChecked === "Yes"}
                      onChange={this.nonEnglishChange}
                      id="checkboxtick"
                      style={{ height: "20px", width: "20px" }}
                      disabled
                    />
                    <label style={{ fontWeight: "bold", marginLeft: "10px", fontSize: "14px", marginTop: "10px" }}>
                      Multilingual Format
                    </label>
                  </div>

                  {/* Save Mapping Button */}
                  <div className="d-flex align-items-center" id="save" style={{ marginTop: "50px" }}
                  >
                    <button
                      className="btn add-button"
                      style={{ width: "130px" }}
                      onClick={this.getMappin}
                    >
                      Save Mapping
                    </button>
                  </div>
                </div>

              </span>)}
              <div class="col-lg-12"
                style={{ display: this.state.clientNameDisplay, marginTop: "-20px" }}
                className="errorMessage"
              >
                Please select advertiser name
              </div>
            </div>
          </div>
           {/* Priyanka 5549 added loader */}
         <div
              align="center"
              id={this.state.loader_progress}
            ></div>
          <div className="card card-signin my-1"
            style={{
              // marginLeft: "8%", // Adjust for consistent left spacing
              // marginRight: "5%",
              marginTop: "15%",
              position: "sticky",
              width: "85%",
              marginLeft: "auto",
              marginRight: "auto",
            }}>
            <div className="card-body" >
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1"></div>
                {/* karan-task-3477-added column coz cannot add more than 1 message in earlier design */}
                <div class="col-xs-12 col-sm-12 col-md-10 col-lg-9">
                  {/* <span
                       style={{ display: this.state.clientNameDisplay }}
                       className="errorMessage"
                     >
                       Please select advertiser name
                     </span> */}
                  {/* //snehal-task-3090-Add validation for customize mapping- User can't add alias name only number(validation message) */}
                  <span
                    style={{ display: this.state.validationDisplay }}
                    className="errorMessage"
                  >
                    Please enter valid alias names
                  </span>
                  {/* //rutuja task - 4779 added error message for all fields are mandetory */}
                  <span
                    style={{ display: this.state.validationDisplay2 }}
                    className="errorMessage"
                  >
                    All Fields are Mandatory
                  </span>
                  {/*//Sanjana-3897-Customize mapping validation for duplicate alias name*/}
                  <span
                    style={{ display: this.state.validationDisplay1 }}
                    className="errorMessage"
                  >
                    Duplicate alias names found
                  </span>
                  {/* Rutuja Task 4832 shown array elements if duplicate alais name fount */}
                  <p className="errorMessage">
                    {this.state.duplicateAliasName.length == 0 ? (
                      <b>{""}</b>
                    ) : (
                      <b> {this.state.duplicateAliasName.join(", ")} </b>
                    )}
                  </p>
                  {/* 4832 added error msg with array if system fileds are used under other fields */}
                  <span
                    style={{ display: this.state.validationDisplay3 }}
                    className="errorMessage"
                  >
                    Please make sure alias names should be identical to an
                    system fields
                  </span>
                  <p className="errorMessage">
                    {this.state.systemFields.length == 0 ? (
                      <b>{""}</b>
                    ) : (
                      <b> {this.state.systemFields.join(", ")} </b>
                    )}
                  </p>

                  {/* karan-task-3477-work phone format error 1 */}
                  {this.state.workPhoneFormatError && (
                    <span
                      className="errorMessage"
                      style={{ display: "block" }}
                    >
                      Invalid work phone format
                    </span>
                  )}
                  {/* karan-task-3477-work phone format error 2 */}
                  {this.state.duplicateWorkPhoneFormatError && (
                    <span
                      className="errorMessage"
                      style={{ display: "block" }}
                    >
                      Duplicate work phone format found
                    </span>
                  )}
                </div>

                <div class="col-xs-12 col-sm-12 col-md-12  col-lg-2 col-xl-2">
                  <input
                    id="search-bar1"
                    type="text"
                    onkeyup="myFunction()"
                    style={{
                      backgroundImage: "url(Group_294.png)",
                      backgroundPosition: "15px 6px",
                      backgroundSize: "15px 15px",
                      backgroundRepeat: "no-repeat",
                      width: "100%",
                      height: "20px",
                      fontSize: "10px",
                      border: "1px solid #ddd",
                      padding: "12px 20px 12px 34px",
                      borderRadius: "25px",
                      backgroundColor: "rgb(221, 221, 221)",
                    }}
                    onChange={this.handleChangeSearch}
                    onPressEnter={this.handleChangeSearch}
                    placeholder="Search..."
                    title="Type in a name"
                  />
                </div>
              </div>
              {/* rutuja 5544 added scroll bar and give height 300 */}
              <div style={{ marginTop: "20px", marginBottom: "20px", maxHeight: "250px", overflowY: "auto", }}  >
                <div className="accordion">
                  <div className="accordion-header" onClick={this.toggleAccordion}>
                    <div>
                      <img
                        style={{ width: '32px', height: '32px' }}
                        src="BasicDetails.PNG"
                        alt="Basic Details"
                      />
                      <span style={{ flex: 1, fontSize: '16px', fontWeight: 'bold' }}>
                        Basic Details
                      </span>
                    </div>
                    <div>
                      <img
                        src="down.png"
                        style={{ width: '28px', height: '28px' }}
                        alt="Toggle Icon"
                        className="toggle-icon"
                      />
                    </div>
                  </div>
                  <div className="accordion-content">
                    {this.state.length === 0 ? (
                      <span>{this.renderTable()}</span>
                    ) : (
                      <span>{this.renderTable()}</span>
                    )}
                  </div>
                </div>

                <div className="accordion">
                  <div className="accordion-header" onClick={this.toggleAccordion}>
                    <div>
                      <img
                        style={{ width: '32px', height: '32px' }}
                        src="CampSpec.png"
                        alt=" Campaign Specification"
                      />
                      <span style={{ flex: 1, fontSize: '16px', fontWeight: 'bold' }}>
                        Campaign Specification
                      </span>
                    </div>
                    <div>
                      <img
                        src="down.png"
                        style={{ width: '28px', height: '28px' }}
                        alt="Toggle Icon"
                        className="toggle-icon"
                      />
                    </div>
                  </div>
                  <div className="accordion-content">
                    {this.state.length === 0 ? (
                      <span>{this.renderTable1()}</span>
                    ) : (
                      <span>{this.renderTable1()}</span>
                    )}
                  </div>
                </div>

                <div className="accordion">
                  <div className="accordion-header" onClick={this.toggleAccordion}>
                    <div>
                      <img
                        style={{ width: '32px', height: '32px' }}
                        src="supporting_documents_step2.png"
                        alt="Asset Details"
                      />
                      <span style={{ flex: 1, fontSize: '16px', fontWeight: 'bold' }}>
                        Asset Details
                      </span>
                    </div>
                    <div>
                      <img
                        src="down.png"
                        style={{ width: '28px', height: '28px' }}
                        alt="Toggle Icon"
                        className="toggle-icon"
                      />
                    </div>
                  </div>
                  <div className="accordion-content">
                    {this.state.length === 0 ? (
                      <span>{this.renderTable2()}</span>
                    ) : (
                      <span>{this.renderTable2()}</span>
                    )}
                  </div>
                </div>

                <div className="accordion">
                  <div className="accordion-header" onClick={this.toggleAccordion}>
                    <div>
                      <img
                        style={{ width: '32px', height: '32px' }}
                        src="incremental.jpg"
                        alt="Extra Fields"
                      />
                      <span style={{ flex: 1, fontSize: '16px', fontWeight: 'bold' }}>
                        Extra Fields
                      </span>
                    </div>
                    <div>
                      <img
                        src="down.png"
                        style={{ width: '28px', height: '28px' }}
                        alt="Toggle Icon"
                        className="toggle-icon"
                      />
                    </div>
                  </div>
                  <div className="accordion-content">
                    {this.renderTable3()}
                    <button
                      onClick={this.addCustomMappingField}
                      disabled={this.state.addedFields >= 10}
                      style={{
                        backgroundColor: "#e0e0e0",
                        padding: "10px",
                        width: "100%",
                        marginRight: "10px",
                        fontWeight: "bold",
                        marginTop: "10px",
                        alignItems: "center",
                        border: 'none',
                        cursor: this.state.customMappingOtherField.length >= 10 ? 'not-allowed' : 'pointer',
                        opacity: this.state.customMappingOtherField.length === 10 ? 0.5 : 1,
                        outline: 'none',
                      }}
                    >
                      {this.state.customMappingOtherField.length >= 10
                        ? 'Max limit to add Extra Fields up to 10. You cannot exceed this limit.'
                        : '+ Add one more extra field'}
                    </button>
                  </div>
                </div>
                <div className="accordion">
                  <div className="accordion-header" onClick={this.toggleAccordion}>
                    <div>
                      <img
                        style={{ width: '32px', height: '32px' }}
                        src="Other.png"
                        alt="Other Details"
                      />
                      <span style={{ flex: 1, fontSize: '16px', fontWeight: 'bold' }}>
                        Other Fields
                      </span>
                    </div>
                    <div>
                      <img
                        src="down.png"
                        style={{ width: '28px', height: '28px' }}
                        alt="Toggle Icon"
                        className="toggle-icon"
                      />
                    </div>
                  </div>
                  <div className="accordion-content">
                    {this.state.length === 0 ? (
                      <span>{this.renderTable4()}</span>
                    ) : (
                      <span>{this.renderTable4()}</span>
                    )}
                  </div>
                </div>




              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  } // end of render
} // end of class

/**
 * @author Narendra Phadke
 * @param  Description handle the login authentication
 * @return Description return All details of authentication
 */
CustomMapping.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(
  withRouter(CustomMapping)
);
